import { OperationScenarioCategory } from "@dtm-frontend/shared/ui";
import { AddingPermissionsOperatorFilterParams } from "./adding-permissions-operator.models";
import { AddingPermissionsPilotFilterParams } from "./adding-permissions-pilot.models";

export enum AddingPermissionsTabType {
    Operators = 0,
    Pilots = 1,
}

export enum AddingPermissionsFiltersTabKeys {
    OperatorsFilters = "operatorsFilters",
    PilotsFilters = "pilotsFilters",
}

export enum AddingPermissionsFilterFormKeys {
    SearchPhrase = "searchPhrase",
    ActiveTabIndex = "activeTabIndex",
}

export type AddingPermissionsFilterParams = AddingPermissionsPilotFilterParams | AddingPermissionsOperatorFilterParams;

export interface PermissionChange {
    id: string;
    permission: boolean;
}

export interface PilotOperatorQualification {
    name: string;
    category: OperationScenarioCategory;
    expirationDate: Date;
}
