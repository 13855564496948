import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface TimeTileComponentState {
    isSelected: boolean;
}

@Component({
    selector: "supervisor-shared-lib-time-tile",
    templateUrl: "./time-tile.component.html",
    styleUrls: ["./time-tile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TimeTileComponent {
    @Input() public set isSelected(value: BooleanInput) {
        this.localStore.patchState({ isSelected: coerceBooleanProperty(value) });
    }
    protected readonly isSelected$ = this.localStore.selectByKey("isSelected");

    constructor(private readonly localStore: LocalComponentStore<TimeTileComponentState>) {
        this.localStore.setState({
            isSelected: false,
        });
    }
}
