<ng-container
    *ngrxLet="{
        weatherViewMode: weatherViewMode$,
        openedPanel: openedPanel$,
        routes: routes$,
        areAreaBuffersProcessing: areAreaBuffersProcessing$,
        shouldShowAreaBuffers: shouldShowAreaBuffers$,
        viewControls: viewControls$,
        viewControlsValue: viewControlsValue$,
        mapLayers: mapLayers$,
        mapLayersValue: mapLayersValue$,
        weatherMissionStatus: weatherMissionStatus$,
        selectedZoneId: selectedZoneId$,
        isGeoZonesInfoEnabled: isGeoZonesInfoEnabled$,
        geoZonesWithInfo: geoZonesWithInfo$,
        geoZonesControls: geoZonesControls$,
        missionTimeRange: missionTimeRange$,
        isKmlImportEnabled: isKmlImportEnabled$,
        kmlFiles: kmlFiles$,
        globalOpacity: globalOpacity$,
        selectedZoneTimesSetting: selectedZoneTimesSetting$,
        isZoneInfoError: isZoneInfoError$
    } as vm"
>
    <mat-progress-bar *ngIf="isGeoZonesDataLoading$ | ngrxPush" mode="indeterminate" class="geo-zones-loader"></mat-progress-bar>
    <div class="layers-controls" [class.control-opened]="vm.openedPanel">
        <div class="action-container">
            <button
                type="button"
                class="toggle-button spatial-data-button"
                [class.opened]="vm.openedPanel === ControlPanel.GeoZones"
                (click)="togglePanel(ControlPanel.GeoZones)"
            >
                <dtm-ui-icon
                    name="cylinder-line"
                    class="spatial-data-icon"
                    [class.active]="
                        vm.geoZonesControls.geoZonesSettings?.areGeoZonesEnabled ||
                        vm.geoZonesControls.localSpatialInfoForDtmSettings?.isLocalSpatialInfoForDtmEnabled
                    "
                ></dtm-ui-icon>
                <ng-container
                    *ngIf="
                        !vm.geoZonesControls.geoZonesSettings?.areGeoZonesEnabled &&
                        !vm.geoZonesControls.localSpatialInfoForDtmSettings?.isLocalSpatialInfoForDtmEnabled
                    "
                >
                    {{ "dtmMapCesium.spatialDataTitle" | transloco }}
                </ng-container>
                <p
                    *ngIf="
                        vm.geoZonesControls.geoZonesSettings?.areGeoZonesEnabled ||
                        vm.geoZonesControls.localSpatialInfoForDtmSettings?.isLocalSpatialInfoForDtmEnabled
                    "
                >
                    {{ "dtmMapCesium.spatialDataTitle" | transloco : { value: vm.selectedZoneTimesSetting } }}
                    <span>
                        {{
                            "dtmMapCesium.spatialDataHeightInfoLabel"
                                | transloco : { value: vm.geoZonesControls.geoZonesFilters?.zoneHeight | localizeNumber }
                        }}{{
                            vm.selectedZoneTimesSetting === ZoneTimesSetting.CustomTimeRange &&
                            vm.geoZonesControls.geoZonesFilters &&
                            vm.geoZonesControls.geoZonesFilters.timeFrom &&
                            vm.geoZonesControls.geoZonesFilters.timeTo
                                ? "," +
                                  (vm.geoZonesControls.geoZonesFilters.timeFrom | datesDiff : vm.geoZonesControls.geoZonesFilters.timeTo)
                                : ""
                        }}
                    </span>
                </p>
                <dtm-ui-icon [name]="vm.openedPanel === ControlPanel.GeoZones ? 'arrow-up' : 'arrow-down'" class="arrow-icon"></dtm-ui-icon>
            </button>
            <div class="toggle-container" *ngIf="vm.shouldShowAreaBuffers">
                <button
                    type="button"
                    class="toggle-button"
                    [class.opened]="vm.openedPanel === ControlPanel.MissionViewSettings"
                    (click)="togglePanel(ControlPanel.MissionViewSettings)"
                >
                    <dtm-ui-icon name="route"></dtm-ui-icon>
                    {{ "dtmMapCesium.missionViewControls.title" | transloco }}
                    <dtm-ui-icon [name]="vm.openedPanel === ControlPanel.MissionViewSettings ? 'arrow-up' : 'arrow-down'"></dtm-ui-icon>
                </button>
                <div class="controls-container" [class.visible]="vm.openedPanel === ControlPanel.MissionViewSettings">
                    <dtm-map-mission-view-settings
                        [viewControls]="vm.viewControls"
                        [viewControlsValue]="vm.viewControlsValue"
                        (viewControlsValueChange)="updateViewControlsValue($event)"
                    ></dtm-map-mission-view-settings>
                </div>
            </div>
            <div class="toggle-container">
                <button
                    type="button"
                    class="toggle-button"
                    [class.opened]="vm.openedPanel === ControlPanel.MapLayers"
                    (click)="togglePanel(ControlPanel.MapLayers)"
                >
                    <dtm-ui-icon name="stack-line"></dtm-ui-icon>
                    {{ "dtmMapCesium.mapLayersSelector.title" | transloco }}
                    <dtm-ui-icon [name]="vm.openedPanel === ControlPanel.MapLayers ? 'arrow-up' : 'arrow-down'"></dtm-ui-icon>
                </button>
            </div>
            <button
                type="button"
                class="toggle-button"
                *ngIf="vm.weatherViewMode !== WeatherViewMode.Hide"
                [class.opened]="vm.openedPanel === ControlPanel.Weather"
                (click)="togglePanel(ControlPanel.Weather)"
            >
                <div
                    *ngIf="weatherMissionStatus$ | ngrxPush as weatherStatus"
                    [ngClass]="['status', weatherStatus]"
                    [class.pulse]="weatherStatus === WeatherStatus.Danger || weatherStatus === WeatherStatus.Warning"
                ></div>
                <dtm-ui-icon name="rainy"></dtm-ui-icon>
                {{ "dtmMapCesium.weatherLabel" | transloco }}
                <dtm-ui-icon [name]="vm.openedPanel == ControlPanel.Weather ? 'arrow-up' : 'arrow-down'"></dtm-ui-icon>
            </button>
            <div class="toggle-container" *ngIf="vm.isKmlImportEnabled && vm.kmlFiles.length">
                <button type="button" class="toggle-button" (click)="togglePanel(ControlPanel.KmlControls)">
                    <dtm-ui-icon
                        #kmlViewIcon
                        name="attachment"
                        class="kml-icon"
                        [class.disabled]="vm.kmlFiles | invoke : isKmlIconDisabled"
                    ></dtm-ui-icon>
                    {{ "dtmMapCesium.kmlViewSettings.mapButtonLabel" | transloco }}
                    <dtm-ui-icon [name]="vm.openedPanel === ControlPanel.KmlControls ? 'arrow-up' : 'arrow-down'"></dtm-ui-icon>
                </button>
                <div class="controls-container" [class.visible]="vm.openedPanel === ControlPanel.KmlControls">
                    <dtm-map-kml-controls
                        [kmlDataSet]="vm.kmlFiles"
                        (allKmlDataDelete)="kmlControlService.deleteAllKmlData()"
                        (kmlImportModalOpen)="kmlControlService.openKmlImportModal()"
                        (visibilityChange)="kmlViewIcon.classList.toggle('disabled', !$event); kmlControlService.refreshView()"
                        (kmlDataZoom)="kmlControlService.zoomToKmlData($event)"
                    ></dtm-map-kml-controls>
                </div>
            </div>
            <div class="additional-features-container toggle-container" *ngIf="vm.isKmlImportEnabled">
                <button
                    type="button"
                    class="button-icon menu-button toggle-button"
                    [matMenuTriggerFor]="additionalFeatures"
                    [attr.aria-label]="'dtmMapCesium.additionalFeaturesButtonArialLabel' | transloco"
                >
                    <dtm-ui-icon name="more"></dtm-ui-icon>
                </button>
                <mat-menu #additionalFeatures="matMenu" xPosition="before">
                    <button mat-menu-item type="button" (click)="openKmlImportModal()">
                        <dtm-ui-icon name="upload"></dtm-ui-icon>
                        {{ "dtmMapCesium.kmlImport.importButtonLabel" | transloco }}
                    </button>
                </mat-menu>
            </div>
        </div>
        <div class="controls-container" [class.visible]="vm.openedPanel === ControlPanel.GeoZones">
            <dtm-map-geographical-zones-controls
                @slideIn
                [missionTimeRange]="vm.missionTimeRange"
                [timeSettingsOptions]="timeSettingsOptions$ | ngrxPush"
                [aupEndTime]="aupEndTime$ | ngrxPush"
                (geoZonesFilterSettingsUpdate)="handeGeographicalZonesFilterSettingsUpdate($event)"
                (watchZonesSettingUpdate)="onWatchZonesSettingUpdate($event)"
                (opacitySettingUpdate)="setGlobalOpacity($event)"
                (selectedZoneTimeSettingUpdate)="setZoneTimesSetting($event)"
            ></dtm-map-geographical-zones-controls>
        </div>
        <div class="controls-container" [class.visible]="vm.openedPanel === ControlPanel.Weather">
            <dtm-map-weather
                [weatherList]="weatherRangeList$ | ngrxPush"
                [weatherByMissionTime]="weatherByMissionTime$ | ngrxPush"
                [weatherViewMode]="vm.weatherViewMode"
                [selectedWeatherRangeIndex]="selectedWeatherRangeIndex$ | ngrxPush"
                [isProcessing]="isWeatherProcessing$ | ngrxPush"
                [isWithinDtm]="isWithinDtm$ | ngrxPush"
                [forecastRefTime]="forecastRefTime$ | ngrxPush"
                [supportedWeatherZones]="supportedWeatherZones$ | ngrxPush"
                (zoneSelected)="selectZone($event)"
            ></dtm-map-weather>
        </div>
        <div class="controls-container" [class.visible]="vm.openedPanel === ControlPanel.MapLayers">
            <dtm-map-layers-selector
                [mapLayers]="vm.mapLayers"
                [mapLayersValue]="vm.mapLayersValue"
                (mapLayersValueChange)="updateMapLayersValue($event)"
            ></dtm-map-layers-selector>
        </div>
    </div>

    <ac-map-layer-provider
        #wmsCesiumProvider
        [provider]="MapLayerProviderOptions.WebMapService"
        [show]="false"
        [options]="
            $any({
                url: sharedMapEndpoints.geoServerEndpoint,
                enablePickFeatures: false,
                layers: [],
                parameters: {
                    transparent: true,
                    format: 'image/png'
                }
            })
        "
    >
    </ac-map-layer-provider>

    <dtm-map-geographical-zones-layer
        [zones]="geoZones$ | ngrxPush"
        [shouldShow]="!!vm.geoZonesControls.geoZonesSettings?.areGeoZonesEnabled"
        [selectedZoneId]="vm.selectedZoneId"
        [globalZoneOpacity]="vm.globalOpacity"
    ></dtm-map-geographical-zones-layer>

    <dtm-map-geographical-zones-layer
        [zones]="customZonesLayersData$ | ngrxPush"
        shouldShow
        [selectedZoneId]="vm.selectedZoneId"
        [globalZoneOpacity]="vm.globalOpacity"
    ></dtm-map-geographical-zones-layer>

    <dtm-map-geographical-zones-layer
        [zones]="localSpatialInfoData$ | ngrxPush"
        [shouldShow]="!!vm.geoZonesControls.localSpatialInfoForDtmSettings?.isLocalSpatialInfoForDtmEnabled"
        [selectedZoneId]="vm.selectedZoneId"
        [globalZoneOpacity]="vm.globalOpacity"
    ></dtm-map-geographical-zones-layer>

    <dtm-map-lib-buffer-areas-layer
        *ngFor="let route of vm.routes"
        [isShown]="vm.shouldShowAreaBuffers"
        [isProcessing]="vm.areAreaBuffersProcessing"
        [route]="route"
        [isSafetyAreaVisible]="!!vm.viewControlsValue.SafetyArea"
        [isGroundRiskAreaVisible]="!!vm.viewControlsValue.GroundRiskBuffer"
    ></dtm-map-lib-buffer-areas-layer>

    <dtm-map-geographical-zones-info
        [selectedZoneId]="vm.selectedZoneId"
        [isEnabled]="vm.isGeoZonesInfoEnabled"
        [zonesWithInfo]="vm.geoZonesWithInfo"
        [isError]="vm.isZoneInfoError"
        (zoneSelectionChange)="selectZoneById($event)"
        (getZonesInfo)="getZonesWithInfo($event)"
    ></dtm-map-geographical-zones-info>
</ng-container>
