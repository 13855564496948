import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { AddingPermissionsFilterFormKeys, AddingPermissionsTabType } from "../../models/adding-permissions.models";

interface AddingPermissionsHeaderComponentState {
    activeTabIndex: AddingPermissionsTabType;
}

@Component({
    selector: "supervisor-shared-lib-adding-permissions-header[activeTabIndex]",
    templateUrl: "./adding-permissions-header.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AddingPermissionsHeaderComponent {
    @Input() public set activeTabIndex(value: AddingPermissionsTabType | undefined) {
        this.localStore.patchState({ activeTabIndex: value || AddingPermissionsTabType.Operators });
    }
    @Output() public readonly tabIndexUpdate = new EventEmitter<AddingPermissionsTabType>();

    protected readonly activeTabIndex$ = this.localStore.selectByKey(AddingPermissionsFilterFormKeys.ActiveTabIndex);

    constructor(private readonly localStore: LocalComponentStore<AddingPermissionsHeaderComponentState>) {
        this.localStore.setState({
            activeTabIndex: AddingPermissionsTabType.Operators,
        });
    }

    protected onUpdateTabIndex(activeTabIndex: AddingPermissionsTabType): void {
        this.localStore.patchState({ activeTabIndex });
        this.tabIndexUpdate.next(activeTabIndex);
    }
}
