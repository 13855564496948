import { Injectable } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import {
    CameraHelperService,
    CylinderEditorLabelProviders,
    EntityEditorConstraints,
    ManualCoordinatesInputDialogComponent,
    ManualCoordinatesInputEntityType,
    ManualWidthInputDialogComponent,
    MapActionType,
    MapActionWithPayload,
    MapEntitiesEditorService,
    MapEntity,
    MapEntityType,
    MapUtils,
    PrismEditorLabelProviders,
    SerializableCartographic,
} from "@dtm-frontend/shared/map/cesium";
import { RxjsUtils, StringUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Feature, Feature as GeoJSONFeature } from "@turf/helpers";
import { firstValueFrom } from "rxjs";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

// eslint-disable-next-line no-magic-numbers
const CORRIDOR_SHADOW_MATERIAL = Cesium.Color.fromCssColorString("rgb(6, 22, 54)").withAlpha(0.4); // $color-gray-900
const DEFAULT_CONSTRAINTS: EntityEditorConstraints = {
    default: {
        horizontalNavigationAccuracy: 50,
        verticalNavigationAccuracy: 1,
        topHeight: 2,
        bottomHeight: 1,
        radius: 1,
        startDelay: 0,
    },
    min: {
        horizontalNavigationAccuracy: 1,
        verticalNavigationAccuracy: 1,
        topHeight: 2,
        bottomHeight: 1,
        radius: 1,
        startDelay: 0,
    },
    max: {
        horizontalNavigationAccuracy: 10000,
        verticalNavigationAccuracy: 10000,
        topHeight: 2,
        bottomHeight: 1,
        radius: 1000000,
        startDelay: 0,
    },
};

@UntilDestroy()
@Injectable({ providedIn: "root" })
export class OperationalSituationMapService {
    public readonly editorContent$ = this.entitiesEditorService.editorContent$;
    public readonly activeMapAction$ = this.entitiesEditorService.activeMapAction$;
    public readonly activeEntityStatus$ = this.entitiesEditorService.activeEntityStatus$;

    constructor(
        private readonly entitiesEditorService: MapEntitiesEditorService,
        private readonly cameraHelperService: CameraHelperService,
        private readonly dialog: MatDialog
    ) {}

    public convertMapEntityToFeature(entity: MapEntity): Feature {
        switch (entity.type) {
            case MapEntityType.Cylinder:
                return MapUtils.convertCylinderEntityToGeoJSONFeature(entity);
            case MapEntityType.Prism:
                return MapUtils.convertPrismEntityToGeoJSONFeature(entity);
            case MapEntityType.Polyline3D:
                return MapUtils.convertPolyline3DEntityToGeoJSONFeature(entity);
        }
    }

    public clearMap(): void {
        this.entitiesEditorService.stopEditors();
    }

    public async processMapActionChange(action: MapActionWithPayload, cylinderLabelProviders: CylinderEditorLabelProviders) {
        switch (action.type) {
            case MapActionType.RemoveContent:
                this.clearMap();

                return;

            case MapActionType.ShowEntireContent:
                this.cameraHelperService.flyToContent(await firstValueFrom(this.editorContent$));

                return;

            case MapActionType.FinishDrawing:
                this.entitiesEditorService.finishActiveEntityDrawing();

                return;

            case MapActionType.RemoveLastPoint:
                this.entitiesEditorService.removeLastPointFromActiveEntity();

                return;

            case MapActionType.CancelDrawing:
                this.entitiesEditorService.cancelActiveEntityDrawing();

                return;

            case MapActionType.ManualCoordinatesInputCylinder:
                this.openManualCoordinatesInputDialog(MapEntityType.Cylinder, cylinderLabelProviders);

                break;

            case MapActionType.ManualCoordinatesInputPolylineCorridor:
                this.openManualCoordinatesInputDialog(MapEntityType.Polyline3D);

                break;

            default:
                this.startEntityDrawing(action, cylinderLabelProviders);

                break;
        }
    }

    public async startEntityDrawing(action: MapActionWithPayload, cylinderLabelProviders: CylinderEditorLabelProviders = {}) {
        const entityId = StringUtils.generateId();

        switch (action.type) {
            case MapActionType.DrawCylinder:
                this.entitiesEditorService.startCylinderEditor(entityId, DEFAULT_CONSTRAINTS, cylinderLabelProviders);

                break;

            case MapActionType.DrawPrism:
                this.entitiesEditorService.startPrismEditor(entityId, DEFAULT_CONSTRAINTS, {});

                break;

            case MapActionType.DrawPolylineCorridor:
                this.startCorridorEditor(entityId, action.payload?.width ?? DEFAULT_CONSTRAINTS.default.horizontalNavigationAccuracy);

                break;

            case MapActionType.ManualWidthInputPolylineCorridor:
                if (action.payload) {
                    this.openManualWidthInputForPolylineCorridorDialog(
                        entityId,
                        action.payload.min,
                        action.payload.max,
                        action.payload.step
                    );
                }
                break;

            default:
                throw new Error(`Invalid action type: ${action.type}`);
        }
    }

    private openManualWidthInputForPolylineCorridorDialog(entityId: string, minWidth: number, maxWidth: number, step: number) {
        const dialogRef = this.dialog.open(ManualWidthInputDialogComponent, {
            data: {
                minWidth,
                maxWidth,
                step,
            },
        });

        dialogRef
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(({ width }) => {
                this.startCorridorEditor(entityId, width);
            });
    }

    private startCorridorEditor(entityId: string, width: number) {
        this.entitiesEditorService.startPolylines3DEditor(
            entityId,
            {
                ...DEFAULT_CONSTRAINTS,
                default: {
                    ...DEFAULT_CONSTRAINTS.default,
                    horizontalNavigationAccuracy: width,
                },
            },
            {
                waypoint: ({ index }) => `A${index + 1}`,
            },
            {
                customMapAction: MapActionType.DrawPolylineCorridor,
                polylineProps: {
                    shadowMaterial: CORRIDOR_SHADOW_MATERIAL,
                },
            }
        );
    }

    private openManualCoordinatesInputDialog(
        type: ManualCoordinatesInputEntityType,
        cylinderLabelProviders?: CylinderEditorLabelProviders
    ) {
        const dialogRef = this.dialog.open(ManualCoordinatesInputDialogComponent, {
            data: {
                type,
                maxRadius: DEFAULT_CONSTRAINTS.max.radius,
                maxHorizontalAccuracy: DEFAULT_CONSTRAINTS.max.horizontalNavigationAccuracy,
                defaultHorizontalAccuracy: DEFAULT_CONSTRAINTS.default.horizontalNavigationAccuracy,
            },
        });

        dialogRef
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(async ({ coordinates, size }: { coordinates: SerializableCartographic[]; size: number }) => {
                this.createEntity(type, coordinates, size, cylinderLabelProviders);
                this.cameraHelperService.flyToContent(await firstValueFrom(this.editorContent$));
            });
    }

    public createEntityFromArea(area: GeoJSONFeature) {
        if (!area?.properties) {
            return;
        }

        const type = area.properties.type as MapEntityType | undefined;

        if (type === MapEntityType.Cylinder && area.properties.center) {
            this.createEditableCylinder(
                area.properties.center[1],
                area.properties.center[0],
                StringUtils.generateId(),
                DEFAULT_CONSTRAINTS,
                {},
                area.properties.radius
            );
        } else if (type === MapEntityType.Prism) {
            this.createEditablePrism(area, DEFAULT_CONSTRAINTS, StringUtils.generateId(), {});
        } else if (type === MapEntityType.Polyline3D) {
            this.createEditablePolyline3D(area.properties.points, area, DEFAULT_CONSTRAINTS, StringUtils.generateId());
        }
    }

    public async createEntity(
        type: MapEntityType,
        coordinates: SerializableCartographic[],
        size: number,
        cylinderLabelProviders: CylinderEditorLabelProviders = {}
    ) {
        const entityId = StringUtils.generateId();

        switch (type) {
            case MapEntityType.Cylinder: {
                const cylinderCenter = MapUtils.convertSerializableCartographicToCartesian3({
                    height: 0,
                    latitude: coordinates[0].latitude,
                    longitude: coordinates[0].longitude,
                });

                this.entitiesEditorService.createEditableCylinder(
                    cylinderCenter,
                    entityId,
                    {
                        ...DEFAULT_CONSTRAINTS,
                        default: {
                            ...DEFAULT_CONSTRAINTS.default,
                            radius: size,
                        },
                    },
                    cylinderLabelProviders,
                    size,
                    1
                );

                break;
            }

            case MapEntityType.Polyline3D: {
                const positions = coordinates.map(MapUtils.convertSerializableCartographicToCartesian3);
                const heights = coordinates.map(() => DEFAULT_CONSTRAINTS.default.topHeight ?? 1);
                const bufferWidths = coordinates.map(() => size);
                const bufferHeights = coordinates.map(() => DEFAULT_CONSTRAINTS.default.verticalNavigationAccuracy ?? 1);

                this.entitiesEditorService.createEditablePolyline3D(
                    positions,
                    heights,
                    bufferWidths,
                    bufferHeights,
                    entityId,
                    DEFAULT_CONSTRAINTS,
                    {}
                );
                break;
            }

            default:
                throw new Error(`Invalid entity type: ${type}`);
        }
    }

    private createEditableCylinder(
        centerLatitude: number,
        centerLongitude: number,
        entityId: string,
        constraints: EntityEditorConstraints,
        labelProviders: CylinderEditorLabelProviders,
        size: number
    ) {
        const cylinderCenter = MapUtils.convertSerializableCartographicToCartesian3({
            height: 0,
            latitude: centerLatitude,
            longitude: centerLongitude,
        });

        this.entitiesEditorService.createEditableCylinder(cylinderCenter, entityId, constraints, labelProviders, size, 1);
    }

    private createEditablePrism(
        area: GeoJSONFeature,
        constraints: EntityEditorConstraints,
        entityId: string,
        labelProviders: PrismEditorLabelProviders
    ) {
        if (area?.geometry?.type !== "Polygon" || !area.properties) {
            return;
        }

        this.entitiesEditorService.createEditablePrism(
            MapUtils.convertGeoJsonPolygonGeometryToCartesian3(area.geometry),
            `${area.properties.type}_${entityId}`,
            constraints,
            labelProviders
        );
    }

    private createEditablePolyline3D(points: number[][], area: GeoJSONFeature, constraints: EntityEditorConstraints, entityId: string) {
        const positions = points.map((point) =>
            MapUtils.convertSerializableCartographicToCartesian3({
                height: 0,
                latitude: point[1],
                longitude: point[0],
            })
        );
        const bufferWidths = area?.properties?.bufferWidths ?? points.map(() => constraints.default.horizontalNavigationAccuracy ?? 0);
        const bufferHeights = points.map(() => constraints.default.verticalNavigationAccuracy ?? 0);

        const polylineEntityId = `${MapEntityType.Polyline3D}_${entityId}`;

        this.entitiesEditorService.createEditablePolyline3D(positions, [1], bufferWidths, bufferHeights, polylineEntityId, constraints, {});
    }
}
