<dtm-ui-expandable-panel *ngrxLet="pilotRequest$ as pilotRequest" [isExpanded]="!!pilotRequest">
    <span class="section-title" headerSlot>
        <dtm-ui-icon name="mail-send"></dtm-ui-icon> {{ "dtmSupPlannedMissions.pilotRequest.header" | transloco }}
    </span>
    <div class="message-content">
        <div class="message-details" *ngIf="pilotRequest.reason">
            <dtm-ui-label-value [label]="pilotRequest.createdAt | localizeDate" [value]="pilotRequest.reason"></dtm-ui-label-value>
        </div>
        <div class="request-priority">
            <dtm-ui-icon
                [name]="pilotRequest.isPriority ? 'checkbox-circle' : 'close-circle'"
                [ngClass]="pilotRequest.isPriority ? 'priority' : 'not-priority'"
            ></dtm-ui-icon>
            {{ "dtmSupPlannedMissions.pilotRequest.priorityValue" | transloco : { value: pilotRequest.isPriority } }}
        </div>
        <dtm-ui-uploaded-files-display
            *ngIf="pilotRequest.attachmentList.length"
            [uploadedFiles]="pilotRequest.attachmentList"
        ></dtm-ui-uploaded-files-display>
    </div>
</dtm-ui-expandable-panel>
