import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { AirspaceElement, GeoZonesActions, GeoZonesState } from "@dtm-frontend/shared/map/geo-zones";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { combineLatest, tap } from "rxjs";
import { MissionUtils } from "../../../shared/utils";
import { MissionSearchActions } from "../../state/mission-search.actions";
import { MissionSearchState } from "../../state/mission-search.state";

@UntilDestroy()
@Component({
    templateUrl: "./mission-preview-container.component.html",
    styleUrls: ["./mission-preview-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionPreviewContainerComponent implements OnDestroy {
    protected readonly mission$ = this.store.select(MissionSearchState.mission).pipe(
        RxjsUtils.filterFalsy(),
        tap((mission) => {
            const currentRoute = this.store.selectSnapshot(MissionSearchState.route);
            if (mission.routeId && mission.routeId !== currentRoute?.routeId) {
                this.store.dispatch(new MissionSearchActions.GetMissionRoute(mission.routeId));
            }
        })
    );
    protected readonly route$ = this.store.select(MissionSearchState.route).pipe(RxjsUtils.filterFalsy());
    protected readonly isProcessing$ = this.store.select(MissionSearchState.isProcessing);
    protected readonly flightPurposes$ = this.store.select(MissionSearchState.flightPurposes).pipe(RxjsUtils.filterFalsy());
    protected readonly analysisStatus$ = this.store.select(MissionSearchState.analysisStatus);
    protected readonly caaPermitData$ = this.store.select(MissionSearchState.caaPermitData);
    protected readonly nearbyMissionsRouteData$ = this.store.select(MissionSearchState.nearbyMissionsRouteData);
    protected readonly isTrafficAnalysisProcessing$ = this.store.select(MissionSearchState.isTrafficAnalysisProcessing);
    protected readonly isFlightRequirementsProcessing$ = this.store.select(MissionSearchState.isFlightRequirementsProcessing);
    protected readonly collisionZones$ = this.store.select(GeoZonesState.customZonesLayersData);
    protected readonly soraSettings$ = this.store.select(MissionSearchState.soraSettings);
    protected readonly selectedZoneId$ = this.store.select(GeoZonesState.selectedZoneId);

    constructor(private readonly store: Store, private readonly transloco: TranslocoService, private readonly toastService: ToastrService) {
        this.store.dispatch(new GeoZonesActions.SetCustomZonesVisibility(true));

        this.manageAirspaceElements();
        this.manageErrors();
    }

    public ngOnDestroy(): void {
        this.store.dispatch([new MissionSearchActions.ClearMissionData(), new GeoZonesActions.SetCustomZonesVisibility(false)]);
    }

    protected selectZone(zone: AirspaceElement) {
        this.store.dispatch(new GeoZonesActions.SetSelectedZoneId(zone?.id));
    }

    private manageAirspaceElements() {
        combineLatest([this.store.select(MissionSearchState.analysisStatus), this.store.select(MissionSearchState.route)])
            .pipe(untilDestroyed(this))
            .subscribe(([analysisStatus, route]) => {
                if (
                    !analysisStatus ||
                    !route ||
                    !(
                        (analysisStatus.airspace.zoneIssues && Object.keys(analysisStatus.airspace.zoneIssues).length) ||
                        (analysisStatus.evaluation.zoneIssues && Object.keys(analysisStatus.evaluation.zoneIssues).length)
                    )
                ) {
                    return;
                }

                const waypoints = MissionUtils.convertRouteToWaypoints(route);
                const timeRange = MissionUtils.getTimeRangeFromWaypointsWithSection(waypoints);
                const upperLimit = route.sections.reduce((result, section) => {
                    const ceiling = section.segment?.safetyArea.volume.ceiling ?? section.flightZone?.safetyArea.volume.ceiling ?? 0;

                    return Math.max(result, ceiling);
                }, 0);

                this.store.dispatch(
                    new MissionSearchActions.SearchAirspaceElements({
                        designators: [
                            ...Object.keys(analysisStatus.airspace.zoneIssues ?? {}),
                            ...Object.keys(analysisStatus.evaluation.zoneIssues ?? {}),
                        ],
                        scope: {
                            startTime: timeRange.min,
                            endTime: timeRange.max,
                            lowerLimit: 0,
                            upperLimit,
                        },
                        includeInformation: true,
                    })
                );
            });
    }

    private manageErrors() {
        this.store
            .select(MissionSearchState.getMissionRouteError)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                this.toastService.error(this.transloco.translate("dtmSharedMissionSearch.missionPreview.cannotGetMissionRouteError"));
            });

        this.store
            .select(MissionSearchState.getNearbyMissionsError)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                this.toastService.error(this.transloco.translate("dtmSharedMissionSearch.missionPreview.cannotGetNearbyMissionsError"));
            });

        this.store
            .select(MissionSearchState.searchAirspaceElementsError)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                this.toastService.error(this.transloco.translate("dtmSharedMissionSearch.missionPreview.cannotFindAirspaceElementsError"));
            });
    }
}
