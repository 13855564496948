import { Inject, Injectable } from "@angular/core";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { WebsocketService } from "@dtm-frontend/shared/websocket";
import { Observable, map } from "rxjs";
import { Alarm, MissionsEvents } from "../../shared/models/shared-supervisor-client.models";
import { GLOBAL_ALARM_ENDPOINTS, GlobalAlarmEndpoints } from "../global-alarm.tokens";
import { convertGlobalAlarmMessageResponseBodyToAlarm } from "./global-alarm.converters";

@Injectable()
export class GlobalAlarmApiService {
    constructor(
        private readonly websocketService: WebsocketService,
        @Inject(GLOBAL_ALARM_ENDPOINTS) private readonly endpoints: GlobalAlarmEndpoints
    ) {}

    public startGlobalAlarmsWatch(): Observable<Alarm> {
        return this.websocketService
            .watchTopic(`${this.endpoints.alarmsWatch}`, [MissionsEvents.SignalViolationOccurred])
            .pipe(map((message) => convertGlobalAlarmMessageResponseBodyToAlarm(JSON.parse(message.body))));
    }

    public startZoneAlarmsWatch(zoneId: string): Observable<Alarm> {
        return this.websocketService
            .watchTopic(StringUtils.replaceInTemplate(this.endpoints.wsZoneAlarmTopicName, { zoneId }), [
                MissionsEvents.SignalViolationOccurred,
            ])
            .pipe(map((message) => convertGlobalAlarmMessageResponseBodyToAlarm(JSON.parse(message.body))));
    }
}
