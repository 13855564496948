import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { DEFAULT_DEBOUNCE_TIME, StringUtils } from "@dtm-frontend/shared/utils";
import { WebsocketService } from "@dtm-frontend/shared/websocket";
import { Observable, catchError, map, startWith, switchMap, throwError } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { DtmStatsResponseBody, convertDtmStatsResponseBodyToDtmStats } from "../converters/dtm-stats.converter";
import { DtmStats, DtmStatsErrorType, MissionsEvents } from "../models/shared-supervisor-client.models";
import { SHARED_ENDPOINTS, SharedEndpoints } from "../shared.tokens";

const WATCHED_DTM_STATS_REFRESH_EVENTS = [
    MissionsEvents.FlightViolationCanceledEvent,
    MissionsEvents.FlightViolationOccurredEvent,
    MissionsEvents.MissionActivatedEvent,
    MissionsEvents.MissionCanceledEvent,
    MissionsEvents.MissionEndedByTimeoutEvent,
    MissionsEvents.MissionEndedEvent,
    MissionsEvents.MissionRealizationStartedEvent,
    MissionsEvents.MissionRealizationTimeoutEvent,
    MissionsEvents.EmergencyRegisteredEvent,
    MissionsEvents.EmergencyReportedEvent,
    MissionsEvents.EmergencyReportedEvent,
    MissionsEvents.SignalViolationOccurred,
];

@Injectable({
    providedIn: "root",
})
export class DtmStatsApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(SHARED_ENDPOINTS) private readonly endpoints: SharedEndpoints,
        private readonly websocketService: WebsocketService
    ) {}

    public getDtmStats(dtmNames: string[]): Observable<{ [dtmName: string]: DtmStats }> {
        const queryParams: HttpParams = new HttpParams().set("dtmNames", dtmNames.join(","));

        return this.httpClient.get<DtmStatsResponseBody>(this.endpoints.getDtmStats, { params: queryParams }).pipe(
            map(convertDtmStatsResponseBodyToDtmStats),
            catchError(() => throwError(() => ({ type: DtmStatsErrorType.CannotGetDtmStats })))
        );
    }

    public startDtmStatusUpdatesWatch(dtmName: string[], authorityUnitId: string): Observable<{ [dtmName: string]: DtmStats }> {
        return this.websocketService
            .watchTopic(
                StringUtils.replaceInTemplate(this.endpoints.wsAuthorityUnitWatchTopicName, { authorityUnitId }),
                WATCHED_DTM_STATS_REFRESH_EVENTS
            )
            .pipe(
                startWith(null),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                switchMap(() => this.getDtmStats(dtmName))
            );
    }
}
