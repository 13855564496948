import { HttpParams } from "@angular/common/http";
import { OperationScenarioCategory, PageResponseBody } from "@dtm-frontend/shared/ui";
import {
    AddingPermissionsOperator,
    AddingPermissionsOperatorFilterFormKeys,
    AddingPermissionsOperatorFilterParams,
    AddingPermissionsOperatorListWithPages,
} from "../models/adding-permissions-operator.models";
import {
    AddingPermissionsPilot,
    AddingPermissionsPilotFilterFormKeys,
    AddingPermissionsPilotFilterParams,
    AddingPermissionsPilotListWithPages,
} from "../models/adding-permissions-pilot.models";
import { AddingPermissionsFilterFormKeys, PilotOperatorQualification } from "../models/adding-permissions.models";

export type GetOperatorsListResponseBody = PageResponseBody<AddingPermissionsOperator>;
export type GetPilotsListResponseBody = PageResponseBody<AddingPermissionsPilot>;

export interface GetOperatorQualificationsResponseBody {
    status: string;
    number: string;
    operationalAuthorizations: {
        name: string;
        category: OperationScenarioCategory;
        expirationDate: string;
    }[];
    otherInformation: {
        id: string;
        text: string;
    }[];
}

export interface GetPilotQualificationsResponseBody {
    status: string;
    number: string;
    competencies: {
        name: string;
        category: OperationScenarioCategory;
        expirationDate: string;
    }[];
    certificates: {
        name: string;
        validUntil: string;
    }[];
    otherInformation: {
        id: string;
        text: string;
    }[];
}

export function convertGetOperatorsListResponseBodyToOperatorsListWithPages(
    response: GetOperatorsListResponseBody
): AddingPermissionsOperatorListWithPages {
    return {
        content: response.content.map((addingPermissionBody) => addingPermissionBody),
        totalElements: response.totalElements,
        pageNumber: response.number,
        pageSize: response.size,
    };
}

export function convertGetPilotsListResponseBodyToPilotsListWithPages(
    response: GetPilotsListResponseBody
): AddingPermissionsPilotListWithPages {
    return {
        content: response.content.map((addingPermissionBody) => addingPermissionBody),
        totalElements: response.totalElements,
        pageNumber: response.number,
        pageSize: response.size,
    };
}

export function convertGetOperatorQualificationsResponseBodyToPilotOperatorQualification(
    response: GetOperatorQualificationsResponseBody
): PilotOperatorQualification[] {
    return response.operationalAuthorizations.map((operationalAuthorization) => ({
        name: operationalAuthorization.name,
        category: operationalAuthorization.category,
        expirationDate: new Date(operationalAuthorization.expirationDate),
    }));
}

export function convertGetPilotQualificationsResponseBodyToPilotOperatorQualification(
    response: GetPilotQualificationsResponseBody
): PilotOperatorQualification[] {
    return response.competencies.map((competency) => ({
        name: competency.name,
        category: competency.category,
        expirationDate: new Date(competency.expirationDate),
    }));
}

export function getAddingPermissionsOperatorParams(filters: AddingPermissionsOperatorFilterParams): HttpParams {
    let params = new HttpParams().set("page", `${filters?.page ?? 0}`);

    if (filters.size) {
        params = params.append("size", filters.size);
    }

    const searchPhrase = filters[AddingPermissionsFilterFormKeys.SearchPhrase];
    const emergencyPermission = filters[AddingPermissionsOperatorFilterFormKeys.EmergencyPermission];

    if (searchPhrase) {
        params = params.append(AddingPermissionsFilterFormKeys.SearchPhrase, searchPhrase);
    }

    if (emergencyPermission) {
        params = params.append(AddingPermissionsOperatorFilterFormKeys.EmergencyPermission, emergencyPermission);
    }

    return params;
}

export function getAddingPermissionsPilotParams(filters: AddingPermissionsPilotFilterParams): HttpParams {
    let params = new HttpParams().set("page", `${filters?.page ?? 0}`);

    if (filters.size) {
        params = params.append("size", filters.size);
    }

    const searchPhrase = filters[AddingPermissionsFilterFormKeys.SearchPhrase];
    const superPilot = filters[AddingPermissionsPilotFilterFormKeys.SuperPilot];

    if (searchPhrase) {
        params = params.append(AddingPermissionsFilterFormKeys.SearchPhrase, searchPhrase);
    }

    if (superPilot) {
        params = params.append(AddingPermissionsPilotFilterFormKeys.SuperPilot, superPilot);
    }

    return params;
}
