import { MissionTimeRange } from "@dtm-frontend/shared/mission";
import { ProceedingMission } from "../../../operational-situation/models/operational.situation.models";
import { Mission } from "../../../planned-missions/models/mission.models";

export const convertToMissionTimeRange = (mission: Mission | ProceedingMission): MissionTimeRange => ({
    flightStartAtMin: mission.flightStartAtMin,
    flightStartAtMax: mission.flightStartAtMax,
    flightStartAt: mission.flightStartAt,
    flightFinishAt: mission.flightFinishAt,
    flightFinishAtMin: mission.flightFinishAtMin,
    flightFinishAtMax: mission.flightFinishAtMax,
    isPathBased: mission.isPathBased,
    plannedStartAt: mission.plannedStartTime?.min,
    plannedFinishAt: mission.plannedEndTime?.max,
});
