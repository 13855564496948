import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from "@angular/forms";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Message } from "../../models/shared-supervisor-client.models";

export const MAX_OPERATOR_DETAILS_LENGTH = 200;

interface PilotMessageComponentState {
    isFormDisplayed: boolean;
    isEditable: boolean;
    maxMessageLength: number | undefined;
    panelTitle: string | undefined;
    controlLabel: string | undefined;
    note: Message | undefined;
}

@UntilDestroy()
@Component({
    selector: "supervisor-shared-lib-plan-message[panelTitle]",
    templateUrl: "./plan-message.component.html",
    styleUrls: ["./plan-message.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PlanMessageComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PlanMessageComponent),
            multi: true,
        },
    ],
})
export class PlanMessageComponent implements ControlValueAccessor, Validators {
    @Input() public set maxMessageLength(value: number | undefined) {
        this.localStore.patchState({ maxMessageLength: value ?? MAX_OPERATOR_DETAILS_LENGTH });
        this.messageControl.setValidators(Validators.maxLength(value ?? MAX_OPERATOR_DETAILS_LENGTH));
    }
    @Input() public set isEditable(value: BooleanInput) {
        this.localStore.patchState({ isEditable: coerceBooleanProperty(value) });
    }
    @Input() public set shouldPilotPanelClose(value: BooleanInput) {
        if (value) {
            this.localStore.patchState({ isFormDisplayed: false });
            this.messageControl.reset();
        }
    }
    @Input() public set panelTitle(value: string | undefined) {
        this.localStore.patchState({ panelTitle: value });
    }
    @Input() public set controlLabel(value: string | undefined) {
        this.localStore.patchState({ controlLabel: value });
    }
    @Input() public set message(value: Message | undefined) {
        if (value) {
            this.localStore.patchState({ note: value });
            this.messageControl.patchValue(value.message);
        }
    }

    @Output() public readonly noteUpdate = new EventEmitter<string>();
    @Output() public readonly messagePanelOpen = new EventEmitter<void>();

    protected readonly isFormDisplayed$ = this.localStore.selectByKey("isFormDisplayed");
    protected readonly isEditable$ = this.localStore.selectByKey("isEditable");
    protected readonly panelTitle$ = this.localStore.selectByKey("panelTitle");
    protected readonly controlLabel$ = this.localStore.selectByKey("controlLabel");
    protected readonly note$ = this.localStore.selectByKey("note");
    protected readonly messageControl = new FormControl<string>(
        { value: "", disabled: true },
        { nonNullable: true, validators: Validators.maxLength(MAX_OPERATOR_DETAILS_LENGTH) }
    );
    protected readonly maxMessageLength$ = this.localStore.selectByKey("maxMessageLength");

    private propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: string) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<PilotMessageComponentState>) {
        this.localStore.setState({
            isFormDisplayed: false,
            isEditable: false,
            maxMessageLength: undefined,
            panelTitle: undefined,
            controlLabel: undefined,
            note: undefined,
        });

        this.messageControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.propagateChange(this.messageControl.value);
            this.propagateTouch();
        });
    }

    protected changeFormVisibility($event: Event): void {
        $event.stopPropagation();
        this.localStore.patchState(({ isFormDisplayed }) => ({ isFormDisplayed: !isFormDisplayed }));

        const formState = this.localStore.selectSnapshotByKey("isFormDisplayed");

        if (formState) {
            this.messageControl.enable();

            return;
        }

        this.messageControl.disable();
        this.messageControl.reset();
        this.noteUpdate.emit("");
    }

    protected saveMessage(): void {
        if (this.messageControl.invalid) {
            this.messageControl.markAsTouched();

            return;
        }

        this.noteUpdate.emit(this.messageControl.value);
        this.localStore.patchState({ isFormDisplayed: false });
    }

    public registerOnChange(fn: (value: string) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(): ValidationErrors | null {
        return this.messageControl.invalid ? { invalidMessage: true } : null;
    }

    public writeValue(value: string | undefined): void {
        if (value) {
            this.messageControl.reset(value, { emitEvent: false });
        } else {
            this.messageControl.reset();
        }
    }
}
