import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { SectorStatus } from "../../../../models/network-monitoring.models";

interface InstallationTileComponentState {
    name: string | undefined;
    status: SectorStatus | SectorStatus[] | undefined;
    shouldDisplayIcon: boolean;
    reverseName: boolean;
    selected: boolean;
    bolderName: boolean;
}

@Component({
    selector: "supervisor-shared-lib-installation-tile[name][status]",
    templateUrl: "./installation-tile.component.html",
    styleUrls: ["./installation-tile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class InstallationTileComponent {
    @Input() public set name(value: string | undefined) {
        this.localStore.patchState({ name: value });
    }
    @Input() public set status(value: SectorStatus | SectorStatus[] | undefined) {
        this.localStore.patchState({ status: value });
    }
    @Input() public set selected(value: BooleanInput) {
        this.localStore.patchState({ selected: coerceBooleanProperty(value) });
    }
    @Input() public set bolderName(value: BooleanInput) {
        this.localStore.patchState({ bolderName: coerceBooleanProperty(value) });
    }
    @Input() public set shouldDisplayIcon(value: BooleanInput) {
        this.localStore.patchState({ shouldDisplayIcon: coerceBooleanProperty(value) });
    }
    @Input() public set reverseName(value: BooleanInput) {
        this.localStore.patchState({ reverseName: coerceBooleanProperty(value) });
    }

    protected readonly name$ = this.localStore.selectByKey("name");
    protected readonly status$ = this.localStore.selectByKey("status");
    protected readonly selected$ = this.localStore.selectByKey("selected");
    protected readonly bolderName$ = this.localStore.selectByKey("bolderName");
    protected readonly shouldDisplayIcon$ = this.localStore.selectByKey("shouldDisplayIcon");
    protected readonly reverseName$ = this.localStore.selectByKey("reverseName");

    constructor(private readonly localStore: LocalComponentStore<InstallationTileComponentState>) {
        this.localStore.setState({
            name: undefined,
            status: undefined,
            shouldDisplayIcon: true,
            reverseName: false,
            selected: false,
            bolderName: false,
        });
    }
}
