export enum SupSoundType {
    PlannedMissionNotification = "plannedMissionNotification",
    TacticalNotification = "tacticalNotification",
    PlannedMissionUtmNotification = "plannedMissionUtmNotification",
}

export const SUP_SOUND_DEFINITIONS = {
    [SupSoundType.PlannedMissionNotification]: "/assets/sounds/plans-notification.wav",
    [SupSoundType.TacticalNotification]: "/assets/sounds/tactical-notification.wav",
    [SupSoundType.PlannedMissionUtmNotification]: "/assets/sounds/utm-notification.mp3",
};
