import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { TranslocoModule } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { SharedSupervisorClientModule } from "../shared/shared-supervisor-client.module";
import { SupUserApiService } from "./services/sup-user-api.service";
import { SupUserState } from "./state/sup-user.state";
import { SUP_USER_TOKENS } from "./sup-user.token";
import { WorkspaceSwitcherComponent } from "./workspace-switcher/workspace-switcher.component";

@NgModule({
    declarations: [WorkspaceSwitcherComponent],
    imports: [
        CommonModule,
        NgxsModule.forFeature([SupUserState]),
        PushModule,
        LetModule,
        SharedUiModule,
        TranslocoModule,
        MatMenuModule,
        SharedSupervisorClientModule,
    ],
    exports: [WorkspaceSwitcherComponent],
})
export class SupUserModule {
    public static forRoot(): ModuleWithProviders<SupUserModule> {
        return {
            ngModule: SupUserModule,
            providers: [
                {
                    provide: SupUserApiService,
                    useClass: SupUserApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<SupUserModule> {
        return {
            ngModule: SupUserModule,
            providers: [
                {
                    provide: SupUserApiService,
                    useValue: null,
                },
            ],
        };
    }

    // TODO:DTM-5282  remove this method after DTM supervisor will be removed
    /**
     * @deprecated temporary solution to work with DTM supervisor
     */
    public static forDtmSupervisor(): ModuleWithProviders<SupUserModule> {
        return {
            ngModule: SupUserModule,
            providers: [
                {
                    provide: SUP_USER_TOKENS,
                    useValue: {},
                },
            ],
        };
    }
}
