<dtm-ui-filters-container *ngrxLet="activeTabIndex$ as activeTabIndex">
    <form [formGroup]="addingPermissionsFiltersForm" class="filters-container">
        <dtm-ui-input-field>
            <input
                matInput
                [formControlName]="AddingPermissionsFilterFormKeys.SearchPhrase"
                type="text"
                [placeholder]="
                    'dtmSupAddingPermissions.filters.searchPhrasePlaceholder' | transloco : { isOperatorMode: activeTabIndex === 0 }
                "
            />
            <dtm-ui-icon name="search" class="field-prefix"></dtm-ui-icon>
        </dtm-ui-input-field>

        <div class="slides-container">
            <mat-slide-toggle
                *ngIf="activeTabIndex === AddingPermissionsTabType.Operators"
                [formControl]="emergencyField"
                (change)="filterPermissionChange.emit($event.checked)"
                labelPosition="before"
            >
                {{ "dtmSupAddingPermissions.permissions.showEmergencyPermissionLabel" | transloco }}
            </mat-slide-toggle>

            <mat-slide-toggle
                *ngIf="activeTabIndex === AddingPermissionsTabType.Pilots"
                [formControl]="superpilotField"
                (change)="filterPermissionChange.emit($event.checked)"
                labelPosition="before"
            >
                <div class="slide-with-icon">
                    <dtm-ui-icon name="star-badge"></dtm-ui-icon>
                    {{ "dtmSupAddingPermissions.permissions.superPilotLabel" | transloco }}
                </div>
            </mat-slide-toggle>
        </div>
    </form>
</dtm-ui-filters-container>

<dtm-ui-filter-chips-display
    selectedFiltersDisplaySlot
    [formGroup]="addingPermissionsFiltersForm"
    [filtersValue]="addingPermissionsFiltersForm.value"
    [filtersMap]="FILTERS_MAP"
    [filtersCount]="appliedFiltersLength$ | ngrxPush"
    (allFiltersReset)="clearFilters()"
>
</dtm-ui-filter-chips-display>
