import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { NgxMaskModule } from "ngx-mask";
import { ADDING_PERMISSIONS_ENDPOINTS } from "./adding-permissions.tokens";
import { AddingPermissionsContainerComponent } from "./components/adding-permissions-container/adding-permissions-container.component";
import { AddingPermissionsFiltersComponent } from "./components/adding-permissions-filters/adding-permissions-filters.component";
import { AddingPermissionsHeaderComponent } from "./components/adding-permissions-header/adding-permissions-header.component";
import { AddingPermissionsOperatorListComponent } from "./components/adding-permissions-operator-list/adding-permissions-operator-list.component";
import { AddingPermissionsPilotListComponent } from "./components/adding-permissions-pilot-list/adding-permissions-pilot-list.component";
import { RegulationExemptionDialogComponent } from "./components/regulation-exemption-dialog/regulation-exemption-dialog.component";
import { AddingPermissionsApiService } from "./services/adding-permissions-api.service";
import { AddingPermissionsState } from "./state/adding-permissions-state.service";

@NgModule({
    declarations: [
        AddingPermissionsContainerComponent,
        AddingPermissionsHeaderComponent,
        AddingPermissionsFiltersComponent,
        AddingPermissionsPilotListComponent,
        AddingPermissionsOperatorListComponent,
        RegulationExemptionDialogComponent,
    ],
    imports: [
        CommonModule,
        SharedUiModule,
        NgxsModule.forFeature([AddingPermissionsState]),
        ReactiveFormsModule,
        SharedI18nModule,
        NgxMaskModule,
        LetModule,
        MatCardModule,
        MatTabsModule,
        MatTableModule,
        MatInputModule,
        PushModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatMenuModule,
        MatChipsModule,
        MatDialogModule,
    ],
    exports: [
        AddingPermissionsHeaderComponent,
        AddingPermissionsFiltersComponent,
        AddingPermissionsPilotListComponent,
        AddingPermissionsOperatorListComponent,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmSupAddingPermissions",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: [AddingPermissionsApiService],
            useValue: undefined,
        },
    ],
})
export class AddingPermissionsModule {
    public static forRoot(): ModuleWithProviders<AddingPermissionsModule> {
        return {
            ngModule: AddingPermissionsModule,
            providers: [AddingPermissionsApiService],
        };
    }

    public static forTest(): ModuleWithProviders<AddingPermissionsModule> {
        return {
            ngModule: AddingPermissionsModule,
            providers: [
                {
                    provide: AddingPermissionsApiService,
                    useValue: null,
                },
                {
                    provide: ADDING_PERMISSIONS_ENDPOINTS,
                    useValue: {},
                },
            ],
        };
    }
}
