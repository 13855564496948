import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, Subject } from "rxjs";

const MIN_REASON_LENGTH = 3;
const MAX_REASON_LENGTH = 255;

@UntilDestroy()
@Component({
    selector: "supervisor-shared-lib-reject-mission-dialog",
    templateUrl: "./reject-mission-dialog.component.html",
    styleUrls: ["./reject-mission-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RejectMissionDialogComponent {
    private readonly setInformationSubject = new Subject<string>();
    public readonly setInformation$ = this.setInformationSubject.asObservable();

    protected reasonControl = new FormControl("", {
        validators: [
            Validators.required,
            Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            Validators.minLength(MIN_REASON_LENGTH),
            Validators.maxLength(MAX_REASON_LENGTH),
        ],
        nonNullable: true,
    });

    protected MAX_REASON_LENGTH = MAX_REASON_LENGTH;

    constructor(@Inject(MAT_DIALOG_DATA) public data: { isReasonProcessing$: Observable<boolean> }) {
        this.data.isReasonProcessing$.pipe(untilDestroyed(this)).subscribe((isProcessing) => {
            if (isProcessing) {
                this.reasonControl.disable();

                return;
            }

            this.reasonControl.enable();
        });
    }

    protected confirm(): void {
        if (this.reasonControl.invalid) {
            this.reasonControl.markAllAsTouched();

            return;
        }

        this.setInformationSubject.next(this.reasonControl.value);
    }
}
