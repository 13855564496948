<div class="alert" *ngrxLet="missionAlert$ as missionAlert">
    <div class="header">
        <h6 class="mission-name">{{ missionAlert.name }}</h6>
        <button type="button" class="button-icon" (click)="alertClose.emit()">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <div class="mission-events">
        <div *ngFor="let alert of missionAlert.violations" class="violations">
            <div
                [ngClass]="{
                        'flight-area-in': alert.violationType === ViolationType.UavLeftOwnFlightArea,
                        'safety-area-in': alert.violationType === ViolationType.UavLeftOwnSafetyArea,
                        'start-outside-area': alert.violationType === ViolationType.UavOutsideStartingFlightZone,
                        'entered-foreign-area': alert.violationType === ViolationType.UavEnteredForeignSafetyArea,
                    }"
            >
                <ng-container
                    [ngTemplateOutlet]="violationTemplate"
                    [ngTemplateOutletContext]="{
                        date: alert.startedAt,
                        direction: DirectionType.Departure,
                        violationType: alert.violationType
                    }"
                ></ng-container>
            </div>
            <div class="finish-at" *ngIf="alert.finishedAt">
                <ng-container
                    [ngTemplateOutlet]="violationTemplate"
                    [ngTemplateOutletContext]="{
                        date: alert.finishedAt,
                        direction: DirectionType.Arrival,
                        violationType: alert.violationType
                    }"
                ></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #violationTemplate let-direction="direction" let-date="date" let-violationType="violationType">
    {{ date | localizeDate : { timeStyle: "medium" } }} -
    <ng-container *ngIf="violationType === ViolationType.UavLeftOwnFlightArea || violationType === ViolationType.UavLeftOwnSafetyArea">
        {{ "dtmSupOperationalSituation.missionAlert.directionValueSelect" | transloco : { value: direction } }}
        {{ "dtmSupOperationalSituation.missionAlert.violationAreaValueSelect" | transloco : { value: violationType } }}
    </ng-container>
    <ng-container *ngIf="violationType === ViolationType.UavOutsideStartingFlightZone">
        {{ "dtmSupOperationalSituation.missionAlert.startOutsideFlightZone" | transloco }}
    </ng-container>
    <ng-container *ngIf="violationType === ViolationType.UavEnteredForeignSafetyArea">
        {{ "dtmSupOperationalSituation.missionAlert.enteredForeignSafetyAreaValueSelect" | transloco : { value: direction } }}
    </ng-container>
</ng-template>
