<dtm-ui-header [routeTitle]="routeTitle$ | ngrxPush" [componentPortalItem]="componentPortalItem$ | ngrxPush">
    <ng-container notificationsSlot>
        <button type="button" class="button-icon notifications-button" [matMenuTriggerFor]="notificationsDropdown">
            <dtm-ui-icon name="notification"></dtm-ui-icon>
        </button>
        <mat-menu #notificationsDropdown="matMenu" xPosition="before">
            <span class="no-notifications-info">
                {{ "dtmSupClient.header.noNotificationsMessage" | transloco }}
            </span>
        </mat-menu>
    </ng-container>

    <dtm-ui-header-user-button
        userButtonSlot
        [isUserLoggedIn]="isLoggedIn$ | ngrxPush"
        [isShortMode]="isHeaderUserButtonShortMode$ | ngrxPush"
        [userName]="userName$ | ngrxPush"
        (logout)="logout()"
    >
    </dtm-ui-header-user-button>
</dtm-ui-header>
