<div class="main-layout" [class.sidebar-collapsed]="isSidebarCollapsed" *ngrxLet="isSidebarCollapsed$ as isSidebarCollapsed">
    <div class="sidebar-overlay" (click)="toggleSidebar()"></div>
    <aside class="sidebar">
        <button type="button" class="sidebar-switcher" (click)="toggleSidebar()">
            <dtm-ui-icon [name]="isSidebarCollapsed ? 'menu-unfold' : 'menu-fold'"></dtm-ui-icon>
        </button>
        <div class="logo">
            <a routerLink="/" (click)="collapseMenu()">
                <img [src]="isSidebarCollapsed ? 'assets/images/logo-collapsed.svg' : 'assets/images/logo.svg'" alt="logo" />
            </a>
        </div>
        <nav class="menu">
            <dtm-sup-client-menu></dtm-sup-client-menu>
        </nav>
    </aside>
    <header class="header">
        <div class="smartphone-header">
            <button type="button" class="sidebar-switcher" (click)="toggleSidebar()">
                <dtm-ui-icon name="menu"></dtm-ui-icon>
            </button>
            <div class="logo">
                <a routerLink="/"><img src="assets/images/logo.svg" alt="logo" /></a>
            </div>
        </div>
        <dtm-sup-client-header></dtm-sup-client-header>
    </header>
    <main class="content" dtmUiDisplayVersion [class]="className$ | ngrxPush">
        <h2 class="route-title">{{ routeTitle$ | ngrxPush }}</h2>
        <dtm-sup-client-lazy-router-outlet class="router-outlet"></dtm-sup-client-lazy-router-outlet>
    </main>
</div>
