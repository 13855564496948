<ng-container
    *ngrxLet="{
        selectedMissionId: selectedOperationId$,
        operationList: operationList$,
        selectedMission: selectedMission$,
        currentMissionPlanData: currentMissionPlanData$,
        zones: zones$,
        currentPlanAnalysisStatus: currentPlanAnalysisStatus$,
        selectedMissionSegmentStatuses: selectedMissionSegmentStatuses$,
        selectedZoneId: selectedZoneId$,
    } as vm"
>
    <div class="header">
        <span class="title">{{ "dtmSupOperationalSituation.proceedingMissionsList.headerLabel" | transloco }}</span>
        <supervisor-shared-lib-mission-type-filter
            shouldDisplayCheckinFilter
            [defaultFilters]="MISSION_TYPE_FILTERS_DEFAULTS"
            (filterChanged)="filterMissionsType($event)"
        ></supervisor-shared-lib-mission-type-filter>
    </div>
    <div class="missions">
        <supervisor-shared-lib-mission-status-filter
            (missionStatusChanged)="filterByStatus($event)"
        ></supervisor-shared-lib-mission-status-filter>

        <ng-container *ngFor="let operation of vm.operationList; let index = index">
            <supervisor-shared-lib-proceeding-mission-tile
                *ngIf="operation.operationType === OperationType.ProceedingMission"
                [proceedingMission]="operation"
                [missionCount]="index + 1"
                [isSelected]="operation.missionId === vm.selectedMissionId"
                [selectedMission]="vm.selectedMission"
                [currentMissionPlanData]="vm.currentMissionPlanData"
                [zones]="vm.zones"
                [currentPlanAnalysisStatus]="vm.currentPlanAnalysisStatus"
                [segmentStatuses]="vm.selectedMissionSegmentStatuses"
                [selectedZoneId]="vm.selectedZoneId"
                (missionReject)="missionReject.emit($event)"
                (missionSelect)="missionSelect.emit($event)"
                (missionTimeChange)="missionTimeChange.emit($event)"
                (missionRoadZoom)="missionRoadZoom.emit($event)"
                (zoneSelect)="zoneSelect.emit($event)"
                isZoomAvailable
            ></supervisor-shared-lib-proceeding-mission-tile>
            <supervisor-shared-lib-checkin-tile
                *ngIf="operation.operationType === OperationType.Checkin"
                [missionCount]="index + 1"
                [checkin]="operation"
                [isSelected]="operation.id === vm.selectedMissionId"
                (checkinSelect)="checkinSelect.emit($event)"
                (checkinZoom)="checkinZoom.emit($event)"
            ></supervisor-shared-lib-checkin-tile>
        </ng-container>
        <div class="empty-list" *ngIf="!vm.operationList.length">
            {{ "dtmSupOperationalSituation.proceedingMissionsList.emptyListLabel" | transloco }}
        </div>
    </div>
</ng-container>
