<div
    class="tile"
    [class.selected]="vm.isSelected"
    *ngrxLet="{
        isExpanded: isExpanded$,
        zone: zone$,
        zoneDetails: zoneDetails$,
        isActive: isActive$,
        isSelected: isSelected$,
        canEdit: canEdit$,
        canPublish: canPublish$,
        canClone: canClone$
    } as vm"
>
    <div class="basic-info">
        <section class="zone-type">
            <dtm-ui-icon
                [name]="vm.zone.type === GeographicalZone.DRAI ? 'cylinder-line' : 'complex-cylinder-fill'"
                [ngClass]="vm.zone.type.toLowerCase()"
            ></dtm-ui-icon>
            <div class="zone-name">
                <span>{{ vm.zone.designator }}</span>
                <div class="zone-info">
                    <ng-container *ngIf="vm.zone.dtmName">{{ vm.zone.dtmName }},</ng-container>
                    {{
                        "dtmSupOperationalSituation.zoneInfo.heightLimitsLabel"
                            | transloco : { min: vm.zone.minAltitude, max: vm.zone.maxAltitude }
                    }}
                </div>
            </div>
        </section>
        <div class="actions" (click)="$event.stopPropagation()">
            <ng-container *ngIf="vm.canEdit">
                <button *ngIf="vm.isExpanded" class="button-icon" type="button">
                    <dtm-ui-icon name="edit"></dtm-ui-icon>
                </button>
                <button class="button-icon" type="button" (click)="$event.stopPropagation(); zoneDelete.emit(vm.zone.id)">
                    <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                </button>
            </ng-container>
            <button *ngIf="vm.canClone" class="button-icon" type="button" (click)="zoneClone.emit(vm.zone.id)">
                <dtm-ui-icon name="file-copy"></dtm-ui-icon>
            </button>
            <button
                *ngIf="vm.isSelected && vm.zoneDetails"
                class="button-icon zoom"
                type="button"
                (click)="zoneZoom.emit(vm.zoneDetails.area?.geometry)"
            >
                <dtm-ui-icon name="road-map"></dtm-ui-icon>
            </button>
            <button class="button-icon" type="button" (click)="expandContent(vm.zone.id)">
                <dtm-ui-icon [name]="vm.isExpanded ? 'arrow-up' : 'arrow-down'"></dtm-ui-icon>
            </button>
        </div>
    </div>
    <div class="zone-details">
        <div class="zone-range">
            <span>{{ "dtmSupOperationalSituation.zoneInfo.fromLabel" | transloco }}</span>
            <dtm-ui-icon name="calendar-event"></dtm-ui-icon>
            {{ vm.zone.startTime | localizeDate : { dateStyle: "short" } }}
            <dtm-ui-icon name="time"></dtm-ui-icon>
            {{ vm.zone.startTime | localizeDate : { timeStyle: "short" } }}
        </div>
        <div class="zone-range">
            <span>{{ "dtmSupOperationalSituation.zoneInfo.toLabel" | transloco }}</span>
            <dtm-ui-icon name="calendar-event"></dtm-ui-icon>
            {{ vm.zone.endTime | localizeDate : { dateStyle: "short" } }}
            <dtm-ui-icon name="time"></dtm-ui-icon>
            {{ vm.zone.endTime | localizeDate : { timeStyle: "short" } }}
        </div>
    </div>
    <div class="expanded-area" *ngIf="vm.isExpanded" @slideIn>
        <ng-container *ngIf="vm.zoneDetails">
            <dtm-ui-label-value
                [label]="'dtmSupOperationalSituation.zoneInfo.createdDateLabel' | transloco"
                [value]="vm.zoneDetails.createdAt | localizeDate : { dateStyle: 'short', timeStyle: 'short' }"
            ></dtm-ui-label-value>
            <dtm-ui-label-value [label]="'dtmSupOperationalSituation.zoneInfo.validityPeriodLabel' | transloco">
                {{
                    "dtmSupOperationalSituation.zoneInfo.zoneRangeTime"
                        | transloco
                            : {
                                  startDate: vm.zoneDetails.zoneStartDate | localizeDate : { dateStyle: "short", timeStyle: "short" },
                                  endDate: vm.zoneDetails.zoneEndDate | localizeDate : { dateStyle: "short", timeStyle: "short" }
                              }
                }}
            </dtm-ui-label-value>
            <dtm-ui-label-value
                *ngIf="vm.zoneDetails.dailyActivations"
                [label]="'dtmSupOperationalSituation.zoneInfo.constantActivityLabel' | transloco"
            >
                {{ vm.zoneDetails.dailyActivations.startTime }} - {{ vm.zoneDetails.dailyActivations.endTime }}
            </dtm-ui-label-value>
            <dtm-ui-label-value
                *ngIf="vm.zoneDetails.weeklyActivations && vm.zoneDetails.weeklyActivations.length"
                [label]="'dtmSupOperationalSituation.zoneInfo.variableActivityLabel' | transloco"
            >
                <div class="day-activation" *ngFor="let activation of vm.zoneDetails.weeklyActivations">
                    {{ "dtmSupOperationalSituation.zoneInfo.weekDaysValue" | transloco : { value: activation.day } }}:
                    {{ activation.startTime }} - {{ activation.endTime }}
                </div>
            </dtm-ui-label-value>
            <dtm-ui-label-value
                *ngIf="vm.zoneDetails.zoneType === GeographicalZone.DRAP || vm.zoneDetails.zoneType === GeographicalZone.DRAR"
                [label]="'dtmSupOperationalSituation.zoneInfo.restrictionsTypeLabel' | transloco"
                [value]="
                    'dtmSupOperationalSituation.zoneInfo.restrictionsValue'
                        | transloco : { value: vm.zoneDetails.zoneType | invoke : removeDash }
                "
            >
            </dtm-ui-label-value>
            <ng-container *ngIf="vm.zoneDetails.zoneType === GeographicalZone.LocalInformation">
                <dtm-ui-label-value [label]="'dtmSupOperationalSituation.zoneInfo.infoZoneLabel' | transloco">
                    <div class="info-type-value">
                        <dtm-ui-icon [name]="vm.zoneDetails.infoZoneType | invoke : getInfoZoneTypeIcon"></dtm-ui-icon>
                        {{ "dtmSupOperationalSituation.zoneInfo.infoZoneType" | transloco : { value: vm.zoneDetails.infoZoneType } }}
                    </div>
                </dtm-ui-label-value>
                <dtm-ui-label-value
                    *ngIf="vm.zoneDetails.zoneObjectType"
                    [label]="'dtmSupOperationalSituation.zoneInfo.objectTypeLabel' | transloco"
                >
                    <div class="info-type-value">
                        <dtm-ui-icon [name]="vm.zoneDetails.zoneObjectType"></dtm-ui-icon>
                        {{
                            "dtmSupOperationalSituation.zoneInfo.zoneObjectValueLabel"
                                | transloco : { value: vm.zoneDetails.zoneObjectType }
                        }}
                    </div>
                </dtm-ui-label-value>
            </ng-container>
            <dtm-ui-label-value
                *ngIf="vm.zoneDetails.zoneDescription"
                [label]="'dtmSupOperationalSituation.zoneInfo.zoneDescriptionLabel' | transloco"
                [value]="vm.zoneDetails.zoneDescription"
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                *ngIf="vm.zoneDetails.note"
                [label]="'dtmSupOperationalSituation.zoneInfo.internalDescriptionLabel' | transloco"
                [value]="vm.zoneDetails.note"
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                *ngIf="vm.zoneDetails.phoneNumber"
                [label]="'dtmSupOperationalSituation.zoneInfo.phoneLabel' | transloco"
                [value]="vm.zoneDetails.phoneNumber | formatPhoneNumber"
            ></dtm-ui-label-value>
        </ng-container>
        <button class="button-primary" type="button" *ngIf="vm.canPublish" (click)="$event.stopPropagation(); zoneManage.emit(vm.zone.id)">
            {{ "dtmSupOperationalSituation.zoneInfo.publishZoneButtonValueSelectLabel" | transloco : { value: vm.isActive } }}
        </button>
    </div>
</div>
