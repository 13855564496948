import { CommonModule } from "@angular/common";
import { importProvidersFrom, ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CesiumMapModule } from "@dtm-frontend/shared/map/cesium";
import { SharedMissionModule } from "@dtm-frontend/shared/mission";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { getTranslocoInlineLoader, LanguageCode, SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { WebsocketService } from "@dtm-frontend/shared/websocket";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { CapabilitiesApiService } from "../shared/services/capabilities-api.service";
import { SharedSupervisorClientModule } from "../shared/shared-supervisor-client.module";
import { AlertListComponent } from "./components/alert-list/alert-list.component";
import { CheckinTileComponent } from "./components/checkin-tile/checkin-tile.component";
import { MissionAlertComponent } from "./components/mission-alert/mission-alert.component";
import { OperationalSituationContainerComponent } from "./components/operational-situation-container/operational-situation-container.component";
import { OtherMissionsComponent } from "./components/other-missions/other-missions.component";
import { MissionTimeChangeDialogComponent } from "./components/proceeding-mission-tile/mission-time-change-dialog/mission-time-change-dialog.component";
import { ProceedingMissionTileComponent } from "./components/proceeding-mission-tile/proceeding-mission-tile.component";
import { RejectMissionDialogComponent } from "./components/proceeding-mission-tile/reject-mission-dialog/reject-mission-dialog.component";
import { MissionStatusFilterComponent } from "./components/proceeding-missions-list/mission-status-filter/mission-status-filter.component";
import { MissionTypeFilterComponent } from "./components/proceeding-missions-list/mission-type-filter/mission-type-filter.component";
import { ProceedingMissionsListComponent } from "./components/proceeding-missions-list/proceeding-missions-list.component";
import { ZoneFiltersComponent } from "./components/proceeding-missions-list/zone-filters/zone-filters.component";
import { TemporaryZonesFormComponent } from "./components/temporary-zones-form/temporary-zones-form.component";
import { TemporaryZonesComponent } from "./components/temporary-zones/temporary-zones.component";
import { ZoneInfoComponent } from "./components/zone-info/zone-info.component";
import { OperationalSituationApiService } from "./services/operational-situation-api.service";
import { OperationalSituationState } from "./state/operational-situation.state";
import { TemporaryZonesState } from "./state/temporary-zones.state";

@NgModule({
    declarations: [
        OperationalSituationContainerComponent,
        MissionAlertComponent,
        AlertListComponent,
        ProceedingMissionTileComponent,
        ProceedingMissionsListComponent,
        MissionTypeFilterComponent,
        MissionStatusFilterComponent,
        OtherMissionsComponent,
        RejectMissionDialogComponent,
        MissionTimeChangeDialogComponent,
        CheckinTileComponent,
        TemporaryZonesComponent,
        TemporaryZonesFormComponent,
        ZoneInfoComponent,
        ZoneFiltersComponent,
    ],
    imports: [
        CommonModule,
        LetModule,
        PushModule,
        NgxsModule.forFeature([OperationalSituationState, TemporaryZonesState]),
        SharedSupervisorClientModule,
        SharedI18nModule,
        SharedUiModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
        MatTabsModule,
        MatDialogModule,
        MatInputModule,
        MatDatepickerModule,
        CesiumMapModule,
        SharedMissionModule,
        MatTooltipModule,
    ],
    exports: [
        MissionAlertComponent,
        AlertListComponent,
        ProceedingMissionTileComponent,
        MissionStatusFilterComponent,
        MissionTypeFilterComponent,
        MissionTimeChangeDialogComponent,
        CheckinTileComponent,
        TemporaryZonesFormComponent,
        ZoneInfoComponent,
        ZoneFiltersComponent,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmSupOperationalSituation",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: [OperationalSituationApiService],
            useValue: undefined,
        },
        {
            provide: WebsocketService,
            useValue: undefined,
        },
    ],
})
export class OperationalSituationModule {
    public static forRoot(): ModuleWithProviders<OperationalSituationModule> {
        return {
            ngModule: OperationalSituationModule,
            providers: [OperationalSituationApiService, WebsocketService],
        };
    }

    public static forTest(): ModuleWithProviders<OperationalSituationModule> {
        return {
            ngModule: OperationalSituationModule,
            providers: [
                {
                    provide: OperationalSituationApiService,
                    useValue: null,
                },
                {
                    provide: CapabilitiesApiService,
                    useValue: null,
                },
                importProvidersFrom(SharedSupervisorClientModule.forTest()),
            ],
        };
    }
}
