import { Address } from "@dtm-frontend/shared/ui";

export enum NetworkMonitoringErrorType {
    CannotGetInstallations = "CannotGetInstallations",
    CannotUpdateNote = "CannotUpdateNote",
    CannotGetDeviceHistory = "CannotGetDeviceHistory",
}

export enum SectorStatus {
    Active = "ACTIVE",
    ConnectionLost = "CONNECTION_LOST",
}

export interface NetworkMonitoringError {
    type: NetworkMonitoringErrorType;
}

export interface Installation {
    dtmName: string;
    aerials: Aerial[];
}

export interface Aerial {
    dtmName: string;
    id: string;
    name: string;
    sectors: Sector[];
    address: Address;
    installationDate: Date;
}

export interface Sector {
    id: string;
    name: string;
    sectorStatus: SectorStatus;
    last7DaysViolationInMinutes: number;
    last60DaysViolationInMinutes: number;
    last90DaysViolationInMinutes: number;
    totalDaysViolationInMinutes: number;
    azimuth: number;
    minViewAngle: number;
    maxViewAngle: number;
    mountedDevicesHistory: MountedDevice[];
}

export interface DeviceHistory {
    signalViolationType: SignalViolationType;
    installationId: string;
    sectorId: string;
    deviceId: string;
    serialNumber: string;
    startedAt: Date;
    finishedAt: Date | undefined;
    violationTimeInMinutes: number;
    signalViolationId: string;
    note?: DeviceHistoryNote;
}

export interface PageableDeviceHistory {
    deviceHistory: DeviceHistory[];
    pageable: Page;
}

export interface DeviceHistoryNote {
    supervisorName: string;
    modifiedAt: Date;
    content: string;
}

export interface MountedDevice {
    id: string;
}

export interface Page {
    size: number;
    page: number;
    totalElements: number;
}

export interface SignalViolationData {
    supervisorName: string;
    note: string;
    signalViolationId: string;
    installationId: string;
    sectorId: string;
}

export enum SignalViolationType {
    DataLost = "DATA_LOST",
    ConnectionLost = "CONNECTION_LOST",
}
