<div
    class="tile"
    [class.reverse]="vm.reverseName"
    [class.selected]="vm.selected"
    [class.bolder]="vm.bolderName"
    *ngrxLet="{
        reverseName: reverseName$,
        shouldDisplayIcon: shouldDisplayIcon$,
        name: name$,
        status: status$,
        selected: selected$,
        bolderName: bolderName$
    } as vm"
>
    <div class="sector-name">
        <dtm-ui-icon *ngIf="vm.shouldDisplayIcon" name="base-station"></dtm-ui-icon>
        {{ vm.name }}
    </div>
    <supervisor-shared-lib-installation-status [status]="vm.status"></supervisor-shared-lib-installation-status>
</div>
