import { Page, PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM, PhoneNumber } from "@dtm-frontend/shared/ui";
import { AddingPermissionsFilterFormKeys, PilotOperatorQualification, RegulationExemption } from "./adding-permissions.models";

export enum AddingPermissionsOperatorErrorType {
    CannotGetAddingPermissionsOperatorList = "CannotGetAddingPermissionsOperatorList",
    CannotSetAddingPermissionsEmergencyPermission = "CannotSetAddingPermissionsEmergencyPermission",
    CannotRemoveAddingPermissionsEmergencyPermission = "CannotRemoveAddingPermissionsEmergencyPermission",
    CannotGetQualifications = "CannotGetQualifications",
    CannotGetOperatorDetails = "CannotGetOperatorDetails",
    CannotGetRegulationExemptions = "CannotGetRegulationExemptions",
    CannotSetRegulationExemptions = "CannotSetRegulationExemptions",
}

export interface AddingPermissionsOperatorListWithPages extends Page {
    content: AddingPermissionsOperator[];
}

export interface AddingPermissionsOperatorError {
    type: AddingPermissionsOperatorErrorType;
}

export enum AddingPermissionsOperatorFilterFormKeys {
    EmergencyPermission = "emergency",
    HasRegulationExemptionsPermission = "regulationExemptions",
}

export enum AddingPermissionsOperatorType {
    Personal = "PERSONAL",
    Enterprise = "ENTERPRISE",
}

export interface AddingPermissionsOperatorFilterParams {
    [PAGE_NUMBER_QUERY_PARAM]: number;
    [PAGE_SIZE_QUERY_PARAM]: number;
    [AddingPermissionsFilterFormKeys.ActiveTabIndex]?: number;
    [AddingPermissionsFilterFormKeys.SearchPhrase]?: string;
    [AddingPermissionsOperatorFilterFormKeys.EmergencyPermission]?: boolean;
    [AddingPermissionsOperatorFilterFormKeys.HasRegulationExemptionsPermission]?: boolean;
}

export interface AddingPermissionsOperator {
    name: string;
    operatorNumber: string;
    id: string;
    type: AddingPermissionsOperatorType;
    email: string;
    phoneNumber: PhoneNumber;
    [AddingPermissionsOperatorFilterFormKeys.EmergencyPermission]: boolean;
    hasRegulationExemptions: boolean;
    details?: AddingPermissionsOperatorDetails;
}

export interface AddingPermissionsOperatorDetails {
    qualifications: PilotOperatorQualification[];
    regulationExemptions: RegulationExemption[];
}
