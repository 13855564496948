<div class="grid">
    <mat-card *ngrxLet="activeTabIndex$ as activeTabIndex">
        <supervisor-shared-lib-adding-permissions-header [activeTabIndex]="activeTabIndex" (tabIndexUpdate)="updateTabIndex($event)">
            <ng-container operatorList>
                <supervisor-shared-lib-adding-permissions-filters
                    [initialFilters]="operatorsFilters$ | ngrxPush"
                    [activeTabIndex]="activeTabIndex"
                    (filtersChange)="navigateByParams($event, AddingPermissionsTabType.Operators)"
                    (filterPermissionChange)="resetPageNumber($event, AddingPermissionsTabType.Operators)"
                ></supervisor-shared-lib-adding-permissions-filters>

                <supervisor-shared-lib-adding-permissions-operator-list
                    [addingPermissionsList]="addingPermissionsOperatorList$ | ngrxPush"
                    [pagination]="addingPermissionsOperatorPages$ | ngrxPush"
                    [isAddingPermissionsListProcessing]="isAddingPermissionsOperatorsListProcessing$ | ngrxPush"
                    [addingPermissionsListError]="addingPermissionsOperatorsListError$ | ngrxPush"
                    (addingPermissionsListRefresh)="getAddingPermissionsOperatorsList()"
                    (pageChange)="addingPermissionsPageChange($event, AddingPermissionsFiltersTabKeys.OperatorsFilters)"
                    (permissionChange)="changeOperatorPermission($event)"
                    (regulationExemptionFormOpen)="openRegulationExemptionForm($event)"
                    (operatorDetailsGet)="getOperatorDetails($event)"
                ></supervisor-shared-lib-adding-permissions-operator-list>
            </ng-container>

            <ng-container pilotList>
                <supervisor-shared-lib-adding-permissions-filters
                    [initialFilters]="pilotsFilters$ | ngrxPush"
                    [activeTabIndex]="activeTabIndex"
                    (filtersChange)="navigateByParams($event, AddingPermissionsTabType.Pilots)"
                    (filterPermissionChange)="resetPageNumber($event, AddingPermissionsTabType.Operators)"
                ></supervisor-shared-lib-adding-permissions-filters>

                <supervisor-shared-lib-adding-permissions-pilot-list
                    [addingPermissionsList]="addingPermissionsPilotList$ | ngrxPush"
                    [pagination]="addingPermissionsPilotPages$ | ngrxPush"
                    [isAddingPermissionsListProcessing]="isAddingPermissionsPilotsListProcessing$ | ngrxPush"
                    [addingPermissionsListError]="addingPermissionsPilotsListError$ | ngrxPush"
                    (addingPermissionsListRefresh)="getAddingPermissionsPilotList()"
                    (pageChange)="addingPermissionsPageChange($event, AddingPermissionsFiltersTabKeys.PilotsFilters)"
                    (permissionChange)="changePilotPermission($event)"
                    (qualificationsGet)="getPilotQualifications($event)"
                ></supervisor-shared-lib-adding-permissions-pilot-list>
            </ng-container>
        </supervisor-shared-lib-adding-permissions-header>
    </mat-card>
</div>
