import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, forwardRef } from "@angular/core";
import {
    ControlValueAccessor,
    FormArray,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validators,
} from "@angular/forms";
import { MatLegacyCheckboxChange as MatCheckboxChange } from "@angular/material/legacy-checkbox";
import { CylinderEntity, MapEntity, MapEntityType, MapUtils } from "@dtm-frontend/shared/map/cesium";
import { PhoneNumber, requiredValidForSmsPhoneNumberValidator } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import {
    DEFAULT_PHONE_COUNTRY_CODE,
    FunctionUtils,
    LocalComponentStore,
    MILLISECONDS_IN_DAY,
    ONLY_WHITE_SPACES_VALIDATION_PATTERN,
} from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Cartesian3 } from "@pansa/ngx-cesium";
import equal from "fast-deep-equal";
import { combineLatest, filter, map } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { GeographicalZone, InfoZone, RestrictionType } from "../../models/operational.situation.models";
import {
    AltitudeType,
    Elevation,
    TemporaryZoneFormData,
    WeeklyActivationData,
    ZoneObjectType,
    infoZoneIconMap,
} from "../../models/temporary-zones.models";
import { weekDayToWeekDayNameMapper } from "../../services/operational-situation.converters";

const MAX_ZONE_NAME_LENGTH = 20;
const MAX_TEXTAREA_DESCRIPTION_LENGTH = 200;
const TODAY_DATE = new Date();

// TODO: DTM-3889 - Lists will be extended
const DRAP_RESTRICTIONS = [RestrictionType.FlightsProhibited];
const DRAR_RESTRICTIONS = [RestrictionType.ApprovalRequired];
const FULL_WEEK_DAYS_BETWEEN = 6;
const FULL_WEEK_DAYS = FULL_WEEK_DAYS_BETWEEN + 1;

interface TemporaryZonesForm {
    zoneName: FormControl<string>;
    zoneType: FormControl<GeographicalZone | null>;
    maxAltitude: FormControl<number | null>;
    minAltitude: FormControl<number | null>;
    minAltitudeType: FormControl<AltitudeType>;
    restriction: FormControl<string | null>;
    infoZoneType: FormControl<InfoZone | null>;
    zoneObjectType: FormControl<ZoneObjectType | null>;
    zoneDescription: FormControl<string>;
    zoneStartDate: FormControl<Date | null>;
    zoneEndDate: FormControl<Date | null>;
    zoneStartTime: FormControl<Date | null>;
    zoneEndTime: FormControl<Date | null>;
    phoneNumber: FormControl<PhoneNumber | null>;
    note: FormControl<string>;
    isValidityPeriod: FormControl<boolean>;
    activityType: FormControl<ActivityZoneType>;
    dailyActivation: FormGroup<DailyActivationRangeForm>;
    weeklyActivationList: FormArray<FormGroup<WeeklyActivationRangeForm>>;
}

interface WeeklyActivationRangeForm extends DailyActivationRangeForm {
    rangeDate: FormControl<Date>;
    isActivated: FormControl<boolean>;
}

interface DailyActivationRangeForm {
    startTime: FormControl<Date | null>;
    endTime: FormControl<Date | null>;
}

interface TemporaryZonesFormComponentState {
    restrictionTypeList: RestrictionType[];
    isPhoneNumberVisible: boolean;
    isNoteFormVisible: boolean;
    isElevationProcessing: boolean;
    mapEntity: MapEntity | undefined;
    elevation: Elevation | undefined;
    canDeletePhoneNumber: boolean;
    activationHours: WeeklyActivationData[] | undefined;
    geographicZones: GeographicalZone[];
}

enum ActivityZoneType {
    Constant = "Constant",
    Variable = "Variable",
}

@UntilDestroy()
@Component({
    selector: "supervisor-shared-lib-temporary-zones-form[mapEntity]",
    templateUrl: "./temporary-zones-form.component.html",
    styleUrls: ["./temporary-zones-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TemporaryZonesFormComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => TemporaryZonesFormComponent),
            multi: true,
        },
    ],
})
export class TemporaryZonesFormComponent implements ControlValueAccessor, Validators {
    @Input() public set mapEntity(value: MapEntity | undefined) {
        this.localStore.patchState({ mapEntity: value });
    }
    @Input() public set isElevationProcessing(value: BooleanInput) {
        this.localStore.patchState({ isElevationProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set elevation(value: Elevation | undefined) {
        this.localStore.patchState({ elevation: value });
    }
    @Input() public set geographicZones(value: GeographicalZone[] | undefined) {
        this.localStore.patchState({ geographicZones: value ?? [] });
    }

    protected readonly temporaryZonesForm = new FormGroup<TemporaryZonesForm>({
        zoneName: new FormControl("", {
            nonNullable: true,
            validators: [
                Validators.required,
                Validators.maxLength(MAX_ZONE_NAME_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
        }),
        zoneType: new FormControl(null, Validators.required),
        maxAltitude: new FormControl({ value: null, disabled: true }, [Validators.required]),
        minAltitude: new FormControl({ value: null, disabled: true }, [Validators.required, Validators.min(0)]),
        minAltitudeType: new FormControl({ value: AltitudeType.Ground, disabled: true }, { nonNullable: true }),
        zoneStartDate: new FormControl(null, Validators.required),
        zoneEndDate: new FormControl(null, Validators.required),
        zoneStartTime: new FormControl(null, Validators.required),
        zoneEndTime: new FormControl(null, Validators.required),
        restriction: new FormControl(null, Validators.required),
        infoZoneType: new FormControl({ value: null, disabled: true }, Validators.required),
        zoneObjectType: new FormControl({ value: null, disabled: true }, Validators.required),
        zoneDescription: new FormControl("", {
            nonNullable: true,
            validators: [Validators.maxLength(MAX_TEXTAREA_DESCRIPTION_LENGTH)],
        }),
        phoneNumber: new FormControl({ value: null, disabled: true }, requiredValidForSmsPhoneNumberValidator),
        note: new FormControl("", {
            nonNullable: true,
            validators: [Validators.maxLength(MAX_TEXTAREA_DESCRIPTION_LENGTH)],
        }),
        isValidityPeriod: new FormControl(false, { nonNullable: true }),
        activityType: new FormControl(ActivityZoneType.Constant, { nonNullable: true }),
        dailyActivation: new FormGroup<DailyActivationRangeForm>({
            startTime: new FormControl({ value: null, disabled: true }, Validators.required),
            endTime: new FormControl({ value: null, disabled: true }, Validators.required),
        }),
        weeklyActivationList: new FormArray<FormGroup<WeeklyActivationRangeForm>>([]),
    });

    protected readonly restrictionTypeList$ = this.localStore.selectByKey("restrictionTypeList");
    protected readonly isPhoneNumberVisible$ = this.localStore.selectByKey("isPhoneNumberVisible");
    protected readonly isNoteFormVisible$ = this.localStore.selectByKey("isNoteFormVisible");
    protected readonly isElevationProcessing$ = this.localStore.selectByKey("isElevationProcessing");
    protected readonly mapEntity$ = this.localStore.selectByKey("mapEntity");
    protected readonly elevation$ = this.localStore.selectByKey("elevation");
    protected readonly canDeletePhoneNumber$ = this.localStore.selectByKey("canDeletePhoneNumber");
    protected readonly geographicZones$ = this.localStore.selectByKey("geographicZones");
    protected readonly serializableMapEntity$ = this.mapEntity$.pipe(
        filter(FunctionUtils.isTruthy),
        filter(
            (mapEntity): mapEntity is CylinderEntity => mapEntity.type === MapEntityType.Cylinder || mapEntity.type === MapEntityType.Prism
        ),
        map((mapEntity) => MapUtils.convertCartesian3ToSerializableCartographic(mapEntity.center))
    );

    protected readonly GeographicalZone = GeographicalZone;
    protected readonly AltitudeType = AltitudeType;
    protected readonly MapEntityType = MapEntityType;
    protected readonly ActivityZoneType = ActivityZoneType;
    protected readonly infoZoneValues = infoZoneIconMap;
    protected readonly ZoneObjectType = ZoneObjectType;
    protected readonly DEFAULT_PHONE_COUNTRY_CODE = DEFAULT_PHONE_COUNTRY_CODE;
    protected readonly TODAY_DATE = TODAY_DATE;
    protected readonly MAX_TEXTAREA_DESCRIPTION_LENGTH = MAX_TEXTAREA_DESCRIPTION_LENGTH;
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;

    private propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: TemporaryZoneFormData) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    constructor(
        private readonly localStore: LocalComponentStore<TemporaryZonesFormComponentState>,
        private readonly translocoHelper: TranslationHelperService
    ) {
        this.localStore.setState({
            restrictionTypeList: [RestrictionType.ApprovalRequired, RestrictionType.FlightsProhibited],
            isPhoneNumberVisible: false,
            isNoteFormVisible: false,
            isElevationProcessing: false,
            mapEntity: undefined,
            elevation: undefined,
            canDeletePhoneNumber: true,
            activationHours: undefined,
            geographicZones: [],
        });

        this.temporaryZonesForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.propagateChange(this.temporaryZonesForm.value as TemporaryZoneFormData);
            this.propagateTouch();
        });

        this.setMinAltitudeValidators();
        this.listenOnElevation();
        this.listenOnZoneType();
        this.listenOnDateRange();
        this.listenOnZoneTimeControls();
        this.listenOnValidityPeriodControl();
    }

    public registerOnChange(fn: (value: TemporaryZoneFormData) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(): ValidationErrors | null {
        return this.temporaryZonesForm.invalid ? { invalidTemporaryZone: true } : null;
    }

    public writeValue(value: TemporaryZoneFormData | undefined): void {
        if (value) {
            this.temporaryZonesForm.reset(value, { emitEvent: false });
            this.updateRestrictionList(value.zoneType);

            if (value.zoneObjectType) {
                this.temporaryZonesForm.controls.zoneObjectType.enable({ emitEvent: false });
            }

            if (value.phoneNumber?.number) {
                this.temporaryZonesForm.controls.phoneNumber.enable({ emitEvent: false });
                this.localStore.patchState({ isPhoneNumberVisible: true });
            }

            if (value.activationHours?.length) {
                this.temporaryZonesForm.controls.activityType.patchValue(ActivityZoneType.Variable);
                this.temporaryZonesForm.controls.dailyActivation.disable({ emitEvent: false });
                this.localStore.patchState({ activationHours: value.activationHours });
            }

            if (value.infoZoneType) {
                this.temporaryZonesForm.controls.infoZoneType.enable({ emitEvent: false });
            }
        } else {
            this.temporaryZonesForm.reset();
        }
    }

    protected displayZoneObjectType(type: InfoZone): void {
        if (this.isGatheringType(type)) {
            this.temporaryZonesForm.controls.zoneObjectType.enable({ emitEvent: false });

            return;
        }
        this.temporaryZonesForm.controls.zoneObjectType.reset();
        this.temporaryZonesForm.controls.zoneObjectType.disable({ emitEvent: false });
    }

    // NOTE: used in template with invoke pipe to prevent circular updates between time fields
    protected getDateFromTime(value: number | undefined) {
        return value ? new Date(value) : null;
    }

    protected updateRestrictionList(value: GeographicalZone): void {
        switch (value) {
            case GeographicalZone.DRAP:
                this.localStore.patchState({ restrictionTypeList: DRAP_RESTRICTIONS });
                this.temporaryZonesForm.controls.restriction.patchValue(RestrictionType.FlightsProhibited);
                this.temporaryZonesForm.controls.restriction.enable();
                break;
            case GeographicalZone.DRAR:
                this.localStore.patchState({ restrictionTypeList: DRAR_RESTRICTIONS });
                this.temporaryZonesForm.controls.restriction.patchValue(RestrictionType.ApprovalRequired);
                this.temporaryZonesForm.controls.restriction.enable();
                break;
            case GeographicalZone.LocalInformation:
                this.temporaryZonesForm.controls.restriction.disable();
                this.temporaryZonesForm.controls.restriction.patchValue(null);
                break;
            case GeographicalZone.DRAI:
                this.localStore.patchState({ restrictionTypeList: [] });
                this.temporaryZonesForm.controls.restriction.disable();
                this.temporaryZonesForm.controls.restriction.patchValue(null);
                break;
        }
    }

    protected changePhoneNumberFormVisibility(isVisible: boolean): void {
        if (!isVisible) {
            this.localStore.patchState({ isPhoneNumberVisible: true });
            this.temporaryZonesForm.controls.phoneNumber.enable();

            return;
        }

        this.localStore.patchState({ isPhoneNumberVisible: false });
        this.temporaryZonesForm.controls.phoneNumber.disable();
    }

    protected changeNoteFormVisibility(isVisible: boolean): void {
        if (!isVisible) {
            this.localStore.patchState({ isNoteFormVisible: true });

            return;
        }

        this.localStore.patchState({ isNoteFormVisible: false });
        this.temporaryZonesForm.controls.note.reset();
    }

    protected setMinAltitudeType(type: AltitudeType): void {
        this.temporaryZonesForm.controls.minAltitudeType.patchValue(type);

        if (type === AltitudeType.Value) {
            this.temporaryZonesForm.controls.minAltitude.enable();

            return;
        }

        this.temporaryZonesForm.controls.minAltitude.disable();
        this.temporaryZonesForm.controls.minAltitude.reset();
    }

    protected setActivityType(type: ActivityZoneType): void {
        this.temporaryZonesForm.controls.activityType.patchValue(type);

        if (type === ActivityZoneType.Constant) {
            this.temporaryZonesForm.controls.dailyActivation.enable();
            this.temporaryZonesForm.controls.dailyActivation.reset();
            this.temporaryZonesForm.controls.weeklyActivationList.controls.forEach((group) => {
                group.controls.startTime.disable({ emitEvent: false });
                group.controls.endTime.disable({ emitEvent: false });
            });

            return;
        }

        this.temporaryZonesForm.controls.dailyActivation.disable();
        this.turnOnWeeklyActivationControls();
    }

    protected convertPolylinePoints(points: Cartesian3[]): { latitude: number; longitude: number }[] {
        return points.map((position) => {
            const { latitude, longitude } = MapUtils.convertCartesian3ToSerializableCartographic(position);

            return {
                latitude,
                longitude,
            };
        });
    }

    protected resetZoneStartTime(): void {
        this.temporaryZonesForm.controls.zoneStartTime.patchValue(null);
    }

    protected resetZoneEndTime(): void {
        this.temporaryZonesForm.controls.zoneEndTime.patchValue(null);
    }

    protected changeWeekDayFormActivity({ checked }: MatCheckboxChange, index: number): void {
        const weeklyActivationForm: FormGroup<WeeklyActivationRangeForm> = this.temporaryZonesForm.controls.weeklyActivationList.at(index);
        if (!checked) {
            weeklyActivationForm.controls.startTime.reset();
            weeklyActivationForm.controls.startTime.disable();
            weeklyActivationForm.controls.endTime.reset();
            weeklyActivationForm.controls.endTime.disable();

            return;
        }

        weeklyActivationForm.controls.startTime.enable();
        weeklyActivationForm.controls.endTime.enable();
    }

    protected isGatheringType(infoZoneType: InfoZone | null): boolean {
        return infoZoneType === InfoZone.TemporaryGathering || infoZoneType === InfoZone.PermanentGathering;
    }

    private setMinAltitudeValidators(): void {
        this.temporaryZonesForm.controls.maxAltitude.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            if (value) {
                this.temporaryZonesForm.controls.minAltitude.setValidators([Validators.required, Validators.max(value)]);
            } else {
                this.temporaryZonesForm.controls.minAltitude.setValidators([Validators.required, Validators.min(0)]);
            }
            this.temporaryZonesForm.controls.minAltitude.updateValueAndValidity();
        });
    }

    private listenOnElevation(): void {
        this.elevation$.pipe(untilDestroyed(this)).subscribe((elevation) => {
            if (elevation) {
                this.temporaryZonesForm.controls.maxAltitude.enable();
                this.temporaryZonesForm.controls.minAltitudeType.enable();
                this.temporaryZonesForm.controls.maxAltitude.setValidators([Validators.required, Validators.min(elevation.max)]);
            } else {
                this.temporaryZonesForm.controls.maxAltitude.disable();
                this.temporaryZonesForm.controls.maxAltitude.reset();
                this.temporaryZonesForm.controls.minAltitudeType.disable();
                this.temporaryZonesForm.controls.minAltitudeType.reset();
                this.temporaryZonesForm.controls.maxAltitude.setValidators([Validators.required]);
            }

            this.temporaryZonesForm.controls.minAltitude.updateValueAndValidity();
        });
    }

    private listenOnZoneType(): void {
        this.temporaryZonesForm.controls.zoneType.valueChanges.pipe(untilDestroyed(this)).subscribe((type) => {
            this.changePhoneNumberFormVisibility(true);
            this.localStore.patchState({ canDeletePhoneNumber: true });

            if (type === GeographicalZone.LocalInformation) {
                this.temporaryZonesForm.controls.zoneDescription.setValidators(Validators.required);
                this.temporaryZonesForm.controls.zoneDescription.updateValueAndValidity();
                this.temporaryZonesForm.controls.infoZoneType.enable({ emitEvent: false });

                return;
            }

            this.temporaryZonesForm.controls.zoneObjectType.disable({ emitEvent: false });
            this.temporaryZonesForm.controls.infoZoneType.disable({ emitEvent: false });
            this.temporaryZonesForm.controls.infoZoneType.disable({ emitEvent: false });
            this.temporaryZonesForm.controls.zoneDescription.clearValidators();
            this.temporaryZonesForm.controls.zoneDescription.updateValueAndValidity();

            if (type === GeographicalZone.DRAR) {
                this.changePhoneNumberFormVisibility(false);
                this.localStore.patchState({ canDeletePhoneNumber: false });
            }
        });
    }

    private turnOnWeeklyActivationControls(): void {
        this.temporaryZonesForm.controls.weeklyActivationList.controls.forEach((group) => {
            group.controls.startTime.reset();
            group.controls.endTime.reset();
            group.controls.startTime.enable({ emitEvent: false });
            group.controls.endTime.enable({ emitEvent: false });
        });
    }

    private listenOnDateRange(): void {
        combineLatest([
            this.temporaryZonesForm.controls.zoneStartDate.valueChanges,
            this.temporaryZonesForm.controls.zoneEndDate.valueChanges,
        ])
            .pipe(distinctUntilChanged(equal), untilDestroyed(this))
            .subscribe(([startDate, endDate]) => {
                this.temporaryZonesForm.controls.weeklyActivationList.clear();
                this.temporaryZonesForm.controls.isValidityPeriod.disable();

                if (startDate && endDate) {
                    this.calculateDateRangeBetweenDays(startDate, endDate);
                    this.temporaryZonesForm.controls.isValidityPeriod.enable();

                    return;
                }

                this.temporaryZonesForm.controls.isValidityPeriod.patchValue(false);
            });
    }

    private calculateDateRangeBetweenDays(startDate: Date, endDate: Date): void {
        if (this.isMoreThanWeek(startDate, endDate)) {
            const startDateCopy = new Date(startDate);
            const firstDayOfWeek = new Date(startDateCopy.setDate(startDateCopy.getDate() - startDateCopy.getDay() + 1));
            const lastDayOfWeek = new Date(startDateCopy.setDate(startDateCopy.getDate() - startDateCopy.getDay() + FULL_WEEK_DAYS));

            this.updateWeekDaysRangeList(firstDayOfWeek, lastDayOfWeek);

            return;
        }

        this.updateWeekDaysRangeList(startDate, endDate);
    }

    private listenOnZoneTimeControls(): void {
        this.temporaryZonesForm.controls.zoneStartTime.valueChanges
            .pipe(distinctUntilChanged(equal), untilDestroyed(this))
            .subscribe((zoneStartTimeDate) => {
                const startDateControl = this.temporaryZonesForm.controls.zoneStartDate;
                const zoneStartTimeControl = this.temporaryZonesForm.controls.zoneStartTime;

                if (!startDateControl.value || !zoneStartTimeDate) {
                    return;
                }

                startDateControl.patchValue(
                    new Date(startDateControl.value.setHours(zoneStartTimeDate.getHours(), zoneStartTimeDate.getMinutes()))
                );
                zoneStartTimeControl.patchValue(new Date(startDateControl.value));
            });

        this.temporaryZonesForm.controls.zoneEndTime.valueChanges
            .pipe(distinctUntilChanged(equal), untilDestroyed(this))
            .subscribe((value) => {
                const endDateControl = this.temporaryZonesForm.controls.zoneEndDate;

                if (!endDateControl.value || !value) {
                    return;
                }

                endDateControl.patchValue(new Date(endDateControl.value.setHours(value.getHours(), value.getMinutes())));
                this.temporaryZonesForm.controls.zoneEndTime.patchValue(new Date(endDateControl.value));
            });
    }

    private listenOnValidityPeriodControl(): void {
        this.temporaryZonesForm.controls.isValidityPeriod.valueChanges.pipe(untilDestroyed(this)).subscribe((isChecked: boolean) => {
            if (!isChecked) {
                this.temporaryZonesForm.controls.dailyActivation.disable({ emitEvent: false });
                this.temporaryZonesForm.controls.dailyActivation.reset();
                this.temporaryZonesForm.controls.weeklyActivationList.reset();
                this.temporaryZonesForm.controls.activityType.patchValue(ActivityZoneType.Constant);

                return;
            }

            const zoneStartDate = this.temporaryZonesForm.controls.zoneStartDate.value;
            const zoneEndDate = this.temporaryZonesForm.controls.zoneEndDate.value;

            if (!this.temporaryZonesForm.controls.weeklyActivationList.length && zoneStartDate && zoneEndDate) {
                this.calculateDateRangeBetweenDays(zoneStartDate, zoneEndDate);
            }

            const activationHours = this.localStore.selectSnapshotByKey("activationHours");

            if (!activationHours) {
                this.temporaryZonesForm.controls.dailyActivation.enable({ emitEvent: false });
            }
        });
    }

    private generateRangeFormGroup(date: Date): FormGroup<WeeklyActivationRangeForm> {
        return new FormGroup<WeeklyActivationRangeForm>({
            startTime: new FormControl({ value: null, disabled: true }, Validators.required),
            endTime: new FormControl({ value: null, disabled: true }, Validators.required),
            rangeDate: new FormControl(date, { nonNullable: true }),
            isActivated: new FormControl(true, { nonNullable: true }),
        });
    }

    private isMoreThanWeek(startDate: Date, endDate: Date): boolean {
        return this.calculateNumberDaysBetween(startDate, endDate) >= FULL_WEEK_DAYS_BETWEEN;
    }

    private updateWeekDaysRangeList(startDate: Date, endDate: Date): void {
        const activationHours = this.localStore.selectSnapshotByKey("activationHours");
        this.temporaryZonesForm.controls.weeklyActivationList.clear();
        this.getDatesBetween(startDate, endDate).forEach((date) => {
            this.temporaryZonesForm.controls.weeklyActivationList.push(this.generateRangeFormGroup(date));
        });

        if (this.temporaryZonesForm.controls.activityType.value === ActivityZoneType.Variable) {
            this.turnOnWeeklyActivationControls();
        }

        if (activationHours) {
            this.relateActivationHoursToGeneratedDays(activationHours);
        }
    }

    private calculateNumberDaysBetween(startDate: Date, endDate: Date): number {
        return Math.round(Math.abs((startDate.getTime() - endDate.getTime()) / MILLISECONDS_IN_DAY));
    }

    private getDatesBetween(startDate: Date, endDate: Date): Date[] {
        const dates = [];
        const currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
    }

    private relateActivationHoursToGeneratedDays(activationList: WeeklyActivationData[]) {
        this.temporaryZonesForm.controls.weeklyActivationList.controls.map((control) => {
            if (!control.value.rangeDate) {
                return;
            }
            const weekDay = control.value.rangeDate.getDay();
            const activation = this.getActivationTimeByWeekDay(activationList, weekDay);

            if (!activation) {
                return;
            }

            const [startTimeHours, startTimeMinutes] = activation.startTime.split(":");
            const [endTimeHours, endTimeMinutes] = activation.endTime.split(":");

            control.patchValue({
                ...control.value,
                startTime: new Date(control.value.rangeDate.setHours(+startTimeHours, +startTimeMinutes)),
                endTime: new Date(control.value.rangeDate.setHours(+endTimeHours, +endTimeMinutes)),
            });
        });
    }

    private getActivationTimeByWeekDay(activationList: WeeklyActivationData[], weekDay: number): WeeklyActivationData | undefined {
        return activationList.filter((activation) => activation.day === weekDayToWeekDayNameMapper.get(weekDay))[0];
    }
}
