import { DtmStats } from "../models/shared-supervisor-client.models";

export interface DtmStatsResponseBody {
    [dtmName: string]: {
        mission: {
            total: number;
            bvlos: number;
            flightsOutsideMission: number;
            flightsWithEmergencies: number;
            violatedFlights: boolean;
        };
        device: {
            total: number;
            violated: number;
            violatedDevices: boolean;
        };
    };
}

export function convertDtmStatsResponseBodyToDtmStats(responseBody: DtmStatsResponseBody): { [dtmName: string]: DtmStats } {
    return Object.keys(responseBody).reduce(
        (acc, dtmName) => ({
            ...acc,
            [dtmName]: {
                mission: {
                    total: responseBody[dtmName].mission.total,
                    bvlos: responseBody[dtmName].mission.bvlos,
                    flightsOutsideMission: responseBody[dtmName].mission.flightsOutsideMission,
                    flightsWithEmergencies: responseBody[dtmName].mission.flightsWithEmergencies,
                    areViolatedFlights: responseBody[dtmName].mission.violatedFlights,
                },
                device: {
                    total: responseBody[dtmName].device.total,
                    violated: responseBody[dtmName].device.violated,
                    areViolatedDevices: responseBody[dtmName].device.violatedDevices,
                },
            } satisfies DtmStats,
        }),
        {}
    );
}
