<div
    class="tile"
    *ngrxLet="{ checkin: checkin$, isExpanded: isExpanded$, isSelected: isSelected$ } as vm"
    [class.in-realization]="vm.checkin.status === CheckinStatus.InRealization"
    [class.expired]="vm.checkin.status === CheckinStatus.Expired"
    [class.active]="vm.checkin.status === CheckinStatus.Submitted"
    [class.selected]="vm.isSelected"
    (click)="selectCheckin(vm.checkin)"
>
    <div class="basic-info">
        <div class="mission-type">
            <dtm-ui-icon name="eye"></dtm-ui-icon>
            <div class="mission-name">
                {{
                    "dtmSupOperationalSituation.proceedingMissionTile.missionLabel"
                        | transloco : { value: missionCount$ | ngrxPush | number : "2." }
                }}
            </div>
            <div class="id" [matTooltip]="vm.checkin.id" matTooltipPosition="above">
                {{ "dtmSupOperationalSituation.checkinTile.idLabel" | transloco : { value: vm.checkin.id | invoke : displayShortId } }}
            </div>
            <div class="uav-name">{{ vm.checkin.uavName }}</div>
        </div>
        <div class="actions">
            <button *ngIf="vm.isSelected" class="button-icon zoom" type="button" (click)="checkinZoom.emit(vm.checkin.geometry)">
                <dtm-ui-icon name="road-map"></dtm-ui-icon>
            </button>
            <button class="button-icon" type="button" (click)="expandContent()">
                <dtm-ui-icon [name]="vm.isExpanded ? 'arrow-up' : 'arrow-down'"></dtm-ui-icon>
            </button>
        </div>
    </div>
    <div class="checkin-details">
        <div class="operation-type">
            <dtm-ui-icon name="map-pin"></dtm-ui-icon>
            {{ "dtmSupOperationalSituation.checkinTile.checkinLabel" | transloco }}
        </div>
        <dtm-mission-mission-time-range
            class="time-range"
            [missionTimeRange]="missionTimeRange$ | ngrxPush"
        ></dtm-mission-mission-time-range>
        <div class="events">
            <dtm-ui-icon name="alarm-warning" [class.emergency]="vm.checkin.isEmergency"></dtm-ui-icon>
        </div>
    </div>
    <div class="expanded-area" *ngIf="vm.isExpanded" @slideIn>
        <div class="additional-info">
            <div class="phone-number">
                <dtm-ui-icon name="phone"></dtm-ui-icon>
                {{
                    vm.checkin.phoneNumber
                        ? (vm.checkin.phoneNumber | formatPhoneNumber)
                        : ("dtmSupOperationalSituation.checkinTile.noPhoneNumber" | transloco)
                }}
            </div>
            <div class="height-agl">
                <dtm-ui-icon name="cylinder-line"></dtm-ui-icon>
                <ng-container *ngIf="!vm.checkin.amslCeil; else amslTemplate">{{
                    "dtmSupOperationalSituation.checkinTile.aglLabel"
                        | transloco : { agl: vm.checkin.heightAgl | localizeNumber : { maximumFractionDigits: 0 } }
                }}</ng-container>
                <ng-template #amslTemplate>{{
                    "dtmSupOperationalSituation.checkinTile.amslLabel"
                        | transloco
                            : {
                                  agl: vm.checkin.heightAgl | localizeNumber : { maximumFractionDigits: 0 },
                                  amsl: vm.checkin.amslCeil | localizeNumber : { maximumFractionDigits: 0 }
                              }
                }}</ng-template>
            </div>
        </div>
    </div>
</div>
