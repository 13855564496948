import { GeoJSON, IconName, PhoneNumber } from "@dtm-frontend/shared/ui";
import { Feature as GeoJSONFeature } from "@turf/helpers";
import { GeographicalZone, InfoZone } from "./operational.situation.models";

export interface ZoneTypeIcon {
    type: InfoZone;
    icon: IconName;
}

export interface SortZone {
    property: string;
    direction: string;
}

export enum ZoneObjectType {
    Education = "EDUCATION",
    Sport = "SPORT_AND_RECREATION",
    Markets = "MARKETS",
    ReligiousObjects = "RELIGIOUS_OBJECTS",
    Gatherings = "GATHERINGS",
    Other = "OTHER",
}

export interface ZoneFilters {
    zoneFiltersData: ZoneFiltersData;
    zoneType: TemporaryZoneType;
}

export interface ZoneSortCriteria {
    property: string;
    direction: string;
}

export interface ZoneFiltersData {
    sort: string | null;
    name: string | null;
    geographicalZoneType: GeographicalZone[] | null;
    infoZoneType: InfoZone | null;
    zoneObjectType: ZoneObjectType | null;
    dateFrom: Date | null;
    dateTo: Date | null;
    timeFrom: Date | null;
    timeTo: Date | null;
}

export interface WeeklyActivationData {
    day: WeekDay;
    startTime: string;
    endTime: string;
}

export enum WeekDay {
    Monday = "MONDAY",
    Tuesday = "TUESDAY",
    Wednesday = "WEDNESDAY",
    Thursday = "THURSDAY",
    Friday = "FRIDAY",
    Saturday = "SATURDAY",
    Sunday = "SUNDAY",
}

export const infoZoneIconMap: ZoneTypeIcon[] = [
    { type: InfoZone.PermanentGathering, icon: "complex-team-fill" },
    { type: InfoZone.TemporaryGathering, icon: "complex-team-fill-less" },
    { type: InfoZone.Obstacles, icon: "barricade-fill" },
    { type: InfoZone.Landings, icon: "hems-emr" },
    { type: InfoZone.AdsbReceivers, icon: "base-station-fill" },
    { type: InfoZone.AdsbCoverMap, icon: "signal-tower-fill" },
    { type: InfoZone.OtherInformation, icon: "stack-fill" },
];

export const sortZoneCriteria: SortZone[] = [
    { property: "StartTimeDesc", direction: "startTime,DESC" },
    { property: "StartTimeAsc", direction: "startTime,ASC" },
    { property: "CreatedDesc", direction: "createdAt,DESC" },
    { property: "CreatedAsc", direction: "createdAt,ASC" },
];

export enum TemporaryZoneType {
    Zone = "Zone",
    Draft = "Draft",
    Archive = "Archive",
}

export enum AltitudeType {
    Ground = "GND",
    Value = "Value",
}

export enum TemporaryZoneTab {
    CurrentlyValid = 0,
    Drafts = 1,
    Archival = 2,
}

export interface Zone {
    zoneName: string;
    zoneType: GeographicalZone;
}

export interface Elevation {
    min: number;
    max: number;
}

export interface BasicTemporaryZone {
    id: string;
    type: GeographicalZone;
    designator: string;
    minAltitude: number;
    maxAltitude: number;
    startTime: Date;
    endTime: Date;
    isH24: boolean;
    dtmName?: string;
}

export interface DailyActivationData {
    startTime: string;
    endTime: string;
}

export interface DailyActivation {
    startTime: Date;
    endTime: Date;
}

export interface WeeklyActivation extends DailyActivation {
    rangeDate: Date;
}

export interface TemporaryZoneFormData {
    zoneName: string;
    zoneType: GeographicalZone;
    maxAltitude: number;
    minAltitude: number;
    minAltitudeType?: AltitudeType;
    restriction?: string;
    zoneDescription: string;
    zoneStartDate: Date;
    zoneEndDate: Date;
    zoneStartTime: Date;
    zoneEndTime: Date;
    phoneNumber?: PhoneNumber;
    note?: string;
    infoZoneType?: InfoZone;
    zoneObjectType?: ZoneObjectType;
    dailyActivation: DailyActivation | undefined;
    weeklyActivationList?: WeeklyActivation[];
    isValidityPeriod: boolean;
    activationHours?: WeeklyActivationData[];
}

export interface TemporaryZone {
    area?: GeoJSONFeature;
    isH24: boolean;
    coordinates: GeoJSON;
    zoneName: string;
    zoneType: GeographicalZone;
    maxAltitude: number;
    minAltitude: number;
    minAltitudeType?: AltitudeType;
    zoneDescription: string;
    zoneStartDate: Date;
    zoneEndDate: Date;
    phoneNumber?: PhoneNumber;
    createdAt: Date;
    note?: string;
    infoZoneType?: InfoZone;
    zoneObjectType?: ZoneObjectType;
    dailyActivations?: {
        startTime: string;
        endTime: string;
    };
    weeklyActivations?: WeeklyActivationData[];
}
