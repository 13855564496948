<div class="filters-container" [formGroup]="filterForm">
    <dtm-ui-select-field
        multiple
        formControlName="dtmArea"
        class="control-dtm-area"
        [placeholder]="'dtmSupPlannedMissions.missionFilters.chooseDtmPlaceholder' | transloco"
    >
        <dtm-ui-select-option *ngFor="let option of dtmAreas$ | ngrxPush" [value]="option">
            {{ option }}
        </dtm-ui-select-option>
    </dtm-ui-select-field>
    <dtm-ui-select-field
        formControlName="sort"
        class="control-sort-by-label"
        [isClearable]="false"
        [placeholder]="'dtmSupPlannedMissions.missionFilters.sortPlaceholder' | transloco"
    >
        <dtm-ui-select-option *ngFor="let option of sortingMap$ | ngrxPush | keyvalue : originalOrder" [value]="option.key">
            {{ "dtmSupPlannedMissions.missionFilters.sortByLabel" | transloco : { value: option.value } }}
        </dtm-ui-select-option>
    </dtm-ui-select-field>
    <dtm-ui-date-field class="control-date" [formGroup]="filterForm.controls.missionDates">
        <mat-date-range-input [rangePicker]="rangeDatePicker" *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder">
            <input matStartDate formControlName="flightDateFrom" [placeholder]="datePickerPlaceholder" />
            <input matEndDate formControlName="flightDateTo" [placeholder]="datePickerPlaceholder" />
        </mat-date-range-input>
        <mat-date-range-picker #rangeDatePicker (opened)="appendFooter()"></mat-date-range-picker>
    </dtm-ui-date-field>
    <!-- NOTE: We cannot use MatDatepickerActions here because it changes the datepicker behavior and forces the use of -->
    <!-- action buttons to select dates. This is a hack that appends HTML content with action buttons to the range datepicker -->
    <!-- without change datepicker behaviour. -->
    <div class="not-visible">
        <div class="tile-wrapper" #datepickerFooter *ngrxLet="calendarRange$ as calendarRange">
            <supervisor-shared-lib-time-tile [isSelected]="calendarRange === CalendarDateRange.Today" (click)="markToday()">
                {{ "dtmSupPlannedMissions.missionFilters.todayLabel" | transloco }}
            </supervisor-shared-lib-time-tile>
            <supervisor-shared-lib-time-tile [isSelected]="calendarRange === CalendarDateRange.OneDay" (click)="markTwoDaysAhead()">
                {{ "dtmSupPlannedMissions.missionFilters.addOneDayLabel" | transloco }}
            </supervisor-shared-lib-time-tile>
        </div>
    </div>
</div>
