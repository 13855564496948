import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
    AirspaceElementResponseBody,
    convertAirspaceElementResponseBodyToAirspaceElement,
    GeoZonesEndpoints,
    GEO_ZONES_ENDPOINTS,
    SearchAirspaceElementsRequestBody,
} from "@dtm-frontend/shared/map/geo-zones";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { SupUserErrorType, SupUserProfile } from "../models/sup-user.models";
import { SupUserEndpoints, SUP_USER_TOKENS } from "../sup-user.token";
import { convertSupUserProfileResponseBodyToSupUserProfile, SupUserProfileResponseBody } from "./sup-user-api.converters";

@Injectable({
    providedIn: "root",
})
export class SupUserApiService {
    constructor(
        @Inject(SUP_USER_TOKENS) private readonly endpoints: SupUserEndpoints,
        @Inject(GEO_ZONES_ENDPOINTS) private readonly geoZonesEndpoints: GeoZonesEndpoints,
        private readonly httpClient: HttpClient
    ) {}

    public fetchSupUserProfile(userId: string): Observable<SupUserProfile> {
        return this.httpClient
            .get<SupUserProfileResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getUserProfile, { userId }))
            .pipe(
                map(convertSupUserProfileResponseBodyToSupUserProfile),
                catchError(() => throwError(() => SupUserErrorType.CannotFetchUserProfile))
            );
    }

    public getUserDtmZonesData(options: Partial<SearchAirspaceElementsRequestBody>) {
        return this.httpClient.post<AirspaceElementResponseBody>(this.geoZonesEndpoints.searchAirspaceElements, options).pipe(
            map((response) => convertAirspaceElementResponseBodyToAirspaceElement(response, options.scope?.endTime)),
            catchError(() => throwError(() => ({ type: SupUserErrorType.CannotGetAirspaceElements })))
        );
    }
}
