import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { GeolocationService, WebGeolocationService } from "@dtm-frontend/shared/map";
import { CesiumMapModule } from "@dtm-frontend/shared/map/cesium";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { AngularCesiumModule } from "@pansa/ngx-cesium";
import { AlarmSliderComponent } from "./components/alarm-slider/alarm-slider.component";
import { DraftAreaLayerComponent } from "./components/draft-area-layer/draft-area-layer.component";
import { DtmAreaComponent } from "./components/dtm-area/dtm-area.component";
import { MessageComponent } from "./components/message/message.component";
import { MissionMapComponent } from "./components/mission-map/mission-map.component";
import { PlanMessageComponent } from "./components/plan-message/plan-message.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { CapabilitiesApiService } from "./services/capabilities-api.service";
import { DtmStatsApiService } from "./services/dtm-stats-api.service";
import { CapabilitiesState } from "./state/capabilities.state";
import { DtmAreasState } from "./state/dtm-areas.state";

@NgModule({
    imports: [
        CommonModule,
        SharedUiModule,
        LetModule,
        PushModule,
        SharedI18nModule,
        SharedUiModule,
        AngularCesiumModule,
        CesiumMapModule,
        ReactiveFormsModule,
        MatInputModule,
        NgxsModule.forFeature([CapabilitiesState, DtmAreasState]),
    ],
    declarations: [
        DtmAreaComponent,
        MissionMapComponent,
        SidebarComponent,
        MessageComponent,
        AlarmSliderComponent,
        DraftAreaLayerComponent,
        PlanMessageComponent,
    ],
    exports: [DtmAreaComponent, MissionMapComponent, SidebarComponent, MessageComponent, AlarmSliderComponent, PlanMessageComponent],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmSupervisorLibShared",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: GeolocationService,
            useClass: WebGeolocationService,
        },
    ],
})
export class SharedSupervisorClientModule {
    public static forTest(): ModuleWithProviders<SharedSupervisorClientModule> {
        return {
            ngModule: SharedSupervisorClientModule,
            providers: [
                {
                    provide: CapabilitiesApiService,
                    useValue: null,
                },
                {
                    provide: DtmStatsApiService,
                    useValue: null,
                },
            ],
        };
    }
}
