import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, Subject } from "rxjs";
import { OverrideMissionFormValue } from "../../../models/operational.situation.models";

const MIN_REASON_LENGTH = 3;
const MAX_REASON_LENGTH = 255;

@UntilDestroy()
@Component({
    selector: "supervisor-shared-lib-mission-time-change-dialog",
    templateUrl: "./mission-time-change-dialog.component.html",
    styleUrls: ["./mission-time-change-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionTimeChangeDialogComponent {
    private readonly overrideTimeSubject = new Subject<OverrideMissionFormValue>();
    public readonly setOverrideForm$ = this.overrideTimeSubject.asObservable();

    protected overrideTimeForm = new FormGroup({
        startAt: new FormControl<Date | null>(null, Validators.required),
        finishAt: new FormControl<Date | null>(null, Validators.required),
        information: new FormControl<string>("", {
            validators: [
                Validators.required,
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
                Validators.minLength(MIN_REASON_LENGTH),
                Validators.maxLength(MAX_REASON_LENGTH),
            ],
            nonNullable: true,
        }),
    });

    protected readonly MAX_REASON_LENGTH = MAX_REASON_LENGTH;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: { isProcessing: Observable<boolean>; startTime: Date; finishTime: Date; missionName: string }
    ) {
        this.overrideTimeForm.controls.startAt.patchValue(new Date(this.data.startTime.setSeconds(0, 0)));
        this.overrideTimeForm.controls.finishAt.patchValue(new Date(this.data.finishTime.setSeconds(0, 0)));

        this.data.isProcessing.pipe(untilDestroyed(this)).subscribe((isProcessing) => {
            if (isProcessing) {
                this.overrideTimeForm.disable();

                return;
            }

            this.overrideTimeForm.enable();
        });
    }

    protected confirm(): void {
        if (this.overrideTimeForm.invalid) {
            this.overrideTimeForm.markAllAsTouched();

            return;
        }

        const { startAt, finishAt, information } = this.overrideTimeForm.controls;

        if (!startAt.value || !finishAt.value) {
            return;
        }

        this.overrideTimeSubject.next({
            startAt: startAt.value,
            finishAt: finishAt.value,
            information: information.value,
        });
    }

    protected getStartTimeMax(startDate: Date): Date {
        return new Date(new Date(startDate).setMinutes(startDate.getMinutes() - 1));
    }

    protected getFinishTimeMin(finishDate: Date): Date {
        return new Date(new Date(finishDate).setMinutes(finishDate.getMinutes() + 1));
    }
}
