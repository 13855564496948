import { HttpParams } from "@angular/common/http";
import { AuthorityAcceptationStatus, MissionProcessingPhase, MissionType } from "@dtm-frontend/shared/mission";
import {
    Elevation,
    MissionPlanRoute,
    MissionPlanRouteFlightZone,
    MissionPlanRouteSection,
    MissionPlanRouteSegment,
    SectionElementResponseBody,
    Waypoint,
} from "@dtm-frontend/shared/ui";
import { DateUtils, Logger } from "@dtm-frontend/shared/utils";
import { IMessage } from "@stomp/rx-stomp";
import { MissionNotification, MissionsEvents } from "../../shared";
import { PartialAuthorityAcceptationResponse, convertSupervisorNote } from "../../shared/converters/plan-analysis.converters";
import { AuthorityUnit } from "../../sup-user";
import {
    ExecutionTime,
    Mission,
    MissionAttachment,
    MissionFilters,
    NearbyMissionFilters,
    PlannedMissionError,
    PlannedMissionErrorType,
} from "../models/mission.models";

const MAXIMUM_MISSION_LIST_SIZE = 2000;
const QUARTER_OF_HOUR_IN_SECONDS = 900;
const ACCEPTED_PLAN_MISSION_PHASES = [
    MissionProcessingPhase.Accepted,
    MissionProcessingPhase.MissionRealized,
    MissionProcessingPhase.MissionAbandoned,
];

// NOTE: Partially accepted plans will be in phase "Waiting",
// but AuthorityAcceptationStatus.Resolved will tell that mission is accepted for the authority unit
const UTM_ACCEPTED_PLAN_MISSION_PHASES = [...ACCEPTED_PLAN_MISSION_PHASES, MissionProcessingPhase.Waiting];

const getDuration = (duration: ExecutionTime) =>
    ({
        [ExecutionTime.Quater]: DateUtils.convertSecondsToISO8601Duration(QUARTER_OF_HOUR_IN_SECONDS),
        [ExecutionTime.SameTime]: DateUtils.convertSecondsToISO8601Duration(0),
    }[duration]);

const isArrayEqual = (array1: string[], array2: string[]): boolean =>
    array1.length === array2.length && array1.every((element: string) => array2.includes(element));

export interface MissionPlanListResponseBody {
    content: MissionResponseBody[];
}

export type MissionResponseBody = Omit<Mission, "routeSections"> & {
    dtmNames: string[];
    routeSections: SectionElementResponseBody[];
    pathBased: boolean;
    created: string;
    remarks: {
        supervisorNote: {
            note: string;
            updatedAt: string;
        };
        formalJustification: {
            requestForPriority: boolean;
            reason: string;
            attachmentIds: string[];
            createdAt: string;
            attachments: MissionAttachment[];
        };
        partialAcceptations: PartialAuthorityAcceptationResponse[];
    };
};

function parseWaypointResponseDates(waypoint: Waypoint, waypointIndex: number): Waypoint {
    const prefix = "A";

    return {
        ...waypoint,
        name: `${prefix}${waypointIndex}`,
        estimatedArriveAt: {
            min: new Date(waypoint.estimatedArriveAt.min),
            max: new Date(waypoint.estimatedArriveAt.max),
        },
    };
}

function convertSectionElementResponseBodyToMissionPlanRouteSection(sections?: SectionElementResponseBody[]): MissionPlanRouteSection[] {
    let sectionId = 0;

    return (
        sections?.map((section, index) => {
            const flightZone: MissionPlanRouteFlightZone | undefined = !section.flightZone
                ? undefined
                : {
                      center: parseWaypointResponseDates(
                          section.flightZone.center,
                          sections[index - 1]?.segment ? (sectionId += 2) : ++sectionId
                      ),
                      flightArea: section.flightZone.flightArea,
                      safetyArea: section.flightZone.safetyArea,
                      groundArea: section.flightZone.ground?.area,
                      groundAdjacentArea: section.flightZone.groundAdjacent?.area,
                      stopover: section.flightZone.stopover,
                      isRunway: section.flightZone.runway,
                  };

            const segment: MissionPlanRouteSegment | undefined = !section.segment
                ? undefined
                : {
                      fromWaypoint: parseWaypointResponseDates(section.segment.from, ++sectionId),
                      toWaypoint: parseWaypointResponseDates(section.segment.to, sectionId + 1),
                      flightArea: section.segment.flightArea,
                      safetyArea: section.segment.safetyArea,
                      groundArea: section.segment.ground?.area,
                      groundAdjacentArea: section.segment.groundAdjacent?.area,
                      elevationProfile: section.segment.elevationProfile,
                      distance: section.segment.distance,
                      speed: section.segment.speed,
                      duration: section.segment.duration,
                  };

            return { flightZone, segment, isActive: section.active };
        }) ?? []
    );
}

const convertAttachmentStringDateToDate = (attachments: MissionAttachment[]) =>
    attachments.map((attachment) => ({
        ...attachment,
        createdAt: new Date(attachment.createdAt),
    }));

export const convertMissionPlanResponseBodyToMissionList = (
    { content }: MissionPlanListResponseBody,
    authorityUnit: AuthorityUnit | undefined
): Mission[] => [
    ...content.map((mission) => ({
        ...mission,
        flightStartAtMin: new Date(mission.flightStartAtMin),
        flightStartAtMax: new Date(mission.flightStartAtMax),
        flightFinishAtMin: new Date(mission.flightFinishAtMin),
        flightFinishAtMax: new Date(mission.flightFinishAtMax),
        flightFinishAt: new Date(mission.flightFinishAt),
        flightStartAt: new Date(mission.flightStartAt),
        dtmNames: mission.dtmNames,
        routeSections: convertSectionElementResponseBodyToMissionPlanRouteSection(mission.routeSections),
        isPathBased: mission.pathBased,
        supervisorMessage: convertSupervisorNote(mission.remarks, authorityUnit),
        createdDate: new Date(mission.created),
        pilotRequest: mission.remarks.formalJustification
            ? {
                  isPriority: mission.remarks.formalJustification.requestForPriority,
                  reason: mission.remarks.formalJustification.reason,
                  createdAt: new Date(mission.remarks.formalJustification.createdAt),
                  attachmentList: convertAttachmentStringDateToDate(mission.remarks.formalJustification.attachments) ?? [],
              }
            : undefined,
    })),
];

export interface MissionPlanRouteResponseBody {
    routeId: string;
    planId: string;
    missionId: string;
    elevations: Elevation[];
    sections: SectionElementResponseBody[];
    pathBased: boolean;
    estimatedDistance: number;
    dtmOnly: boolean;
    dtmNames: string[];
}

export function convertMissionPlanRouteResponseBodyToMissionPlanRoute(response: MissionPlanRouteResponseBody): MissionPlanRoute {
    return {
        routeId: response.routeId,
        planId: response.planId,
        missionId: response.missionId,
        elevations: response.elevations,
        estimatedDistance: response.estimatedDistance,
        sections: convertSectionElementResponseBodyToMissionPlanRouteSection(response.sections),
        isPathBased: response.pathBased,
    };
}

export const transformChangeMissionPhaseError = (missionPhase: MissionProcessingPhase): PlannedMissionError => {
    switch (missionPhase) {
        case MissionProcessingPhase.Accepted:
            return { type: PlannedMissionErrorType.CannotAcceptMission };
        case MissionProcessingPhase.Rejected:
            return { type: PlannedMissionErrorType.CannotRejectMission };
        default: {
            return { type: PlannedMissionErrorType.Unknown };
        }
    }
};

export const convertMissionFiltersToHttpParams = (query: MissionFilters, missionPhase: MissionProcessingPhase): HttpParams => {
    const now = new Date();
    const yesterday = new Date(now.setDate(now.getDate() - 1)).toISOString();
    let params = new HttpParams();

    params =
        missionPhase === MissionProcessingPhase.Accepted
            ? params.set("processingPhase", `${ACCEPTED_PLAN_MISSION_PHASES}`)
            : params.set("processingPhase", missionPhase);

    params = params.set("size", MAXIMUM_MISSION_LIST_SIZE);

    if (query.flightDateFrom) {
        params = params.append("flightDateFrom", query.flightDateFrom.toISOString());
    }

    if (!query.flightDateFrom && (missionPhase === MissionProcessingPhase.Rejected || missionPhase === MissionProcessingPhase.Accepted)) {
        params = params.append("flightDateFrom", yesterday);
    }

    if (query.flightDateTo) {
        params = params.append("flightDateTo", query.flightDateTo.toISOString());
    }

    if (query.sort) {
        params = params.append("sort", query.sort);
    }

    if (query.dtmArea) {
        params = params.append("dtmNames", `${query.dtmArea}`);
    }

    return params;
};

export const convertUtmPlanFiltersToHttpParams = (
    query: MissionFilters,
    acceptationStatus: AuthorityAcceptationStatus | undefined,
    missionPhase: MissionProcessingPhase
): HttpParams => {
    const now = new Date();
    const yesterday = DateUtils.addDays(now, -1).toISOString();
    let params = new HttpParams();

    params =
        missionPhase === MissionProcessingPhase.Accepted
            ? params.set("processingPhase", `${UTM_ACCEPTED_PLAN_MISSION_PHASES}`)
            : params.set("processingPhase", missionPhase);

    if (acceptationStatus) {
        params = params.set("authorityUnitAcceptationStatuses", acceptationStatus);
    }
    params = params.set("size", MAXIMUM_MISSION_LIST_SIZE);

    if (query.flightDateFrom) {
        params = params.append("flightDateFrom", query.flightDateFrom.toISOString());
    }

    if (!query.flightDateFrom && (missionPhase === MissionProcessingPhase.Rejected || missionPhase === MissionProcessingPhase.Accepted)) {
        params = params.append("flightDateFrom", yesterday);
    }

    if (query.flightDateTo) {
        params = params.append("flightDateTo", query.flightDateTo.toISOString());
    }

    if (query.sort) {
        params = params.append("sort", query.sort);
    }

    if (query.authorityUnits) {
        params = params.append("authorityUnits", `${query.authorityUnits}`);
    }

    if (query.zoneDesignators) {
        params = params.append("zoneDesignators", `${query.zoneDesignators}`);
    }

    return params;
};

export const convertNearbyMissionFiltersToHttpParams = (planId: string, nearbyMissionFilters: NearbyMissionFilters): HttpParams => {
    let params = new HttpParams();

    params.set("size", MAXIMUM_MISSION_LIST_SIZE);

    if (nearbyMissionFilters.missionType && !isArrayEqual(nearbyMissionFilters.missionType, Object.values(MissionType))) {
        params = params.set("flightType", `${nearbyMissionFilters.missionType}`);
    }

    if (nearbyMissionFilters.executionTime) {
        params = params.set("timeBuffer", getDuration(nearbyMissionFilters.executionTime));
    }

    if (nearbyMissionFilters.missionArea) {
        params = params.set("neighbourhoodType", nearbyMissionFilters.missionArea);
    }

    return params;
};

export interface GenericMissionNotificationResponse {
    type: string;
    planId: string;
}

export interface PlanVerificationCompletedNotificationResponse {
    type: MissionsEvents.PlanVerificationCompletedEvent;
    planId: string;
    missionForbidden: boolean;
}

export type MissionNotificationResponse = PlanVerificationCompletedNotificationResponse | GenericMissionNotificationResponse;

export const convertMissionNotificationResponseToMissionNotification = (response: IMessage): MissionNotification | undefined => {
    try {
        const type = response.headers["event-type"] as MissionsEvents;
        const body = JSON.parse(response.body) as MissionNotificationResponse;

        if (type === MissionsEvents.PlanVerificationCompletedEvent) {
            return {
                type: MissionsEvents.PlanVerificationCompletedEvent,
                planId: body.planId,
                isMissionForbidden: (body as PlanVerificationCompletedNotificationResponse).missionForbidden,
            };
        }

        return {
            type,
            planId: body.planId,
        };
    } catch (error) {
        Logger.captureException(error);

        return;
    }
};
