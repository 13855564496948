import { InjectionToken } from "@angular/core";

export interface AddingPermissionsEndpoints {
    getOperatorsList: string;
    getPilotsList: string;
    removeOperatorPermission: string;
    setOperatorPermission: string;
    removePilotPermission: string;
    setPilotPermission: string;
    getOperatorPublicProfile: string;
    getPilotPublicProfile: string;
}

export const ADDING_PERMISSIONS_ENDPOINTS = new InjectionToken<AddingPermissionsEndpoints>("Adding permissions endpoints");
