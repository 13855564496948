import { Alarm, SignalViolationType } from "../../shared/models/shared-supervisor-client.models";

export interface GlobalAlarmMessageResponseBody {
    id: string;
    signalViolationType: SignalViolationType;
    dtmName: string;
    installationId: string;
    installationName: string;
    sectorId: string;
    sectorName: string;
    deviceId: string;
    serialNumber: string;
    createdAt: string;
}

export const convertGlobalAlarmMessageResponseBodyToAlarm = (messageResponseBody: GlobalAlarmMessageResponseBody): Alarm => ({
    id: messageResponseBody.id,
    message: `${messageResponseBody.dtmName}, ${messageResponseBody.installationName}, ${messageResponseBody.sectorName}`,
    signalViolationType: messageResponseBody.signalViolationType,
});
