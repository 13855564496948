import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { Aerial, Sector, SectorStatus } from "../../models/network-monitoring.models";

interface SectorInfoPanelComponentState {
    aerial: Aerial | undefined;
    selectedAerialId: string | undefined;
}

@Component({
    selector: "supervisor-shared-lib-aerial-info-panel[aerial][selectedAerialId]",
    templateUrl: "./aerial-info-panel.component.html",
    styleUrls: ["./aerial-info-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AerialInfoPanelComponent {
    @Input() public set aerial(value: Aerial | undefined) {
        this.localStore.patchState({ aerial: value });
    }
    @Input() public set selectedAerialId(value: string | undefined) {
        this.localStore.patchState({ selectedAerialId: value });
    }

    @Output() public aerialExpand = new EventEmitter<string>();

    protected readonly aerial$ = this.localStore.selectByKey("aerial").pipe(RxjsUtils.filterFalsy());
    protected readonly selectedAerialId$ = this.localStore.selectByKey("selectedAerialId");

    constructor(private readonly localStore: LocalComponentStore<SectorInfoPanelComponentState>) {
        this.localStore.setState({ aerial: undefined, selectedAerialId: undefined });
    }

    protected getSectorStatuses(sectors: Sector[]): SectorStatus[] {
        return sectors.map((sector) => sector.sectorStatus);
    }
}
