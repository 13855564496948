import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDatepicker } from "@angular/material/datepicker";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { DateUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { map } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { DtmArea, MissionFilters, PlannedMissionFilters } from "../../models/mission.models";

export const FILTER_DEBOUNCE_TIME = 300;

interface MissionFilterComponentState {
    calendarRange: number | undefined;
    sortingMap: SortingMap | undefined;
    dtmAreas: DtmArea[];
}

enum CalendarDateRange {
    Today = 0,
    OneDay = 1,
}

interface MissionDatesForm {
    flightDateFrom: FormControl<Date | null>;
    flightDateTo: FormControl<Date | null>;
}

type SortingMap = Record<string, string>;

interface MissionFilterForm {
    dtmArea: FormControl<string[]>;
    sort: FormControl<string | null>;
    missionDates: FormGroup<MissionDatesForm>;
}

@UntilDestroy()
@Component({
    selector: "supervisor-shared-lib-mission-filter[missionDefaultsFilters]",
    templateUrl: "./mission-filter.component.html",
    styleUrls: ["./mission-filter.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionFilterComponent {
    @Input() public set missionDefaultsFilters(value: MissionFilters | undefined) {
        if (!value) {
            return;
        }

        this.filterForm.patchValue(
            {
                dtmArea: value.dtmArea ?? [],
                sort: value.sort,
                missionDates: {
                    flightDateFrom: value.flightDateFrom,
                    flightDateTo: value.flightDateTo,
                },
            },
            { emitEvent: false }
        );
    }
    @Input() public set sortingMap(value: SortingMap | undefined) {
        this.localStore.patchState({ sortingMap: value });
    }
    @Input() public set dtmAreas(value: DtmArea[] | undefined) {
        this.localStore.patchState({ dtmAreas: value ?? [] });
    }

    @Output() public readonly missionFilters = new EventEmitter<PlannedMissionFilters>();

    @ViewChild("datepickerFooter", { static: false }) public datepickerFooter: ElementRef | undefined;
    @ViewChild("rangeDatePicker", { static: false }) public datepicker: MatDatepicker<unknown> | undefined;

    protected readonly calendarRange$ = this.localStore.selectByKey("calendarRange");
    protected readonly sortingMap$ = this.localStore.selectByKey("sortingMap");
    protected readonly CalendarDateRange = CalendarDateRange;
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;
    protected readonly dateFromControl = new FormControl(null);
    protected readonly dateToControl = new FormControl(null);
    protected readonly dtmAreas$ = this.localStore.selectByKey("dtmAreas").pipe(map((area) => area.map(({ name }) => name)));
    public readonly filterForm = new FormGroup<MissionFilterForm>({
        dtmArea: new FormControl([], { nonNullable: true }),
        sort: new FormControl(null),
        missionDates: new FormGroup<MissionDatesForm>({
            flightDateFrom: this.dateFromControl,
            flightDateTo: this.dateToControl,
        }),
    });

    constructor(
        private readonly translocoHelper: TranslationHelperService,
        private readonly localStore: LocalComponentStore<MissionFilterComponentState>
    ) {
        this.localStore.setState({
            calendarRange: undefined,
            sortingMap: undefined,
            dtmAreas: [],
        });

        this.filterForm.valueChanges.pipe(debounceTime(FILTER_DEBOUNCE_TIME), untilDestroyed(this)).subscribe(() => {
            const formValue = this.filterForm.getRawValue();
            const missionFlightFrom = formValue.missionDates.flightDateFrom;
            const missionFlightTo = formValue.missionDates.flightDateTo;
            this.missionFilters.emit(this.getMissionFilters());

            this.localStore.patchState({ calendarRange: undefined });

            if (!missionFlightFrom || !missionFlightTo) {
                return;
            }

            this.updateCalendarRange(missionFlightFrom, missionFlightTo);
        });
    }

    public getMissionFilters(): PlannedMissionFilters {
        const formValue = this.filterForm.getRawValue();
        const missionFlightFrom = formValue.missionDates.flightDateFrom;
        const missionFlightTo = formValue.missionDates.flightDateTo;

        return {
            dtmArea: formValue.dtmArea,
            sort: formValue.sort,
            flightDateFrom: missionFlightFrom,
            flightDateTo: missionFlightTo,
        };
    }

    protected get missionFlightDateFrom(): FormControl<Date | null> {
        return this.filterForm.controls.missionDates.controls.flightDateFrom;
    }

    protected get missionFlightDateTo(): FormControl<Date | null> {
        return this.filterForm.controls.missionDates.controls.flightDateTo;
    }

    protected appendFooter(): void {
        /* NOTE: We cannot use MatDatepickerActions here because it changes the datepicker behavior and forces the use of
           action buttons to select dates. This is a hack that appends HTML content with action buttons to the range datepicker
           without change datepicker behaviour. */

        const matCalendar = document.getElementsByClassName("mat-datepicker-content")[0] as HTMLElement;
        if (this.datepickerFooter) {
            matCalendar.appendChild(this.datepickerFooter.nativeElement);
        }
    }

    protected markToday(): void {
        const startDate = new Date(new Date().setHours(0, 0, 0));
        // eslint-disable-next-line no-magic-numbers
        const endDate = new Date(new Date().setHours(23, 59, 59));
        this.missionFlightDateFrom.patchValue(startDate);
        this.missionFlightDateTo.patchValue(endDate);
        this.datepicker?.close();
    }

    protected markTwoDaysAhead(): void {
        const startDate = new Date(new Date().setHours(0, 0, 0));
        // eslint-disable-next-line no-magic-numbers
        const endDate = new Date(new Date().setHours(23, 59, 59));
        this.missionFlightDateFrom.patchValue(startDate);

        if (!this.missionFlightDateFrom.value) {
            return;
        }

        this.missionFlightDateTo.patchValue(DateUtils.addDays(endDate, CalendarDateRange.OneDay));
        this.datepicker?.close();
    }

    protected originalOrder(): number {
        return 0;
    }

    private updateCalendarRange(missionFlightFrom: Date, missionFlightTo: Date): void {
        this.localStore.patchState({ calendarRange: undefined });

        if (this.isDifferenceInDaysFromNow(missionFlightFrom, missionFlightTo, CalendarDateRange.Today)) {
            this.localStore.patchState({ calendarRange: CalendarDateRange.Today });
        }

        if (this.isDifferenceInDaysFromNow(missionFlightFrom, missionFlightTo, CalendarDateRange.OneDay)) {
            this.localStore.patchState({ calendarRange: CalendarDateRange.OneDay });
        }
    }

    private isDifferenceInDaysFromNow(firstDate: Date, secondDate: Date, days: number): boolean {
        return this.isToday(firstDate) && this.isDifferenceBetweenDays(firstDate, secondDate, days);
    }

    private isDifferenceBetweenDays(firstDate: Date, secondDate: Date, days: number): boolean {
        return DateUtils.calculateDiffDate(firstDate, secondDate).days === days;
    }

    private isToday(date: Date): boolean {
        const today = new Date();

        return today.toDateString() === date.toDateString();
    }
}
