import { WeatherPayloadData, WeatherViewMode } from "../models/weather.models";

export namespace WeatherActions {
    export class GetWeatherConditions {
        public static readonly type = "[Weather] Get weather conditions";
        constructor(public zoneDesignator: string) {}
    }

    export class ChangeWeatherVisibility {
        public static readonly type = "[Weather] Change weather visibility";
        constructor(public viewMode: WeatherViewMode) {}
    }

    export class GetMissionPlanWeatherRange {
        public static readonly type = "[Weather] Get mission plan weather range";
        constructor(public weatherPayloadData: WeatherPayloadData) {}
    }

    export class ChangeWeatherPanelVisibility {
        public static readonly type = "[Weather] Change weather panel visibility";
    }

    export class ResetWeatherState {
        public static readonly type = "[Weather] Reset weather state";
    }

    export class GetSupportedWeatherZones {
        public static readonly type = "[Weather] Get supported weather zones";
    }

    export class SetSupportedWeatherZones {
        public static readonly type = "[Weather] Set supported weather zones";
        constructor(public zoneDesignators: string[]) {}
    }
}
