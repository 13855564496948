<ng-container *ngrxLet="{ mission: mission$, canEdit: canEdit$, workspacePermissions: workspacePermissions$ } as vm">
    <div class="panel" [class.focused]="isSelected$ | ngrxPush" *ngrxLet="isExpanded$ as isExpanded">
        <div class="chip-list" [class.editable]="vm.canEdit">
            <span *ngIf="!vm.mission.neighbourhoodType && vm.mission.dtmName" class="chip default">{{ vm.mission.dtmName }}</span>
            <span
                *ngIf="vm.mission.neighbourhoodType"
                class="chip default"
                [class.collision]="vm.mission.neighbourhoodType === NeighbourhoodType.Collision"
                [class.conflict]="vm.mission.neighbourhoodType === NeighbourhoodType.Conflict"
            >
                {{
                    "dtmSupPlannedMissions.missionInfoPanel.neighbourhoodTypeValueSelect"
                        | transloco : { value: vm.mission.neighbourhoodType }
                }}
            </span>
            <span class="chip disabled">
                <dtm-ui-icon [name]="vm.mission.flightType === MissionType.BVLOS ? 'eye-off' : 'eye'"></dtm-ui-icon>
                {{ "dtmSupPlannedMissions.missionInfoPanel.missionTypeLabel" | transloco : { value: vm.mission.flightType } }}
            </span>
            <span class="chip default priority" *ngIf="vm.workspacePermissions | invoke : isPriorityEditable">
                <dtm-ui-icon name="arrow-up-double"></dtm-ui-icon>
                {{ (vm.mission.priority | number : "1.1") ?? "-" }}
                <button
                    *ngIf="vm.canEdit"
                    (click)="
                        priorityEdit.emit({
                            priority: vm.mission.priority ?? DEFAULT_PRIORITY,
                            systemVerificationId: vm.mission.systemVerificationId
                        })
                    "
                    type="button"
                    class="button-icon"
                >
                    <dtm-ui-icon name="edit"></dtm-ui-icon>
                </button>
            </span>
        </div>
        <ng-container *ngIf="shouldChangeBasicMissionDetails$ | ngrxPush; else basicMissionDetailsTemplate">
            <div class="flight-basic-info">
                <span class="pilot-name">
                    {{ vm.mission.pilotName }}, <ng-container *ngIf="vm.mission.operatorName">{{ vm.mission.operatorName }},</ng-container>
                    {{ vm.mission.uavName }}</span
                >
                <button type="button" class="button-icon" (click)="toggleZoom($event, vm.mission.routeId)">
                    <dtm-ui-icon name="road-map"></dtm-ui-icon>
                </button>
            </div>
            <dtm-mission-mission-time-range [missionTimeRange]="missionTimeRange$ | ngrxPush"></dtm-mission-mission-time-range>
        </ng-container>
        <ng-template #basicMissionDetailsTemplate>
            <div class="flight-basic-info">
                <span class="pilot-name">
                    {{ vm.mission.pilotName }}, <ng-container *ngIf="vm.mission.operatorName">{{ vm.mission.operatorName }},</ng-container>
                    {{ vm.mission.uavName }}
                </span>
            </div>
            <dtm-mission-mission-time-range
                shouldDisplayDate
                [missionTimeRange]="missionTimeRange$ | ngrxPush"
            ></dtm-mission-mission-time-range>
        </ng-template>
        <div class="content" *ngIf="isExpanded">
            <dtm-ui-loader-container [isShown]="isPlanRouteProcessing$ | ngrxPush">
                <ng-content></ng-content>
                <div class="actions" *ngIf="vm.canEdit">
                    <button type="button" (click)="cancel.emit()" class="button-secondary remove-button">
                        <dtm-ui-icon name="close-circle"></dtm-ui-icon>
                        {{ "dtmSupPlannedMissions.missionInfoPanel.rejectButtonLabel" | transloco }}
                    </button>
                    <div>
                        <button type="button" (click)="save.emit()" class="button-primary">
                            {{ "dtmSupPlannedMissions.missionInfoPanel.approveButtonLabel" | transloco }}
                        </button>
                    </div>
                </div>
            </dtm-ui-loader-container>
        </div>
        <div class="expand">
            <button type="button" class="button-tertiary" (click)="toggle()">
                <span>{{ "dtmSupPlannedMissions.missionInfoPanel.expandLabel" | transloco : { value: isExpanded } }}</span>
            </button>
            <dtm-ui-icon [name]="isExpanded ? 'arrow-up' : 'arrow-down'"></dtm-ui-icon>
        </div>
    </div>
</ng-container>
