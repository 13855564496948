import { Injectable } from "@angular/core";

import { Store } from "@ngxs/store";
import { lastValueFrom } from "rxjs";
import { NetworkMonitoringActions } from "../state/network-monitoring.actions";

@Injectable({
    providedIn: "root",
})
export class NetworkMonitoringResolver {
    constructor(private readonly store: Store) {}

    public async resolve(): Promise<void> {
        return lastValueFrom(this.store.dispatch(NetworkMonitoringActions.GetInstallations));
    }
}
