import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ZoneTimesSetting } from "@dtm-frontend/shared/map/geo-zones";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { of } from "rxjs";
import { GlobalAlarmActions } from "../../../global-alarm/state/global-alarm.actions";
import { GlobalAlarmState } from "../../../global-alarm/state/global-alarm.state";
import { Alarm } from "../../../shared/models/shared-supervisor-client.models";
import { dtmBboxFeaturesMap } from "../../../shared/utils/dtm-bbox-features-map";
import { Aerial, SectorStatus } from "../../models/network-monitoring.models";
import { NetworkMonitoringActions } from "../../state/network-monitoring.actions";
import { NetworkMonitoringState } from "../../state/network-monitoring.state";
import { SignalViolationData } from "../event-tile/event-tile.component";

interface NetworkMonitoringContainerComponentState {
    isPanelFolded: boolean;
}

@UntilDestroy()
@Component({
    selector: "supervisor-shared-lib-network-monitoring-container",
    templateUrl: "./network-monitoring-container.component.html",
    styleUrls: ["./network-monitoring-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NetworkMonitoringContainerComponent {
    protected readonly installations$ = this.store.select(NetworkMonitoringState.installations);
    protected readonly error$ = this.store.select(NetworkMonitoringState.installationsError);
    protected readonly selectedInstallation$ = this.store.select(NetworkMonitoringState.selectedInstallation);
    protected readonly selectedAerialId$ = this.store.select(NetworkMonitoringState.selectedAerialId);
    protected readonly alarmList$ = this.store.select(GlobalAlarmState.alarms);
    protected readonly currentSectorList$ = this.store.select(NetworkMonitoringState.currentSectorList);
    protected readonly deviceHistoryList$ = this.store.select(NetworkMonitoringState.deviceHistoryList);
    protected readonly isDeviceHistoryFullyLoaded$ = this.store.select(NetworkMonitoringState.isDeviceHistoryFullyLoaded);
    protected readonly isProcessing$ = this.store.select(NetworkMonitoringState.isProcessing);
    protected readonly isPanelFolded$ = this.localStore.selectByKey("isPanelFolded");
    // TODO: DTM-3115
    protected readonly routeData$ = of(undefined);
    protected readonly initialViewGeometry = dtmBboxFeaturesMap.get("DTM Nadarzyn");

    protected readonly ZoneTimesSetting = ZoneTimesSetting;

    constructor(
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<NetworkMonitoringContainerComponentState>,
        private readonly transloco: TranslocoService,
        private readonly toastr: ToastrService
    ) {
        this.localStore.setState({
            isPanelFolded: true,
        });
    }

    protected togglePanel(): void {
        this.localStore.patchState(({ isPanelFolded }) => ({ isPanelFolded: !isPanelFolded }));
    }

    protected showInstallationDetails(installationName: string): void {
        this.localStore.patchState({ isPanelFolded: false });
        this.store.dispatch(new NetworkMonitoringActions.SelectInstallation(installationName));
    }

    protected closeGlobalAlarm(alarm: Alarm): void {
        this.store.dispatch(new GlobalAlarmActions.DeleteAlarm(alarm.id));
    }

    protected getDtmStatus(aerials: Aerial[] | undefined): SectorStatus {
        return aerials &&
            aerials.map((aerial) => aerial.sectors.some((sector) => sector.sectorStatus === SectorStatus.ConnectionLost)).includes(true)
            ? SectorStatus.ConnectionLost
            : SectorStatus.Active;
    }

    protected selectInstallation(installationId: string) {
        this.store.dispatch(new NetworkMonitoringActions.SelectInstallation(installationId));
    }

    protected getDeviceHistory(deviceId: string): void {
        this.store
            .dispatch(new NetworkMonitoringActions.GetDeviceHistory(deviceId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(NetworkMonitoringState.deviceHistoryListError);

                if (error) {
                    this.toastr.error(
                        this.transloco.translate("dtmSupNetworkMonitoring.networkMonitoringContainer.getDeviceHistoryErrorMessage")
                    );
                }
            });
    }

    protected updateNote(noteData: SignalViolationData): void {
        this.store
            .dispatch(new NetworkMonitoringActions.UpdateSignalViolationNote(noteData))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(NetworkMonitoringState.updateNoteError);

                if (error) {
                    this.toastr.error(
                        this.transloco.translate("dtmSupNetworkMonitoring.networkMonitoringContainer.updateNoteErrorMessage")
                    );

                    return;
                }

                this.toastr.success(
                    this.transloco.translate("dtmSupNetworkMonitoring.networkMonitoringContainer.updateNoteSuccessMessage")
                );
            });
    }

    protected loadMoreDeviceHistory(deviceId: string): void {
        this.store
            .dispatch(new NetworkMonitoringActions.LoadMoreDeviceHistory(deviceId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(NetworkMonitoringState.deviceHistoryListError);

                if (error) {
                    this.toastr.error(
                        this.transloco.translate("dtmSupNetworkMonitoring.networkMonitoringContainer.loadDeviceHistoryErrorMessage")
                    );
                }
            });
    }
}
