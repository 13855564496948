import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ErrorMode, Page } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import {
    AddingPermissionsPilot,
    AddingPermissionsPilotError,
    AddingPermissionsPilotFilterFormKeys,
} from "../../models/adding-permissions-pilot.models";
import { PermissionChange } from "../../models/adding-permissions.models";

interface AddingPermissionsListComponentState {
    addingPermissionsList: AddingPermissionsPilot[];
    addingPermissionsListError: AddingPermissionsPilotError | undefined;
    isAddingPermissionsListProcessing: boolean;
    pagination: Page | undefined;
    expandedElement: string | undefined;
}

@Component({
    selector: "supervisor-shared-lib-adding-permissions-pilot-list[addingPermissionsList][pagination]",
    templateUrl: "./adding-permissions-pilot-list.component.html",
    styleUrls: ["./adding-permissions-pilot-list.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation()],
})
export class AddingPermissionsPilotListComponent {
    @Input() public set addingPermissionsList(value: AddingPermissionsPilot[] | undefined) {
        this.localStore.patchState({ addingPermissionsList: value ?? [] });
    }
    @Input() public set isAddingPermissionsListProcessing(value: BooleanInput) {
        this.localStore.patchState({ isAddingPermissionsListProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set addingPermissionsListError(value: AddingPermissionsPilotError | undefined) {
        this.localStore.patchState({ addingPermissionsListError: value });
    }
    @Input() public set pagination(value: Page | undefined) {
        this.localStore.patchState({ pagination: value });
    }

    @Output() public readonly addingPermissionsListRefresh = new EventEmitter<void>();
    @Output() public readonly pageChange = new EventEmitter<PageEvent>();
    @Output() public readonly permissionChange = new EventEmitter<PermissionChange>();
    @Output() public readonly qualificationsGet = new EventEmitter<AddingPermissionsPilot>();

    protected readonly addingPermissionsList$ = this.localStore.selectByKey("addingPermissionsList");
    protected readonly expandedElement$ = this.localStore.selectByKey("expandedElement");
    protected readonly isAddingPermissionsListProcessing$ = this.localStore.selectByKey("isAddingPermissionsListProcessing");
    protected readonly addingPermissionsListError$ = this.localStore.selectByKey("addingPermissionsListError");
    protected readonly pagination$ = this.localStore.selectByKey("pagination");
    protected readonly displayedColumns = ["expandHandle", "name", "operatorNumber", "email", "phoneNumber", "permission", "actions"];

    protected readonly AddingPermissionsPilotFilterFormKeys = AddingPermissionsPilotFilterFormKeys;
    protected readonly ErrorMode = ErrorMode;

    constructor(private readonly localStore: LocalComponentStore<AddingPermissionsListComponentState>) {
        this.localStore.setState({
            addingPermissionsList: [],
            isAddingPermissionsListProcessing: false,
            addingPermissionsListError: undefined,
            pagination: undefined,
            expandedElement: undefined,
        });
    }

    protected toggleExpandableRow(element: AddingPermissionsPilot) {
        if (this.localStore.selectSnapshotByKey("expandedElement") === element.id) {
            this.localStore.patchState({ expandedElement: undefined });

            return;
        }

        if (!element.qualifications) {
            this.getOperatorQualifications(element);
        }

        this.localStore.patchState({ expandedElement: element.id });
    }

    private getOperatorQualifications(operator: AddingPermissionsPilot) {
        this.qualificationsGet.emit(operator);
    }
}
