import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AirspaceElement, AirspaceElementsInfo } from "@dtm-frontend/shared/map/geo-zones";
import { MissionPlanAnalysisStatus, MissionPlanDataAndCapabilities, MissionType } from "@dtm-frontend/shared/mission";
import { MissionData } from "@dtm-frontend/shared/ui/tactical";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest, map } from "rxjs";
import { Checkin, MissionStatus, OperationType, ProceedingMission } from "../../models/operational.situation.models";
import { MissionTypeFilters } from "../proceeding-missions-list/mission-type-filter/mission-type-filter.component";

const MISSION_TYPE_FILTERS_DEFAULTS: MissionTypeFilters = {
    vlos: true,
    bvlos: true,
    checkin: true,
};

interface OtherMissionsComponentState {
    incomingMissions: ProceedingMission[];
    filteredIncomingMissions: ProceedingMission[];
    finishedMissions: ProceedingMission[];
    filteredFinishedMissions: ProceedingMission[];
    incomingMissionsTypeFilter: MissionTypeFilters;
    finishedMissionsTypeFilter: {
        missionType: MissionTypeFilters;
        missionStatus: MissionStatus;
    };
    checkinList: Checkin[];
    filteredCheckinList: Checkin[];
    selectedOperationId: string | undefined;
    currentMissionPlanData: MissionPlanDataAndCapabilities | undefined;
    selectedMission: MissionData | undefined;
    zones: AirspaceElementsInfo | undefined;
    currentPlanAnalysisStatus: MissionPlanAnalysisStatus | undefined;
    areMissionManageable: boolean;
    selectedZoneId: string | undefined;
}

@UntilDestroy()
@Component({
    selector: "supervisor-shared-lib-other-missions[incomingMissions][finishedMissions]",
    templateUrl: "./other-missions.component.html",
    styleUrls: ["./other-missions.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OtherMissionsComponent {
    @Input() public set incomingMissions(value: ProceedingMission[] | undefined) {
        this.localStore.patchState({ incomingMissions: value ?? [], filteredIncomingMissions: value ?? [] });
    }
    @Input() public set finishedMissions(value: ProceedingMission[] | undefined) {
        this.localStore.patchState({ finishedMissions: value ?? [], filteredFinishedMissions: value ?? [] });
    }
    @Input() public set checkinList(value: Checkin[] | undefined) {
        this.localStore.patchState({ checkinList: value ?? [], filteredCheckinList: value ?? [] });
    }
    @Input() public set selectedOperationId(value: string | undefined) {
        this.localStore.patchState({ selectedOperationId: value });
    }
    @Input() public set currentMissionPlanData(value: MissionPlanDataAndCapabilities | undefined) {
        this.localStore.patchState({ currentMissionPlanData: value });
    }
    @Input() public set selectedMission(value: MissionData | undefined) {
        this.localStore.patchState({ selectedMission: value });
    }
    @Input() public set zones(value: AirspaceElementsInfo | undefined) {
        this.localStore.patchState({ zones: value });
    }
    @Input() public set currentPlanAnalysisStatus(value: MissionPlanAnalysisStatus | undefined) {
        this.localStore.patchState({ currentPlanAnalysisStatus: value });
    }
    @Input() public set areMissionManageable(value: boolean) {
        this.localStore.patchState({ areMissionManageable: value });
    }
    @Input() public set selectedZoneId(value: string | undefined) {
        this.localStore.patchState({ selectedZoneId: value });
    }

    @Output() public readonly missionReject = new EventEmitter<string>();
    @Output() public readonly missionTimeChange = new EventEmitter<ProceedingMission>();
    @Output() public readonly missionSelected = new EventEmitter<ProceedingMission>();
    @Output() public readonly missionRoadZoom = new EventEmitter<string>();
    @Output() public readonly zoneSelect = new EventEmitter<AirspaceElement>();

    protected readonly filteredIncomingMissions$ = this.localStore.selectByKey("filteredIncomingMissions");
    protected readonly filteredFinishedMissions$ = this.localStore.selectByKey("filteredFinishedMissions");
    protected readonly filteredCheckinList$ = this.localStore.selectByKey("filteredCheckinList");
    protected readonly currentMissionPlanData$ = this.localStore.selectByKey("currentMissionPlanData");
    protected readonly selectedMission$ = this.localStore.selectByKey("selectedMission");
    protected readonly currentPlanAnalysisStatus$ = this.localStore.selectByKey("currentPlanAnalysisStatus");
    protected readonly zones$ = this.localStore.selectByKey("zones");
    protected readonly selectedOperationId$ = this.localStore.selectByKey("selectedOperationId");
    protected readonly areMissionManageable$ = this.localStore.selectByKey("areMissionManageable");
    protected readonly selectedZoneId$ = this.localStore.selectByKey("selectedZoneId");
    protected readonly MISSION_TYPE_FILTERS_DEFAULTS = MISSION_TYPE_FILTERS_DEFAULTS;

    protected readonly operationList$ = combineLatest([this.filteredFinishedMissions$, this.filteredCheckinList$]).pipe(
        map(([finishedList, checkinList]) =>
            [...finishedList, ...checkinList].sort((left, right) => {
                if (left.flightStartAt && right.flightStartAt) {
                    return right.flightStartAt.getTime() - left.flightStartAt.getTime();
                }

                return 0;
            })
        )
    );
    protected readonly OperationType = OperationType;

    constructor(protected readonly localStore: LocalComponentStore<OtherMissionsComponentState>) {
        this.localStore.setState({
            incomingMissions: [],
            filteredIncomingMissions: [],
            finishedMissions: [],
            filteredFinishedMissions: [],
            checkinList: [],
            filteredCheckinList: [],
            incomingMissionsTypeFilter: MISSION_TYPE_FILTERS_DEFAULTS,
            finishedMissionsTypeFilter: { missionType: MISSION_TYPE_FILTERS_DEFAULTS, missionStatus: MissionStatus.All },
            selectedOperationId: undefined,
            currentMissionPlanData: undefined,
            selectedMission: undefined,
            zones: undefined,
            currentPlanAnalysisStatus: undefined,
            areMissionManageable: false,
            selectedZoneId: undefined,
        });

        this.watchIncomingMissionsFilters();
        this.watchFinishedMissionsFilters();
    }

    protected updateIncomingMissionFilters(missionType: MissionTypeFilters): void {
        this.localStore.patchState({ incomingMissionsTypeFilter: missionType });
    }

    protected updateFinishedMissionFilters(missionType: MissionTypeFilters): void {
        this.localStore.patchState(({ finishedMissionsTypeFilter }) => ({
            finishedMissionsTypeFilter: {
                ...finishedMissionsTypeFilter,
                missionType: missionType,
            },
        }));
    }

    private watchIncomingMissionsFilters(): void {
        this.localStore
            .selectByKey("incomingMissionsTypeFilter")
            .pipe(untilDestroyed(this))
            .subscribe((missionType) => {
                const baseIncomingList = this.localStore.selectSnapshotByKey("incomingMissions");
                const filteredMissions = baseIncomingList.filter((mission) => this.hasMissionType(mission.missionType, missionType));
                this.localStore.patchState({ filteredIncomingMissions: filteredMissions });
            });
    }

    private watchFinishedMissionsFilters(): void {
        this.localStore
            .selectByKey("finishedMissionsTypeFilter")
            .pipe(untilDestroyed(this))
            .subscribe((filters) => {
                const finishedMissions = this.localStore.selectSnapshotByKey("finishedMissions");
                const checkinList = this.localStore.selectSnapshotByKey("checkinList");

                const filteredFinishedMissions = finishedMissions.filter((mission) =>
                    this.hasMissionType(mission.missionType, filters.missionType)
                );

                const filteredCheckins = checkinList.filter(
                    (checkin) => filters.missionType.checkin && this.hasMissionType(checkin.checkinType, filters.missionType)
                );

                this.localStore.patchState({
                    filteredFinishedMissions: filteredFinishedMissions,
                    filteredCheckinList: filteredCheckins,
                });
            });
    }

    private hasMissionType(currentMissionType: MissionType, missionType: MissionTypeFilters): boolean {
        return (
            (currentMissionType === MissionType.VLOS && missionType.vlos) || (currentMissionType === MissionType.BVLOS && missionType.bvlos)
        );
    }
}
