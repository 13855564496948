<ng-container *ngrxLet="{ routesData: routesData$, hasBackdrop: hasBackdrop$, isProcessing: isProcessing$ } as vm">
    <dtm-ui-loader-container [isShown]="vm.isProcessing">
        <ac-map [sceneMode]="SceneMode.SCENE3D" dtmMapPointerManager [class.backdrop]="vm.hasBackdrop">
            <dtm-map-azure-maps-layer [options]="AZURE_MAPS_LAYER_OPTIONS"></dtm-map-azure-maps-layer>
            <dtm-map-routes-list-view-layer
                [routesList]="vm.routesData"
                [drawableFeatures]="routeDrawableFeature"
                [isShown]="!!vm.routesData.length && !vm.isProcessing"
                (missionSelect)="zoomToMission($event)"
            ></dtm-map-routes-list-view-layer>
            <dtm-map-measures-cesium [isMeasureEnabled]="isMeasureToolActive$ | ngrxPush"></dtm-map-measures-cesium>
        </ac-map>
        <dtm-map-controls isMeasureToolEnabled (measureToolStatusChange)="updateMeasureToolStatus($event)"></dtm-map-controls>
    </dtm-ui-loader-container>
</ng-container>
