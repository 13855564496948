import { SignalViolationData } from "../models/network-monitoring.models";

export namespace NetworkMonitoringActions {
    export class GetInstallations {
        public static readonly type = "[SUP - Network Monitoring] Get installations";
    }

    export class SelectInstallation {
        public static readonly type = "[SUP - Network Monitoring] Select installation";
        constructor(public id: string) {}
    }

    export class GetDeviceHistory {
        public static readonly type = "[SUP - Network Monitoring] Get device history";
        constructor(public deviceId: string) {}
    }

    export class LoadMoreDeviceHistory {
        public static readonly type = "[SUP - Network Monitoring] Load more device history";
        constructor(public deviceId: string) {}
    }

    export class UpdateSignalViolationNote {
        public static readonly type = "[SUP - Network Monitoring] Update signal violation note";
        constructor(public noteData: SignalViolationData) {}
    }
}
