import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Aerial, Installation, Sector, SectorStatus } from "../../models/network-monitoring.models";

interface InstallationsComponentState {
    installations: Installation[];
    selectedTile: string | undefined;
}
@Component({
    selector: "supervisor-shared-lib-installations[installations]",
    templateUrl: "./installations.component.html",
    styleUrls: ["./installations.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class InstallationsComponent {
    @Input() public set installations(value: Installation[] | undefined) {
        this.localStore.patchState({ installations: value ?? [] });
    }

    @Output() public installationSelect = new EventEmitter<string>();

    protected readonly installations$ = this.localStore.selectByKey("installations");
    protected readonly selectedTile$ = this.localStore.selectByKey("selectedTile");

    constructor(private readonly localStore: LocalComponentStore<InstallationsComponentState>) {
        this.localStore.setState({ installations: [], selectedTile: undefined });
    }

    protected getDtmStatus(aerials: Aerial[]): SectorStatus {
        return aerials.map((aerial) => aerial.sectors.some((sector) => sector.sectorStatus === SectorStatus.ConnectionLost)).includes(true)
            ? SectorStatus.ConnectionLost
            : SectorStatus.Active;
    }

    protected getSectorStatuses(sectors: Sector[]): SectorStatus[] {
        return sectors.map((sector) => sector.sectorStatus);
    }

    protected selectItem(installationName: string): void {
        this.localStore.patchState({ selectedTile: installationName });
        this.installationSelect.emit(installationName);
    }
}
