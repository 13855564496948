<div
    class="tile"
    [class.in-realization]="vm.proceedingMission.missionStatus === MissionStatus.Started"
    [class.active]="vm.proceedingMission.missionStatus === MissionStatus.Activated"
    [class.selected]="isSelected$ | ngrxPush"
    *ngrxLet="{
        proceedingMission: proceedingMission$,
        currentMissionPlanData: currentMissionPlanData$,
        currentPlanAnalysisStatus: currentPlanAnalysisStatus$,
        selectedMission: selectedMission$,
        missionTimeRange: missionTimeRange$,
        isManageable: isManageable$,
    } as vm"
    (click)="selectMission(vm.proceedingMission)"
>
    <ng-container *ngrxLet="isExpanded$ as isExpanded">
        <div class="basic-info">
            <div class="mission-type">
                <dtm-ui-icon [name]="vm.proceedingMission.missionType === MissionType.VLOS ? 'eye' : 'eye-off'"></dtm-ui-icon>
                <div class="mission-name">
                    {{ vm.proceedingMission.supMissionIdentifier }}
                </div>
                <div class="id" [matTooltip]="vm.proceedingMission.id" matTooltipPosition="above">
                    {{
                        "dtmSupOperationalSituation.proceedingMissionTile.idLabel"
                            | transloco : { value: vm.proceedingMission.id | invoke : displayShortId }
                    }}
                </div>
                <div class="uav-name">{{ vm.proceedingMission.uavName }}</div>
            </div>
            <div class="actions">
                <button
                    class="button-icon zoom"
                    type="button"
                    (click)="zoomToRoute($event, vm.proceedingMission.routeId)"
                    *ngIf="isZoomAvailable$ | ngrxPush"
                >
                    <dtm-ui-icon name="road-map"></dtm-ui-icon>
                </button>
                <button class="button-icon" type="button" (click)="expandContent()">
                    <dtm-ui-icon [name]="isExpanded ? 'arrow-up' : 'arrow-down'"></dtm-ui-icon>
                </button>
            </div>
        </div>
        <div class="mission-details" *ngrxLet="isActiveMission$ as isActiveMission">
            <div class="priority">
                <dtm-ui-icon name="arrow-up-double"></dtm-ui-icon>
                {{ vm.proceedingMission.priority }}
            </div>
            <div class="time-range">
                <dtm-mission-mission-time-range
                    [class.time-range-left]="!isActiveMission && vm.proceedingMission.missionStatus !== MissionStatus.Rejected"
                    [missionTimeRange]="vm.missionTimeRange"
                ></dtm-mission-mission-time-range>
            </div>
            <div class="rejected-mission" *ngIf="vm.proceedingMission.phase === MissionProcessingPhase.Rejected">
                <dtm-ui-icon name="close-circle-fill"></dtm-ui-icon>
                {{ "dtmSupOperationalSituation.proceedingMissionTile.rejectedLabel" | transloco }}
            </div>
            <div class="canceled-mission" *ngIf="vm.proceedingMission.phase === MissionProcessingPhase.MissionAbandoned">
                {{ "dtmSupOperationalSituation.proceedingMissionTile.canceledLabel" | transloco }}
            </div>
            <div
                class="events"
                [class.no-tracker]="
                    !vm.proceedingMission.trackerId ||
                    (vm.proceedingMission.missionStatus !== MissionStatus.Rejected &&
                        vm.proceedingMission.missionStatus !== MissionStatus.Activated &&
                        vm.proceedingMission.missionStatus !== MissionStatus.Started)
                "
                *ngIf="isActiveMission"
            >
                <div class="violation" *ngIf="vm.proceedingMission.trackerId">
                    <dtm-ui-icon name="complex-leaving-area"></dtm-ui-icon>
                    <div class="status" [class.active]="!vm.proceedingMission.isViolated"></div>
                </div>
                <dtm-ui-icon name="alarm-warning" [class.emergency]="vm.proceedingMission.isEmergency"></dtm-ui-icon>
            </div>
        </div>
        <div class="expanded-area" *ngIf="isExpanded" @slideIn>
            <div class="basic-data">
                <div class="realization-date">
                    <dtm-ui-icon name="calendar-event"></dtm-ui-icon>
                    <div>{{ vm.proceedingMission.flightStartAt | localizeDate : { dateStyle: "short" } }}</div>
                </div>
                <div class="additional-info">
                    <div class="phone-number">
                        <dtm-ui-icon name="phone"></dtm-ui-icon>
                        {{ vm.proceedingMission.phoneNumber | formatPhoneNumber }}
                    </div>
                    <div class="tracker" *ngIf="vm.proceedingMission.trackerId">
                        <dtm-ui-icon name="tracker"></dtm-ui-icon>
                        {{ vm.proceedingMission.trackerId }}
                    </div>
                </div>
            </div>
            <div class="actions" *ngIf="vm.isManageable">
                <span
                    *ngIf="
                        !(vm.proceedingMission.missionStatus | invoke : isFinishedMission) &&
                        vm.proceedingMission.missionStatus !== MissionStatus.Started &&
                        vm.proceedingMission.missionStatus !== MissionStatus.Activated
                    "
                    >{{ "dtmSupOperationalSituation.proceedingMissionTile.actionLabel" | transloco }}</span
                >
                <button
                    class="button-icon"
                    type="button"
                    *ngIf="vm.proceedingMission | invoke : shouldOverrideButtonBeVisible"
                    (click)="missionTimeChange.emit(vm.proceedingMission)"
                >
                    <dtm-ui-icon name="time"></dtm-ui-icon>
                </button>
                <button
                    class="button-icon reject"
                    type="button"
                    *ngIf="vm.proceedingMission.missionStatus | invoke : isReadyMission"
                    (click)="missionReject.emit(vm.proceedingMission.missionId)"
                >
                    <dtm-ui-icon name="close-circle-fill"></dtm-ui-icon>
                </button>
            </div>
            <div class="panels">
                <dtm-ui-expandable-panel
                    *ngIf="
                        vm.selectedMission?.missionId === vm.proceedingMission.missionId &&
                        vm.selectedMission?.timeOverrideJustification as timeOverrideJustification
                    "
                >
                    <dtm-ui-expandable-simple-header
                        headerSlot
                        [label]="'dtmSupOperationalSituation.missionTimeChangeDialog.informationLabel' | transloco"
                    ></dtm-ui-expandable-simple-header>
                    {{ timeOverrideJustification }}
                </dtm-ui-expandable-panel>
                <dtm-mission-mission-details [mission]="missionDataSimple$ | ngrxPush" [isExpanded]="false">
                    <dtm-ui-expandable-simple-header
                        headerSlot
                        [label]="'dtmSupPlannedMissions.phaseDescription.basicDataLabel' | transloco"
                    >
                    </dtm-ui-expandable-simple-header>
                </dtm-mission-mission-details>
                <dtm-ui-expandable-panel>
                    <h2 class="section-title" headerSlot>
                        {{ "dtmSupPlannedMissions.phaseDescription.routeDetailsLabel" | transloco }}
                    </h2>
                    <dtm-ui-route-details
                        [route]="vm.selectedMission?.route"
                        [sectionStatuses]="segmentStatuses$ | ngrxPush"
                    ></dtm-ui-route-details>
                </dtm-ui-expandable-panel>
                <dtm-mission-flight-requirements
                    *ngIf="vm.currentMissionPlanData?.plan as missionPlan"
                    [zones]="zones$ | ngrxPush"
                    [currentPlanAnalysisStatus]="vm.currentPlanAnalysisStatus"
                    [remarks]="missionPlan.remarks"
                    [formalJustification]="missionPlan.remarks.justification"
                    [shouldDisplayMessageConfirmation]="false"
                    [missionContext]="MissionContextType.Supervisor"
                    [selectedZoneId]="selectedZoneId$ | ngrxPush"
                    [isUtmSupervisorIntegrated]="!isOldDtmSup"
                    [rejectionDate]="missionPlan.mission?.rejectedAt"
                    [rejectionJustification]="missionPlan.mission?.rejectionJustification"
                    [missionPhase]="missionPlan.phase"
                    (zoneSelect)="zoneSelect.emit($event)"
                ></dtm-mission-flight-requirements>
                <supervisor-shared-lib-plan-message
                    *ngIf="vm.currentMissionPlanData?.plan?.supervisorMessage"
                    [panelTitle]="'dtmSupPlannedMissions.phaseDescription.supMessageHeader' | transloco"
                    [message]="vm.currentMissionPlanData?.plan?.supervisorMessage"
                ></supervisor-shared-lib-plan-message>
            </div>
        </div>
    </ng-container>
</div>
