import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { DtmArea } from "../../../planned-missions/models/mission.models";

interface DtmAreaComponentState {
    dtmAreas: DtmArea[];
    selectedAreaName: string | undefined;
}

@Component({
    selector: "supervisor-shared-lib-dtm-area[dtmAreas]",
    templateUrl: "./dtm-area.component.html",
    styleUrls: ["./dtm-area.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class DtmAreaComponent {
    @Input() public set dtmAreas(value: DtmArea[] | undefined) {
        this.localStore.patchState({ dtmAreas: value ?? [] });
    }
    @Input() public set selectedAreaName(value: string | undefined) {
        this.localStore.patchState({ selectedAreaName: value });
    }

    @Output() public readonly dtmSelect = new EventEmitter<DtmArea>();

    protected readonly dtmAreas$ = this.localStore.selectByKey("dtmAreas");
    protected readonly selectedAreaName$ = this.localStore.selectByKey("selectedAreaName");

    constructor(private readonly localStore: LocalComponentStore<DtmAreaComponentState>) {
        this.localStore.setState({ dtmAreas: [], selectedAreaName: undefined });
    }

    protected selectDtmArea(dtmArea: DtmArea): void {
        this.localStore.patchState({ selectedAreaName: dtmArea.name });
        this.dtmSelect.emit(dtmArea);
    }
}
