import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { SupUserProfile, SupWorkspace } from "../models/sup-user.models";

interface WorkspaceSwitcherComponentState {
    userProfile: SupUserProfile | undefined;
    selectedWorkspace: SupWorkspace | undefined;
    userName: string | undefined;
}

@Component({
    selector: "supervisor-shared-lib-workspace-switcher",
    templateUrl: "./workspace-switcher.component.html",
    styleUrls: ["./workspace-switcher.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class WorkspaceSwitcherComponent {
    @Input() public set userProfile(value: SupUserProfile | undefined) {
        this.localStore.patchState({ userProfile: value });
    }
    @Input() public set selectedWorkspace(value: SupWorkspace | undefined) {
        this.localStore.patchState({ selectedWorkspace: value });
    }
    @Input() public set userName(value: string | undefined) {
        this.localStore.patchState({ userName: value });
    }
    @Output() public readonly logout = new EventEmitter<void>();
    @Output() public readonly workspaceChange = new EventEmitter<string>();

    public readonly userProfile$ = this.localStore.selectByKey("userProfile");
    public readonly selectedWorkspace$ = this.localStore.selectByKey("selectedWorkspace");
    public readonly userName$ = this.localStore.selectByKey("userName");

    constructor(private readonly localStore: LocalComponentStore<WorkspaceSwitcherComponentState>) {
        localStore.setState({
            userProfile: undefined,
            selectedWorkspace: undefined,
            userName: undefined,
        });
    }
}
