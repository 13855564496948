import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { MissionProcessingPhase, MissionType } from "../../../../shared/models/mission.models";
import { Mission } from "../../../models/mission-search.models";

interface MissionItemComponentState {
    mission: Mission | undefined;
    isSelected: boolean;
    isMissionZoomButtonVisible: boolean;
}

@Component({
    selector: "dtm-mission-mission-search-mission-item[mission]",
    templateUrl: "./mission-item.component.html",
    styleUrls: ["./mission-item.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionItemComponent {
    @Input() public set mission(value: Mission | undefined) {
        this.localStore.patchState({ mission: value });
    }

    @HostBinding("class.mission-tile-selected")
    @Input()
    public set isSelected(value: BooleanInput) {
        this.localStore.patchState({ isSelected: coerceBooleanProperty(value) });
    }
    public get isSelected() {
        return this.localStore.selectSnapshotByKey("isSelected");
    }

    @Input() public set isMissionZoomButtonVisible(value: BooleanInput) {
        this.localStore.patchState({ isMissionZoomButtonVisible: coerceBooleanProperty(value) });
    }

    @Output() public readonly detailsShow = new EventEmitter<void>();
    @Output() public readonly missionZoom = new EventEmitter<void>();

    protected readonly MissionType = MissionType;
    protected readonly MissionProcessingPhase = MissionProcessingPhase;
    protected readonly mission$ = this.localStore.selectByKey("mission").pipe(RxjsUtils.filterFalsy());
    protected readonly isMissionZoomButtonVisible$ = this.localStore.selectByKey("isMissionZoomButtonVisible");

    constructor(private readonly localStore: LocalComponentStore<MissionItemComponentState>) {
        this.localStore.setState({
            mission: undefined,
            isSelected: false,
            isMissionZoomButtonVisible: false,
        });
    }
}
