import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LetDirective, PushPipe } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { TimeRangeSliderComponent } from "./components/time-range-slider/time-range-slider.component";
import { WeatherConditionBoxComponent } from "./components/weather-condition-box/weather-condition-box.component";
import { WeatherConditionsGridComponent } from "./components/weather-conditions-grid/weather-conditions-grid.component";
import { WeatherComponent } from "./components/weather.component";
import { WeatherApiService } from "./services/weather-api.service";
import { WeatherState } from "./state/weather.state";

@NgModule({
    declarations: [WeatherComponent, WeatherConditionsGridComponent, WeatherConditionBoxComponent, TimeRangeSliderComponent],
    imports: [NgxsModule.forFeature([WeatherState]), CommonModule, LetDirective, PushPipe, SharedUiModule, SharedI18nModule, MatTabsModule],
    exports: [WeatherComponent, WeatherConditionsGridComponent, WeatherConditionBoxComponent, TimeRangeSliderComponent],
    providers: [
        {
            provide: WeatherApiService,
            useValue: undefined,
        },
    ],
})
export class WeatherModule {
    public static forRoot(): ModuleWithProviders<WeatherModule> {
        return {
            ngModule: WeatherModule,
            providers: [
                {
                    provide: WeatherApiService,
                    useClass: WeatherApiService,
                },
            ],
        };
    }
}
