import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { AnimationUtils } from "@dtm-frontend/shared/utils";
import { Observable, Subject } from "rxjs";
import { RegulationExemption, RegulationExemptionType } from "../../models/adding-permissions.models";

interface RegulationExemptionItemForm {
    isHeightEnabled: FormControl<boolean>;
    height: FormControl<number | null>;
    isDistanceEnabled: FormControl<boolean>;
    distance: FormControl<number | null>;
    isUavTakeOffMassEnabled: FormControl<boolean>;
    uavTakeOffMass: FormControl<number | null>;
    arePilotsExemptedFromCompetencies: FormControl<boolean>;
}

interface RegulationExemptionForm {
    vlos: FormGroup<RegulationExemptionItemForm>;
    bvlos: FormGroup<RegulationExemptionItemForm>;
}

interface RegulationExemptionItemFormValues {
    isHeightEnabled: boolean;
    height: number | null;
    isDistanceEnabled: boolean;
    distance: number | null;
    isUavTakeOffMassEnabled: boolean;
    uavTakeOffMass: number | null;
    arePilotsExemptedFromCompetencies: boolean;
}

export interface RegulationExemptionDialogData {
    regulationExemptions: RegulationExemption[];
    operatorName: string;
    operatorNumber: string;
    isProcessing$: Observable<boolean>;
}

const MIN_HEIGHT_IN_METERS = 1;
const MAX_HEIGHT_IN_METERS = 3000;
const HEIGHT_STEP = 10;
const HEIGHT_DEFAULT_VALUE = 150;
const MIN_DISTANCE_IN_KILOMETERS = 1;
const MAX_DISTANCE_IN_KILOMETERS = 1000;
const DISTANCE_STEP = 5;
const BVLOS_DISTANCE_DEFAULT_VALUE = 5;
const VLOS_DISTANCE_DEFAULT_VALUE = 2;
const MIN_TOM_IN_KILOGRAMS = 1;
const MAX_TOM_IN_KILOGRAMS = 1000;
const TOM_STEP = 10;
const TOM_DEFAULT_VALUE = 30;

@Component({
    templateUrl: "./regulation-exemption-dialog.component.html",
    styleUrls: ["./regulation-exemption-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation(100)],
})
export class RegulationExemptionDialogComponent {
    private readonly setRegulationExemptionValueSubject = new Subject<RegulationExemption[]>();
    public readonly setRegulationExemptionValue$ = this.setRegulationExemptionValueSubject.asObservable();

    public setRegulationExemptions(regulationExemptions: RegulationExemption[] | undefined) {
        this.setFormValuesByRegulationExemptions(regulationExemptions ?? []);
    }

    protected readonly form = new FormGroup<RegulationExemptionForm>({
        vlos: this.createRegulationExemptionItemForm(RegulationExemptionType.VLOS),
        bvlos: this.createRegulationExemptionItemForm(RegulationExemptionType.BVLOS),
    });

    protected readonly MIN_HEIGHT_IN_METERS = MIN_HEIGHT_IN_METERS;
    protected readonly MAX_HEIGHT_IN_METERS = MAX_HEIGHT_IN_METERS;
    protected readonly HEIGHT_STEP = HEIGHT_STEP;
    protected readonly MIN_DISTANCE_IN_KILOMETERS = MIN_DISTANCE_IN_KILOMETERS;
    protected readonly MAX_DISTANCE_IN_KILOMETERS = MAX_DISTANCE_IN_KILOMETERS;
    protected readonly DISTANCE_STEP = DISTANCE_STEP;
    protected readonly MIN_TOM_IN_KILOGRAMS = MIN_TOM_IN_KILOGRAMS;
    protected readonly MAX_TOM_IN_KILOGRAMS = MAX_TOM_IN_KILOGRAMS;
    protected readonly TOM_STEP = TOM_STEP;

    constructor(@Inject(MAT_DIALOG_DATA) protected readonly data: RegulationExemptionDialogData) {
        this.setFormValuesByRegulationExemptions(data.regulationExemptions);
    }

    protected save(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();

            return;
        }

        const formValues = this.form.getRawValue();
        const regulationExemptionVlos = this.createRegulationExemptionFromFormValues(formValues.vlos, RegulationExemptionType.VLOS);
        const regulationExemptionBvlos = this.createRegulationExemptionFromFormValues(formValues.bvlos, RegulationExemptionType.BVLOS);
        const regulationExemption = [];

        if (regulationExemptionVlos) {
            regulationExemption.push(regulationExemptionVlos);
        }

        if (regulationExemptionBvlos) {
            regulationExemption.push(regulationExemptionBvlos);
        }

        this.setRegulationExemptionValueSubject.next(regulationExemption);
    }

    private createRegulationExemptionItemForm(type: RegulationExemptionType): FormGroup<RegulationExemptionItemForm> {
        return new FormGroup<RegulationExemptionItemForm>({
            isHeightEnabled: new FormControl<boolean>(false, { nonNullable: true }),
            height: new FormControl<number>(HEIGHT_DEFAULT_VALUE, {
                nonNullable: true,
                validators: [Validators.min(MIN_HEIGHT_IN_METERS), Validators.max(MAX_HEIGHT_IN_METERS)],
            }),
            isDistanceEnabled: new FormControl<boolean>(false, { nonNullable: true }),
            distance: new FormControl<number>(
                type === RegulationExemptionType.VLOS ? VLOS_DISTANCE_DEFAULT_VALUE : BVLOS_DISTANCE_DEFAULT_VALUE,
                {
                    nonNullable: true,
                    validators: [Validators.min(MIN_DISTANCE_IN_KILOMETERS), Validators.max(MAX_DISTANCE_IN_KILOMETERS)],
                }
            ),
            isUavTakeOffMassEnabled: new FormControl<boolean>(false, { nonNullable: true }),
            uavTakeOffMass: new FormControl<number>(TOM_DEFAULT_VALUE, {
                nonNullable: true,
                validators: [Validators.min(MIN_TOM_IN_KILOGRAMS), Validators.max(MAX_TOM_IN_KILOGRAMS)],
            }),
            arePilotsExemptedFromCompetencies: new FormControl<boolean>(false, { nonNullable: true }),
        });
    }

    private setFormValuesByRegulationExemptions(regulationExemptions: RegulationExemption[]) {
        const vlosExemption = regulationExemptions.find((exemption) => exemption.type === RegulationExemptionType.VLOS);
        const bvlosExemption = regulationExemptions.find((exemption) => exemption.type === RegulationExemptionType.BVLOS);

        this.form.setValue(
            {
                vlos: {
                    isHeightEnabled: (vlosExemption?.height ?? null) !== null,
                    height: vlosExemption?.height ?? HEIGHT_DEFAULT_VALUE,
                    isDistanceEnabled: (vlosExemption?.distance ?? null) !== null,
                    distance: vlosExemption?.distance ?? VLOS_DISTANCE_DEFAULT_VALUE,
                    isUavTakeOffMassEnabled: (vlosExemption?.uavTakeOffMass ?? null) !== null,
                    uavTakeOffMass: vlosExemption?.uavTakeOffMass ?? TOM_DEFAULT_VALUE,
                    arePilotsExemptedFromCompetencies: vlosExemption?.arePilotsExemptedFromCompetencies ?? false,
                },
                bvlos: {
                    isHeightEnabled: (bvlosExemption?.height ?? null) !== null,
                    height: bvlosExemption?.height ?? HEIGHT_DEFAULT_VALUE,
                    isDistanceEnabled: (bvlosExemption?.distance ?? null) !== null,
                    distance: bvlosExemption?.distance ?? BVLOS_DISTANCE_DEFAULT_VALUE,
                    isUavTakeOffMassEnabled: (bvlosExemption?.uavTakeOffMass ?? null) !== null,
                    uavTakeOffMass: bvlosExemption?.uavTakeOffMass ?? TOM_DEFAULT_VALUE,
                    arePilotsExemptedFromCompetencies: bvlosExemption?.arePilotsExemptedFromCompetencies ?? false,
                },
            },
            { emitEvent: false }
        );
    }

    private createRegulationExemptionFromFormValues(
        values: RegulationExemptionItemFormValues | undefined,
        type: RegulationExemptionType
    ): RegulationExemption | undefined {
        const height = values?.isHeightEnabled && values?.height ? values.height : null;
        const distance = values?.isDistanceEnabled && values?.distance ? values.distance : null;
        const uavTakeOffMass = values?.isUavTakeOffMassEnabled && values?.uavTakeOffMass ? values.uavTakeOffMass : null;
        const arePilotsExemptedFromCompetencies = !!values?.arePilotsExemptedFromCompetencies;

        if (height || distance || uavTakeOffMass || arePilotsExemptedFromCompetencies) {
            return {
                type,
                height,
                distance,
                uavTakeOffMass,
                arePilotsExemptedFromCompetencies,
            };
        }

        return undefined;
    }
}
