import { AddingPermissionsOperator, AddingPermissionsOperatorFilterParams } from "../models/adding-permissions-operator.models";
import { AddingPermissionsPilot, AddingPermissionsPilotFilterParams } from "../models/adding-permissions-pilot.models";

export namespace AddingPermissionsActions {
    export class GetAddingPermissionsOperators {
        public static readonly type = "[Adding permissions] Get adding permissions operators list";
        constructor(public filtersQuery: AddingPermissionsOperatorFilterParams) {}
    }

    export class GetAddingPermissionsPilots {
        public static readonly type = "[Adding permissions] Get adding permissions pilots list";
        constructor(public filtersQuery: AddingPermissionsPilotFilterParams) {}
    }

    export class SetAddingPermissionsSuperPilotPermission {
        public static readonly type = "[Adding permissions] Set adding permissions super pilot permission";
        constructor(public pilotId: string) {}
    }

    export class RemoveAddingPermissionsSuperPilotPermission {
        public static readonly type = "[Adding permissions] Remove adding permissions super pilot permission";
        constructor(public pilotId: string) {}
    }

    export class SetAddingPermissionsOperatorEmergencyPermission {
        public static readonly type = "[Adding permissions] Set adding permissions operator emergency permission";
        constructor(public operatorId: string) {}
    }

    export class RemoveAddingPermissionsOperatorEmergencyPermission {
        public static readonly type = "[Adding permissions] Remove adding permissions operator emergency permission";
        constructor(public operatorId: string) {}
    }

    export class GetOperatorQualifications {
        public static readonly type = "[Adding permissions] Get operator qualifications";

        constructor(public operator: AddingPermissionsOperator) {}
    }

    export class GetPilotQualifications {
        public static readonly type = "[Adding permissions] Get pilot qualifications";

        constructor(public operator: AddingPermissionsPilot) {}
    }
}
