import { Injectable } from "@angular/core";
import { Page } from "@dtm-frontend/shared/ui";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, finalize, tap } from "rxjs";
import { catchError } from "rxjs/operators";
import {
    AddingPermissionsOperator,
    AddingPermissionsOperatorError,
    AddingPermissionsOperatorListWithPages,
} from "../models/adding-permissions-operator.models";
import {
    AddingPermissionsPilot,
    AddingPermissionsPilotError,
    AddingPermissionsPilotListWithPages,
} from "../models/adding-permissions-pilot.models";
import { AddingPermissionsApiService } from "../services/adding-permissions-api.service";
import { AddingPermissionsActions } from "./adding-permissions.actions";

interface AddingPermissionsModel {
    addingPermissionsOperatorsList: AddingPermissionsOperator[] | undefined;
    addingPermissionsOperatorsListError: AddingPermissionsOperatorError | undefined;
    gettingQualificationsError: AddingPermissionsOperatorError | undefined;
    isAddingPermissionsOperatorsListProcessing: boolean;
    addingPermissionsOperatorsPages: Page | undefined;

    addingPermissionsPilotsList: AddingPermissionsPilot[] | undefined;
    addingPermissionsPilotsListError: AddingPermissionsPilotError | undefined;
    isAddingPermissionsPilotsListProcessing: boolean;
    addingPermissionsPilotsPages: Page | undefined;

    addingPermissionsPermissionError: AddingPermissionsOperatorError | AddingPermissionsPilotError | undefined;
}

const defaultState: AddingPermissionsModel = {
    addingPermissionsOperatorsList: undefined,
    addingPermissionsOperatorsListError: undefined,
    gettingQualificationsError: undefined,
    isAddingPermissionsOperatorsListProcessing: false,
    addingPermissionsOperatorsPages: undefined,

    addingPermissionsPilotsList: undefined,
    addingPermissionsPilotsListError: undefined,
    isAddingPermissionsPilotsListProcessing: false,
    addingPermissionsPilotsPages: undefined,
    addingPermissionsPermissionError: undefined,
};

@State<AddingPermissionsModel>({
    name: "addingPermissions",
    defaults: defaultState,
})
@Injectable()
export class AddingPermissionsState {
    constructor(private readonly addingPermissionsApiService: AddingPermissionsApiService) {}

    @Selector()
    public static addingPermissionsOperatorsList(state: AddingPermissionsModel): AddingPermissionsOperator[] | undefined {
        return state.addingPermissionsOperatorsList;
    }

    @Selector()
    public static addingPermissionsOperatorsListError(state: AddingPermissionsModel): AddingPermissionsOperatorError | undefined {
        return state.addingPermissionsOperatorsListError;
    }

    @Selector()
    public static isAddingPermissionsOperatorsListProcessing(state: AddingPermissionsModel): boolean {
        return state.isAddingPermissionsOperatorsListProcessing;
    }

    @Selector()
    public static addingPermissionsOperatorsPages(state: AddingPermissionsModel): Page | undefined {
        return state.addingPermissionsOperatorsPages;
    }

    @Selector()
    public static addingPermissionsPilotsList(state: AddingPermissionsModel): AddingPermissionsPilot[] | undefined {
        return state.addingPermissionsPilotsList;
    }

    @Selector()
    public static addingPermissionsPilotsListError(state: AddingPermissionsModel): AddingPermissionsPilotError | undefined {
        return state.addingPermissionsPilotsListError;
    }

    @Selector()
    public static isAddingPermissionsPilotsListProcessing(state: AddingPermissionsModel): boolean {
        return state.isAddingPermissionsPilotsListProcessing;
    }

    @Selector()
    public static addingPermissionsPilotsPages(state: AddingPermissionsModel): Page | undefined {
        return state.addingPermissionsPilotsPages;
    }

    @Selector()
    public static addingPermissionsPermissionError(
        state: AddingPermissionsModel
    ): AddingPermissionsOperatorError | AddingPermissionsPilotError | undefined {
        return state.addingPermissionsPermissionError;
    }

    @Selector()
    public static gettingQualificationsError(state: AddingPermissionsModel): AddingPermissionsOperatorError | undefined {
        return state.gettingQualificationsError;
    }

    @Action(AddingPermissionsActions.GetAddingPermissionsOperators)
    public getAddingPermissionsOperatorsList(
        context: StateContext<AddingPermissionsModel>,
        { filtersQuery }: AddingPermissionsActions.GetAddingPermissionsOperators
    ) {
        context.patchState({ isAddingPermissionsOperatorsListProcessing: true, addingPermissionsOperatorsListError: undefined });

        return this.addingPermissionsApiService.getOperatorsList(filtersQuery).pipe(
            tap(({ content, pageSize, pageNumber, totalElements }: AddingPermissionsOperatorListWithPages) =>
                context.patchState({
                    addingPermissionsOperatorsList: content,
                    addingPermissionsOperatorsPages: {
                        pageSize,
                        pageNumber,
                        totalElements,
                    },
                })
            ),
            catchError((addingPermissionsOperatorsListError) => {
                context.patchState({ addingPermissionsOperatorsListError, addingPermissionsOperatorsList: [] });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isAddingPermissionsOperatorsListProcessing: false,
                })
            )
        );
    }

    @Action(AddingPermissionsActions.GetAddingPermissionsPilots)
    public getAddingPermissionsPilotsList(
        context: StateContext<AddingPermissionsModel>,
        action: AddingPermissionsActions.GetAddingPermissionsPilots
    ) {
        context.patchState({ isAddingPermissionsPilotsListProcessing: true, addingPermissionsPilotsListError: undefined });

        return this.addingPermissionsApiService.getPilotsList(action.filtersQuery).pipe(
            tap(({ content, pageSize, pageNumber, totalElements }: AddingPermissionsPilotListWithPages) =>
                context.patchState({
                    addingPermissionsPilotsList: content,
                    addingPermissionsPilotsPages: {
                        pageSize,
                        pageNumber,
                        totalElements,
                    },
                })
            ),
            catchError((addingPermissionsPilotsListError) => {
                context.patchState({ addingPermissionsPilotsListError, addingPermissionsPilotsList: [] });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isAddingPermissionsPilotsListProcessing: false,
                })
            )
        );
    }

    @Action(AddingPermissionsActions.SetAddingPermissionsOperatorEmergencyPermission)
    public setAddingPermissionsOperatorEmergencyPermission(
        context: StateContext<AddingPermissionsModel>,
        { operatorId }: AddingPermissionsActions.SetAddingPermissionsOperatorEmergencyPermission
    ) {
        context.patchState({ isAddingPermissionsOperatorsListProcessing: true, addingPermissionsPermissionError: undefined });

        return this.addingPermissionsApiService.setAddingPermissionsEmergencyPermission(operatorId).pipe(
            catchError((addingPermissionsPermissionError) => {
                context.patchState({ addingPermissionsPermissionError });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isAddingPermissionsOperatorsListProcessing: false,
                })
            )
        );
    }

    @Action(AddingPermissionsActions.RemoveAddingPermissionsOperatorEmergencyPermission)
    public removeAddingPermissionsOperatorEmergencyPermission(
        context: StateContext<AddingPermissionsModel>,
        { operatorId }: AddingPermissionsActions.RemoveAddingPermissionsOperatorEmergencyPermission
    ) {
        context.patchState({ isAddingPermissionsOperatorsListProcessing: true, addingPermissionsPermissionError: undefined });

        return this.addingPermissionsApiService.removeAddingPermissionsEmergencyPermission(operatorId).pipe(
            catchError((addingPermissionsPermissionError) => {
                context.patchState({ addingPermissionsPermissionError });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isAddingPermissionsOperatorsListProcessing: false,
                })
            )
        );
    }

    @Action(AddingPermissionsActions.SetAddingPermissionsSuperPilotPermission)
    public setAddingPermissionsSuperPilotPermission(
        context: StateContext<AddingPermissionsModel>,
        { pilotId }: AddingPermissionsActions.SetAddingPermissionsSuperPilotPermission
    ) {
        context.patchState({ isAddingPermissionsPilotsListProcessing: true, addingPermissionsPermissionError: undefined });

        return this.addingPermissionsApiService.setAddingPermissionsSuperPilotPermission(pilotId).pipe(
            catchError((addingPermissionsPermissionError) => {
                context.patchState({ addingPermissionsPermissionError });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isAddingPermissionsPilotsListProcessing: false,
                })
            )
        );
    }

    @Action(AddingPermissionsActions.RemoveAddingPermissionsSuperPilotPermission)
    public removeAddingPermissionsSuperPilotPermission(
        context: StateContext<AddingPermissionsModel>,
        { pilotId }: AddingPermissionsActions.RemoveAddingPermissionsSuperPilotPermission
    ) {
        context.patchState({ isAddingPermissionsPilotsListProcessing: true, addingPermissionsPermissionError: undefined });

        return this.addingPermissionsApiService.removeAddingPermissionsSuperPilotPermission(pilotId).pipe(
            catchError((addingPermissionsPermissionError) => {
                context.patchState({ addingPermissionsPermissionError });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isAddingPermissionsPilotsListProcessing: false,
                })
            )
        );
    }

    @Action(AddingPermissionsActions.GetOperatorQualifications)
    public getOperatorQualifications(
        context: StateContext<AddingPermissionsModel>,
        action: AddingPermissionsActions.GetOperatorQualifications
    ) {
        context.patchState({ gettingQualificationsError: undefined, isAddingPermissionsOperatorsListProcessing: true });

        return this.addingPermissionsApiService.getOperatorQualifications(action.operator.operatorNumber).pipe(
            tap((qualifications) => {
                const operatorsList = [...(context.getState().addingPermissionsOperatorsList ?? [])];
                const modifiedList = operatorsList.map((operator) => {
                    if (operator.operatorNumber === action.operator.operatorNumber) {
                        return { ...operator, qualifications };
                    }

                    return operator;
                });

                context.patchState({
                    addingPermissionsOperatorsList: modifiedList,
                });
            }),
            catchError((getOperatorQualificationsError) => {
                context.patchState({ gettingQualificationsError: getOperatorQualificationsError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isAddingPermissionsOperatorsListProcessing: false }))
        );
    }

    @Action(AddingPermissionsActions.GetPilotQualifications)
    public getPilotQualifications(context: StateContext<AddingPermissionsModel>, action: AddingPermissionsActions.GetPilotQualifications) {
        context.patchState({ gettingQualificationsError: undefined, isAddingPermissionsOperatorsListProcessing: true });

        return this.addingPermissionsApiService.getPilotQualifications(action.operator.pilotNumber).pipe(
            tap((qualifications) => {
                const pilotsList = [...(context.getState().addingPermissionsPilotsList ?? [])];
                const modifiedList = pilotsList.map((pilot) => {
                    if (pilot.pilotNumber === action.operator.pilotNumber) {
                        return { ...pilot, qualifications };
                    }

                    return pilot;
                });

                context.patchState({
                    addingPermissionsPilotsList: modifiedList,
                });
            }),
            catchError((getPilotQualificationsError) => {
                context.patchState({ gettingQualificationsError: getPilotQualificationsError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isAddingPermissionsOperatorsListProcessing: false }))
        );
    }
}
