<ng-container *ngrxLet="{ dtmStats: dtmStats$ } as vm">
    <div
        *ngFor="let area of dtmAreas$ | ngrxPush"
        class="dtm-tile"
        [class.selected]="(selectedAreaName$ | ngrxPush) === area"
        (click)="selectDtmArea(area)"
        (keydown.enter)="selectDtmArea(area)"
        tabindex="0"
        role="button"
    >
        <div class="header">
            <h2 class="dtm-name">{{ area }}</h2>
            <dtm-ui-icon name="alarm-warning" [class.is-alarm]="!!vm.dtmStats?.[area]?.mission?.flightsWithEmergencies"></dtm-ui-icon>
        </div>
        <div class="info-container">
            <div>
                <dtm-ui-icon name="drone"></dtm-ui-icon>
                {{ vm.dtmStats?.[area]?.mission?.total ?? '-' }}
                (<dtm-ui-icon name="eye-off"></dtm-ui-icon> {{vm.dtmStats?.[area]?.mission?.bvlos ?? '-'}})
            </div>
            <div>
                <dtm-ui-icon name="complex-leaving-area" [title]="'dtmSupervisorLibShared.dtmArea.leavingArea' | transloco"></dtm-ui-icon>
                <div class="status" [ngClass]="vm.dtmStats?.[area] | invoke: getFlightsOutsideMissionLevel"></div>
            </div>
            <div>
                <dtm-ui-icon name="base-station" [title]="'dtmSupervisorLibShared.dtmArea.adsbInfrastructure' | transloco"></dtm-ui-icon>
                <div class="status" [ngClass]="vm.dtmStats?.[area] | invoke: getDevicesViolatedLevel"></div>
            </div>
        </div>
    </div>
</ng-container>
