<ng-container *ngrxLet="activeFilter$ as activeFilter">
    <div
        *ngFor="let configuration of filterConfiguration"
        class="selectable-tile"
        [class.selected]="configuration.status === activeFilter"
        (click)="selectStatus(configuration.status)"
    >
        <div class="content">
            <dtm-ui-icon
                *ngIf="configuration.iconName"
                [name]="configuration.iconName"
                [class.in-realization]="configuration.status === MissionStatusFilter.Started"
                [class.activated]="configuration.status === MissionStatusFilter.Activated"
            ></dtm-ui-icon>
            <span class="selectable-tile-label">{{ configuration.translationKey | transloco }}</span>
        </div>
    </div>
</ng-container>
