import {
    AdditionalCrewMember,
    AirRisk,
    AirwaysCollisionTranslationArgs,
    AnalysisIssueSource,
    AnalysisIssueStatus,
    AnalysisOptionStatus,
    AuthorityAcceptationItem,
    AuthorityAcceptationStatus,
    DensityBox,
    DensityBoxColor,
    EvaluationIssueClosingEntity,
    EvaluationIssueStatus,
    FlightTerminationSystem,
    MissionCategory,
    MissionPlanAirspaceAnalysisOption,
    MissionPlanAnalysisEvaluationIssue,
    MissionPlanAnalysisIssue,
    MissionPlanAnalysisIssueBase,
    MissionPlanAnalysisIssueStatus,
    MissionPlanAnalysisOptionStatus,
    MissionPlanAnalysisStatus,
    MissionPlanContentActions,
    MissionPlanDataAndCapabilities,
    MissionPlanEvaluationOption,
    MissionPlanFlightPurpose,
    MissionPlanInformation,
    MissionPlanOperationCategoryOption,
    MissionPlanOperationCategoryOptionIssue,
    MissionPlanRemarks,
    MissionPlanResponseBodyCapabilitiesEntity,
    MissionPlanSpecificPermitType,
    MissionPlanStatus,
    MissionPlanTrafficAnalysisOption,
    MissionPlanVerificationResponseBodyAnalysisIssue,
    MissionProcessingPhase,
    MissionType,
    PlanVerificationStatus,
    SoraResult,
    TacticalMitigationPerformanceRequirementProperty,
    TrafficCollisionTranslationArgs,
} from "@dtm-frontend/shared/mission";
import { ItineraryEditorType } from "@dtm-frontend/shared/ui";
import { ArrayUtils, FunctionUtils, ObjectUtils } from "@dtm-frontend/shared/utils";

export interface MissionPlanFlightPurposeOption {
    id: string;
    displayName: string;
    description: string;
    codename: string;
    isLoadWeightRequired: boolean;
    isCommentRequired: boolean;
    isEmergency: boolean;
}

export interface MissionPlanCapabilities {
    flightType: MissionType;
    operatorId: string;
    pilotId: string;
    uavSetupId: string;
    additionalCrew: AdditionalCrewMember[];
}

export interface MissionPlan {
    id: string;
    status: MissionPlanStatus;
    capabilities: MissionPlanCapabilities;
    operationCategory: MissionPlanOperationCategoryOption | undefined;
    flightPurpose: MissionPlanFlightPurpose | undefined;
    information: MissionPlanInformation;
}

export interface MissionPlanAndCapabilities {
    plan: MissionPlan;
    flightPurposes: MissionPlanFlightPurposeOption[];
    operationCategories: MissionPlanOperationCategoryOption[];
    editors: {
        options: ItineraryEditorType[];
        suggested: ItineraryEditorType;
    };
}

export interface AuthorityAcceptation {
    acceptationStatus: AuthorityAcceptationStatus;
    comment: string;
    updatedAt: string;
    confirmedByUser: boolean;
}

export enum MissionStatus {
    Accepted = "ACCEPTED",
    Canceled = "CANCELED",
    Activated = "ACTIVATED",
    Rejected = "REJECTED",
    Finished = "FINISHED",
}

export interface MissionPlanOperationCategoryResponseBody {
    type: MissionCategory;
    specificPermit: MissionPlanSpecificPermitType;
    scenarioName: string;
    specificCaaPermitId: string;
    specificLucSkipSora: boolean;
}

export interface MissionPlanCapabilitiesResponseBodyCategoryEntity {
    id: string;
    name: string;
    type: MissionCategory;
    disabled: boolean;
    issues: {
        id: string;
        args: Record<string, string>;
    }[];
}

export interface MissionPlanCapabilitiesResponseBody {
    plan: MissionPlanCapabilitiesResponseBodyPlanEntity;
    operationCategories?: MissionPlanCapabilitiesResponseBodyCategoryEntity[];
    operatorHasLightUasCertificate: boolean;
    flightPurposes?: {
        id: string;
        name: string;
        description: string;
        codename: string;
        loadWeightRequired: boolean;
        commentRequired: boolean;
        emergency: boolean;
    }[];
    options?: {
        [key in ItineraryEditorType]: { suggested: boolean };
    };
}

export interface MissionPlanCapabilitiesResponseBodyPlanEntity {
    id: string;
    status: MissionPlanStatus;
    capabilities: MissionPlanResponseBodyCapabilitiesEntity;
    flightPurpose?: MissionPlanFlightPurpose;
    category?: MissionPlanOperationCategoryResponseBody;
    information?: {
        name: string;
        description: string;
        notes?: string;
    };
    itinerary?: {
        id: string;
        editorType: ItineraryEditorType;
    };
    route?: {
        id: string;
        created: string;
        modified: string;
        dtmNames: string[];
        dtmOnly: boolean;
        pathBased: boolean;
    };
    mission?: {
        id: string;
        routeId: string;
        status: MissionPlanStatus;
        startAt: string;
    };
    uav: {
        serialNumbers: string[];
        name: string;
        model: string;
        manufacturer: string;
    };
}

export interface FormalJustificationResponseBody {
    requestForPriority?: boolean;
    reason?: string | null;
    attachmentIds?: string[];
    attachments?: {
        contentType?: string;
        createdAt: Date;
        fileId?: string;
        name?: string;
    }[];
}

export function convertMissionPlanOperationCategoryToOption(
    category: MissionPlanOperationCategoryResponseBody
): MissionPlanOperationCategoryOption | undefined {
    const { specificCaaPermitId } = category;
    if (category.type === MissionCategory.Open) {
        return {
            type: MissionCategory.Open,
            scenarioName: category.scenarioName,
            isDisabled: false,
            issues: [],
        };
    }

    if (category.type === MissionCategory.Specific) {
        switch (category.specificPermit) {
            case MissionPlanSpecificPermitType.Individual:
                return {
                    type: MissionCategory.Specific,
                    specificPermitType: MissionPlanSpecificPermitType.Individual,
                    specificCaaPermitId,
                    isDisabled: false,
                };
            case MissionPlanSpecificPermitType.Luc:
                return {
                    type: MissionCategory.Specific,
                    specificPermitType: MissionPlanSpecificPermitType.Luc,
                    shouldSkipSora: category.specificLucSkipSora,
                    isDisabled: false,
                };
            case MissionPlanSpecificPermitType.Sts:
                return {
                    type: MissionCategory.Specific,
                    specificPermitType: MissionPlanSpecificPermitType.Sts,
                    scenarioName: category.scenarioName,
                    isDisabled: false,
                    issues: [],
                };
        }
    }

    return undefined;
}

function convertAcceptationData(data?: AuthorityAcceptation): AuthorityAcceptationItem | undefined {
    return data
        ? {
              acceptationStatus: data.acceptationStatus,
              comment: data.comment,
              updatedAt: new Date(data.updatedAt),
              isConfirmedByUser: data.confirmedByUser,
          }
        : undefined;
}

export interface MissionPlanVerificationResponseBodyEvaluationIssue extends MissionPlanAnalysisIssueBase {
    status: EvaluationIssueStatus;
    closedBy: EvaluationIssueClosingEntity;
    path: string;
}

export interface FlightTerminationSystemResponseBody {
    available: boolean;
    required: boolean;
    issues: string[];
}

export interface MissionPlanVerificationResponseBody {
    planId: string;
    routeId: string;
    specificCaaPermitId: string;
    planStatus: MissionPlanStatus;
    planSubmittable: boolean;
    manualVerificationRequired: boolean;
    trafficStatus: AnalysisOptionStatus;
    status: PlanVerificationStatus;
    airspace: {
        adjacentControlled: boolean;
        adjacentUncontrolled: boolean;
        operationalControlled: boolean;
        operationalUncontrolled: boolean;
    };
    airspaceStatus: AnalysisOptionStatus;
    evaluationIssues: MissionPlanVerificationResponseBodyEvaluationIssue[];
    analysisIssues: MissionPlanVerificationResponseBodyAnalysisIssue[];
    applyForSpecificPermit: boolean;
    caaPermitRequired: boolean;
    caaPermitStatus?: AnalysisOptionStatus;
    soraRequired: boolean;
    soraStatus?: AnalysisOptionStatus;
    soraResult?: {
        finalGrl: number;
        finalGrc: number;
        intrinsicGrc: number;
        groundRiskTradeoff: number;
        residualArc: number;
        initialArc: number;
        sail: number;
        tmpr?: TacticalMitigationPerformanceRequirementProperty;
        populationDensity?: {
            boxes?: Partial<Record<DensityBoxColor, number>>;
            peoplePerSquareKilometerAvg?: number;
            peoplePerSquareKilometerMax?: number;
            urban?: boolean;
        };
        flightTerminationSystem: FlightTerminationSystemResponseBody;
        adjacentArea?: Omit<SoraResult, "adjacentArea" | "airRisk" | "tacticalMitigationPerformanceRequirementProperty">;
        airRisk: AirRisk;
    };
    version: number;
    update: string;
    systemVerificationId: string;
    flightConditions: {
        checkinApprovalRequired: boolean;
        translationArgs: Record<string, string> | null;
        translationId: string;
    }[];
}

interface MissionPlanListResponseBodyContentEntity {
    id: string;
    missionId: string;
    thumbnail: string;
    created: Date;
    modified: Date;
    name: string;
    status: MissionPlanStatus;
    phase: MissionProcessingPhase;
    abilities: MissionPlanContentActions;
    specificCaaPermitId: string;
    permitBusinessId: string;
    uavId: string;
    uavName?: string;
    uavSetupName: string;
    pilotId: string;
    pilotName?: string;
    operatorId: string;
    operatorName?: string;
    missionSubmittedAt: Date;
    flightStartAtMin: Date;
    flightStartAtMax: Date;
    flightFinishAtMin: Date;
    flightFinishAtMax: Date;
    flightType: MissionType;
    operationCategoryType: MissionCategory;
    flightPurpose: string;
    flightPurposeComment: string;
    soraSail: number;
    description: string;
    missionStatus: MissionStatus;
    uncertainConditions: boolean;
    remarks?: RemarksResponse;
}

interface RemarksResponse {
    formalJustification?: FormalJustificationResponseBody;
    dtm?: AuthorityAcceptation;
    utm?: AuthorityAcceptation;
    dss?: AuthorityAcceptation;
    partialAcceptations?: PartialAuthorityAcceptationResponse[];
}

export interface PartialAuthorityAcceptationResponse {
    acknowledgedByUserAt: string;
    authorityType: string;
    authorityUnitId: string;
    authorityUnitName: string;
    authorityUnitType: string;
    comment: string;
    status: AuthorityAcceptationStatus;
    updatedAt: string;
}

export type MissionPlanDataResponseBody = {
    plan: MissionPlanListResponseBodyContentEntity & MissionPlanCapabilitiesResponseBodyPlanEntity;
} & Omit<MissionPlanCapabilitiesResponseBody, "plan">;

export function convertFlightTerminationSystem({
    required,
    available,
    issues,
}: FlightTerminationSystemResponseBody): FlightTerminationSystem {
    return {
        isRequired: required,
        isAvailable: available,
        issues,
    };
}

const transformIssueStatus = (status: AnalysisIssueStatus): MissionPlanAnalysisIssueStatus => {
    switch (status) {
        case AnalysisIssueStatus.Failure:
            return MissionPlanAnalysisIssueStatus.Error;
        case AnalysisIssueStatus.Warning:
            return MissionPlanAnalysisIssueStatus.Warning;
        case AnalysisIssueStatus.Success:
            return MissionPlanAnalysisIssueStatus.Success;
        case AnalysisIssueStatus.Info:
            return MissionPlanAnalysisIssueStatus.Info;
        case AnalysisIssueStatus.Hold:
            return MissionPlanAnalysisIssueStatus.Hold;
        default:
            return MissionPlanAnalysisIssueStatus.FatalError;
    }
};

const transformSoraResultFromResponse = (response: MissionPlanVerificationResponseBody): SoraResult | undefined => {
    const responseSoraResult = response.soraResult;

    if (!responseSoraResult) {
        return undefined;
    }

    const {
        finalGrc,
        finalGrl,
        intrinsicGrc,
        populationDensity,
        sail,
        residualArc,
        adjacentArea,
        airRisk,
        groundRiskTradeoff,
        initialArc,
        flightTerminationSystem,
        tmpr,
    } = responseSoraResult;

    return {
        finalGrc,
        finalGrl,
        intrinsicGrc,
        sail,
        groundRiskTradeoff,
        initialArc,
        residualArc,
        adjacentArea,
        airRisk,
        flightTerminationSystem: convertFlightTerminationSystem(flightTerminationSystem),
        tacticalMitigationPerformanceRequirementProperty: tmpr,
        populationDensity: {
            peoplePerSquareKilometerAvg: populationDensity?.peoplePerSquareKilometerAvg,
            peoplePerSquareKilometerMax: populationDensity?.peoplePerSquareKilometerMax,
            boxes: {
                [DensityBox.Lowest]: populationDensity?.boxes?.GREEN,
                [DensityBox.Low]: populationDensity?.boxes?.YELLOW,
                [DensityBox.Medium]: populationDensity?.boxes?.RED,
                [DensityBox.High]: populationDensity?.boxes?.VIOLET,
                [DensityBox.Highest]: populationDensity?.boxes?.BLUE,
                [DensityBox.Unknown]: populationDensity?.boxes?.UNKNOWN,
            },
        },
    };
};

const getIssuesForAnalysisOption = (
    option: AnalysisIssueSource,
    analysisIssues: MissionPlanVerificationResponseBodyAnalysisIssue[]
): MissionPlanAnalysisIssue[] =>
    analysisIssues
        .filter((issue) => issue.source === option)
        .map((issue) => ({
            codename: issue.codename,
            translationArgs: issue.translationArgs,
            translationId: issue.translationId,
            status: transformIssueStatus(issue.status),
            designators: issue.translationArgs?.designators
                ?.split(",")
                .filter(FunctionUtils.isTruthy)
                .map((designator) => designator.trim()),
        }));

const transformOptionStatus = (status: AnalysisOptionStatus): MissionPlanAnalysisOptionStatus => {
    switch (status) {
        case AnalysisOptionStatus.Completed:
            return MissionPlanAnalysisOptionStatus.Success;
        case AnalysisOptionStatus.Failure:
            return MissionPlanAnalysisOptionStatus.Error;
        case AnalysisOptionStatus.Canceled:
            return MissionPlanAnalysisOptionStatus.Canceled;
        case AnalysisOptionStatus.None:
            return MissionPlanAnalysisOptionStatus.Pending;
        case AnalysisOptionStatus.Reverted:
            return MissionPlanAnalysisOptionStatus.Reverted;
        case AnalysisOptionStatus.Hold:
            return MissionPlanAnalysisOptionStatus.Hold;
        default:
            return MissionPlanAnalysisOptionStatus.FatalError;
    }
};

export const transformMissionPlanVerificationResponseBodyToMissionPlanAnalysisStatus = (
    response: MissionPlanVerificationResponseBody
): MissionPlanAnalysisStatus => {
    const trafficIssues = getIssuesForAnalysisOption(AnalysisIssueSource.Traffic, response.analysisIssues);
    const [nearbyMissionsPlanIds, collisionMissionsPlanIds] = ArrayUtils.partition(
        trafficIssues.filter((item) => !!item.translationArgs?.planIds),
        (item) => item.status !== MissionPlanAnalysisIssueStatus.Error
    ).map((item) =>
        item
            .map((issue) => (issue.translationArgs as TrafficCollisionTranslationArgs)?.planIds?.split(", "))
            .filter(FunctionUtils.isTruthy)
            .flat()
    );

    const traffic: MissionPlanTrafficAnalysisOption = {
        status: transformOptionStatus(response.trafficStatus),
        issues: trafficIssues,
        nearbyMissionsPlanIds,
        collisionMissionsPlanIds,
    };

    const evaluationIssues: MissionPlanAnalysisEvaluationIssue[] = response.evaluationIssues.map((issue) => ({
        codename: issue.codename,
        status: issue.status,
        translationArgs: issue.translationArgs,
        translationId: issue.translationId,
    }));

    const evaluation: MissionPlanEvaluationOption | undefined = evaluationIssues.reduce<MissionPlanEvaluationOption>(
        (assignedIssues, issue) => {
            const { zoneIssues } = assignedIssues;
            const designators = (issue.translationArgs as AirwaysCollisionTranslationArgs)?.designators
                ?.split(",")
                .map((designator) => designator.trim());

            designators?.forEach((designator) => {
                zoneIssues[designator] = zoneIssues[designator] ? [...zoneIssues[designator], issue] : [issue];
            });

            issue.designators = designators;

            assignedIssues.issues.push(issue);

            return assignedIssues;
        },
        { issues: [], zoneIssues: {} }
    );

    const airspaceIssues = getIssuesForAnalysisOption(AnalysisIssueSource.Airspace, response.analysisIssues);
    const airspace: MissionPlanAirspaceAnalysisOption = {
        status: transformOptionStatus(response.trafficStatus),
        issues: airspaceIssues,
        zoneIssues: airspaceIssues.reduce<Record<string, MissionPlanAnalysisIssue[]>>((assignedIssues, issue) => {
            const designators = (issue.translationArgs as AirwaysCollisionTranslationArgs)?.designators
                ?.split(",")
                .map((designator) => designator.trim());

            designators?.forEach((designator) => {
                assignedIssues[designator] = assignedIssues[designator] ? [...assignedIssues[designator], issue] : [issue];
            });

            return assignedIssues;
        }, {}),
        isAdjacentAreaControlled: response.airspace.adjacentControlled,
        isAdjacentAreaUncontrolled: response.airspace.adjacentUncontrolled,
        isOperationalAreaControlled: response.airspace.operationalControlled,
        isOperationalAreaUncontrolled: response.airspace.operationalUncontrolled,
    };

    return {
        planId: response.planId,
        planStatus: response.planStatus,
        isPlanSubmittable: response.planSubmittable,
        isManualVerificationRequired: response.manualVerificationRequired,
        status: response.status,
        airspace,
        traffic,
        caaPermit:
            response.caaPermitRequired && !!response.caaPermitStatus
                ? {
                      status: transformOptionStatus(response.caaPermitStatus),
                      issues: getIssuesForAnalysisOption(AnalysisIssueSource.CaaPermit, response.analysisIssues),
                  }
                : undefined,
        sora:
            response.soraRequired && !!response.soraStatus
                ? {
                      status: transformOptionStatus(response.soraStatus),
                      issues: getIssuesForAnalysisOption(AnalysisIssueSource.Sora, response.analysisIssues),
                      result: transformSoraResultFromResponse(response),
                  }
                : undefined,
        version: response.version,
        evaluation,
        applyForSpecificPermit: response.applyForSpecificPermit,
        systemVerificationId: response.systemVerificationId,
        flightRules:
            response.planStatus === MissionPlanStatus.MissionSubmitted
                ? response.flightConditions.map((condition) => ({
                      translationArgs:
                          { designators: condition.translationArgs?.designators.split(",").map((designator) => designator.trim()) } ?? {},
                      translationId: condition.translationId,
                      isCheckinApprovalRequired: condition.checkinApprovalRequired,
                  }))
                : [],
    };
};

export function convertMissionPlanResponseBodyToMissionPlanAndCapabilities(
    response: MissionPlanCapabilitiesResponseBody
): MissionPlanAndCapabilities {
    const operationCategories = (response.operationCategories ?? []).map((category): MissionPlanOperationCategoryOption => {
        const issues = category.issues.map(
            (issue): MissionPlanOperationCategoryOptionIssue => ({
                code: issue.id,
                args: issue.args,
            })
        );

        if (category.type === MissionCategory.Open) {
            return {
                type: category.type,
                scenarioName: category.name,
                issues,
                isDisabled: category.disabled,
            };
        } else {
            return {
                type: MissionCategory.Specific,
                scenarioName: category.name,
                specificPermitType: MissionPlanSpecificPermitType.Sts,
                issues,
                isDisabled: category.disabled,
            };
        }
    });

    operationCategories.push({
        type: MissionCategory.Specific,
        specificPermitType: MissionPlanSpecificPermitType.Individual,
        isDisabled: false,
    });

    if (response.operatorHasLightUasCertificate) {
        const lucCategoryOption: MissionPlanOperationCategoryOption = {
            type: MissionCategory.Specific,
            specificPermitType: MissionPlanSpecificPermitType.Luc,
            shouldSkipSora: false,
            isDisabled: false,
        };
        const lucCategorySkipSoraOption: MissionPlanOperationCategoryOption = {
            type: MissionCategory.Specific,
            specificPermitType: MissionPlanSpecificPermitType.Luc,
            shouldSkipSora: true,
            isDisabled: false,
        };

        operationCategories.push(lucCategoryOption, lucCategorySkipSoraOption);
    }

    // TODO: DTM-828 Unlock other editors when implemented
    const enabledEditors: ItineraryEditorType[] = [ItineraryEditorType.Standard, ItineraryEditorType.Custom, ItineraryEditorType.Assisted];
    const availableEditorTypes: ItineraryEditorType[] = (response.options &&
        Object.keys(response.options).filter((name): name is ItineraryEditorType =>
            enabledEditors.includes(name as ItineraryEditorType)
        )) ?? [ItineraryEditorType.Standard];

    return {
        plan: {
            id: response.plan.id,
            status: response.plan.status,
            capabilities: {
                flightType: response.plan.capabilities.flightType,
                operatorId: response.plan.capabilities.operator,
                pilotId: response.plan.capabilities.pilot,
                uavSetupId: response.plan.capabilities.uavSetup,
                additionalCrew: response.plan.capabilities.additionalCrew,
            },
            flightPurpose: response.plan.flightPurpose,
            operationCategory: response.plan.category && convertMissionPlanOperationCategoryToOption(response.plan.category),
            information: {
                name: response.plan.information?.name,
                description: response.plan.information?.description,
                notes: response.plan.information?.notes,
            },
        },
        flightPurposes: (response.flightPurposes ?? []).map(
            (purpose): MissionPlanFlightPurposeOption => ({
                codename: purpose.codename,
                displayName: purpose.name,
                description: purpose.description,
                id: purpose.id,
                isCommentRequired: purpose.commentRequired,
                isLoadWeightRequired: purpose.loadWeightRequired,
                isEmergency: purpose.emergency,
            })
        ),
        operationCategories,
        editors: {
            options: availableEditorTypes,
            suggested:
                availableEditorTypes.find(
                    (type) => availableEditorTypes.length === 1 || (response.options && response.options[type].suggested)
                ) ?? ItineraryEditorType.Standard,
        },
    };
}

function convertRemarksResponse(remarksResponse: RemarksResponse | undefined): MissionPlanRemarks {
    return {
        authorityAcceptation: {
            dtm: convertAcceptationData(remarksResponse?.dtm),
            utm: convertAcceptationData(remarksResponse?.utm),
            dss: convertAcceptationData(remarksResponse?.dss),
        },
        justification: remarksResponse?.formalJustification
            ? {
                  shouldRequestForPriority: remarksResponse.formalJustification.requestForPriority,
                  reason: remarksResponse.formalJustification.reason,
                  attachmentIds: remarksResponse.formalJustification.attachmentIds ?? [],
                  attachments:
                      remarksResponse.formalJustification.attachments?.map(({ name, fileId }) => ({
                          id: fileId ?? "",
                          name: name ?? "",
                      })) ?? [],
              }
            : undefined,
        partialAcceptations:
            remarksResponse?.partialAcceptations?.map((acceptation) => ({
                acknowledgedByUserAt: acceptation.acknowledgedByUserAt ? new Date(acceptation.acknowledgedByUserAt) : undefined,
                authorityType: acceptation.authorityType,
                authorityUnitId: acceptation.authorityUnitId,
                authorityUnitName: acceptation.authorityUnitName,
                authorityUnitType: acceptation.authorityUnitType,
                comment: acceptation.comment,
                status: acceptation.status,
                updatedAt: acceptation.updatedAt ? new Date(acceptation.updatedAt) : undefined,
            })) ?? [],
    };
}

export function convertMissionPlanDataResponseBodyToMissionPlanDataAndCapabilities(
    response: MissionPlanDataResponseBody
): MissionPlanDataAndCapabilities {
    const { operationCategories } = convertMissionPlanResponseBodyToMissionPlanAndCapabilities(response);

    // TODO: DTM-828 Unlock other editors when implemented
    const enabledEditors = [ItineraryEditorType.Standard, ItineraryEditorType.Custom, ItineraryEditorType.Assisted];
    const availableEditorTypes: ItineraryEditorType[] = (response.options &&
        Object.keys(response.options).filter((name): name is ItineraryEditorType =>
            enabledEditors.includes(name as ItineraryEditorType)
        )) ?? [ItineraryEditorType.Standard];

    const itinerary = response.plan.itinerary
        ? {
              ...response.plan.itinerary,
              editorType: response.plan.itinerary?.editorType.toLowerCase() as ItineraryEditorType,
          }
        : undefined;

    const plan = ObjectUtils.convertStringPropertiesToDate(response.plan, [
        "flightFinishAtMin",
        "flightFinishAtMax",
        "flightStartAtMin",
        "flightStartAtMax",
        "created",
        "modified",
        "missionSubmittedAt",
    ]);

    return {
        plan: {
            ...plan,
            itinerary,
            id: response.plan.id,
            status: response.plan.status,
            category: response.plan.category && convertMissionPlanOperationCategoryToOption(response.plan.category),
            availableActions: response.plan.abilities,
            route: plan.route
                ? {
                      id: plan.route.id,
                      dtmNames: plan.route.dtmNames,
                      isPathBased: plan.route.pathBased,
                      isDtmOnly: plan.route.dtmOnly,
                      modificationDate: new Date(plan.route.modified),
                      creationDate: new Date(plan.route.created),
                  }
                : undefined,
            remarks: convertRemarksResponse(plan.remarks),
        },
        flightPurposes: response.flightPurposes,
        operationCategories,
        editors: {
            options: availableEditorTypes,
            suggested:
                availableEditorTypes.find(
                    (type) => availableEditorTypes.length === 1 || (response.options && response.options[type].suggested)
                ) ?? ItineraryEditorType.Standard,
        },
    };
}
