import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import {
    AZURE_MAPS_LAYER_OPTIONS,
    CameraHelperService,
    DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS,
} from "@dtm-frontend/shared/map/cesium";
import { ZoneTimesSetting } from "@dtm-frontend/shared/map/geo-zones";
import { GeoJSON, MissionPlanRoute, MissionPlanRouteSection, RouteAreaTypeId, RouteData } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SceneMode, ViewerConfiguration } from "@pansa/ngx-cesium";
import turfBbox from "@turf/bbox";
import { Feature as GeoJSONFeature, feature as createFeature, featureCollection as createFeatureCollection } from "@turf/helpers";
import { combineLatest } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { MissionCategory } from "../../../../shared/models/mission.models";
import { MissionWithRoute } from "../../../models/mission-search.models";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

interface ResultMapComponentState {
    missions: MissionWithRoute[];
    isProcessing: boolean;
    selectedMissionId: string | undefined;
    isMeasureToolActive: boolean;
}

interface RouteMissionData {
    planId: string;
    routeId: string;
    route: MissionPlanRoute;
}

// NOTE: initial viewbox set to Poland
const INITIAL_VIEWBOX: GeoJSON = {
    type: "Polygon",
    coordinates: [
        [
            /* eslint-disable no-magic-numbers */
            [14.069638889, 49.0020432310001],
            [14.069638889, 55.849716667],
            [24.150833333, 55.849716667],
            [24.150833333, 49.0020432310001],
            [14.069638889, 49.0020432310001],
            /* eslint-enable no-magic-numbers */
        ],
    ],
};

@UntilDestroy()
@Component({
    selector: "dtm-mission-mission-search-result-map",
    templateUrl: "./result-map.component.html",
    styleUrls: ["./result-map.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ResultMapComponent implements OnInit {
    @Input() public set missions(value: MissionWithRoute[] | undefined) {
        this.localStore.patchState({ missions: value ?? [] });
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set selectedMissionId(value: string | undefined) {
        this.localStore.patchState({ selectedMissionId: value });
    }

    protected readonly missions$ = this.localStore
        .selectByKey("missions")
        .pipe(RxjsUtils.filterFalsy(), shareReplay({ bufferSize: 1, refCount: true }));
    protected readonly routesData$ = combineLatest([this.missions$, this.localStore.selectByKey("selectedMissionId")]).pipe(
        map(([missions, selectedMissionId]) => this.convertMissionsToRouteData(missions, selectedMissionId))
    );
    protected readonly hasBackdrop$ = this.missions$.pipe(map((missions) => !missions.length));
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly isMeasureToolActive$ = this.localStore.selectByKey("isMeasureToolActive");

    protected readonly routeDrawableFeature: RouteAreaTypeId[] = ["flightArea", "waypoint"];
    protected readonly MissionCategory = MissionCategory;
    protected readonly AZURE_MAPS_LAYER_OPTIONS = AZURE_MAPS_LAYER_OPTIONS;
    protected readonly SceneMode = SceneMode;
    protected readonly ZoneTimesSetting = ZoneTimesSetting;

    constructor(
        private readonly localStore: LocalComponentStore<ResultMapComponentState>,
        private readonly cameraHelperService: CameraHelperService,
        viewerConfiguration: ViewerConfiguration
    ) {
        this.localStore.setState({
            missions: [],
            isProcessing: false,
            selectedMissionId: undefined,
            isMeasureToolActive: false,
        });

        viewerConfiguration.viewerOptions = {
            ...DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS,
            sceneMode: SceneMode.SCENE3D,
        };

        this.watchMissionsChange();
    }

    public ngOnInit() {
        this.initView();
    }

    public zoomToMission(planId: string) {
        const mission = this.localStore.selectSnapshotByKey("missions").find((missionItem) => missionItem.planId === planId);

        if (mission) {
            // eslint-disable-next-line no-magic-numbers
            this.flyToRouteSections(mission.route.sections, 0.8);
        }
    }

    protected updateMeasureToolStatus(isActive: boolean) {
        this.localStore.patchState({ isMeasureToolActive: isActive });
    }

    private initView() {
        Cesium.Camera.DEFAULT_VIEW_FACTOR = 0;
        Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(...turfBbox(INITIAL_VIEWBOX));
        // eslint-disable-next-line no-magic-numbers
        this.cameraHelperService.flyToGeoJSON(INITIAL_VIEWBOX, 0.1);
    }

    private watchMissionsChange() {
        this.missions$
            .pipe(
                map((missions) => {
                    const routesSections = this.getMissionsRouteSections(missions ?? []);

                    if (!routesSections.length) {
                        return false;
                    }

                    // NOTE: setTimeout is needed to make sure that the camera is set after the map is initialized
                    setTimeout(() => this.flyToRouteSections(routesSections));

                    return true;
                }),
                RxjsUtils.filterFalsy(),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private getMissionsRouteSections(missions: MissionWithRoute[]) {
        return missions.reduce<MissionPlanRouteSection[]>((sections, mission) => [...sections, ...mission.route.sections], []);
    }

    private flyToRouteSections(routeSections: MissionPlanRouteSection[], animationDuration?: number) {
        const zoomArea = createFeatureCollection(
            routeSections.reduce<GeoJSONFeature[]>((features, section) => {
                const area = section.flightZone?.safetyArea.volume.area ?? section.segment?.safetyArea.volume.area;

                if (area) {
                    features.push(createFeature(area));
                }

                return features;
            }, [])
        );

        // eslint-disable-next-line no-magic-numbers
        this.cameraHelperService.flyToGeoJSON(zoomArea, animationDuration ?? 0.1);
    }

    private convertMissionsToRouteData(missions: MissionWithRoute[], selectedMissionId: string | undefined): RouteData<RouteMissionData>[] {
        return missions.map((mission, index) => ({
            data: {
                planId: mission.planId,
                routeId: mission.route.routeId,
                route: mission.route,
            },
            isMain: false,
            isPathBased: mission.route.isPathBased,
            isSelected: mission.planId === selectedMissionId,
            route: mission.route,
            uniqueRouteId: index,
            isOutsideDtm: mission.route.stats?.flight.dtmNames && mission.route.stats.flight.dtmNames.length === 0,
        }));
    }
}
