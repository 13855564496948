<ac-layer #draftAreaLayer acFor="let entity of draftAreaEntities$" [store]="true" [context]="this" [show]="!(isProcessing$ | ngrxPush)">
    <ac-polygon-desc
        props="{
                hierarchy: entity.positions,
                material: DRAFT_AREA_FILL_COLOR,
                height: 0
            }"
    >
    </ac-polygon-desc>
</ac-layer>
