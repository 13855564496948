<ng-container *ngrxLet="isPlanRouteProcessing$ as isPlanRouteProcessing">
    <!--TODO: DTM-2877 - error handling-->
    <div
        class="planned-mission"
        *ngrxLet="{
            isPlannedMissionFolded: isPlannedMissionFolded$,
            zones: collisionZones$,
            isProcessing: isProcessing$,
            isMissionListProcessing: isMissionListProcessing$,
            dtmAreas: dtmAreas$,
            workspacePermissions: workspacePermissions$,
            selectedZoneId: selectedZoneId$,
        } as vm"
    >
        <div class="planned-mission-panel" [class.folded]="vm.isPlannedMissionFolded">
            <div class="mission-details">
                <dtm-ui-loader-container [isShown]="vm.isProcessing || vm.isMissionListProcessing">
                    <supervisor-shared-lib-planned-mission-details
                        [currentPlanAnalysisStatus]="currentPlanAnalysisStatus$ | ngrxPush"
                        [zones]="vm.zones"
                        [waitingMissions]="waitingMissions$ | ngrxPush"
                        [acceptedMissions]="acceptedMissions$ | ngrxPush"
                        [rejectedMissions]="rejectedMissions$ | ngrxPush"
                        [isProcessing]="vm.isProcessing"
                        [isMissionListProcessing]="vm.isMissionListProcessing"
                        [shouldPilotPanelClose]="shouldPilotPanelClose$ | ngrxPush"
                        [isPlanRouteProcessing]="isPlanRouteProcessing$ | ngrxPush"
                        [selectedMissionRoute]="selectedMissionPlanRoute$ | ngrxPush"
                        [selectedTileId]="selectedMissionPlanId$ | ngrxPush"
                        [areNewMissionsAvailable]="areNewMissionsAvailable$ | ngrxPush"
                        [missionDefaultsFilters]="missionDefaultsFilters$ | ngrxPush"
                        [currentMissionPlanData]="currentMissionPlanData$ | ngrxPush"
                        [dtmAreas]="dtmAreas$ | ngrxPush"
                        [workspacePermissions]="vm.workspacePermissions"
                        [selectedZoneId]="vm.selectedZoneId"
                        (missionFilters)="filterMissions($event)"
                        (missionPhaseChange)="changeMissionPhase($event)"
                        (missionSelect)="selectMission($event)"
                        (priorityEdit)="openEditPriorityDialog($event)"
                        (newMissionAvailabilityClear)="clearAvailabilityNewMission()"
                        (noteUpdate)="updateSupervisorNote($event)"
                        (attachmentDownload)="downloadAttachment($event)"
                        (messagePanelOpen)="changePilotMessagePanelState()"
                        (zoneSelect)="selectZone($event)"
                    ></supervisor-shared-lib-planned-mission-details>
                </dtm-ui-loader-container>
            </div>
            <div class="view-switcher">
                <button type="button" (click)="togglePanelFold(vm.isPlannedMissionFolded, SidebarType.LeftSidebar)">
                    <dtm-ui-icon [name]="vm.isPlannedMissionFolded ? 'menu-unfold' : 'menu-fold'"></dtm-ui-icon>
                </button>
            </div>
        </div>
        <ng-container *ngrxLet="isOtherMissionPanelFolded$ as isOtherMissionPanelFolded">
            <div class="planned-mission-map">
                <supervisor-shared-lib-message-slider
                    [alarmList]="alarmList$ | ngrxPush"
                    (alarmClose)="closeGlobalAlarm($event)"
                ></supervisor-shared-lib-message-slider>
                <div class="map">
                    <supervisor-shared-lib-dtm-area
                        [dtmAreas]="dtmAreas$ | ngrxPush"
                        (dtmSelect)="navigateToOperationalSituation($event)"
                    ></supervisor-shared-lib-dtm-area>
                    <supervisor-shared-lib-mission-map
                        [routeData]="routeData$ | ngrxPush"
                        [isProcessing]="isPlanRouteProcessing"
                        [zoomToRoute]="zoomToRoute$ | ngrxPush"
                        [isOtherMissionPanelFolded]="isOtherMissionPanelFolded"
                        [initialViewGeometry]="initialViewGeometry"
                        [zoneTimeSettingOptions]="{
                            available: [ZoneTimesSetting.MissionTime, ZoneTimesSetting.CustomTimeRange, ZoneTimesSetting.Soon],
                        }"
                        shouldZoomOnRouteUpdate
                    ></supervisor-shared-lib-mission-map>
                </div>
            </div>
            <supervisor-shared-lib-sidebar
                [isSidebarFolded]="isOtherMissionPanelFolded"
                [title]="'dtmSupPlannedMissions.plannedMissionContainer.nearbyMissionsHeader' | transloco"
                (panelFold)="togglePanelFold(isOtherMissionPanelFolded, SidebarType.RightSidebar)"
            >
                <supervisor-shared-lib-nearby-missions
                    [otherMissions]="nearbyMissions$ | ngrxPush"
                    [nearbyDefaultsFilters]="NearbyMissionDefaultFilters"
                    [isSelectedMission]="isSelectedMission$ | ngrxPush"
                    [selectedNearbyMissionId]="selectedNearbyMissionId$ | ngrxPush"
                    [isNearbyMissionRouteProcessing]="isNearbyMissionRouteProcessing$ | ngrxPush"
                    [nearbyMissionRoute]="selectedNearbyMissionRoute$ | ngrxPush"
                    [areNewMissionsAvailable]="areNearbyMissionsAvailable$ | ngrxPush"
                    [currentNearbyMissionData]="currentNearbyMissionData$ | ngrxPush"
                    [workspacePermissions]="vm.workspacePermissions"
                    [zones]="vm.zones"
                    (filtersChange)="filterNearbyMissions($event)"
                    (nearbyMissionSelect)="selectNearbyMission($event)"
                    (routeZoom)="zoomToRoute($event)"
                    (nearbyMissionAvailabilityClear)="clearAvailabilityNearbyMission()"
                    (zoneSelect)="selectZone($event)"
                ></supervisor-shared-lib-nearby-missions>
            </supervisor-shared-lib-sidebar>
        </ng-container>
    </div>
</ng-container>
