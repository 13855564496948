import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { ScrollPosition } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { getWeatherStatus } from "../../../shared/utils/weather-status";
import { Weather } from "../../models/weather.models";

const DEFAULT_OFFSET_VALUE = 300;

interface TimeRangeSliderComponentState {
    selectedWeatherRangeIndex: number | undefined;
    isRightScrollButtonDisabled: boolean;
    isLeftScrollButtonDisabled: boolean;
    weatherRange: Weather[];
}

@Component({
    selector: "dtm-map-time-range-slider",
    templateUrl: "./time-range-slider.component.html",
    styleUrls: ["./time-range-slider.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TimeRangeSliderComponent {
    @Input() public set weatherRange(value: Weather[] | undefined) {
        this.localStore.patchState({ weatherRange: value ?? [] });
    }
    @Input() public set selectedWeatherRangeIndex(value: number | undefined) {
        this.localStore.patchState({ selectedWeatherRangeIndex: value });
    }

    @ViewChild("timeRangeContent") private alarmContentRef!: ElementRef;

    @Output() public readonly rangeSelect = new EventEmitter<number>();

    protected readonly selectedWeatherRangeIndex$ = this.localStore.selectByKey("selectedWeatherRangeIndex");
    protected readonly isRightScrollButtonDisabled$ = this.localStore.selectByKey("isRightScrollButtonDisabled");
    protected readonly isLeftScrollButtonDisabled$ = this.localStore.selectByKey("isLeftScrollButtonDisabled");
    protected readonly weatherRange$ = this.localStore.selectByKey("weatherRange");

    constructor(private readonly localStore: LocalComponentStore<TimeRangeSliderComponentState>) {
        this.localStore.setState({
            selectedWeatherRangeIndex: 0,
            isRightScrollButtonDisabled: false,
            isLeftScrollButtonDisabled: true,
            weatherRange: [],
        });
    }

    protected scrollRight(): void {
        this.alarmContentRef.nativeElement.scrollTo({
            left: this.alarmContentRef.nativeElement.scrollLeft + DEFAULT_OFFSET_VALUE,
            behavior: "smooth",
        });
    }

    protected scrollLeft(): void {
        this.alarmContentRef.nativeElement.scrollTo({
            left: this.alarmContentRef.nativeElement.scrollLeft - DEFAULT_OFFSET_VALUE,
            behavior: "smooth",
        });
    }

    protected changeScrollButtonState(scrollPosition: ScrollPosition): void {
        this.localStore.patchState({
            isLeftScrollButtonDisabled: this.shouldDisableLeftScrollButton(scrollPosition.scrollLeft),
            isRightScrollButtonDisabled: this.shouldDisableRightScrollButton(scrollPosition),
        });
    }

    protected selectRange(index: number): void {
        this.localStore.patchState({ selectedWeatherRangeIndex: index });
        this.rangeSelect.emit(index);
    }

    protected formatRange(isoString: string): string {
        const baseTime = new Date(isoString);
        const startRange = new Date(isoString);
        const endRange = new Date(baseTime.setHours(startRange.getHours() + 1));

        return `${startRange.getHours()} - ${endRange.getHours()}`;
    }

    protected getRangeStatus(range: Weather) {
        return getWeatherStatus(range);
    }

    private shouldDisableLeftScrollButton(scrollLeft: number): boolean {
        return scrollLeft === 0;
    }

    private shouldDisableRightScrollButton({ scrollLeft, offsetWidth, scrollWidth }: ScrollPosition): boolean {
        return Math.ceil(scrollLeft + offsetWidth) === scrollWidth;
    }
}
