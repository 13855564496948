import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface SidebarComponentState {
    isSidebarFolded: boolean;
    title: string | undefined;
}

@Component({
    selector: "supervisor-shared-lib-sidebar[title]",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class SidebarComponent {
    @Input() public set title(value: string | undefined) {
        this.localStore.patchState({ title: value });
    }
    @Input() public set isSidebarFolded(value: BooleanInput) {
        this.localStore.patchState({ isSidebarFolded: coerceBooleanProperty(value) });
    }

    @Output() public readonly panelFold = new EventEmitter<void>();

    protected readonly isSidebarFolded$ = this.localStore.selectByKey("isSidebarFolded");
    protected readonly title$ = this.localStore.selectByKey("title");

    constructor(private readonly localStore: LocalComponentStore<SidebarComponentState>) {
        this.localStore.setState({
            isSidebarFolded: true,
            title: undefined,
        });
    }

    protected changePanelVisibility(): void {
        this.panelFold.emit();
        this.localStore.patchState(({ isSidebarFolded }) => ({ isSidebarFolded: !isSidebarFolded }));
    }
}
