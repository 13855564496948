import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { ScrollPosition } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Alarm } from "../../models/shared-supervisor-client.models";

const DEFAULT_OFFSET_VALUE = 600;

interface MessageSliderComponentState {
    alarmList: Alarm[];
    shouldScrollButtonsBeVisible: boolean;
    isRightScrollButtonDisabled: boolean;
    isLeftScrollButtonDisabled: boolean;
}

@Component({
    selector: "supervisor-shared-lib-message-slider[alarmList]",
    templateUrl: "./alarm-slider.component.html",
    styleUrls: ["./alarm-slider.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AlarmSliderComponent {
    @ViewChild("alarmContentRef") public alarmContentRef!: ElementRef;
    @ViewChild("wrapperRef") public wrapperRef!: ElementRef;

    @Input() public set alarmList(value: Alarm[] | undefined) {
        this.localStore.patchState({ alarmList: value ?? [] });
    }

    @Output() public alarmClose = new EventEmitter<Alarm>();

    protected readonly alarmList$ = this.localStore.selectByKey("alarmList");
    protected readonly shouldScrollButtonsBeVisible$ = this.localStore.selectByKey("shouldScrollButtonsBeVisible");
    protected readonly isRightScrollButtonDisabled$ = this.localStore.selectByKey("isRightScrollButtonDisabled");
    protected readonly isLeftScrollButtonDisabled$ = this.localStore.selectByKey("isLeftScrollButtonDisabled");

    constructor(public readonly localStore: LocalComponentStore<MessageSliderComponentState>) {
        this.localStore.setState({
            alarmList: [],
            shouldScrollButtonsBeVisible: false,
            isRightScrollButtonDisabled: false,
            isLeftScrollButtonDisabled: true,
        });
    }

    protected scrollRight(): void {
        this.alarmContentRef.nativeElement.scrollTo({
            left: this.alarmContentRef.nativeElement.scrollLeft + DEFAULT_OFFSET_VALUE,
            behavior: "smooth",
        });
    }

    protected scrollLeft(): void {
        this.alarmContentRef.nativeElement.scrollTo({
            left: this.alarmContentRef.nativeElement.scrollLeft - DEFAULT_OFFSET_VALUE,
            behavior: "smooth",
        });
    }

    protected changeScrollButtonState(scrollPosition: ScrollPosition): void {
        this.localStore.patchState({
            isLeftScrollButtonDisabled: this.shouldDisableLeftScrollButton(scrollPosition.scrollLeft),
            isRightScrollButtonDisabled: this.shouldDisableRightScrollButton(scrollPosition),
            shouldScrollButtonsBeVisible: scrollPosition.scrollWidth > this.wrapperRef.nativeElement.offsetWidth,
        });
    }

    private shouldDisableLeftScrollButton(scrollLeft: number): boolean {
        return scrollLeft === 0;
    }

    private shouldDisableRightScrollButton({ scrollLeft, offsetWidth, scrollWidth }: ScrollPosition): boolean {
        return Math.ceil(scrollLeft + offsetWidth) === scrollWidth;
    }
}
