import { CommonModule } from "@angular/common";
import { importProvidersFrom, ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { getTranslocoInlineLoader, LanguageCode, SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { WebsocketService } from "@dtm-frontend/shared/websocket";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { SharedSupervisorClientModule } from "../shared/shared-supervisor-client.module";
import { EventHistoryListComponent } from "./components/event-history-list/event-history-list.component";
import { EventTileComponent } from "./components/event-tile/event-tile.component";
import { InstallationStatusComponent } from "./components/installations/components/installation-status/installation-status.component";
import { InstallationTileComponent } from "./components/installations/components/installation-tile/installation-tile.component";
import { InstallationsComponent } from "./components/installations/installations.component";
import { NetworkMonitoringContainerComponent } from "./components/network-monitoring-container/network-monitoring-container.component";
import { AerialInfoPanelComponent } from "./components/sector-info-panel/aerial-info-panel.component";
import { UnavailabilitySectorDetailsComponent } from "./components/unavailability-sector-details/unavailability-sector-details.component";
import { NetworkMonitoringApiService } from "./services/network-monitoring-api.service";
import { NetworkMonitoringState } from "./state/network-monitoring.state";

@NgModule({
    imports: [
        CommonModule,
        LetModule,
        PushModule,
        NgxsModule.forFeature([NetworkMonitoringState]),
        SharedI18nModule,
        SharedUiModule,
        SharedSupervisorClientModule,
        MatTabsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatTooltipModule,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmSupNetworkMonitoring",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: [NetworkMonitoringApiService],
            useValue: undefined,
        },
        {
            provide: WebsocketService,
            useValue: undefined,
        },
    ],
    declarations: [
        NetworkMonitoringContainerComponent,
        InstallationsComponent,
        InstallationTileComponent,
        InstallationStatusComponent,
        AerialInfoPanelComponent,
        UnavailabilitySectorDetailsComponent,
        EventTileComponent,
        EventHistoryListComponent,
    ],
    exports: [
        InstallationsComponent,
        InstallationTileComponent,
        InstallationStatusComponent,
        AerialInfoPanelComponent,
        UnavailabilitySectorDetailsComponent,
        EventTileComponent,
        EventHistoryListComponent,
    ],
})
export class NetworkMonitoringModule {
    public static forRoot(): ModuleWithProviders<NetworkMonitoringModule> {
        return {
            ngModule: NetworkMonitoringModule,
            providers: [NetworkMonitoringApiService, WebsocketService],
        };
    }

    public static forTest(): ModuleWithProviders<NetworkMonitoringModule> {
        return {
            ngModule: NetworkMonitoringModule,
            providers: [
                {
                    provide: NetworkMonitoringApiService,
                    useValue: null,
                },
                importProvidersFrom(SharedSupervisorClientModule.forTest()),
            ],
        };
    }
}
