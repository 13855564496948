import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, ViewChild } from "@angular/core";
import { GeoJSON } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { AcEntity, AcLayerComponent, AcNotification, ActionType, CesiumService } from "@pansa/ngx-cesium";
import { Observable, map, mergeMap, of, switchMap, tap } from "rxjs";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

const DEFAULT_FILL_OPACITY = 0.8;
const DRAFT_AREA_FILL_COLOR = Cesium.Color.fromCssColorString("#223d6b").withAlpha(DEFAULT_FILL_OPACITY); // $color-gray-500

interface DraftAreaAcEntity extends AcEntity {
    id: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    positions: any;
}

interface DraftAreaLayerComponentState {
    isProcessing: boolean;
    area: GeoJSON | undefined;
    draftAreaLayer: AcLayerComponent | undefined;
}

@UntilDestroy()
@Component({
    selector: "supervisor-shared-lib-draft-area-layer[area][isProcessing]",
    templateUrl: "./draft-area-layer.component.html",
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DraftAreaLayerComponent {
    protected readonly Cesium = Cesium;
    protected readonly DRAFT_AREA_FILL_COLOR = DRAFT_AREA_FILL_COLOR;

    @ViewChild("draftAreaLayer") protected set draftAreaLayer(value: AcLayerComponent) {
        this.localStore.patchState({ draftAreaLayer: value });
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set area(value: GeoJSON | undefined) {
        this.localStore.patchState({ area: value });
    }

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly draftAreaEntities$ = this.initDraftAreaEntities();

    constructor(
        private readonly localStore: LocalComponentStore<DraftAreaLayerComponentState>,
        private readonly cesiumService: CesiumService
    ) {
        this.localStore.setState({
            isProcessing: true,
            area: undefined,
            draftAreaLayer: undefined,
        });
    }

    private initDraftAreaLayer(layer: AcLayerComponent): Observable<AcNotification> {
        return this.localStore.selectByKey("area").pipe(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            switchMap(async (area: any): Promise<DraftAreaAcEntity[]> => {
                if (!area) {
                    return [];
                }

                const geoJsonData = await Cesium.GeoJsonDataSource.load(area);

                return (
                    (geoJsonData.entities.values as any[])
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        .filter((entity: any) => entity?.polygon?.hierarchy)
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        .map((entity: any, index: any) => {
                            const result: DraftAreaAcEntity = {
                                id: `draftArea_${index}`,
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                positions: entity!.polygon!.hierarchy!.valueOf(),
                            };

                            return result;
                        })
                );
            }),
            map((entities) => this.getDraftAreaEntityNotifications(layer, entities)),
            mergeMap((entities) => of(...entities)),
            tap(() => this.cesiumService.getScene().requestRender())
        );
    }

    private initDraftAreaEntities(): Observable<AcNotification> {
        return this.localStore.selectByKey("draftAreaLayer").pipe(
            RxjsUtils.filterFalsy(),
            switchMap((draftAreaLayer) => this.initDraftAreaLayer(draftAreaLayer))
        );
    }

    private getDraftAreaEntityNotifications(layer: AcLayerComponent, entities: DraftAreaAcEntity[]): AcNotification[] {
        const result: AcNotification[] = [];
        const unusedIds = new Set(Array.from(layer.getStore().keys()));

        entities.forEach((entity) => {
            unusedIds.delete(entity.id);
            result.push({
                entity,
                actionType: ActionType.ADD_UPDATE,
                id: entity.id,
            });
        });

        unusedIds.forEach((entityId) =>
            result.push({
                id: entityId,
                actionType: ActionType.DELETE,
            })
        );

        return result;
    }
}
