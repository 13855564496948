import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { AirspaceElement, AirspaceElementsInfo } from "@dtm-frontend/shared/map/geo-zones";
import {
    MissionContextType,
    MissionDataSimple,
    MissionPlanAnalysisStatus,
    MissionPlanDataAndCapabilities,
} from "@dtm-frontend/shared/mission";
import { MissionPlanRoute } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { WorkspacePermission } from "../../../sup-user";
import { Mission, MissionProcessingPhase, NoteData, PhasePayloadData, PriorityPayloadData } from "../../models/mission.models";

interface PhaseDescriptionComponentState {
    isProcessing: boolean;
    missions: Mission[] | undefined;
    selectedMissionRoute: MissionPlanRoute | undefined;
    selectedTileId: string | undefined;
    isPlanRouteProcessing: boolean;
    canEdit: boolean;
    shouldPilotPanelClose: boolean;
    currentMissionPlanData: MissionPlanDataAndCapabilities | undefined;
    zones: AirspaceElementsInfo | undefined;
    currentPlanAnalysisStatus: MissionPlanAnalysisStatus | undefined;
    workspacePermissions: WorkspacePermission[] | undefined;
    selectedZoneId: string | undefined;
}

export const MAX_OPERATOR_DETAILS_LENGTH = 200;

@UntilDestroy()
@Component({
    selector: "supervisor-shared-lib-phase-description[missions][zones][currentPlanAnalysisStatus]",
    templateUrl: "./phase-description.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PhaseDescriptionComponent {
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set missions(value: Mission[] | undefined) {
        this.localStore.patchState({ missions: value });
    }
    @Input() public set currentMissionPlanData(value: MissionPlanDataAndCapabilities | undefined) {
        this.localStore.patchState({ currentMissionPlanData: value });
    }
    @Input() public set selectedTileId(value: string | undefined) {
        this.localStore.patchState({ selectedTileId: value });
    }
    @Input() public set selectedMissionRoute(value: MissionPlanRoute | undefined) {
        this.localStore.patchState({ selectedMissionRoute: value });
    }
    @Input() public set shouldPilotPanelClose(value: BooleanInput) {
        this.localStore.patchState({ shouldPilotPanelClose: coerceBooleanProperty(value) });
    }
    @Input() public set isPlanRouteProcessing(value: BooleanInput) {
        this.localStore.patchState({ isPlanRouteProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set canEdit(value: BooleanInput) {
        this.localStore.patchState({ canEdit: coerceBooleanProperty(value) });
    }
    @Input() public set zones(value: AirspaceElementsInfo | undefined) {
        this.localStore.patchState({ zones: value });
    }
    @Input() public set currentPlanAnalysisStatus(value: MissionPlanAnalysisStatus | undefined) {
        this.localStore.patchState({ currentPlanAnalysisStatus: value });
    }
    @Input() public set workspacePermissions(value: WorkspacePermission[] | undefined) {
        this.localStore.patchState({ workspacePermissions: value });
    }
    @Input() public set selectedZoneId(value: string | undefined) {
        this.localStore.patchState({ selectedZoneId: value });
    }

    @Output() public readonly missionPhaseChange = new EventEmitter<PhasePayloadData>();
    @Output() public readonly missionSelect = new EventEmitter<Mission>();
    @Output() public readonly priorityEdit = new EventEmitter<PriorityPayloadData>();
    @Output() public readonly noteUpdate = new EventEmitter<NoteData>();
    @Output() public readonly attachmentDownload = new EventEmitter<string>();
    @Output() public readonly messagePanelOpen = new EventEmitter<void>();
    @Output() public readonly zoneSelect = new EventEmitter<AirspaceElement>();

    protected readonly canEdit$ = this.localStore.selectByKey("canEdit");
    protected readonly selectedTileId$ = this.localStore.selectByKey("selectedTileId");
    protected readonly isPlanRouteProcessing$ = this.localStore.selectByKey("isPlanRouteProcessing");
    protected readonly shouldPilotPanelClose$ = this.localStore.selectByKey("shouldPilotPanelClose");
    protected readonly currentMissionPlanData$ = this.localStore.selectByKey("currentMissionPlanData");
    protected readonly currentPlanAnalysisStatus$ = this.localStore.selectByKey("currentPlanAnalysisStatus");
    protected readonly zones$ = this.localStore.selectByKey("zones");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly missions$ = this.localStore.selectByKey("missions").pipe(RxjsUtils.filterFalsy());
    protected readonly selectedMissionRoute$ = this.localStore.selectByKey("selectedMissionRoute");
    protected readonly workspacePermissions$ = this.localStore.selectByKey("workspacePermissions");
    protected readonly selectedZoneId$ = this.localStore.selectByKey("selectedZoneId");
    protected readonly MissionProcessingPhase = MissionProcessingPhase;
    protected readonly MissionContextType = MissionContextType;

    protected readonly operatorDetailsControl = new FormControl(
        { value: "", disabled: true },
        { nonNullable: true, validators: Validators.maxLength(MAX_OPERATOR_DETAILS_LENGTH) }
    );

    constructor(private readonly localStore: LocalComponentStore<PhaseDescriptionComponentState>) {
        this.localStore.setState({
            isProcessing: false,
            missions: undefined,
            selectedMissionRoute: undefined,
            selectedTileId: undefined,
            isPlanRouteProcessing: false,
            canEdit: false,
            shouldPilotPanelClose: false,
            currentMissionPlanData: undefined,
            zones: undefined,
            currentPlanAnalysisStatus: undefined,
            workspacePermissions: undefined,
            selectedZoneId: undefined,
        });
    }

    protected trackByMissionId(_: number, mission: Mission) {
        return mission.id;
    }

    protected updateMissionPhase(phase: MissionProcessingPhase, verificationId: string) {
        this.missionPhaseChange.emit({
            systemVerificationId: verificationId,
            missionPhase: phase,
            comment: this.operatorDetailsControl.value,
        });
    }

    protected updateNote(message: string, { systemVerificationId }: Mission): void {
        this.noteUpdate.emit({ message, systemVerificationId });
    }

    protected prepareMissionDataSimple(
        missionPlanData: MissionPlanDataAndCapabilities | undefined,
        route: MissionPlanRoute | undefined,
        operatorName: string
    ): MissionDataSimple | undefined {
        if (!missionPlanData) {
            return undefined;
        }

        const { plan, flightPurposes } = missionPlanData;

        return {
            isRoutePathBased: !!route?.isPathBased,
            flightStartAtMin: plan.flightStartAtMin,
            flightStartAtMax: plan.flightStartAtMax,
            flightFinishAtMin: plan.flightFinishAtMin,
            flightFinishAtMax: plan.flightFinishAtMax,
            phase: plan.phase,
            distance: route?.estimatedDistance,
            operatorName,
            pilotName: plan.capabilities.pilotName,
            uavName:
                plan.capabilities.uavName && plan.capabilities.setupName
                    ? `${plan.capabilities.uavName} (${plan.capabilities.setupName})`
                    : undefined,
            uavSerialNumbers: plan.uav.serialNumbers ?? [],
            trackersIdentifiers: plan.capabilities.trackersIdentifiers ?? [],
            category: plan.category,
            flightPurpose: {
                nameTranslationKey: flightPurposes?.find((purpose) => purpose.id === plan.flightPurpose?.id)?.name ?? "",
                comment: plan.flightPurpose?.comment ?? undefined,
            },
            additionalCrew: plan.capabilities.additionalCrew,
        };
    }
}
