import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { AlertPayloadData, MissionAlert } from "../../models/operational.situation.models";

interface AlertListComponentState {
    alertList: MissionAlert[];
    isProcessing: boolean;
}

@Component({
    selector: "supervisor-shared-lib-alert-list[alertList]",
    templateUrl: "./alert-list.component.html",
    styleUrls: ["./alert-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AlertListComponent {
    @Input() public set alertList(value: MissionAlert[] | undefined) {
        this.localStore.patchState({ alertList: value ?? [] });
    }
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Output() public readonly alertClose = new EventEmitter<AlertPayloadData>();

    protected readonly alertList$ = this.localStore.selectByKey("alertList");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");

    constructor(private readonly localStore: LocalComponentStore<AlertListComponentState>) {
        this.localStore.setState({ alertList: [], isProcessing: false });
    }
}
