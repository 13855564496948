import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, Subject } from "rxjs";

const PRIORITY_RANGE_SETTINGS = {
    minValue: 1,
    maxValue: 9,
};

@UntilDestroy()
@Component({
    selector: "supervisor-shared-lib-priority-dialog",
    templateUrl: "./priority-dialog.component.html",
    styles: [":host { display: block; width: 500px }"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorityDialogComponent {
    private readonly prioritySubject = new Subject<string>();
    public readonly priority$ = this.prioritySubject.asObservable();

    protected priorityControl = new FormControl("", {
        nonNullable: true,
        validators: [
            Validators.required,
            Validators.min(PRIORITY_RANGE_SETTINGS.minValue),
            Validators.max(PRIORITY_RANGE_SETTINGS.maxValue),
        ],
    });

    protected readonly PRIORITY_RANGE_SETTINGS = PRIORITY_RANGE_SETTINGS;

    constructor(@Inject(MAT_DIALOG_DATA) public data: { priority: number; isPriorityProcessing$: Observable<boolean> }) {
        if (this.data.priority) {
            this.priorityControl.setValue(this.data.priority.toFixed(2));
        }

        this.listenOnNameProcessing();
    }

    protected confirm(): void {
        if (this.priorityControl.invalid) {
            return;
        }

        this.prioritySubject.next(this.priorityControl.value);
    }

    private listenOnNameProcessing(): void {
        this.data.isPriorityProcessing$.pipe(untilDestroyed(this)).subscribe((isProcessing: boolean) => {
            if (isProcessing) {
                this.priorityControl.disable();

                return;
            }

            this.priorityControl.enable();
        });
    }
}
