import {
    ItineraryEditorType,
    MissionPlanRouteFlightZone,
    MissionPlanRouteSection,
    MissionPlanRouteSegment,
    SectionElementResponseBody,
    Waypoint,
} from "@dtm-frontend/shared/ui";
import { ArrayUtils, DateUtils, FunctionUtils } from "@dtm-frontend/shared/utils";
import {
    AirwaysCollisionTranslationArgs,
    AnalysisIssueSource,
    AnalysisIssueStatus,
    AnalysisOptionStatus,
    AuthorityAcceptationItem,
    CaaPermitData,
    DensityBox,
    MissionCategory,
    MissionPhaseExtensions,
    MissionPlanAirspaceAnalysisOption,
    MissionPlanAnalysisEvaluationIssue,
    MissionPlanAnalysisIssue,
    MissionPlanAnalysisIssueStatus,
    MissionPlanAnalysisOptionStatus,
    MissionPlanAnalysisStatus,
    MissionPlanEvaluationOption,
    MissionPlanOperationCategoryOption,
    MissionPlanRoute,
    MissionPlanSpecificPermitType,
    MissionPlanTrafficAnalysisOption,
    MissionPlanVerificationResponseBodyAnalysisIssue,
    MissionPreviewData,
    MissionProcessingPhase,
    SoraResult,
    SoraSettings,
    TrafficCollisionTranslationArgs,
} from "../../shared";
import { FilterConditions, Mission, Paging, SearchArea, Sorting } from "../models/mission-search.models";
import {
    AuthorityAcceptation,
    MissionPlanCaaPermitDetailsResponseBody,
    MissionPlanDataResponseBody,
    MissionPlanItineraryEntityType,
    MissionPlanItineraryResponseBody,
    MissionPlanPublicDetailsResponseBody,
    MissionPlanRequestPayload,
    MissionPlanRouteResponseBody,
    MissionPlanVerificationResponseBody,
    MissionPlansResponseBody,
    PublicMissionPlanData,
} from "./mission-search-api.models";

function convertSortingToMissionPlanRequestPayloadPage(sorting: Sorting, paging: Paging): MissionPlanRequestPayload["page"] {
    const sort: Array<{
        property: string;
        direction: "ASC" | "DESC";
    }> = [];

    if (sorting === Sorting.StartDateAsc) {
        sort.push({
            property: "flightStartAtMin",
            direction: "ASC",
        });
    } else if (sorting === Sorting.StartDateDesc) {
        sort.push({
            property: "flightStartAtMin",
            direction: "DESC",
        });
    }

    return {
        sort,
        page: paging.page,
        size: paging.size,
    };
}

export function convertFilterConditionsAndSortingToMissionPlanRequestPayload(
    filterConditions: FilterConditions | undefined,
    sorting: Sorting,
    paging: Paging
): MissionPlanRequestPayload {
    const listRequest: MissionPlanRequestPayload["listRequest"] = {};

    if (filterConditions?.missionId) {
        listRequest.planId = !filterConditions.missionId.id ? filterConditions.missionId.name : filterConditions.missionId.id;
    }

    if (filterConditions?.missionType) {
        listRequest.flightType = [filterConditions.missionType];
    }

    if (filterConditions?.operator) {
        listRequest.operator = filterConditions.operator.name;
    }

    if (filterConditions?.pilot) {
        listRequest.pilot = filterConditions.pilot.name;
    }

    if (filterConditions?.uav) {
        listRequest.uavSerialNumber = filterConditions.uav.name;
    }

    if (filterConditions?.status) {
        listRequest.processingPhase = [filterConditions.status];
    }

    if (filterConditions?.fromDate) {
        listRequest.flightDateFrom = filterConditions.fromDate.toISOString();
    }

    if (filterConditions?.toDate) {
        listRequest.flightDateTo = filterConditions.toDate.toISOString();
    }

    if (filterConditions?.area === SearchArea.Country) {
        // NOTE: Simple geometry that represents Poland
        listRequest.geometry = {
            type: "Polygon",
            coordinates: [
                [
                    /* eslint-disable no-magic-numbers */
                    [14.069638889, 49.0020432310001],
                    [14.069638889, 55.849716667],
                    [24.150833333, 55.849716667],
                    [24.150833333, 49.0020432310001],
                    [14.069638889, 49.0020432310001],
                    /* eslint-enable no-magic-numbers */
                ],
            ],
        };
    } else if (filterConditions?.area === SearchArea.Custom) {
        listRequest.geometry = filterConditions.customArea?.geometry ?? undefined;
    }

    return {
        listRequest,
        page: convertSortingToMissionPlanRequestPayloadPage(sorting, paging),
    };
}

export function convertMissionPlansResponseBodyToMissions(content: MissionPlansResponseBody[]): Mission[] {
    return content.map((item) => ({
        id: item.planId,
        flightStartAt: DateUtils.convertStringDateToDateWithoutTimeZone(item.flightStartAtMin),
        flightStartAtMin: DateUtils.convertStringDateToDateWithoutTimeZone(item.flightStartAtMin),
        flightStartAtMax: DateUtils.convertStringDateToDateWithoutTimeZone(item.flightStartAtMax),
        flightFinishAt: DateUtils.convertStringDateToDateWithoutTimeZone(item.flightFinishAtMax),
        flightFinishAtMin: DateUtils.convertStringDateToDateWithoutTimeZone(item.flightFinishAtMin),
        flightFinishAtMax: DateUtils.convertStringDateToDateWithoutTimeZone(item.flightFinishAtMax),
        isPathBased: item.pathBased,
        flightType: item.flightType,
        status: item.processingPhase,
        dtmNames: item.dtmNames,
        operatorNumber: item.operatorNumber,
        operatorName: item.operatorName,
        pilotNumber: item.pilotNumber,
        pilotName: item.pilotName,
        uavName: item.uavManufacturer + " " + item.uavModel,
        uavSerialNumbers: item.uavSerialNumbers,
    }));
}

function convertAcceptationData(data?: AuthorityAcceptation): AuthorityAcceptationItem | undefined {
    return data
        ? {
              acceptationStatus: data.acceptationStatus,
              comment: data.comment,
              updatedAt: DateUtils.convertStringDateToDateWithoutTimeZone(data.updatedAt),
              isConfirmedByUser: data.confirmedByUser,
          }
        : undefined;
}

export function convertPlanDataResponsesToMissionDetails(
    { plan }: MissionPlanDataResponseBody,
    caaPermitDetailsResponse: MissionPlanCaaPermitDetailsResponseBody
): MissionPreviewData {
    let category: MissionPlanOperationCategoryOption | undefined;

    if (plan.category.type === MissionCategory.Open) {
        category = {
            type: MissionCategory.Open,
            scenarioName: plan.category.scenarioName,
            isDisabled: false,
            issues: [],
        };
    } else if (plan.category.type === MissionCategory.Specific) {
        switch (plan.category.specificPermit) {
            case MissionPlanSpecificPermitType.Individual:
                category = {
                    type: MissionCategory.Specific,
                    specificPermitType: MissionPlanSpecificPermitType.Individual,
                    specificCaaPermitId: plan.category.specificCaaPermitId,
                    isDisabled: false,
                };
                break;
            case MissionPlanSpecificPermitType.Luc:
                category = {
                    type: MissionCategory.Specific,
                    specificPermitType: MissionPlanSpecificPermitType.Luc,
                    shouldSkipSora: plan.category.specificLucSkipSora,
                    isDisabled: false,
                };
                break;
            case MissionPlanSpecificPermitType.Sts:
                category = {
                    type: MissionCategory.Specific,
                    specificPermitType: MissionPlanSpecificPermitType.Sts,
                    scenarioName: plan.category.scenarioName,
                    isDisabled: false,
                    issues: [],
                };
                break;
        }
    }

    return {
        id: plan.id,
        routeId: plan.route?.id,
        flightType: plan.flightType,
        flightStartAtMin: DateUtils.convertStringDateToDateWithoutTimeZone(plan.flightStartAtMin),
        flightStartAtMax: DateUtils.convertStringDateToDateWithoutTimeZone(plan.flightStartAtMax),
        flightFinishAtMin: DateUtils.convertStringDateToDateWithoutTimeZone(plan.flightFinishAtMin),
        flightFinishAtMax: DateUtils.convertStringDateToDateWithoutTimeZone(plan.flightFinishAtMax),
        phase:
            plan.phase === MissionProcessingPhase.Accepted && plan.uncertainConditions
                ? MissionPhaseExtensions.AcceptedConditionally
                : plan.phase,
        operatorName: caaPermitDetailsResponse.crew.operatorName,
        pilotName: plan.capabilities.pilotName,
        uavName: `${plan.uav.manufacturer} ${plan.uav.model}`,
        uavSerialNumbers: plan.uav.serialNumbers,
        trackersIdentifiers: plan.capabilities.trackersIdentifiers,
        category,
        additionalCrew: plan.capabilities.additionalCrew,
        setupName: plan.capabilities.setupName,
        flightPurpose: plan.flightPurpose,
        itineraryEditorType: plan.itinerary?.editorType.toLowerCase() as ItineraryEditorType,
        rejectionJustification: plan.mission?.rejectionJustification,
        rejectedAt: plan.mission?.rejectedAt ? DateUtils.convertStringDateToDateWithoutTimeZone(plan.mission?.rejectedAt) : undefined,
        remarks: {
            authorityAcceptation: {
                dtm: convertAcceptationData(plan.remarks?.dtm),
                utm: convertAcceptationData(plan.remarks?.utm),
                dss: convertAcceptationData(plan.remarks?.dss),
            },
            justification: plan.remarks?.formalJustification
                ? {
                      shouldRequestForPriority: plan.remarks.formalJustification.requestForPriority,
                      reason: plan.remarks.formalJustification.reason,
                      attachmentIds: plan.remarks.formalJustification.attachmentIds ?? [],
                      attachments:
                          plan.remarks.formalJustification.attachments?.map(({ name, fileId }) => ({
                              id: fileId ?? "",
                              name: name ?? "",
                          })) ?? [],
                  }
                : undefined,
        },
    };
}

export function convertPlanDataResponsesToCaaPermitData(response: MissionPlanCaaPermitDetailsResponseBody): CaaPermitData | undefined {
    return {
        operationalGeometry: {
            safetyAreaWidth: response.safetyArea.width,
            safetyAreaHeight: response.safetyArea.height,
            groundRiskBuffer: response.groundRisk.buffer,
            groundRiskBufferType: response.groundRisk.bufferType,
            adjacentAreaBufferHorizontal: response.adjacentArea.bufferHorizontal,
            adjacentAreaHeight: response.adjacentArea.height,
            flightAreaMaxRadius: response.flightArea.maxRadius,
            flightAreaMaxDeclaredHeight: response.flightArea.maxDeclaredHeight,
            operationAreaMaxRadius: response.operationArea.maxRadius,
            flightAreaMaxHeight: response.flightArea.maxDeclaredHeight,
            operationAreaMaxHeight: response.operationArea.maxHeight,
            flightAreaEstimatedDistance: response.flightArea.estimatedDistance,
            flightAreaMaxWidth: response.flightArea.maxWidth,
            operationAreaMaxWidth: response.operationArea.maxWidth,
            operationAreaMaxDeclaredHeight: response.operationArea.maxDeclaredHeight,
            flightAreaSize: response.flightArea.size,
            operationAreaSize: response.operationArea.size,
        },
        technicalSpecification: {
            takeOffMass: response.uav.takeOffMass,
            maxFlightSpeed: response.uav.maxFlightSpeed,
            maxDroneWidth: response.uav.maxDroneWidth,
            hasGeofencing: response.uav.geofencing,
            hasGeocage: response.uav.geocage,
            flightSpeedLimit: response.uav.flightSpeedLimit,
            failSafe: response.uav.failSafe,
        },
    };
}

function transformIssueStatus(status: AnalysisIssueStatus): MissionPlanAnalysisIssueStatus {
    switch (status) {
        case AnalysisIssueStatus.Failure:
            return MissionPlanAnalysisIssueStatus.Error;
        case AnalysisIssueStatus.Warning:
            return MissionPlanAnalysisIssueStatus.Warning;
        case AnalysisIssueStatus.Success:
            return MissionPlanAnalysisIssueStatus.Success;
        case AnalysisIssueStatus.Info:
            return MissionPlanAnalysisIssueStatus.Info;
        case AnalysisIssueStatus.Hold:
            return MissionPlanAnalysisIssueStatus.Hold;
        default:
            return MissionPlanAnalysisIssueStatus.FatalError;
    }
}

function transformOptionStatus(status: AnalysisOptionStatus): MissionPlanAnalysisOptionStatus {
    switch (status) {
        case AnalysisOptionStatus.Completed:
            return MissionPlanAnalysisOptionStatus.Success;
        case AnalysisOptionStatus.Failure:
            return MissionPlanAnalysisOptionStatus.Error;
        case AnalysisOptionStatus.Canceled:
            return MissionPlanAnalysisOptionStatus.Canceled;
        case AnalysisOptionStatus.None:
            return MissionPlanAnalysisOptionStatus.Pending;
        case AnalysisOptionStatus.Reverted:
            return MissionPlanAnalysisOptionStatus.Reverted;
        case AnalysisOptionStatus.Hold:
            return MissionPlanAnalysisOptionStatus.Hold;
        default:
            return MissionPlanAnalysisOptionStatus.FatalError;
    }
}

function getIssuesForAnalysisOption(
    option: AnalysisIssueSource,
    analysisIssues: MissionPlanVerificationResponseBodyAnalysisIssue[]
): MissionPlanAnalysisIssue[] {
    return analysisIssues
        .filter((issue) => issue.source === option)
        .map((issue) => ({
            codename: issue.codename,
            translationArgs: issue.translationArgs,
            translationId: issue.translationId,
            status: transformIssueStatus(issue.status),
            designators: issue.translationArgs?.designators
                ?.split(",")
                .filter(FunctionUtils.isTruthy)
                .map((designator) => designator.trim()),
        }));
}

function createSoraResultFromResponse(response: MissionPlanVerificationResponseBody): SoraResult | undefined {
    const responseSoraResult = response.soraResult;

    if (!responseSoraResult) {
        return undefined;
    }

    const {
        effectiveGrl,
        finalGrc,
        finalGrl,
        intrinsicGrc,
        populationDensity,
        sail,
        residualArc,
        adjacentArea,
        airRisk,
        groundRiskTradeoff,
        criticalArea,
        initialArc,
        flightTerminationSystem,
        tmpr,
    } = responseSoraResult;

    return {
        effectiveGrl,
        finalGrc,
        finalGrl,
        intrinsicGrc,
        sail,
        groundRiskTradeoff,
        criticalArea,
        initialArc,
        residualArc,
        adjacentArea,
        airRisk,
        flightTerminationSystem: {
            isRequired: flightTerminationSystem.required,
            isAvailable: flightTerminationSystem.available,
            issues: flightTerminationSystem.issues,
        },
        tacticalMitigationPerformanceRequirementProperty: tmpr,
        populationDensity: {
            peoplePerSquareKilometerAvg: populationDensity?.peoplePerSquareKilometerAvg,
            peoplePerSquareKilometerMax: populationDensity?.peoplePerSquareKilometerMax,
            boxes: {
                [DensityBox.Lowest]: populationDensity?.boxes?.GREEN,
                [DensityBox.Low]: populationDensity?.boxes?.YELLOW,
                [DensityBox.Medium]: populationDensity?.boxes?.RED,
                [DensityBox.High]: populationDensity?.boxes?.VIOLET,
                [DensityBox.Highest]: populationDensity?.boxes?.BLUE,
                [DensityBox.Unknown]: populationDensity?.boxes?.UNKNOWN,
            },
        },
    };
}

export function convertPlanDataResponsesToAnalysisStatus(
    response: MissionPlanVerificationResponseBody
): MissionPlanAnalysisStatus | undefined {
    const trafficIssues = getIssuesForAnalysisOption(AnalysisIssueSource.Traffic, response.analysisIssues);
    const [nearbyMissionsPlanIds, collisionMissionsPlanIds] = ArrayUtils.partition(
        trafficIssues.filter((item) => !!item.translationArgs?.planIds),
        (item) => item.status !== MissionPlanAnalysisIssueStatus.Error
    ).map((item) =>
        item
            .map((issue) => (issue.translationArgs as TrafficCollisionTranslationArgs)?.planIds?.split(", "))
            .filter(FunctionUtils.isTruthy)
            .flat()
    );

    const traffic: MissionPlanTrafficAnalysisOption | undefined =
        response.trafficRequired && !!response.trafficStatus
            ? {
                  status: transformOptionStatus(response.trafficStatus),
                  issues: trafficIssues,
                  nearbyMissionsPlanIds,
                  collisionMissionsPlanIds,
              }
            : undefined;

    const evaluationIssues: MissionPlanAnalysisEvaluationIssue[] = response.evaluationIssues.map((issue) => ({
        codename: issue.codename,
        status: issue.status,
        translationArgs: issue.translationArgs,
        translationId: issue.translationId,
    }));

    const evaluation: MissionPlanEvaluationOption | undefined = evaluationIssues.reduce<MissionPlanEvaluationOption>(
        (assignedIssues, issue) => {
            const { zoneIssues } = assignedIssues;
            const designators = (issue.translationArgs as AirwaysCollisionTranslationArgs)?.designators
                ?.split(",")
                .map((designator) => designator.trim());

            designators?.forEach((designator) => {
                zoneIssues[designator] = zoneIssues[designator] ? [...zoneIssues[designator], issue] : [issue];
            });

            issue.designators = designators;

            assignedIssues.issues.push(issue);

            return assignedIssues;
        },
        { issues: [], zoneIssues: {} }
    );

    const airspaceIssues = getIssuesForAnalysisOption(AnalysisIssueSource.Airspace, response.analysisIssues);
    const airspace: MissionPlanAirspaceAnalysisOption = {
        status: transformOptionStatus(response.airspaceStatus),
        issues: airspaceIssues,
        zoneIssues: airspaceIssues.reduce<Record<string, MissionPlanAnalysisIssue[]>>((assignedIssues, issue) => {
            const designators = (issue.translationArgs as AirwaysCollisionTranslationArgs)?.designators
                ?.split(",")
                .map((designator) => designator.trim());

            designators?.forEach((designator) => {
                assignedIssues[designator] = assignedIssues[designator] ? [...assignedIssues[designator], issue] : [issue];
            });

            return assignedIssues;
        }, {}),
        isAdjacentAreaControlled: response.airspace.adjacentControlled,
        isAdjacentAreaUncontrolled: response.airspace.adjacentUncontrolled,
        isOperationalAreaControlled: response.airspace.operationalControlled,
        isOperationalAreaUncontrolled: response.airspace.operationalUncontrolled,
    };

    return {
        planId: response.planId,
        planStatus: response.planStatus,
        isPlanSubmittable: response.planSubmittable,
        status: response.status,
        isManualVerificationRequired: response.manualVerificationRequired,
        routeId: response.routeId,
        airspace,
        traffic,
        caaPermit:
            response.caaPermitRequired && !!response.caaPermitStatus
                ? {
                      status: transformOptionStatus(response.caaPermitStatus),
                      issues: getIssuesForAnalysisOption(AnalysisIssueSource.CaaPermit, response.analysisIssues),
                  }
                : undefined,
        sora:
            response.soraRequired && !!response.soraStatus
                ? {
                      status: transformOptionStatus(response.soraStatus),
                      issues: getIssuesForAnalysisOption(AnalysisIssueSource.Sora, response.analysisIssues),
                      result: createSoraResultFromResponse(response),
                  }
                : undefined,
        version: response.version,
        evaluation,
        applyForSpecificPermit: response.applyForSpecificPermit,
        systemVerificationId: response.systemVerificationId,
        flightRules: response.flightConditions.map((condition) => ({
            translationArgs:
                { designators: condition.translationArgs?.designators.split(",").map((designator) => designator.trim()) } ?? {},
            translationId: condition.translationId,
            isCheckinApprovalRequired: condition.checkinApprovalRequired,
        })),
    };
}

export function convertPlanDataResponsesToSoraSettings(response: MissionPlanItineraryResponseBody): SoraSettings | undefined {
    const itineraryInputType = response.itinerary?.input?.["@type"];
    const entityTypes = [
        MissionPlanItineraryEntityType.Assisted,
        MissionPlanItineraryEntityType.Custom,
        MissionPlanItineraryEntityType.Standard,
    ];

    if (itineraryInputType && entityTypes.includes(itineraryInputType)) {
        return response.itinerary?.input?.soraSettings;
    }

    return undefined;
}

function parseWaypointResponseDates(waypoint: Waypoint, waypointIndex: number): Waypoint {
    const prefix = "A";

    return {
        ...waypoint,
        name: `${prefix}${waypointIndex}`,
        estimatedArriveAt: {
            min: new Date(waypoint.estimatedArriveAt.min),
            max: new Date(waypoint.estimatedArriveAt.max),
        },
    };
}

function convertSectionElementResponseBodyToMissionPlanRouteSection(sections?: SectionElementResponseBody[]): MissionPlanRouteSection[] {
    let sectionId = 0;

    return (
        sections?.map((section, index) => {
            const flightZone: MissionPlanRouteFlightZone | undefined = !section.flightZone
                ? undefined
                : {
                      center: parseWaypointResponseDates(
                          section.flightZone.center,
                          sections[index - 1]?.segment ? (sectionId += 2) : ++sectionId
                      ),
                      flightArea: section.flightZone.flightArea,
                      safetyArea: section.flightZone.safetyArea,
                      groundArea: section.flightZone.ground?.area,
                      groundAdjacentArea: section.flightZone.groundAdjacent?.area,
                      stopover: section.flightZone.stopover,
                      isRunway: section.flightZone.runway,
                  };

            const segment: MissionPlanRouteSegment | undefined = !section.segment
                ? undefined
                : {
                      fromWaypoint: parseWaypointResponseDates(section.segment.from, ++sectionId),
                      toWaypoint: parseWaypointResponseDates(section.segment.to, sectionId + 1),
                      flightArea: section.segment.flightArea,
                      safetyArea: section.segment.safetyArea,
                      groundArea: section.segment.ground?.area,
                      groundAdjacentArea: section.segment.groundAdjacent?.area,
                      elevationProfile: section.segment.elevationProfile,
                      distance: section.segment.distance,
                      speed: section.segment.speed,
                      duration: section.segment.duration,
                  };

            return { flightZone, segment, isActive: section.active };
        }) ?? []
    );
}

export function convertMissionPlanRouteResponseBodyToMissionPlanRoute(response: MissionPlanRouteResponseBody): MissionPlanRoute {
    return {
        routeId: response.routeId,
        planId: response.planId,
        missionId: response.missionId,
        elevations: response.elevations,
        estimatedDistance: response.estimatedDistance,
        sections: convertSectionElementResponseBodyToMissionPlanRouteSection(response.sections),
        isPathBased: response.pathBased,
    };
}

function convertResponseSections(sections: SectionElementResponseBody[]): MissionPlanRouteSection[] {
    let sectionId = 0;

    return sections.map((section, index): MissionPlanRouteSection => {
        const flightZone: MissionPlanRouteFlightZone | undefined = !section.flightZone
            ? undefined
            : {
                  center: parseWaypointResponseDates(
                      section.flightZone.center,
                      sections[index - 1]?.segment ? (sectionId += 2) : ++sectionId
                  ),
                  flightArea: section.flightZone.flightArea,
                  safetyArea: section.flightZone.safetyArea,
                  groundArea: section.flightZone.ground?.area,
                  groundAdjacentArea: section.flightZone.groundAdjacent?.area,
                  stopover: section.flightZone.stopover,
                  isRunway: section.flightZone.runway,
              };

        const segment: MissionPlanRouteSegment | undefined = !section.segment
            ? undefined
            : {
                  fromWaypoint: parseWaypointResponseDates(section.segment.from, ++sectionId),
                  toWaypoint: parseWaypointResponseDates(section.segment.to, sectionId + 1),
                  flightArea: section.segment.flightArea,
                  safetyArea: section.segment.safetyArea,
                  groundArea: section.segment.ground?.area,
                  groundAdjacentArea: section.segment.groundAdjacent?.area,
                  elevationProfile: section.segment.elevationProfile,
                  distance: section.segment.distance,
                  speed: section.segment.speed && {
                      min: +section.segment.speed.min || 0,
                      max: +section.segment.speed.max || 0,
                  },
                  duration: section.segment.duration,
              };

        return { flightZone, segment, isActive: section.active };
    });
}

export function convertMissionPlanPublicDetailsResponseBodyToRouteData(
    response: MissionPlanPublicDetailsResponseBody
): PublicMissionPlanData {
    return {
        id: response.id,
        missionId: response.missionId,
        capabilities: response.capabilities,
        flightStartAtMin: response.flightStartAtMin ? new Date(response.flightStartAtMin) : undefined,
        flightFinishAtMax: response.flightFinishAtMax ? new Date(response.flightFinishAtMax) : undefined,
        route: response.route
            ? {
                  routeId: response.route.id,
                  dtmNames: response.route.dtmNames,
                  isPathBased: response.route.pathBased,
                  isDtmOnly: response.route.dtmOnly,
                  estimatedDistance: response.route.estimatedDistance,
                  sections: convertResponseSections(response.route.sections),
              }
            : {
                  routeId: "",
                  sections: [],
              },
    };
}
