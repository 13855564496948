import { Address, PageResponseBody } from "@dtm-frontend/shared/ui";
import { Installation, PageableDeviceHistory, SectorStatus, SignalViolationType } from "../models/network-monitoring.models";

const GROUP_ALERTS_KEY = "dtmName";

export interface InstallationResponseBody {
    dtmName: string;
    id: string;
    name: string;
    address: Address;
    installationDate: string;
    sectors: SectorResponseBody[];
}

interface SectorResponseBody {
    id: string;
    deviceId: string;
    name: string;
    sectorStatus: SectorStatus.Active;
    signalViolationTimes: {
        last7DaysInMinutes: number;
        last30DaysInMinutes: number;
        last60DaysInMinutes: number;
        last90DaysInMinutes: number;
        total: number;
    };
    mountedDevicesHistory: string[];
    position: {
        altitude: number;
        azimuth: number;
        latitude: number;
        longitude: number;
        viewAngleRange: {
            min: number;
            max: number;
        };
    };
    startedAt: string;
}

export interface DeviceHistoryContentResponseBody {
    signalViolationType: SignalViolationType;
    installationId: string;
    sectorId: string;
    deviceId: string;
    serialNumber: string;
    startedAt: string;
    finishedAt: string | undefined;
    violationTimeInMinutes: number;
    signalViolationId: string;
    note: {
        person: string;
        modifiedAt: string;
        content: string;
    };
}

export type DeviceHistoryResponseBody = PageResponseBody<DeviceHistoryContentResponseBody>;

const groupDtm = (alerts: InstallationResponseBody[]) =>
    alerts.reduce<{ [key: string]: InstallationResponseBody[] }>(
        (hash, obj) => ({ ...hash, [obj[GROUP_ALERTS_KEY]]: [...(hash[obj[GROUP_ALERTS_KEY]] ?? []), obj] }),
        {}
    );

export const convertInstallationResponseBodyToInstallationList = (response: InstallationResponseBody[]): Installation[] => {
    const groupedDtm = groupDtm(response);

    // TODO: DTM-3451 - Delete mocked mounted devices

    return Object.keys(groupedDtm).map((name) => ({
        dtmName: groupedDtm[name][0].dtmName,
        aerials: groupedDtm[name].map((aerial) => ({
            ...aerial,
            address: aerial.address,
            installationDate: new Date(aerial.installationDate),
            sectors: aerial.sectors
                .map((sector) => ({
                    id: sector.id,
                    name: sector.name,
                    sectorStatus: sector.sectorStatus,
                    last7DaysViolationInMinutes: sector.signalViolationTimes.last7DaysInMinutes,
                    last60DaysViolationInMinutes: sector.signalViolationTimes.last60DaysInMinutes,
                    last90DaysViolationInMinutes: sector.signalViolationTimes.last90DaysInMinutes,
                    totalDaysViolationInMinutes: sector.signalViolationTimes.total,
                    azimuth: sector.position.azimuth,
                    minViewAngle: sector.position.viewAngleRange.min,
                    maxViewAngle: sector.position.viewAngleRange.max,
                    mountedDevicesHistory: sector.mountedDevicesHistory.map((device) => ({
                        id: device,
                    })),
                }))
                .sort((left, right) => left.name.localeCompare(right.name)),
        })),
    }));
};

export const convertDeviceHistoryResponseBodyToDeviceHistory = (deviceHistory: DeviceHistoryResponseBody): PageableDeviceHistory => ({
    deviceHistory: deviceHistory.content.map((response) => ({
        ...response,
        startedAt: new Date(response.startedAt),
        finishedAt: response.finishedAt ? new Date(response.finishedAt) : undefined,
        note: response.note
            ? {
                  ...response.note,
                  modifiedAt: new Date(response.note.modifiedAt),
                  supervisorName: response.note.person,
              }
            : undefined,
    })),
    pageable: {
        size: deviceHistory.size,
        page: deviceHistory.number,
        totalElements: deviceHistory.totalElements,
    },
});
