<dtm-ui-loader-container
    *ngrxLet="{
        isResultsListProcessing: isAddingPermissionsListProcessing$,
        resultsList: addingPermissionsList$,
        expandedElement: expandedElement$,
    } as vm"
    [isShown]="vm.isResultsListProcessing"
>
    <table mat-table [dataSource]="vm.resultsList" multiTemplateDataRows class="dtm-table">
        <ng-container [matColumnDef]="displayedColumns[0]">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button type="button" class="button-icon" (click)="$event.stopPropagation(); toggleExpandableRow(element)">
                    <dtm-ui-icon
                        name="arrow-right"
                        class="expand-handle"
                        [class.expanded]="element.id === vm.expandedElement"
                    ></dtm-ui-icon>
                </button>
            </td>
        </ng-container>
        <ng-container [matColumnDef]="displayedColumns[1]">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dtmSupAddingPermissions.list.namePilotLabel" | transloco }}
            </th>
            <td mat-cell *matCellDef="let addingPermission">
                {{ addingPermission.name }}
            </td>
        </ng-container>

        <ng-container [matColumnDef]="displayedColumns[2]">
            <th mat-header-cell *matHeaderCellDef>{{ "dtmSupAddingPermissions.list.pilotNumberLabel" | transloco }}</th>
            <td mat-cell *matCellDef="let addingPermission">
                {{ addingPermission.pilotNumber }}
            </td>
        </ng-container>

        <ng-container [matColumnDef]="displayedColumns[3]">
            <th mat-header-cell *matHeaderCellDef>{{ "dtmSupAddingPermissions.list.emailLabel" | transloco }}</th>
            <td mat-cell *matCellDef="let addingPermission">
                {{ addingPermission.email }}
            </td>
        </ng-container>

        <ng-container [matColumnDef]="displayedColumns[4]">
            <th mat-header-cell *matHeaderCellDef>{{ "dtmSupAddingPermissions.list.phoneNumberLabel" | transloco }}</th>
            <td mat-cell *matCellDef="let addingPermission">
                {{ addingPermission.phoneNumber | formatPhoneNumber }}
            </td>
        </ng-container>

        <ng-container [matColumnDef]="displayedColumns[5]">
            <th mat-header-cell *matHeaderCellDef>{{ "dtmSupAddingPermissions.list.permissionsLabel" | transloco }}</th>
            <td mat-cell *matCellDef="let addingPermission">
                <mat-chip
                    class="chip superpilot-permissions"
                    *ngIf="addingPermission[AddingPermissionsPilotFilterFormKeys.SuperPilot]; else noData"
                >
                    <dtm-ui-icon name="star-badge"></dtm-ui-icon>
                    {{ "dtmSupAddingPermissions.permissions.superPilotLabel" | transloco }}
                </mat-chip>

                <ng-template #noData>{{ "dtmSupAddingPermissions.list.noDataLabel" | transloco }}</ng-template>
            </td>
        </ng-container>

        <ng-container [matColumnDef]="displayedColumns[6]">
            <th mat-header-cell *matHeaderCellDef>{{ "dtmSupAddingPermissions.list.actionsLabel" | transloco }}</th>
            <td mat-cell *matCellDef="let addingPermission">
                <button [matMenuTriggerFor]="actions" class="button-icon" type="button" (click)="$event.stopPropagation()">
                    <dtm-ui-icon name="more"></dtm-ui-icon>
                </button>
                <mat-menu #actions="matMenu">
                    <div class="slides-container">
                        <mat-slide-toggle
                            labelPosition="before"
                            [checked]="addingPermission[AddingPermissionsPilotFilterFormKeys.SuperPilot]"
                            (change)="permissionChange.next({ id: addingPermission.id, permission: $event.checked })"
                        >
                            <div class="slide-with-icon">
                                <dtm-ui-icon name="star-badge"></dtm-ui-icon>
                                {{ "dtmSupAddingPermissions.permissions.superPilotLabel" | transloco }}
                            </div>
                        </mat-slide-toggle>
                    </div>
                </mat-menu>
            </td>
        </ng-container>

        <ng-container matColumnDef="noResults">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                <dtm-ui-error
                    *ngIf="addingPermissionsListError$ | ngrxPush; else emptyListTemplate"
                    [mode]="ErrorMode.Secondary"
                    (reload)="addingPermissionsListRefresh.emit()"
                ></dtm-ui-error>
                <ng-template #emptyListTemplate>
                    <dtm-ui-no-results></dtm-ui-no-results>
                </ng-template>
            </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="expanded-row-content" *ngIf="vm.expandedElement === element.id && element.qualifications" @slideIn>
                    <dtm-ui-label-value [label]="'dtmSupAddingPermissions.list.pilotQualificationsLabel' | transloco">
                        <span *ngIf="!element.qualifications.length; else qualificationsListTemplate">{{
                            "dtmSupAddingPermissions.list.noDataLabel" | transloco
                        }}</span>
                        <ng-template #qualificationsListTemplate>
                            <div class="qualifications-list">
                                <dtm-ui-qualification-badge
                                    *ngFor="let qualification of element.qualifications"
                                    [qualification]="qualification"
                                ></dtm-ui-qualification-badge>
                            </div>
                        </ng-template>
                    </dtm-ui-label-value>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="pagination">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                <dtm-ui-pagination *ngIf="pagination$ | ngrxPush as pagination" [pagination]="pagination" (page)="pageChange.emit($event)">
                </dtm-ui-pagination>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
            mat-footer-row
            *matFooterRowDef="['noResults']"
            [class.hide-footer]="vm.resultsList.length || vm.isResultsListProcessing"
            class="no-results-row"
        >
            <dtm-ui-no-results></dtm-ui-no-results>
        </tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.expanded]="vm.expandedElement === row.id"></tr>
        <tr mat-row *matRowDef="let expandedRow; columns: ['expandedDetail']" class="expansion-row"></tr>
        <tr mat-footer-row *matFooterRowDef="['pagination']" [class.hide-footer]="!vm.resultsList.length" class="pagination-row"></tr>
    </table>
</dtm-ui-loader-container>
