<div class="alarm-slider" #wrapperRef>
    <div class="content" #alarmContentRef dtmUiScrollTracker (scrollTrack)="changeScrollButtonState($event)">
        <supervisor-shared-lib-message *ngFor="let alarm of alarmList$ | ngrxPush" severity="error" (messageClose)="alarmClose.emit(alarm)">
            {{ alarm.message }}
            <span *ngIf="alarm.signalViolationType">
                - {{ "dtmSupervisorLibShared.alarmSlider.signalViolationValue" | transloco : { value: alarm.signalViolationType } }}
            </span>
        </supervisor-shared-lib-message>
    </div>
    <div class="scroll-buttons" *ngIf="shouldScrollButtonsBeVisible$ | ngrxPush">
        <button class="button-icon" type="button" [disabled]="isLeftScrollButtonDisabled$ | ngrxPush" (click)="scrollLeft()">
            <dtm-ui-icon name="arrow-left"></dtm-ui-icon>
        </button>
        <button class="button-icon" type="button" [disabled]="isRightScrollButtonDisabled$ | ngrxPush" (click)="scrollRight()">
            <dtm-ui-icon name="arrow-right"></dtm-ui-icon>
        </button>
    </div>
</div>
