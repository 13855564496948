<ng-container *ngrxLet="data.isPriorityProcessing$ as isProcessing" class="dialog-wrapper">
    <div mat-dialog-content>
        <dtm-ui-input-field>
            <label>{{ "dtmSupPlannedMissions.priorityDialog.priorityControlLabel" | transloco }} </label>
            <input [formControl]="priorityControl" matInput [dropSpecialCharacters]="false" mask="9.9" type="text" />
            <div class="field-error" *dtmUiFieldHasError="priorityControl; name: 'required'">
                {{ "dtmUi.editEmailAddress.fieldRequiredError" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="priorityControl; name: 'min'">
                {{
                    "dtmSupPlannedMissions.priorityDialog.minValueErrorHint"
                        | transloco
                            : {
                                  min: PRIORITY_RANGE_SETTINGS.minValue
                              }
                }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="priorityControl; name: 'max'">
                {{
                    "dtmSupPlannedMissions.priorityDialog.maxValueErrorHint"
                        | transloco
                            : {
                                  max: PRIORITY_RANGE_SETTINGS.maxValue
                              }
                }}
            </div>
        </dtm-ui-input-field>
    </div>
    <div mat-dialog-actions align="end">
        <button type="button" class="button-secondary" [mat-dialog-close]="false" [disabled]="isProcessing">
            {{ "dtmSupPlannedMissions.priorityDialog.cancelButtonLabel" | transloco }}
        </button>
        <button type="button" class="button-primary" (click)="confirm()" [disabled]="isProcessing">
            {{ "dtmSupPlannedMissions.priorityDialog.saveButtonLabel" | transloco }}
        </button>
    </div>
</ng-container>
