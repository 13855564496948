<div
    *ngFor="let area of dtmAreas$ | ngrxPush"
    class="dtm-tile"
    [class.selected]="(selectedAreaName$ | ngrxPush) === area.name"
    (click)="selectDtmArea(area)"
    (keydown.enter)="selectDtmArea(area)"
    tabindex="0"
    role="button"
>
    <div class="header">
        <h2 class="dtm-name">{{ area.name }}</h2>
        <dtm-ui-icon name="alarm-warning" [class.is-alarm]="area.isAlarm"></dtm-ui-icon>
    </div>
    <div class="info-container">
        <div>
            <dtm-ui-icon name="drone"></dtm-ui-icon> {{ area.dronesNumber }} (<dtm-ui-icon name="eye-off"></dtm-ui-icon>
            {{ area.bvlosNumber }})
        </div>
        <div>
            <dtm-ui-icon name="complex-leaving-area" [title]="'dtmSupervisorLibShared.dtmArea.leavingArea' | transloco"></dtm-ui-icon>
            <div class="status" [ngClass]="area.isLeavingArea ? 'active' : 'inactive'"></div>
        </div>
        <div>
            <dtm-ui-icon name="base-station" [title]="'dtmSupervisorLibShared.dtmArea.adsbInfrastructure' | transloco"></dtm-ui-icon>
            <div class="status" [ngClass]="area.isAdsb ? 'active' : 'inactive'"></div>
        </div>
    </div>
</div>
