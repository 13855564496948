import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { AnalysisResult } from "../../models/weather.models";

interface WeatherConditionBoxComponentState {
    analysisState: AnalysisResult;
    title: string | undefined;
    shouldDisplayValue: boolean;
}

@Component({
    selector: "dtm-map-weather-condition-box[title]",
    templateUrl: "./weather-condition-box.component.html",
    styleUrls: ["./weather-condition-box.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class WeatherConditionBoxComponent {
    @Input() public set analysisResult(value: AnalysisResult | undefined) {
        this.localStore.patchState({ analysisState: value });
    }
    @Input() public set title(value: string | undefined) {
        this.localStore.patchState({ title: value });
    }
    @Input() public set shouldDisplayValue(value: BooleanInput) {
        this.localStore.patchState({ shouldDisplayValue: coerceBooleanProperty(value) });
    }

    protected readonly analysisState$ = this.localStore.selectByKey("analysisState");
    protected readonly title$ = this.localStore.selectByKey("title");

    protected readonly AnalysisResultState = AnalysisResult;

    constructor(private readonly localStore: LocalComponentStore<WeatherConditionBoxComponentState>) {
        this.localStore.setState({
            analysisState: AnalysisResult.None,
            title: undefined,
            shouldDisplayValue: false,
        });
    }
}
