import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { EMPTY, Subject, takeUntil, tap } from "rxjs";
import { catchError } from "rxjs/operators";
import { DtmStats, DtmStatsError } from "../models/shared-supervisor-client.models";
import { DtmStatsApiService } from "../services/dtm-stats-api.service";
import { DtmAreasActions } from "./dtm-areas.actions";

export interface DtmDataStoreState {
    dtmAreas: string[] | undefined;
    stats: {
        [key: string]: DtmStats;
    };
    statsError: DtmStatsError | undefined;
}

const defaultState: DtmDataStoreState = {
    dtmAreas: undefined,
    stats: {},
    statsError: undefined,
};

@State<DtmDataStoreState>({
    name: "dtmAreas",
    defaults: defaultState,
})
@Injectable()
export class DtmAreasState {
    constructor(private readonly dtmStatsApiService: DtmStatsApiService, private readonly store: Store) {}

    private dtmStatsStop$: Subject<void> | undefined;

    @Selector()
    public static dtmAreas(state: DtmDataStoreState): string[] | undefined {
        return state.dtmAreas;
    }
    @Selector()
    public static getDtmStats(state: DtmDataStoreState) {
        return state.stats;
    }

    @Selector()
    public static getDtmsStatsError(state: DtmDataStoreState) {
        return state.statsError;
    }

    @Action(DtmAreasActions.SetDtmAreas)
    public setDtmAreas(context: StateContext<DtmDataStoreState>, { dtmAreas }: DtmAreasActions.SetDtmAreas) {
        context.patchState({ dtmAreas });
    }

    @Action(DtmAreasActions.GetDtmStats)
    public getDtmStats(context: StateContext<DtmDataStoreState>, { dtmNames, authorityUnitId }: DtmAreasActions.GetDtmStats) {
        this.dtmStatsStop$?.next();
        this.dtmStatsStop$?.complete();

        if (!dtmNames.length || !authorityUnitId) {
            context.patchState({
                stats: {},
                statsError: undefined,
            });

            return;
        }

        this.dtmStatsStop$ = new Subject();

        return this.dtmStatsApiService.startDtmStatusUpdatesWatch(dtmNames, authorityUnitId).pipe(
            takeUntil(this.dtmStatsStop$),
            tap((result) => {
                context.patchState({
                    stats: {
                        ...context.getState().stats,
                        ...result,
                    },
                    statsError: undefined,
                });
            }),
            catchError((error) => {
                context.patchState({
                    statsError: error,
                });

                return EMPTY;
            })
        );
    }
}
