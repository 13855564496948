import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, QueryList, ViewChildren } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { DeviceHistory, Sector } from "../../models/network-monitoring.models";
import { EventHistoryListComponent } from "../event-history-list/event-history-list.component";
import { SignalViolationData } from "../event-tile/event-tile.component";

interface UnavailabilitySectorDetailsComponentState {
    sectorList: Sector[];
    deviceHistoryList: DeviceHistory[];
    isProcessing: boolean;
    isDeviceHistoryFullyLoaded: boolean;
}

@Component({
    selector: "supervisor-shared-lib-unavailability-sector-details[sectorList]",
    templateUrl: "./unavailability-sector-details.component.html",
    styleUrls: ["./unavailability-sector-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UnavailabilitySectorDetailsComponent {
    @Input() public set sectorList(value: Sector[] | undefined) {
        this.localStore.patchState({ sectorList: value ?? [] });
    }
    @Input() public set deviceHistoryList(value: DeviceHistory[] | undefined) {
        this.localStore.patchState({ deviceHistoryList: value ?? [] });
    }
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set isDeviceHistoryFullyLoaded(value: BooleanInput) {
        this.localStore.patchState({ isDeviceHistoryFullyLoaded: coerceBooleanProperty(value) });
    }

    @Output() public readonly deviceSelect = new EventEmitter<string>();
    @Output() public readonly contentLoad = new EventEmitter<string>();
    @Output() public readonly formSave = new EventEmitter<SignalViolationData>();

    @ViewChildren(EventHistoryListComponent) private readonly eventHistoryList!: QueryList<EventHistoryListComponent>;

    protected readonly sectorList$ = this.localStore.selectByKey("sectorList");
    protected readonly deviceHistoryList$ = this.localStore.selectByKey("deviceHistoryList");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly isDeviceHistoryFullyLoaded$ = this.localStore.selectByKey("isDeviceHistoryFullyLoaded");

    constructor(private readonly localStore: LocalComponentStore<UnavailabilitySectorDetailsComponentState>) {
        this.localStore.setState({
            sectorList: [],
            deviceHistoryList: [],
            isProcessing: false,
            isDeviceHistoryFullyLoaded: false,
        });
    }

    protected tabSelect(index: number): void {
        this.deviceSelect.emit(this.eventHistoryList.get(index)?.deviceControlValue ?? "");
    }
}
