import { MapEntity } from "@dtm-frontend/shared/map/cesium";
import { DtmName } from "../../planned-missions/models/mission.models";
import { TemporaryZoneFormData, TemporaryZoneType, ZoneFiltersData } from "../models/temporary-zones.models";

export namespace TemporaryZonesActions {
    export class AddTemporaryZone {
        public static readonly type = "[SUP - Temporary zone] Add temporary zone";
        constructor(public zoneData: TemporaryZoneFormData, public mapEntity: MapEntity, public zones?: string[]) {}
    }

    export class GetDraftDetails {
        public static readonly type = "[SUP - Temporary zone] Get draft details";
        constructor(public draftId: string) {}
    }

    export class GetZoneDetails {
        public static readonly type = "[SUP - Temporary zone] Get zone details";
        constructor(public zoneId: string) {}
    }

    export class DeleteDraft {
        public static readonly type = "[SUP - Temporary zone] Delete draft";
        constructor(public draftId: string) {}
    }

    export class GetElevation {
        public static readonly type = "[SUP - Temporary zone] Get elevation";
        constructor(public mapEntity: MapEntity) {}
    }

    export class PublishZone {
        public static readonly type = "[SUP - Temporary zone] Publish zone";
        constructor(public draftId: string) {}
    }

    export class FinishZone {
        public static readonly type = "[SUP - Temporary zone] Finish zone";
        constructor(public zoneId: string) {}
    }

    export class GetDraftZones {
        public static readonly type = "[SUP - Temporary zone] Get draft zones";
        constructor(public dtmName: DtmName) {}
    }

    export class ClearElevation {
        public static readonly type = "[SUP - Temporary zone] Clear elevation values";
    }

    export class GetActiveZones {
        public static readonly type = "[SUP - Temporary zone] Get active zones";
        constructor(public dtmName: DtmName) {}
    }

    export class GetArchiveZones {
        public static readonly type = "[SUP - Temporary zone] Get archive zones";
        constructor(public dtmName: DtmName) {}
    }

    export class ClearSelectedZoneArea {
        public static readonly type = "[SUP - Temporary zone] Clear temporary zone selected area";
    }

    export class LoadMoreArchivedZones {
        public static readonly type = "[SUP - Temporary zone] Load more archived Zones";
        constructor(public dtmName: DtmName) {}
    }

    export class UpdateZoneFiltersData {
        public static readonly type = "[SUP - Temporary zone] Update zone filters data";
        constructor(public zoneFiltersData: ZoneFiltersData, public zoneType: TemporaryZoneType) {}
    }

    export class ClonePublishedZone {
        public static readonly type = "[SUP - Temporary zone] Clone published zone";
        constructor(public zoneId: string) {}
    }
}
