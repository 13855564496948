import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { DeviceHistory, MountedDevice } from "../../models/network-monitoring.models";
import { DEVICE_VIOLATION_HISTORY_DEFAULT_ELEMENTS_NUMBER } from "../../services/network-monitoring-api.service";
import { SignalViolationData } from "../event-tile/event-tile.component";

interface EventHistoryListComponentState {
    deviceHistoryList: DeviceHistory[];
    mountedDevice: MountedDevice[];
    isProcessing: boolean;
    isDeviceHistoryFullyLoaded: boolean;
}

@UntilDestroy()
@Component({
    selector: "supervisor-shared-lib-event-history-list[deviceHistoryList][mountedDevices]",
    templateUrl: "./event-history-list.component.html",
    styleUrls: ["./event-history-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class EventHistoryListComponent {
    @Input() public set deviceHistoryList(value: DeviceHistory[] | undefined) {
        this.localStore.patchState({ deviceHistoryList: value || [] });
    }
    @Input() public set mountedDevices(value: MountedDevice[] | undefined) {
        if (value) {
            this.localStore.patchState({ mountedDevice: value });
            this.deviceControl.setValue(value[0].id, { emitEvent: false });
        }
    }
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set isDeviceHistoryFullyLoaded(value: BooleanInput) {
        this.localStore.patchState({ isDeviceHistoryFullyLoaded: coerceBooleanProperty(value) });
    }

    @Output() public readonly deviceSelect = new EventEmitter<string>();
    @Output() public readonly contentLoad = new EventEmitter<string>();
    @Output() public readonly formSave = new EventEmitter<SignalViolationData>();

    protected readonly DEVICE_VIOLATION_HISTORY_DEFAULT_ELEMENTS_NUMBER = DEVICE_VIOLATION_HISTORY_DEFAULT_ELEMENTS_NUMBER;

    protected readonly deviceControl = new FormControl<string | null>(null);
    protected readonly deviceHistoryList$ = this.localStore.selectByKey("deviceHistoryList");
    protected readonly mountedDevices$ = this.localStore.selectByKey("mountedDevice");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly isDeviceHistoryFullyLoaded$ = this.localStore.selectByKey("isDeviceHistoryFullyLoaded");

    constructor(private readonly localStore: LocalComponentStore<EventHistoryListComponentState>) {
        this.localStore.setState({ deviceHistoryList: [], mountedDevice: [], isProcessing: false, isDeviceHistoryFullyLoaded: false });

        this.deviceControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            if (!this.deviceControl.value) {
                return;
            }
            this.deviceSelect.emit(this.deviceControl.value);
        });
    }

    public get deviceControlValue(): string | null {
        return this.deviceControl.value;
    }
}
