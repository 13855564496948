import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { DtmStats } from "../../models/shared-supervisor-client.models";

interface DtmAreaComponentState {
    dtmAreas: string[];
    selectedAreaName: string | undefined;
    dtmStats: Record<string, DtmStats> | undefined;
}

enum DtmStateLevel {
    Success = "Success",
    Warning = "Warning",
    High = "High",
    Danger = "Danger",
}

const WORKING_DEVICES_WARNING_MEDIUM_THRESHOLD = 0.75;
const WORKING_DEVICES_WARNING_HIGH_THRESHOLD = 0.5;

@Component({
    selector: "supervisor-shared-lib-dtm-area[dtmAreas]",
    templateUrl: "./dtm-area.component.html",
    styleUrls: ["./dtm-area.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class DtmAreaComponent {
    @Input() public set dtmAreas(value: string[] | undefined) {
        this.localStore.patchState({ dtmAreas: value ?? [] });
    }
    @Input() public set selectedAreaName(value: string | undefined) {
        this.localStore.patchState({ selectedAreaName: value });
    }
    @Input() public set dtmStats(value: Record<string, DtmStats> | undefined) {
        this.localStore.patchState({ dtmStats: value });
    }

    @Output() public readonly dtmSelect = new EventEmitter<string>();

    protected readonly dtmAreas$ = this.localStore.selectByKey("dtmAreas");
    protected readonly selectedAreaName$ = this.localStore.selectByKey("selectedAreaName");
    protected readonly dtmStats$ = this.localStore.selectByKey("dtmStats");

    constructor(private readonly localStore: LocalComponentStore<DtmAreaComponentState>) {
        this.localStore.setState({ dtmAreas: [], selectedAreaName: undefined, dtmStats: undefined });
    }

    protected selectDtmArea(dtmName: string): void {
        this.localStore.patchState({ selectedAreaName: dtmName });
        this.dtmSelect.emit(dtmName);
    }

    protected getFlightsOutsideMissionLevel(dtmStats: DtmStats | undefined): DtmStateLevel | undefined {
        if (!dtmStats) {
            return;
        }
        const flightsOutsideMission = dtmStats.mission.flightsOutsideMission;

        if (flightsOutsideMission > 1) {
            return DtmStateLevel.Danger;
        }

        if (flightsOutsideMission === 1) {
            return DtmStateLevel.Warning;
        }

        return DtmStateLevel.Success;
    }

    protected getDevicesViolatedLevel(dtmStats: DtmStats | undefined): DtmStateLevel | undefined {
        if (!dtmStats) {
            return;
        }
        const violatedDevices = dtmStats.device.violated;
        const totalDevices = dtmStats.device.total;
        const workingDevices = (totalDevices - violatedDevices) / totalDevices;

        if (workingDevices === 1) {
            return DtmStateLevel.Success;
        }

        if (workingDevices >= WORKING_DEVICES_WARNING_MEDIUM_THRESHOLD) {
            return DtmStateLevel.Warning;
        }

        if (workingDevices >= WORKING_DEVICES_WARNING_HIGH_THRESHOLD) {
            return DtmStateLevel.High;
        }

        return DtmStateLevel.Danger;
    }
}
