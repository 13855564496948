import { AuthorityAcceptationStatus, MissionType } from "@dtm-frontend/shared/mission";
import { MissionPlanRouteSection, TimeRange } from "@dtm-frontend/shared/ui";
import { Message } from "../../shared/index";

export enum MissionProcessingPhase {
    Accepted = "ACCEPTED",
    Rejected = "REJECTED",
    Waiting = "WAITING",
}

export interface MissionFilters {
    dtmArea?: string[] | null;
    authorityUnits?: string[] | null;
    zoneDesignators?: string[] | null;
    sort?: string | null;
    flightDateFrom?: Date | null;
    flightDateTo?: Date | null;
    phase?: MissionProcessingPhase | null;
    authorityUnitAcceptationStatuses?: AuthorityAcceptationStatus | null;
}

export interface MissionAttachment {
    fileId: string;
    contentType: string;
    name: string;
    createdAt: Date;
}

export interface Mission {
    id: string;
    missionId: string;
    name: string;
    phase: MissionProcessingPhase;
    missionSubmittedAt: Date;
    flightStartAt: Date;
    flightFinishAt: Date;
    flightStartAtMax: Date;
    flightStartAtMin: Date;
    flightFinishAtMin: Date;
    flightFinishAtMax: Date;
    flightType: MissionType;
    priority?: number;
    isNew: boolean;
    dtmNames: string[];
    routeId: string;
    routeSections?: MissionPlanRouteSection[];
    systemVerificationId: string;
    pilotName: string;
    operatorName: string;
    uavName: string;
    neighbourhoodType?: string;
    isPathBased: boolean;
    pilotRequest?: {
        isPriority: boolean;
        reason: string;
        createdAt: Date;
        attachmentList: MissionAttachment[];
    };
    supervisorMessage?: Message;
    createdDate: Date;
    plannedStartTime?: TimeRange;
    plannedEndTime?: TimeRange;
}

export enum MissionStatusType {
    Mission = "Mission",
    Plan = "Plan",
}

export interface PlannedMissionFilters {
    dtmArea?: string[];
    authorityUnits?: string[];
    sort: string | null;
    flightDateFrom: Date | null;
    flightDateTo: Date | null;
}

export interface PhaseFilters extends PlannedMissionFilters {
    phase: MissionProcessingPhase;
}

export enum PlannedMissionErrorType {
    Unknown = "Unknown",
    CannotGetMissionList = "CannotGetMissionList",
    CannotChangeMissionPhase = "CannotChangeMissionPhase",
    CannotGetMissionRoute = "CannotGetMissionRoute",
    CannotUpdateMissionPriority = "CannotUpdateMissionPriority",
    CannotAcceptMission = "CannotAcceptMission",
    CannotRejectMission = "CannotRejectMission",
    CannotGetNearbyMissionList = "CannotGetNearbyMissionList",
    CannotUpdateSupervisorNote = "CannotUpdateSupervisorNote",
    CannotGetAttachment = "CannotGetAttachment",
    CannotGetMissionPlanData = "CannotGetMissionPlanData",
    CannotGetAirspaceElements = "CannotGetAirspaceElements",
}

export interface PlannedMissionError {
    type: PlannedMissionErrorType;
}

export interface NearbyMissionFilters {
    missionType: MissionType[];
    executionTime: ExecutionTime | null;
    missionArea: MissionArea | null;
}

export enum ExecutionTime {
    SameTime = "SameTime",
    Quater = "Quater",
}

export enum MissionArea {
    Collision = "COLLISION",
    Nearby = "NEARBY",
    Dtm = "DTM",
}

export enum NeighbourhoodType {
    Collision = "COLLISION",
    Nearby = "NEARBY",
    Dtm = "DTM",
    Other = "OTHER",
}

export interface PhasePayloadData {
    systemVerificationId: string;
    missionPhase: MissionProcessingPhase;
    comment?: string;
    reviewerUnitId?: string;
}

export interface PriorityPayloadData {
    priority: number;
    systemVerificationId: string;
}

export interface NoteData {
    systemVerificationId: string;
    message: string;
}
