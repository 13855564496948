import { InjectionToken } from "@angular/core";

export interface SharedEndpoints {
    getCapabilities: string;
}

export const SHARED_ENDPOINTS = new InjectionToken<SharedEndpoints>("Shared endpoints");

// TODO: DTM-5282 remove it after old DTM SUP will be disabled
export const IS_OLD_DTM_SUP = new InjectionToken<boolean>("Is old DTM SUP");
