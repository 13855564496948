import { SearchAirspaceElementsOptions } from "@dtm-frontend/shared/map/geo-zones";
import { MissionPlanAnalysisStatus, MissionProcessingPhase } from "@dtm-frontend/shared/mission";
import { BBox } from "@turf/helpers";
import { DtmName } from "../../planned-missions/models/mission.models";
import { MissionsEvents } from "../../shared/models/shared-supervisor-client.models";
import {
    AlertPayloadData,
    Checkin,
    CheckinStatus,
    MissionListType,
    OverrideMissionFormValue,
    ProceedingMission,
} from "../models/operational.situation.models";

export namespace OperationalSituationActions {
    export class GetMissionAlerts {
        public static readonly type = "[SUP - Operational Situation] Get mission alerts";
        constructor(public dtmName: string) {}
    }

    export class GetMissions {
        public static readonly type = "[SUP - Operational Situation] Get missions";
        constructor(public dtmName: string) {}
    }

    export class GetMission {
        public static readonly type = "[SUP - Operational Situation] Get mission";
        constructor(public missionId: string, public eventType: MissionsEvents) {}
    }

    export class UpdateIncomingMissions {
        public static readonly type = "[SUP - Operational Situation] Update incoming missions";
        constructor(public mission: ProceedingMission) {}
    }

    export class MoveMissionToFinished {
        public static readonly type = "[SUP - Operational Situation] Move mission to finished list";
        constructor(public missionId: string, public fromListType: MissionListType, public phase?: MissionProcessingPhase) {}
    }

    export class MoveMissionToRealization {
        public static readonly type = "[SUP - Operational Situation] Move mission to realization";
        constructor(public mission: ProceedingMission) {}
    }

    export class StartMissionRealization {
        public static readonly type = "[SUP - Operational Situation] Start mission realization";
        constructor(public missionId: string) {}
    }

    export class CloseMissionAlert {
        public static readonly type = "[SUP - Operational Situation] Close mission alert";
        constructor(public payloadData: AlertPayloadData) {}
    }

    export class TacticalMissionsWatch {
        public static readonly type = "[SUP - Operational Situation] Tactical missions watch";
        constructor(public dtmName: string) {}
    }

    export class RejectMission {
        public static readonly type = "[SUP - Operational Situation] Reject mission";
        constructor(public missionId: string, public listType: MissionListType, public information: string) {}
    }

    export class RejectIncomingMission {
        public static readonly type = "[SUP - Operational Situation] Reject incoming mission";
        constructor(public missionId: string) {}
    }

    export class RejectProceedingMission {
        public static readonly type = "[SUP - Operational Situation] Reject proceeding mission";
        constructor(public missionId: string) {}
    }

    export class MarkViolation {
        public static readonly type = "[SUP - Operational Situation] Mark violation";
        constructor(public missionId: string) {}
    }

    export class OverrideMissionTime {
        public static readonly type = "[SUP - Operational Situation] Override mission time";
        constructor(public missionId: string, public data: OverrideMissionFormValue, public dtmName: string) {}
    }

    export class StopTacticalMissionsWatch {
        public static readonly type = "[SUP - Operational Situation] Stop Tactical missions watch";
    }

    export class StartFlightPositionsUpdateWatch {
        public static readonly type = "[SUP - Operational Situation] Start Flight Position Watch";
        constructor(public bbox: BBox) {}
    }

    export class StopFlightPositionUpdatesWatch {
        public static readonly type = "[SUP - Operational Situation] Stop Flight Position Watch";
    }

    export class GetSelectedMissionDetails {
        public static readonly type = "[SUP - Operational Situation] Get mission details";
        constructor(public missionId: string, public planId: string) {}
    }

    export class ClearMapEntities {
        public static readonly type = "[SUP - Operational Situation] Clear map entities";
    }

    export class StartSectionDeactivationEventWatch {
        public static readonly type = "[SUP - Operational Situation] Start section deactivation event watch";
        constructor(public dtmName: string) {}
    }

    export class StopSectionDeactivationEventWatch {
        public static readonly type = "[SUP - Operational Situation] Stop section deactivation event watch";
    }

    export class GetCurrentMissionsDetails {
        public static readonly type = "[SUP - Operational Situation] Get current missions details";
        constructor(public missionIds: string[]) {}
    }

    export class RefreshCurrentMissionDetails {
        public static readonly type = "[SUP - Operational Situation] Refresh current missions details";
        constructor(public missionId: string) {}
    }

    export class UpdateCheckinList {
        public static readonly type = "[SUP - Operational Situation] Update checkin list";
        constructor(public checkin: Checkin, public eventType: MissionsEvents) {}
    }

    export class GetCheckins {
        public static readonly type = "[SUP - Operational Situation] Get checkins";
        constructor(public dtmName: string) {}
    }

    export class PatchSingleCheckin {
        public static readonly type = "[SUP - Operational Situation] Patch single checkin";
        constructor(public checkin: Checkin) {}
    }

    export class ChangeCheckinStatus {
        public static readonly type = "[SUP - Operational Situation] Change checkin status";
        constructor(public checkinId: string, public checkinStatus: CheckinStatus) {}
    }

    export class MoveCompletedCheckin {
        public static readonly type = "[SUP - Operational Situation] Move completed checkin";
        constructor(public checkinId: string) {}
    }

    export class UpdateSelectedCheckin {
        public static readonly type = "[SUP - Operational Situation] Update selected operation id";
        constructor(public checkin: Checkin) {}
    }

    export class SearchAirspaceElements {
        public static readonly type = "[SUP - Operational Situation] Search airspace elements";
        constructor(public options: SearchAirspaceElementsOptions) {}
    }

    export class UpdateMissionPlanAnalysisStatus {
        public static readonly type = "[SUP - Operational Situation] Update mission plan analysis status";
        constructor(public status: MissionPlanAnalysisStatus) {}
    }

    export class GetMissionPlanAnalysis {
        public static readonly type = "[SUP - Operational Situation] Get mission plan analysis";
        constructor(public planId: string) {}
    }

    export class GetMissionPlanData {
        public static readonly type = "[SUP - Operational Situation] Get mission plan data";
        constructor(public planId: string) {}
    }

    export class UpdateSelectedDtmName {
        public static readonly type = "[SUP - Operational Situation] Update selected DTM name";
        constructor(public dtmName: DtmName | undefined) {}
    }

    export class ClearAirspaceElements {
        public static readonly type = "[SUP - Operational Situation] Clear airspace elements";
    }

    export class ClearMissionAnalysisAndCapabilities {
        public static readonly type = "[SUP - Operational Situation] Clear mission analysis and capabilities";
    }
}
