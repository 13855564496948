import { SearchAirspaceElementsOptions } from "@dtm-frontend/shared/map/geo-zones";
import { MissionPlanAnalysisStatus } from "@dtm-frontend/shared/mission";
import {
    DtmArea,
    Mission,
    MissionFilters,
    MissionProcessingPhase,
    MissionStatusType,
    NearbyMissionFilters,
    NoteData,
    PhasePayloadData,
    PriorityPayloadData,
} from "../models/mission.models";

export namespace MissionActions {
    export class SetDtmAreas {
        public static readonly type = "[SUP - Mission] Set dtm areas";
        constructor(public dtmAreas: DtmArea[]) {}
    }

    export class ClearNewMissionAvailability {
        public static readonly type = "[SUP - Mission] Clear new mission availability";
    }

    export class ClearNearbyMissionAvailability {
        public static readonly type = "[SUP - Mission] Clear nearby mission availability";
    }

    export class PlannedMissionsWatch {
        public static readonly type = "[SUP - Mission] Planned missions watch";
    }

    export class PlannedMissionsWatchByAuthorityUnit {
        public static readonly type = "[SUP - Mission] Planned missions watch by authority unit";
        constructor(public unitId: string, public authorityUnitsZones: string[]) {}
    }

    export class NearbyMissionsWatch {
        public static readonly type = "[SUP - Mission] Nearby missions watch";
        constructor(public planId: string) {}
    }

    export class StopPlannedMissionsWatch {
        public static readonly type = "[SUP - Mission] Stop planned missions watch";
    }

    export class StopNearbyMissionsWatch {
        public static readonly type = "[SUP - Mission] Stop nearby missions watch";
    }

    export class GetAllMissions {
        public static readonly type = "[SUP - Mission] Get all plans";
    }

    export class GetMissionPlanList {
        public static readonly type = "[SUP - Mission] Get missions list";
        constructor(public missionPhase: MissionProcessingPhase, public submittedPlanId?: string) {}
    }

    export class UpdateMissionFilters {
        public static readonly type = "[SUP - Mission] Update mission filters";
        constructor(public missionFilters: MissionFilters) {}
    }

    export class UpdateMissionPriority {
        public static readonly type = "[SUP - Mission] Update mission priority";
        constructor(public priorityData: PriorityPayloadData) {}
    }

    export class PartitionMissionsByPhase {
        public static readonly type = "[SUP - Mission] Partition missions by phase";
        constructor(public missionList: Mission[], public phase: MissionProcessingPhase) {}
    }

    export class ChangeMissionPhase {
        public static readonly type = "[SUP - Mission] Change mission phase";
        constructor(public phasePayloadData: PhasePayloadData) {}
    }

    export class GetMissionRoute {
        public static readonly type = "[SUP - Mission] Get mission route";
        constructor(public routeId: string) {}
    }

    export class ClearMissionData {
        public static readonly type = "[SUP - Mission] Clear mission data";
        constructor(public routeId: string) {}
    }

    export class GetNearbyMissions {
        public static readonly type = "[SUP - Mission] Get nearby missions";
        constructor(public planId: string, public nearbyMissionFilters?: NearbyMissionFilters) {}
    }

    export class UpdateSupervisorNote {
        public static readonly type = "[SUP - Mission] Update supervisor note";
        constructor(public noteData: NoteData) {}
    }

    export class GetNearbyMissionRoute {
        public static readonly type = "[SUP - Mission] Get nearby mission route";
        constructor(public routeId: string) {}
    }

    export class GetRequestPilotAttachment {
        public static readonly type = "[SUP - Mission] Get pilot request attachment file";
        constructor(public attachmentId: string, public selectedPlanId: string) {}
    }

    export class GetMissionPlanData {
        public static readonly type = "[SUP - Mission] Get mission plan data";
        constructor(public planId: string) {}
    }

    export class GetNearbyMissionData {
        public static readonly type = "[SUP - Mission] Get nearby mission plan data";
        constructor(public planId: string) {}
    }

    export class SearchAirspaceElements {
        public static readonly type = "[SUP - Mission] Search airspace elements";
        constructor(public options: SearchAirspaceElementsOptions) {}
    }

    export class UpdateMissionPlanAnalysisStatus {
        public static readonly type = "[SUP - Mission] Update mission plan analysis status";
        constructor(public status: MissionPlanAnalysisStatus, public type: MissionStatusType) {}
    }

    export class ClearAirspaceElements {
        public static readonly type = "[SUP - Mission] Clear airspace elements";
    }

    export class GetMissionPlanAnalysis {
        public static readonly type = "[SUP - Mission] Get mission plan analysis";
        constructor(public planId: string, public type: MissionStatusType) {}
    }

    export class ClearMissionAnalysisAndCapabilities {
        public static readonly type = "[SUP - Operational Situation] Clear mission analysis and capabilities";
    }
}
