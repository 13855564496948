import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Router } from "@angular/router";
import { CameraHelperService } from "@dtm-frontend/shared/map/cesium";
import { WeatherActions, WeatherViewMode } from "@dtm-frontend/shared/map/geo-weather";
import { AirspaceElement, GeoZonesActions, GeoZonesState, ZoneTimesSetting } from "@dtm-frontend/shared/map/geo-zones";
import { MissionType } from "@dtm-frontend/shared/mission";
import { ButtonTheme, ConfirmationDialogComponent, RouteData } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { FunctionUtils, LocalComponentStore, RxjsUtils, SpatialUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, Store, ofActionDispatched } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { Observable, Subject, combineLatestWith, firstValueFrom, map, merge, share, switchMap, tap } from "rxjs";
import { first } from "rxjs/operators";
import { GlobalAlarmActions } from "../../../global-alarm/state/global-alarm.actions";
import { GlobalAlarmState } from "../../../global-alarm/state/global-alarm.state";
import { Alarm } from "../../../shared/models/shared-supervisor-client.models";
import { fallbackDtmArea } from "../../../shared/utils/dtm-bbox-features-map";
import { SupUserActions, SupUserState } from "../../../sup-user";
import {
    DtmArea,
    ExecutionTime,
    Mission,
    MissionArea,
    MissionFilters,
    MissionProcessingPhase,
    NearbyMissionFilters,
    NoteData,
    PhasePayloadData,
    PriorityPayloadData,
} from "../../models/mission.models";
import { MissionActions } from "../../state/mission.actions";
import { MissionState } from "../../state/mission.state";
import { PriorityDialogComponent } from "../priority-dialog/priority-dialog.component";
import { WithdrawApprovalConfirmationDialogComponent } from "../withdraw-aproval-confirmation-dialog/withdraw-approval-confirmation-dialog.component";

interface PlannedMissionContainerComponentState {
    isPlannedMissionFolded: boolean;
    isOtherMissionPanelFolded: boolean;
    shouldPilotPanelClose: boolean;
    selectedMissionPlanId: string | undefined;
    selectedNearbyMissionId: string | undefined;
}

enum SidebarType {
    LeftSidebar = "LeftSidebar",
    RightSidebar = "RightSidebar",
}

const NearbyMissionDefaultFilters: NearbyMissionFilters = {
    missionType: [MissionType.BVLOS, MissionType.VLOS],
    executionTime: ExecutionTime.Quater,
    missionArea: MissionArea.Nearby,
};

interface DialogSettings {
    titleText: string;
    subtitleTextKey: string;
    confirmButtonKey: string;
    cancelButtonKey: string;
    buttonTheme: ButtonTheme;
}

const MissionPhaseDialogSettings: { [key: string]: DialogSettings } = {
    [MissionProcessingPhase.Accepted]: {
        titleText: "dtmSupPlannedMissions.plannedMissionContainer.dialogAcceptedTitleText",
        subtitleTextKey: "dtmSupPlannedMissions.plannedMissionContainer.dialogSubtitleText",
        confirmButtonKey: "dtmSupPlannedMissions.plannedMissionContainer.dialogAcceptedConfirmButtonLabel",
        cancelButtonKey: "dtmSupPlannedMissions.plannedMissionContainer.dialogCancelButtonLabel",
        buttonTheme: ButtonTheme.Primary,
    },
    [MissionProcessingPhase.Rejected]: {
        titleText: "dtmSupPlannedMissions.plannedMissionContainer.dialogRejectedTitleText",
        subtitleTextKey: "dtmSupPlannedMissions.plannedMissionContainer.dialogSubtitleText",
        confirmButtonKey: "dtmSupPlannedMissions.plannedMissionContainer.dialogRejectedConfirmButtonLabel",
        cancelButtonKey: "dtmSupPlannedMissions.plannedMissionContainer.dialogCancelButtonLabel",
        buttonTheme: ButtonTheme.Warn,
    },
};

@UntilDestroy()
@Component({
    selector: "supervisor-shared-lib-planned-mission-container",
    templateUrl: "./planned-mission-container.component.html",
    styleUrls: ["./planned-mission-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PlannedMissionContainerComponent implements OnDestroy, AfterViewInit, OnInit {
    protected readonly isPlannedMissionFolded$ = this.localStore.selectByKey("isPlannedMissionFolded");
    protected readonly isOtherMissionPanelFolded$ = this.localStore.selectByKey("isOtherMissionPanelFolded");
    protected readonly selectedMissionPlanId$ = this.localStore.selectByKey("selectedMissionPlanId");
    protected readonly selectedNearbyMissionId$ = this.localStore.selectByKey("selectedNearbyMissionId");
    protected readonly shouldPilotPanelClose$ = this.localStore.selectByKey("shouldPilotPanelClose");
    protected readonly waitingMissions$ = this.store.select(MissionState.waitingMissions).pipe(share());
    protected readonly rejectedMissions$ = this.store.select(MissionState.rejectedMissions).pipe(share());
    protected readonly acceptedMissions$ = this.store.select(MissionState.acceptedMissions).pipe(share());
    protected readonly nearbyMissions$ = this.store.select(MissionState.nearbyMissions);
    protected readonly missionDefaultsFilters$ = this.store.select(MissionState.missionDefaultFilters);
    protected readonly areNewMissionsAvailable$ = this.store.select(MissionState.areNewMissionsAvailable);
    protected readonly areNearbyMissionsAvailable$ = this.store.select(MissionState.areNearbyMissionsAvailable);
    protected readonly dtmAreas$ = this.store.select(MissionState.dtmAreas);
    protected readonly isProcessing$ = this.store.select(MissionState.isProcessing);
    protected readonly isMissionListProcessing$ = this.store.select(MissionState.isMissionListProcessing);
    protected readonly isPlanRouteProcessing$ = this.store.select(MissionState.isPlanRouteProcessing);
    protected readonly isNearbyMissionRouteProcessing$ = this.store.select(MissionState.isNearbyMissionRouteProcessing);
    protected readonly selectedNearbyMissionRoute$ = this.store.select(MissionState.selectedNearbyMissionRoute);
    protected readonly selectedMissionPlanRoute$ = this.store.select(MissionState.selectedMissionPlanRoute);
    protected readonly currentMissionPlanData$ = this.store.select(MissionState.currentMissionPlanData);
    protected readonly currentNearbyMissionData$ = this.store.select(MissionState.currentNearbyMissionData);
    protected readonly currentPlanAnalysisStatus$ = this.store.select(MissionState.currentPlanAnalysisStatus);
    protected readonly collisionZones$ = this.store.select(GeoZonesState.customZonesLayersData);
    protected readonly alarmList$ = this.store.select(GlobalAlarmState.alarms);
    protected readonly workspacePermissions$ = this.store
        .select(SupUserState.selectedWorkspace)
        .pipe(map((workspace) => workspace?.permissions));
    protected readonly selectedZoneId$ = this.store.select(GeoZonesState.selectedZoneId);
    protected readonly availableZoneDesignators$ = this.store
        .select(MissionState.dtmAreas)
        .pipe(map((areas) => areas?.map(({ name }) => name)));
    protected readonly SidebarType = SidebarType;
    protected readonly ZoneTimesSetting = ZoneTimesSetting;
    protected readonly NearbyMissionDefaultFilters = NearbyMissionDefaultFilters;
    protected readonly routeData$ = this.initRouteData();
    protected readonly isSelectedMission$ = this.selectedMissionPlanId$.pipe(map((value) => !!value));
    protected readonly zoomToRoute$ = new Subject<RouteData<Mission>>();
    protected readonly initialViewGeometry = fallbackDtmArea;

    constructor(
        private readonly localStore: LocalComponentStore<PlannedMissionContainerComponentState>,
        private readonly store: Store,
        private readonly transloco: TranslocoService,
        private readonly toastr: ToastrService,
        private readonly matDialog: MatDialog,
        private readonly router: Router,
        private readonly cameraHelperService: CameraHelperService,
        private readonly actions: Actions,
        private readonly translationHelperService: TranslationHelperService
    ) {
        this.localStore.setState({
            isPlannedMissionFolded: false,
            isOtherMissionPanelFolded: true,
            selectedMissionPlanId: undefined,
            selectedNearbyMissionId: undefined,
            shouldPilotPanelClose: false,
        });
    }

    public ngOnInit(): void {
        const workspace = this.store.selectSnapshot(SupUserState.selectedWorkspace);
        if (workspace) {
            this.store.dispatch(
                new MissionActions.PlannedMissionsWatchByAuthorityUnit(
                    workspace.authorityUnit.id,
                    workspace.authorityUnitZones.map((zone) => zone.designator)
                )
            );
        } else {
            this.store.dispatch(MissionActions.PlannedMissionsWatch);
        }
        this.store.dispatch([
            new GeoZonesActions.SetCustomZonesVisibility(true),
            new WeatherActions.ChangeWeatherVisibility(WeatherViewMode.Basic),
            new MissionActions.GetAllMissions(),
        ]);

        this.watchDispatchedActions();
        this.watchMissionListUpdatesAndRefreshSelectedPlan();
    }

    public async ngAfterViewInit() {
        // TODO:  DTM-5188 - remove waitForTranslation when DatesDiffPipe translation problem will be fixed
        this.translationHelperService
            .waitForTranslation("dtmUi.timeLabels.days")
            .pipe(first(), untilDestroyed(this))
            .subscribe(() => this.store.dispatch(GeoZonesActions.EnableAllGeoZones));

        this.store
            .select(SupUserState.selectedWorkspace)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(async ({ authorityUnitZones }) => this.flyToCombinedDtmAreas(authorityUnitZones.map((zone) => zone.designator)));

        const dtmNames = await firstValueFrom(
            this.store.select(MissionState.dtmAreas).pipe(
                first(FunctionUtils.isTruthy),
                map((areas) => areas.map((area) => area.name))
            )
        );
        await firstValueFrom(this.store.dispatch(new SupUserActions.GetUserDtmZonesData(dtmNames)));

        this.flyToCombinedDtmAreas(dtmNames);
    }

    private async flyToCombinedDtmAreas(dtnNames: string[]) {
        const dtmAreas = this.store.selectSnapshot(SupUserState.dtmZonesData).filter((zone) => dtnNames.includes(zone.designator ?? ""));
        if (!dtmAreas.length) {
            return;
        }
        const combinedDtmAreas = SpatialUtils.union(dtmAreas.map((area) => area.geometry));
        this.cameraHelperService.flyToGeoJSON(combinedDtmAreas ?? fallbackDtmArea);
    }

    public ngOnDestroy(): void {
        this.store.dispatch([
            MissionActions.ClearMissionData,
            MissionActions.StopPlannedMissionsWatch,
            MissionActions.StopNearbyMissionsWatch,
            MissionActions.ClearMissionAnalysisAndCapabilities,
            new WeatherActions.ChangeWeatherVisibility(WeatherViewMode.Hide),
            MissionActions.ClearNewMissionAvailability,
        ]);
    }

    protected navigateToOperationalSituation(dtm: DtmArea): void {
        this.router.navigate(["/operational-situation"], {
            queryParams: { dtmName: dtm.name },
        });
    }

    protected closeGlobalAlarm(alarm: Alarm): void {
        this.store.dispatch(new GlobalAlarmActions.DeleteAlarm(alarm.id));
    }

    protected changePilotMessagePanelState(): void {
        this.localStore.patchState({ shouldPilotPanelClose: false });
    }

    protected togglePanelFold(isFolded: boolean, panelType: SidebarType): void {
        if (panelType === SidebarType.LeftSidebar) {
            this.localStore.patchState({ isPlannedMissionFolded: !isFolded });

            return;
        }

        this.localStore.patchState({ isOtherMissionPanelFolded: !isFolded });
    }

    protected downloadAttachment(attachmentId: string): void {
        const selectedPlanId = this.localStore.selectSnapshotByKey("selectedMissionPlanId");

        if (!selectedPlanId) {
            return;
        }

        this.store
            .dispatch(new MissionActions.GetRequestPilotAttachment(attachmentId, selectedPlanId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(MissionState.attachmentError);

                if (error) {
                    this.toastr.error(this.transloco.translate("dtmSupPlannedMissions.plannedMissionContainer.attachmentErrorMessage"));
                }
            });
    }

    protected clearAvailabilityNearbyMission(): void {
        this.store.dispatch(MissionActions.ClearNearbyMissionAvailability);
    }

    protected filterMissions(filters: MissionFilters): void {
        this.store.dispatch(new MissionActions.UpdateMissionFilters(filters));
        this.localStore.patchState({ selectedMissionPlanId: undefined });
    }

    protected changeMissionPhase(payloadData: PhasePayloadData): void {
        this.openMissionPhaseConfirmationDialog(payloadData.missionPhase)
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new MissionActions.ChangeMissionPhase(payloadData))),
                tap(() => this.getTranslationKeysByMissionPhaseAndNotify(payloadData.missionPhase)),
                tap(() => this.localStore.patchState({ selectedMissionPlanId: undefined, shouldPilotPanelClose: true })),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected openWithdrawConfirmationDialog(systemVerificationId: string): void {
        this.matDialog
            .open<WithdrawApprovalConfirmationDialogComponent, null, string | false>(WithdrawApprovalConfirmationDialogComponent)
            .afterClosed()
            .subscribe((comment) => {
                if (comment === false) {
                    return;
                }

                this.store.dispatch(
                    new MissionActions.ChangeMissionPhase({
                        systemVerificationId,
                        comment,
                        missionPhase: MissionProcessingPhase.Rejected,
                    })
                );
            });
    }

    private openMissionPhaseConfirmationDialog(phase: MissionProcessingPhase): Observable<boolean> {
        const settings = MissionPhaseDialogSettings[phase];
        const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.transloco.translate(settings.titleText),
                confirmationText: this.transloco.translate(settings.subtitleTextKey),
                declineButtonLabel: this.transloco.translate(settings.cancelButtonKey),
                confirmButtonLabel: this.transloco.translate(settings.confirmButtonKey),
                theme: settings.buttonTheme,
            },
        });

        return dialogRef.afterClosed();
    }

    protected filterNearbyMissions(filters: NearbyMissionFilters): void {
        const selectedMissionId = this.localStore.selectSnapshotByKey("selectedMissionPlanId");

        if (!selectedMissionId) {
            return;
        }

        this.getNearbyMissions(selectedMissionId, filters);
    }

    protected selectMission(mission: Mission): void {
        this.store.dispatch([MissionActions.StopNearbyMissionsWatch, MissionActions.ClearNearbyMissionAvailability]);
        const previousSelectedPlanId = this.localStore.selectSnapshotByKey("selectedMissionPlanId");

        if (previousSelectedPlanId === mission.id) {
            return;
        }

        this.store.dispatch(new GeoZonesActions.SetSelectedZoneId(undefined));

        this.localStore.patchState({
            selectedMissionPlanId: mission.id,
            isOtherMissionPanelFolded: false,
            selectedNearbyMissionId: undefined,
            shouldPilotPanelClose: true,
        });

        this.getMissionRoute(mission.routeId);
        this.getNearbyMissions(mission.id, NearbyMissionDefaultFilters);
        this.getMissionPlanData(mission.id);
    }

    protected watchMissionListUpdatesAndRefreshSelectedPlan(): void {
        merge(this.waitingMissions$, this.acceptedMissions$, this.rejectedMissions$)
            .pipe(untilDestroyed(this))
            .subscribe((missions) => {
                const selectedMissionId = this.localStore.selectSnapshotByKey("selectedMissionPlanId");
                if (!selectedMissionId) {
                    return;
                }

                if (missions?.find(({ id }) => id === selectedMissionId)) {
                    this.getMissionPlanData(selectedMissionId);
                }
            });
    }

    protected selectNearbyMission(mission: Mission): void {
        const activeNearbyMissionId = this.localStore.selectSnapshotByKey("selectedNearbyMissionId");

        if (mission.missionId === activeNearbyMissionId) {
            this.localStore.patchState({ selectedNearbyMissionId: undefined });

            return;
        }
        this.localStore.patchState({ selectedNearbyMissionId: mission.missionId });
        this.getNearbyMissionRoute(mission.routeId);
        this.getNearbyMissionData(mission.id);
    }

    protected openEditPriorityDialog({ priority, systemVerificationId }: PriorityPayloadData): void {
        const dialogRef = this.matDialog.open(PriorityDialogComponent, {
            data: { priority, isPriorityProcessing$: this.isProcessing$ },
        });

        dialogRef.componentInstance.priority$
            .pipe(
                switchMap((newPriority: string) =>
                    this.store.dispatch(new MissionActions.UpdateMissionPriority({ priority: +newPriority, systemVerificationId }))
                ),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(MissionState.updatePriorityError);

                if (error) {
                    this.toastr.error(
                        this.transloco.translate("dtmSupPlannedMissions.plannedMissionContainer.missionPriorityErrorMessage")
                    );

                    return;
                }

                this.toastr.success(
                    this.transloco.translate("dtmSupPlannedMissions.plannedMissionContainer.missionPrioritySuccessMessage")
                );

                dialogRef.close();
            });
    }

    protected clearAvailabilityNewMission(): void {
        this.store.dispatch(MissionActions.ClearNewMissionAvailability);
    }

    protected zoomToRoute(selectedRouteId?: string) {
        let mission;
        let route;

        if (selectedRouteId) {
            mission = this.store.selectSnapshot(MissionState.nearbyMissions)?.find(({ routeId }) => routeId === selectedRouteId);
        }

        if (!mission?.routeSections) {
            route = this.store.selectSnapshot(MissionState.selectedMissionPlanRoute);
            selectedRouteId = route?.routeId;
        }

        if (!selectedRouteId || !(mission || route)) {
            return;
        }

        const routeData: RouteData<Mission> = {
            route: {
                routeId: selectedRouteId,
                sections: mission?.routeSections ?? route?.sections ?? [],
                isPathBased: !!mission?.isPathBased,
            },
        };

        this.zoomToRoute$.next(routeData);
    }

    protected updateSupervisorNote(noteData: NoteData): void {
        this.store
            .dispatch(new MissionActions.UpdateSupervisorNote(noteData))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(MissionState.updateSupervisorNoteError);

                if (error) {
                    this.toastr.error(this.transloco.translate("dtmSupPlannedMissions.plannedMissionContainer.supUpdateNoteErrorMessage"));

                    return;
                }

                this.toastr.success(this.transloco.translate("dtmSupPlannedMissions.plannedMissionContainer.supUpdateNoteSuccessMessage"));
            });
    }

    protected selectZone(zone: AirspaceElement) {
        if (this.store.selectSnapshot(GeoZonesState.selectedZoneId) === zone?.id) {
            this.store.dispatch(new GeoZonesActions.SetSelectedZoneId(undefined));

            return;
        }

        this.cameraHelperService.flyToGeoJSON(zone.geometry);
        this.store.dispatch(new GeoZonesActions.SetSelectedZoneId(zone?.id));
    }

    private getMissionPlanData(planId: string): void {
        this.store
            .dispatch(new MissionActions.GetMissionPlanData(planId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(MissionState.missionPlanDataError);

                if (error) {
                    this.toastr.error(
                        this.transloco.translate("dtmSupPlannedMissions.plannedMissionContainer.missionPlanDataErrorMessage")
                    );
                }
            });
    }

    private getNearbyMissionRoute(missionRouteId: string): void {
        this.store
            .dispatch(new MissionActions.GetNearbyMissionRoute(missionRouteId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(MissionState.nearbyMissionRouteError);

                if (error) {
                    this.toastr.error(
                        this.transloco.translate("dtmSupPlannedMissions.plannedMissionContainer.nearbyMissionRouteErrorMessage")
                    );
                }
            });
    }

    private getNearbyMissionData(planId: string): void {
        this.store
            .dispatch(new MissionActions.GetNearbyMissionData(planId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(MissionState.nearbyMissionDataError);

                if (error) {
                    this.toastr.error(
                        this.transloco.translate("dtmSupPlannedMissions.plannedMissionContainer.nearbyMissionDataErrorMessage")
                    );
                }
            });
    }

    private getNearbyMissions(missionId: string, filters: NearbyMissionFilters): void {
        this.store
            .dispatch(new MissionActions.GetNearbyMissions(missionId, filters))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(MissionState.nearbyMissionsError);

                if (error) {
                    this.toastr.error(this.transloco.translate("dtmSupPlannedMissions.plannedMissionContainer.nearbyMissionsErrorMessage"));

                    return;
                }
            });
    }

    private getMissionRoute(routeId: string): void {
        this.store
            .dispatch(new MissionActions.GetMissionRoute(routeId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(MissionState.missionPlanRouteError);

                if (error) {
                    this.toastr.error(this.transloco.translate("dtmSupPlannedMissions.plannedMissionContainer.missionRouteErrorMessage"));
                }
            });
    }

    private initRouteData(): Observable<RouteData<Mission> | undefined> {
        return this.selectedMissionPlanRoute$.pipe(
            RxjsUtils.filterFalsy(),
            map((data, uniqueRouteId) => ({
                route: data,
                data: this.getAllMissionsSnapshot().find(({ routeId }) => routeId === data.routeId),
                isMain: true,
                uniqueRouteId,
            })),
            switchMap((data) =>
                this.nearbyMissions$.pipe(
                    map((nearbyMissionData, uniqueNearbyRoutesId) => ({
                        ...data,
                        uniqueNearbyRoutesId,
                        nearbyMissionsData: nearbyMissionData?.map((mission) => ({
                            route: { routeId: mission.routeId, sections: mission.routeSections ?? [], isPathBased: mission.isPathBased },
                            data: mission,
                        })),
                    }))
                )
            ),
            combineLatestWith(this.selectedNearbyMissionId$),
            map(([data, selectedNearbyMissionId]) => ({
                ...data,
                nearbyMissionsData: data.nearbyMissionsData?.map((mission) => ({
                    ...mission,
                    isSelected: mission.data.missionId === selectedNearbyMissionId,
                })),
            }))
        );
    }

    private getAllMissionsSnapshot() {
        const acceptedMissions = this.store.selectSnapshot(MissionState.acceptedMissions) ?? [];
        const waitingMissions = this.store.selectSnapshot(MissionState.waitingMissions) ?? [];
        const rejectedMissions = this.store.selectSnapshot(MissionState.rejectedMissions) ?? [];

        return [...acceptedMissions, ...waitingMissions, ...rejectedMissions];
    }

    private getTranslationKeysByMissionPhaseAndNotify(missionPhase: MissionProcessingPhase) {
        if (missionPhase === MissionProcessingPhase.Accepted) {
            const error = this.store.selectSnapshot(MissionState.acceptanceMissionError);

            if (error) {
                this.toastr.error(this.transloco.translate("dtmSupPlannedMissions.plannedMissionContainer.missionAcceptanceErrorMessage"));

                return;
            }

            this.toastr.success(this.transloco.translate("dtmSupPlannedMissions.plannedMissionContainer.missionAcceptanceSuccessMessage"));
        }

        if (missionPhase === MissionProcessingPhase.Rejected) {
            const error = this.store.selectSnapshot(MissionState.rejectionMissionError);

            if (error) {
                this.toastr.error(this.transloco.translate("dtmSupPlannedMissions.plannedMissionContainer.missionRejectionErrorMessage"));

                return;
            }

            this.toastr.success(this.transloco.translate("dtmSupPlannedMissions.plannedMissionContainer.missionRejectionSuccessMessage"));
        }
    }

    private watchDispatchedActions(): void {
        this.actions
            .pipe(ofActionDispatched(MissionActions.GetMissionPlanAnalysis, MissionActions.SearchAirspaceElements), untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(MissionState.missionAnalysisDataError);

                if (error) {
                    this.toastr.error(this.transloco.translate("dtmSupPlannedMissions.plannedMissionContainer.getPlanDataErrorMessage"));
                }
            });
    }
}
