import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { SectorStatus } from "../../../../models/network-monitoring.models";

interface InstallationStatusComponentState {
    statuses: SectorStatus[];
}

@Component({
    selector: "supervisor-shared-lib-installation-status[status]",
    templateUrl: "./installation-status.component.html",
    styleUrls: ["./installation-status.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class InstallationStatusComponent {
    @Input() public set status(value: SectorStatus[] | SectorStatus | undefined) {
        if (!value) {
            this.localStore.patchState({ statuses: [] });

            return;
        }

        this.localStore.patchState({ statuses: Array.isArray(value) ? value : [value] });
    }

    protected readonly statuses$ = this.localStore.selectByKey("statuses");
    protected readonly SectorStatus = SectorStatus;

    constructor(private readonly localStore: LocalComponentStore<InstallationStatusComponentState>) {
        this.localStore.setState({ statuses: [] });
    }
}
