import { ChangeDetectionStrategy, Component } from "@angular/core";
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from "@angular/router";
import { DeviceSize, DeviceSizeService, UIActions, UIState } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { CapabilitiesState, SupFeatures } from "@dtm-frontend/supervisor-shared-lib";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";

interface MenuComponentState {
    activeRoute: string | undefined;
    previousActiveRoute: string | undefined;
}

const BASE_ROUTE = "/";
const REDIRECT_BASE_URL = "/planned-missions";

@UntilDestroy()
@Component({
    selector: "dtm-sup-client-menu",
    templateUrl: "./menu.component.html",
    styleUrls: ["./menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MenuComponent {
    protected readonly isCollapsed$ = this.store.select(UIState.isMenuCollapsed);
    protected readonly activeRoute$ = this.localStore.selectByKey("activeRoute");
    protected readonly isMissionSearchFeatureAvailable$ = this.store.select(CapabilitiesState.isFeatureAvailable(SupFeatures.MissionList));

    constructor(
        private readonly store: Store,
        private readonly deviceSizeService: DeviceSizeService,
        private router: Router,
        private readonly localStore: LocalComponentStore<MenuComponentState>
    ) {
        this.localStore.setState({
            activeRoute: undefined,
            previousActiveRoute: undefined,
        });

        this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
            if (event instanceof NavigationStart) {
                const queryParamsIndex = event.url.indexOf("?");
                this.localStore.patchState((state) => ({
                    previousActiveRoute: state.activeRoute,
                    activeRoute: event.url === BASE_ROUTE ? REDIRECT_BASE_URL : this.getActiveRoute(queryParamsIndex, event.url),
                }));
            } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
                this.localStore.patchState((state) => ({
                    activeRoute: event instanceof NavigationEnd ? state.activeRoute : state.previousActiveRoute,
                    previousActiveRoute: undefined,
                }));
            }
        });
    }

    public collapseMenu(): void {
        if (this.deviceSizeService.isSize(DeviceSize.SmartphoneWide, DeviceSize.Smartphone)) {
            this.store.dispatch(new UIActions.SetMenuCollapsedState(true));
        }
    }

    private getActiveRoute(queryParamsIndex: number, url: string): string {
        return queryParamsIndex > 0 ? url.slice(0, queryParamsIndex) : url;
    }
}
