import { AuthorityUnitType, SupUserProfile, WorkspacePermission } from "../models/sup-user.models";

export interface SupUserProfileResponseBody {
    userId: string;
    workspaces: {
        id: string;
        authorityUnit: {
            id: string;
            type: AuthorityUnitType;
            name: string;
        };
        name: string;
        imageUrl: string;
        manageRestOfCountry: boolean;
        authorityUnitsZones: {
            designator: string;
        }[];
    }[];
}

export function convertSupUserProfileResponseBodyToSupUserProfile(response: SupUserProfileResponseBody): SupUserProfile {
    return {
        userId: response.userId,
        workspaces: response.workspaces.map((workspace) => {
            const permissions = [
                WorkspacePermission.PlannedMissions,
                WorkspacePermission.OperationalSituation,
                WorkspacePermission.ZoneManagementDRAI,
                WorkspacePermission.ZoneManagementDRAP,
            ];

            // TODO: DTM-5283 temporary until backend will provide permissions
            if (workspace.name === "SUP DTM") {
                permissions.push(
                    WorkspacePermission.PriorityChanges,
                    WorkspacePermission.PermissionManagement,
                    WorkspacePermission.Monitoring,
                    WorkspacePermission.TacticalMissionManagement,
                    WorkspacePermission.ZoneManagementDRAR,
                    WorkspacePermission.ZoneManagementLocal
                );
            }

            return {
                id: workspace.id,
                name: workspace.name,
                imageUrl: workspace.imageUrl,
                isManageRestOfCountryAllowed: workspace.manageRestOfCountry,
                authorityUnit: workspace.authorityUnit,
                authorityUnitZones: workspace.authorityUnitsZones,
                permissions,
            };
        }),
    };
}
