<supervisor-shared-lib-message-slider
    [alarmList]="alarmList$ | ngrxPush"
    (alarmClose)="closeGlobalAlarm($event)"
></supervisor-shared-lib-message-slider>
<ng-container
    *ngrxLet="{
        installations: installations$,
        error: error$,
        isPanelFolded: isPanelFolded$,
        selectedInstallation: selectedInstallation$,
        selectedAerialId: selectedAerialId$,
        currentSectorList: currentSectorList$
    } as vm"
>
    <dtm-ui-error
        *ngIf="vm.error; else networkMonitoringTemplate"
        [errorMessage]="'dtmSupNetworkMonitoring.networkMonitoringContainer.networkMonitoringUnknownErrorMessage' | transloco"
    ></dtm-ui-error>
    <ng-template #networkMonitoringTemplate>
        <supervisor-shared-lib-installations
            [installations]="vm.installations"
            (installationSelect)="showInstallationDetails($event)"
        ></supervisor-shared-lib-installations>
        <div class="network-monitoring" [class.hidden-panel]="vm.isPanelFolded">
            <section class="network-monitoring-panel card-box">
                <div class="content" *ngIf="!vm.isPanelFolded">
                    <div class="dtm-name">
                        <supervisor-shared-lib-installation-status
                            [status]="vm.selectedInstallation?.aerials | invoke : getDtmStatus"
                        ></supervisor-shared-lib-installation-status>
                        <span>{{ vm.selectedInstallation?.dtmName }}</span>
                    </div>
                    <supervisor-shared-lib-aerial-info-panel
                        *ngFor="let aerial of vm.selectedInstallation?.aerials"
                        [aerial]="aerial"
                        [selectedAerialId]="vm.selectedAerialId"
                        (aerialExpand)="selectInstallation($event)"
                    ></supervisor-shared-lib-aerial-info-panel>
                    <supervisor-shared-lib-unavailability-sector-details
                        [sectorList]="vm.currentSectorList"
                        [deviceHistoryList]="deviceHistoryList$ | ngrxPush"
                        [isProcessing]="isProcessing$ | ngrxPush"
                        [isDeviceHistoryFullyLoaded]="isDeviceHistoryFullyLoaded$ | ngrxPush"
                        (deviceSelect)="getDeviceHistory($event)"
                        (formSave)="updateNote($event)"
                        (contentLoad)="loadMoreDeviceHistory($event)"
                    ></supervisor-shared-lib-unavailability-sector-details>
                </div>
                <div class="view-switcher" *ngIf="vm.selectedInstallation">
                    <button type="button" (click)="togglePanel()">
                        <dtm-ui-icon [name]="vm.isPanelFolded ? 'menu-unfold' : 'menu-fold'"></dtm-ui-icon>
                    </button>
                </div>
            </section>
            <section class="network-monitoring-map">
                <div class="map">
                    <supervisor-shared-lib-mission-map
                        [isProcessing]="false"
                        [routeData]="routeData$ | ngrxPush"
                        [initialViewGeometry]="initialViewGeometry"
                        [zoneTimeSettingOptions]="{
                            available: [ZoneTimesSetting.Soon, ZoneTimesSetting.Current],
                            default: ZoneTimesSetting.Current
                        }"
                    ></supervisor-shared-lib-mission-map>
                </div>
            </section>
        </div>
    </ng-template>
</ng-container>
