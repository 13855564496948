import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { supPermissionGuard } from "../shared/guards/sup-permission.guard";
import { WorkspacePermission } from "../sup-user/index";
import { AddingPermissionsContainerComponent } from "./components/adding-permissions-container/adding-permissions-container.component";

const routes: Routes = [
    {
        path: "adding-permissions",
        canActivate: [DtmRoleGuard, supPermissionGuard([WorkspacePermission.PermissionRead])],
        component: AddingPermissionsContainerComponent,
        data: {
            titleTranslationKey: "dtmSupAddingPermissions.routeTitles.addingPermissions",
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AddingPermissionsRoutingModule {}
