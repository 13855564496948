<dtm-ui-expandable-panel *ngrxLet="alertList$ as alertList" isExpanded hasHeaderSeparator="false">
    <div class="panel-header" headerSlot>
        {{ "dtmSupOperationalSituation.alertList.alertLabel" | transloco }}
        <span>({{ alertList.length }})</span>
    </div>
    <div class="alert-list">
        <dtm-ui-loader-container [isShown]="isProcessing$ | ngrxPush">
            <supervisor-shared-lib-mission-alert
                *ngFor="let alert of alertList; let index = index"
                [missionAlert]="alert"
                (alertClose)="alertClose.emit({ missionId: alert.missionId, alertIndex: index })"
            ></supervisor-shared-lib-mission-alert>
        </dtm-ui-loader-container>
    </div>
    <div class="no-alert" *ngIf="!alertList.length">{{ "dtmSupOperationalSituation.alertList.emptyAlertsLabel" | transloco }}</div>
</dtm-ui-expandable-panel>
