<mat-tab-group
    [selectedIndex]="activeTabIndex$ | ngrxPush"
    (selectedIndexChange)="onUpdateTabIndex($event)"
    class="dtm-tabs"
    color="accent"
    dynamicHeight
>
    <mat-tab [label]="'dtmSupAddingPermissions.tabs.operatorsLabel' | transloco">
        <ng-content select="[operatorList]"></ng-content>
    </mat-tab>

    <mat-tab [label]="'dtmSupAddingPermissions.tabs.pilotsLabel' | transloco">
        <ng-content select="[pilotList]"></ng-content>
    </mat-tab>
</mat-tab-group>
