import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { tap } from "rxjs";
import { Weather, WeatherViewMode } from "../models/weather.models";

interface WeatherComponentState {
    isProcessing: boolean;
    weatherList: Weather[];
    weatherByMissionTime: Weather | undefined;
    selectedRange: Weather | undefined;
    weatherViewMode: WeatherViewMode | undefined;
    selectedWeatherRangeIndex: number | undefined;
    selectedZone: string | undefined;
    isWithinDtm: boolean;
    forecastRefTime: Date | undefined;
    supportedWeatherZones: string[];
}

@UntilDestroy()
@Component({
    selector: "dtm-map-weather",
    templateUrl: "./weather.component.html",
    styleUrls: ["./weather.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class WeatherComponent {
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set weatherList(value: Weather[] | undefined) {
        if (value?.length) {
            this.localStore.patchState({ weatherList: value });
        }
    }
    @Input() public set weatherByMissionTime(value: Weather | undefined) {
        this.localStore.patchState({ weatherByMissionTime: value });
    }
    @Input() public set selectedWeatherRangeIndex(value: number | undefined) {
        const weatherList = this.localStore.selectSnapshotByKey("weatherList");

        if (value || value === 0) {
            this.localStore.patchState({ selectedWeatherRangeIndex: value });

            return;
        }

        if (!value && weatherList.length) {
            this.localStore.patchState({ selectedWeatherRangeIndex: 0 });
        }
    }
    @Input() public set weatherViewMode(value: WeatherViewMode | undefined) {
        this.localStore.patchState({ weatherViewMode: value });
    }
    @Input() public set isWithinDtm(value: BooleanInput) {
        this.localStore.patchState({ isWithinDtm: coerceBooleanProperty(value) });
    }
    @Input() public set forecastRefTime(value: Date | undefined) {
        this.localStore.patchState({ forecastRefTime: value });
    }
    @Input() public set supportedWeatherZones(value: string[] | undefined) {
        this.localStore.patchState({ supportedWeatherZones: value });
        if (value?.length === 1) {
            this.selectZone(value[0]);
        }
    }
    @Output() public readonly zoneSelected = new EventEmitter<string>();

    protected readonly weatherList$ = this.localStore.selectByKey("weatherList").pipe(
        tap((weatherList) => {
            const selectedWeatherRangeIndex = this.localStore.selectSnapshotByKey("selectedWeatherRangeIndex");
            if (weatherList.length && selectedWeatherRangeIndex !== undefined) {
                this.localStore.patchState({ selectedRange: weatherList[selectedWeatherRangeIndex] });
            }
        })
    );
    protected readonly weatherByMissionTime$ = this.localStore.selectByKey("weatherByMissionTime");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly weatherViewMode$ = this.localStore.selectByKey("weatherViewMode");
    protected readonly selectedZone$ = this.localStore.selectByKey("selectedZone");
    protected readonly isWithinDtm$ = this.localStore.selectByKey("isWithinDtm");
    protected readonly forecastRefTime$ = this.localStore.selectByKey("forecastRefTime");
    protected readonly selectedRange$ = this.localStore.selectByKey("selectedRange").pipe(RxjsUtils.filterFalsy());
    protected readonly selectedWeatherRangeIndex$ = this.localStore.selectByKey("selectedWeatherRangeIndex");
    protected readonly supportedWeatherZones$ = this.localStore.selectByKey("supportedWeatherZones");

    protected readonly WeatherViewMode = WeatherViewMode;

    constructor(private readonly localStore: LocalComponentStore<WeatherComponentState>) {
        this.localStore.setState({
            isProcessing: false,
            weatherList: [],
            weatherByMissionTime: undefined,
            selectedRange: undefined,
            weatherViewMode: undefined,
            selectedWeatherRangeIndex: undefined,
            selectedZone: undefined,
            isWithinDtm: false,
            forecastRefTime: undefined,
            supportedWeatherZones: [],
        });
    }

    protected changeWeatherConditions(index: number): void {
        const weatherList = this.localStore.selectSnapshotByKey("weatherList");
        this.localStore.patchState({ selectedRange: weatherList[index] });
    }

    protected selectZone(zoneDesignator: string): void {
        this.localStore.patchState({ selectedZone: zoneDesignator });
        this.zoneSelected.emit(zoneDesignator);
    }
}
