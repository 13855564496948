<button type="button" class="button-icon" [matDialogClose]="false">
    <dtm-ui-icon name="close"></dtm-ui-icon>
</button>
<div class="content" [formGroup]="formGroup">
    <h4 mat-dialog-title>{{ "dtmSupPlannedMissions.withdrawApprovalDialog.header" | transloco }}</h4>
    <dtm-ui-textarea-field [maxLength]="MAX_REJECTION_MESSAGE_LENGTH">
        <label>{{ "dtmSupPlannedMissions.withdrawApprovalDialog.reasonLabel" | transloco }}</label>
        <textarea [formControl]="rejectionMessageFormControl" matInput cdkFocusInitial class="comment-area"></textarea>
        <div class="field-error" *dtmUiFieldHasError="rejectionMessageFormControl; name: 'maxlength'; error as error">
            {{ "dtmSupPlannedMissions.withdrawApprovalDialog.maxLengthErrorLabel" | transloco : { maxLength: error.requiredLength } }}
        </div>
    </dtm-ui-textarea-field>
</div>

<mat-dialog-actions align="end">
    <button type="button" [mat-dialog-close]="false" class="button-secondary">
        {{ "dtmSupPlannedMissions.withdrawApprovalDialog.cancelButtonLabel" | transloco }}
    </button>
    <button type="button" class="button-warn" (click)="confirm()">
        {{ "dtmSupPlannedMissions.withdrawApprovalDialog.confirmButtonLabel" | transloco }}
    </button>
</mat-dialog-actions>
