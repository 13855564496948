import { Injectable } from "@angular/core";
import { Action, createSelector, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY } from "rxjs";
import { catchError, finalize, map } from "rxjs/operators";
import { CapabilitiesError, SupFeatures } from "../models/shared-supervisor-client.models";
import { CapabilitiesApiService } from "../services/capabilities-api.service";
import { CapabilitiesActions } from "./capabilities.actions";

export interface CapabilitiesModel {
    isProcessing: boolean;
    capabilitiesError: CapabilitiesError | undefined;
    features: SupFeatures[] | undefined;
}

const defaultState: CapabilitiesModel = {
    isProcessing: false,
    capabilitiesError: undefined,
    features: undefined,
};

@State<CapabilitiesModel>({
    name: "capabilitiesContext",
    defaults: defaultState,
})
@Injectable()
export class CapabilitiesState {
    constructor(private readonly capabilitiesApiService: CapabilitiesApiService) {}

    @Selector()
    public static capabilitiesError(state: CapabilitiesModel): CapabilitiesError | undefined {
        return state.capabilitiesError;
    }

    @Selector()
    public static isProcessing(state: CapabilitiesModel): boolean {
        return state.isProcessing;
    }

    public static isFeatureAvailable(feature: SupFeatures) {
        return createSelector([CapabilitiesState], (state: CapabilitiesModel) =>
            !state.features ? false : state.features.includes(feature)
        );
    }

    @Action(CapabilitiesActions.GetCapabilities)
    public getGlobalCapabilities(context: StateContext<CapabilitiesModel>) {
        context.patchState({ isProcessing: true, capabilitiesError: undefined });

        return this.capabilitiesApiService.getCapabilities().pipe(
            map((result) => {
                context.patchState({
                    features: result.features ?? [],
                });
            }),
            catchError((error) => {
                context.patchState({ capabilitiesError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }
}
