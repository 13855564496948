import { HttpClient, HttpContext, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, map, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { SUPERVISOR_MAX_HOURS_AFTER } from "../../shared/utils/weather-constants";
import { SupportedWeatherResponseBody, WeatherDetails, WeatherErrorType, WeatherPayloadData } from "../models/weather.models";
import { WEATHER_ENDPOINTS, WeatherEndpoints } from "../weather.tokens";
import { WeatherConditionsResponseBody, convertWeatherResponseBodyMapToWeather, payloadFactoryConfig } from "./weather.converters";

@Injectable()
export class WeatherApiService {
    constructor(private readonly httpClient: HttpClient, @Inject(WEATHER_ENDPOINTS) private readonly endpoints: WeatherEndpoints) {}

    public getWeather(zoneDesignator: string): Observable<WeatherDetails> {
        const params: HttpParams = new HttpParams()
            .append("forecastHoursBefore", 0)
            .append("forecastHoursAfter", SUPERVISOR_MAX_HOURS_AFTER);

        return this.httpClient
            .get<WeatherConditionsResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getWeatherConditions, { zoneDesignator }), {
                params,
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
            })
            .pipe(
                map((response) => convertWeatherResponseBodyMapToWeather(response)),
                catchError(() => throwError(() => ({ type: WeatherErrorType.CannotGetWeatherConditions })))
            );
    }

    public getMissionPlanWeather(weatherPayloadData: WeatherPayloadData): Observable<WeatherDetails> {
        const payload = payloadFactoryConfig(weatherPayloadData);

        return this.httpClient
            .post<WeatherConditionsResponseBody>(this.endpoints.getMissionPlanWeather, payload, {
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
            })
            .pipe(
                map((response) => convertWeatherResponseBodyMapToWeather(response)),
                catchError(() => throwError(() => ({ type: WeatherErrorType.CannotGetWeatherConditions })))
            );
    }

    public getSupportedWeatherZones(): Observable<string[]> {
        return this.httpClient
            .get<SupportedWeatherResponseBody>(this.endpoints.getSupportedWeatherZones, {
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
            })
            .pipe(
                map(({ supportedZones }) => supportedZones.map((zone) => zone.zoneDesignator)),
                catchError(() => throwError(() => ({ type: WeatherErrorType.CannotGetWeatherConditions })))
            );
    }
}
