<div
    *ngrxLet="severity$ as severity"
    class="inline-message"
    [ngClass]="{
        success: severity === 'success',
        info: severity === 'info',
        error: severity === 'error',
        warning: severity === 'warning'
    }"
>
    <div class="content">
        <dtm-ui-icon [name]="severity | invoke : getIconName"></dtm-ui-icon>
        <ng-content></ng-content>
    </div>
    <button (click)="messageClose.emit()" type="button" class="button-icon">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>
