import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Subscription, finalize, merge, tap } from "rxjs";
import { Alarm } from "../../shared/models/shared-supervisor-client.models";
import { GlobalAlarmApiService } from "../services/global-alarm-api.service";
import { GlobalAlarmActions } from "./global-alarm.actions";

interface AlertsStateModel {
    alarms: Alarm[] | undefined;
}

const defaultState: AlertsStateModel = {
    alarms: undefined,
};

@State<AlertsStateModel>({
    name: "globalAlarm",
    defaults: defaultState,
})
@Injectable()
export class GlobalAlarmState {
    private zoneAlarmWatchSubscription: Subscription | undefined;

    constructor(private readonly alarmApiService: GlobalAlarmApiService) {}

    @Selector()
    public static alarms(state: AlertsStateModel): Alarm[] | undefined {
        return state.alarms;
    }

    @Action(GlobalAlarmActions.GlobalAlarmWatch)
    public globalAlarmWatch(context: StateContext<AlertsStateModel>) {
        return this.alarmApiService.startGlobalAlarmsWatch().pipe(
            tap((response) => {
                const alarmState = context.getState().alarms;
                context.patchState({
                    alarms: [response, ...(alarmState ?? [])],
                });
            })
        );
    }

    @Action(GlobalAlarmActions.ZonesAlarmWatch)
    public zonesAlarmWatch(context: StateContext<AlertsStateModel>, action: GlobalAlarmActions.ZonesAlarmWatch) {
        this.zoneAlarmWatchSubscription?.unsubscribe();

        const alarmWatchStreams = action.zones.map((zoneId) => this.alarmApiService.startZoneAlarmsWatch(zoneId));

        this.zoneAlarmWatchSubscription = merge(...alarmWatchStreams)
            .pipe(
                tap((response) => {
                    const alarmState = context.getState().alarms;
                    context.patchState({
                        alarms: [response, ...(alarmState ?? [])],
                    });
                }),
                finalize(() => {
                    context.patchState({ alarms: [] });
                })
            )
            .subscribe();
    }

    @Action(GlobalAlarmActions.DeleteAlarm)
    public deleteAlarm(context: StateContext<AlertsStateModel>, action: GlobalAlarmActions.DeleteAlarm) {
        const alarms = context.getState().alarms;

        if (!alarms) {
            return;
        }

        context.patchState({
            alarms: [...alarms.filter((alarm) => alarm.id !== action.alarmId)],
        });
    }
}
