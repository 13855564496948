import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { IconName } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

type MessageSeverity = "success" | "info" | "error" | "warning";

interface MessageComponentState {
    severity: MessageSeverity | undefined;
}

const ICON_MAP: Record<MessageSeverity, IconName> = {
    success: "checkbox-circle-fill",
    info: "information-fill",
    error: "error-warning-fill",
    warning: "error-warning-fill",
};

@Component({
    selector: "supervisor-shared-lib-message[severity]",
    templateUrl: "./message.component.html",
    styleUrls: ["./message.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MessageComponent {
    @Input() public set severity(value: MessageSeverity | undefined) {
        this.localStore.patchState({ severity: value });
    }

    @Output() public readonly messageClose = new EventEmitter<void>();

    protected readonly severity$ = this.localStore.selectByKey("severity");

    constructor(private readonly localStore: LocalComponentStore<MessageComponentState>) {
        this.localStore.setState({ severity: undefined });
    }

    protected getIconName(severity: MessageSeverity | undefined): string | undefined {
        return severity && ICON_MAP[severity];
    }
}
