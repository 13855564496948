export interface SupUserProfile {
    userId: string;
    workspaces: SupWorkspace[];
}

export interface SupWorkspace {
    id: string;
    name: string;
    imageUrl: string;
    isManageRestOfCountryAllowed: boolean;
    authorityUnitZones: { designator: string }[];
    authorityUnit: AuthorityUnit;
    permissions: WorkspacePermission[];
}

export interface AuthorityUnit {
    id: string;
    name: string;
    type: AuthorityUnitType;
}

export enum SupUserErrorType {
    CannotFetchUserProfile = "CannotFetchUserProfile",
    CannotGetAirspaceElements = "CannotGetAirspaceElements",
}

export enum WorkspacePermission {
    PlannedMissionsRead = "PlannedMissionsRead",
    PlannedMissionsAccept = "PlannedMissionsAccept",
    OperationalSituationRead = "OperationalSituationRead",
    OperationalSituationManagement = "OperationalSituationManagement",
    PriorityChanges = "PriorityChanges",
    MonitoringRead = "MonitoringRead",
    MonitoringManagement = "MonitoringManagement",
    PermissionManagement = "PermissionManagement",
    PermissionRead = "PermissionRead",
    ZoneManagementDRAI = "ZoneManagementDRAI",
    ZoneManagementDRAP = "ZoneManagementDRAP",
    ZoneManagementDRAR = "ZoneManagementDRAR",
    ZoneManagementLocal = "ZoneManagementLocal",
}

export enum AuthorityUnitType {
    Dtm = "DTM",
    Atz = "ATZ",
    Utm = "UTM",
    AtzInDtm = "ATZ_IN_DTM",
    Other = "OTHER",
}
