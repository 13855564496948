import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { MissionAlert, ViolationType } from "../../models/operational.situation.models";

enum DirectionType {
    Departure = "Departure",
    Arrival = "Arrival",
}

interface MissionAlertComponentState {
    missionAlert: MissionAlert | undefined;
}

@Component({
    selector: "supervisor-shared-lib-mission-alert[missionAlert]",
    templateUrl: "./mission-alert.component.html",
    styleUrls: ["./mission-alert.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionAlertComponent {
    @Input() public set missionAlert(value: MissionAlert | undefined) {
        this.localStore.patchState({ missionAlert: value });
    }

    @Output() public readonly alertClose = new EventEmitter<void>();

    protected readonly missionAlert$ = this.localStore.selectByKey("missionAlert").pipe(RxjsUtils.filterFalsy());
    protected readonly ViolationType = ViolationType;
    protected readonly DirectionType = DirectionType;

    constructor(private readonly localStore: LocalComponentStore<MissionAlertComponentState>) {
        this.localStore.setState({
            missionAlert: undefined,
        });
    }
}
