<div class="sidebar" [class.sidebar-folded]="isSidebarFolded" *ngrxLet="isSidebarFolded$ as isSidebarFolded">
    <div class="switcher-frame">
        <div class="switcher">
            <button type="button" (click)="changePanelVisibility()">
                <dtm-ui-icon [name]="isSidebarFolded ? 'menu-fold' : 'menu-unfold'"></dtm-ui-icon>
            </button>
        </div>
    </div>
    <div class="sidebar-content">
        <div class="panel-header">
            <h5 class="heading-text">{{ title$ | ngrxPush }}</h5>
            <button (click)="changePanelVisibility()" type="button" class="button-icon">
                <dtm-ui-icon name="close"></dtm-ui-icon>
            </button>
        </div>
        <ng-content></ng-content>
    </div>
</div>
