import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, NgZone, Output } from "@angular/core";
import { Area, MissionPlanRoute, MissionSegmentStatus, RouteAreaTypeId, RouteData } from "@dtm-frontend/shared/ui";
import { DEFAULT_DEBOUNCE_TIME, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AcNotification, CesiumService } from "@pansa/ngx-cesium";
import { Polygon } from "@turf/helpers";
import { Observable, combineLatestWith, concat, distinctUntilChanged, map, of, pairwise, tap, throttleTime } from "rxjs";
import { filter, shareReplay, switchMap } from "rxjs/operators";
import { CameraHelperService, MapLabelsUtils, MapUtils } from "../../index";
import { RouteAcEntity } from "../../models/route.models";
import { RouteViewerService } from "../../services/route-viewer/route-viewer.service";
import { CesiumImageObjectSettings, ROUTES_VISUAL_DATA, VisualSettings } from "../route-viewer/route-viewer.data";

/* eslint-disable @typescript-eslint/no-explicit-any*/
declare const Cesium: any; // TODO: DTM-966

interface RoutesListViewLayerComponentState<T> {
    routesList: RouteData<T>[];
    drawableFeatures: RouteAreaTypeId[];
    isShown: boolean;
}

interface RouteItemAcEntity extends RouteAcEntity {
    planId: string;
    isPathBased: boolean;
    style?: {
        fill?: any;
        outline?: any;
        outlineWidth?: any;
    };
}

interface PlansChanges<T> {
    toAdd: Set<string>;
    toRemove: Set<string>;
    previousRoutesList: RouteData<T>[];
    currentRoutesList: RouteData<T>[];
}

const LABELS_SHOWING_DISTANCE_IN_METERS = 20000;
const FILL_IMAGE_SCALE = 15;

@UntilDestroy()
@Component({
    selector: "dtm-map-routes-list-view-layer",
    templateUrl: "./routes-list-view-layer.component.html",
    styleUrls: ["./routes-list-view-layer.component.scss", "../../../shared/styles/map-segment-pin.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class RoutesListViewLayerComponent<T extends { planId: string; route: MissionPlanRoute }> {
    @Input({ required: true }) public set routesList(value: RouteData<T>[] | undefined) {
        this.localStore.patchState({ routesList: value ?? [] });
    }

    @Input() public set drawableFeatures(value: RouteAreaTypeId[] | undefined) {
        this.localStore.patchState({ drawableFeatures: [...new Set(value)] });
    }

    @Input() public set isShown(value: BooleanInput) {
        this.localStore.patchState({ isShown: coerceBooleanProperty(value) });
        this.cesiumService.getScene().requestRender();
    }

    @Output() public readonly missionSelect = new EventEmitter<string>();

    protected readonly isShown$ = this.localStore.selectByKey("isShown");
    protected readonly areLabelsVisible$ = this.cameraHelperService.postRender$.pipe(
        map(() => this.cesiumService.getViewer().camera.positionCartographic.height <= LABELS_SHOWING_DISTANCE_IN_METERS),
        distinctUntilChanged(),
        shareReplay({ bufferSize: 1, refCount: true })
    );
    private readonly plansChanges$ = this.localStore.selectByKey("routesList").pipe(
        pairwise(),
        map(([previousRoutesList, routesList]) => this.getPlansChanges(previousRoutesList, routesList)),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    protected readonly flightAreaEntities$ = this.initFlightAreaData();
    protected readonly pathEntities$ = this.initPathData();
    protected readonly waypointEntities$ = this.initWaypointData();
    protected readonly pinEntities$ = this.initPinData();

    constructor(
        private readonly localStore: LocalComponentStore<RoutesListViewLayerComponentState<T>>,
        private readonly routeViewerService: RouteViewerService<T>,
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly zone: NgZone,
        private readonly cesiumService: CesiumService,
        private readonly cameraHelperService: CameraHelperService
    ) {
        this.localStore.setState({
            routesList: [],
            drawableFeatures: [],
            isShown: false,
        });

        this.watchLabelsOverlapping();
    }

    protected getHeightRangeValues(flightArea?: Area) {
        if (!flightArea) {
            return;
        }

        const elevationMin = flightArea.elevationMin ?? 0;
        const elevationMax = flightArea.elevationMax ?? 0;
        const isOnTheGround = flightArea.volume.floor <= elevationMin;
        const minHeightAmsl = flightArea.volume.floor;
        const maxHeightAmsl = flightArea.volume.ceiling;
        const maxHeightAgl = flightArea.volume.ceiling - elevationMax;
        const minHeightAgl = flightArea.volume.floor - elevationMin;

        return {
            minHeightAmsl,
            maxHeightAmsl,
            isOnTheGround,
            maxHeightAgl,
            minHeightAgl,
        };
    }

    private initFlightAreaData() {
        return this.prepareInit((routeData, drawableFeatures, index) =>
            this.routeViewerService.transformRouteDataToFlightAreaEntities(routeData, drawableFeatures, index)
        ).pipe(
            map((notification: AcNotification) => {
                const entity: RouteItemAcEntity = notification.entity as RouteItemAcEntity;
                if (entity) {
                    entity.style = {
                        fill: this.getRouteEntityFill(entity),
                        outline: this.getRouteEntityMaterial(entity),
                        outlineWidth: this.getRouteEntityOutlineWidth(entity),
                    };
                }

                return notification;
            })
        );
    }

    private initPathData() {
        return this.prepareInit((routeData, drawableFeatures, index) =>
            this.routeViewerService.transformRouteDataToPathEntities(routeData, drawableFeatures, index)
        ).pipe(
            map((notification: AcNotification) => {
                const entity: RouteItemAcEntity = notification.entity as RouteItemAcEntity;
                if (entity) {
                    entity.style = {
                        outline: this.getRouteEntityMaterial(entity),
                        outlineWidth: this.getRouteEntityOutlineWidth(entity),
                    };
                }

                return notification;
            })
        );
    }

    private initWaypointData() {
        return this.prepareInit((routeData, drawableFeatures, index) =>
            this.routeViewerService.transformRouteDataToWaypointEntities(routeData, drawableFeatures, index)
        ).pipe(
            map((notification: AcNotification) => {
                const entity: RouteItemAcEntity = notification.entity as RouteItemAcEntity;
                if (entity) {
                    entity.style = {
                        fill: this.getRouteEntityFill(entity),
                    };
                }

                return notification;
            })
        );
    }

    private initPinData() {
        return this.prepareInit((routeData, drawableFeatures, index) =>
            this.routeViewerService.mapRouteToSinglePinEntities(routeData, drawableFeatures, index)
        );
    }

    private prepareInit(
        fn: (routeData: RouteData<T>, drawableFeatures: RouteAreaTypeId[], index: number) => Observable<any>
    ): Observable<AcNotification> {
        return this.plansChanges$.pipe(
            combineLatestWith(this.localStore.selectByKey("drawableFeatures")),
            switchMap(([changes, drawableFeatures]) =>
                concat(
                    ...changes.previousRoutesList.map((route, index) =>
                        fn(route, [], index).pipe(
                            map((notification: AcNotification) => this.prepareAcNotification(notification, route)),
                            filter((notification) => changes.toRemove.has((notification.entity as RouteItemAcEntity).planId))
                        )
                    ),
                    ...changes.currentRoutesList.map((route, index) =>
                        fn(route, drawableFeatures, index).pipe(
                            map((notification: AcNotification) => this.prepareAcNotification(notification, route)),
                            filter((notification) => changes.toAdd.has((notification.entity as RouteItemAcEntity).planId))
                        )
                    )
                )
            ),
            tap(() => {
                this.cesiumService.getScene().requestRender();
            })
        );
    }

    private prepareAcNotification(notification: AcNotification, route: RouteData<T>): AcNotification {
        const entity: RouteItemAcEntity = notification.entity as RouteItemAcEntity;

        return {
            ...notification,
            entity: {
                ...entity,
                isMain: route?.isMain,
                isSelected: route?.isSelected,
                isCollision: route?.isCollision,
                emergency: route?.emergency,
                planId: route?.data?.planId,
                isPathBased: !!route?.data?.route?.isPathBased,
            },
        };
    }

    private getRouteEntityFill(entity: RouteItemAcEntity) {
        const visualData = this.getEntityVisualData(entity);

        if (visualData?.fill instanceof CesiumImageObjectSettings && entity.degreesPositions) {
            const geometry: Polygon = {
                type: "Polygon",
                coordinates: [entity.degreesPositions],
            };

            return visualData.fill.createImageMaterialProperty(MapUtils.getCesiumImageScaleForGeometry(geometry, FILL_IMAGE_SCALE));
        }

        return visualData?.fill ?? Cesium.Color.TRANSPARENT;
    }

    private getRouteEntityOutlineWidth(entity: RouteItemAcEntity) {
        return this.getEntityVisualData(entity)?.outlineWidth ?? 0;
    }

    private getRouteEntityMaterial(entity: RouteItemAcEntity) {
        return this.getEntityVisualData(entity)?.outlineMaterial;
    }

    private getEntityVisualData(entity: RouteItemAcEntity): VisualSettings | undefined {
        let visualData = ROUTES_VISUAL_DATA[entity.id]?.[MissionSegmentStatus.DEFAULT];

        if (entity.isSelected) {
            visualData = ROUTES_VISUAL_DATA[entity.id]?.[MissionSegmentStatus.ACTIVE_SEGMENT];
        }

        return visualData;
    }

    private watchLabelsOverlapping() {
        this.isShown$
            .pipe(
                switchMap((isShown) => {
                    if (!isShown) {
                        return of(undefined);
                    }

                    return this.cameraHelperService.postRender$.pipe(
                        throttleTime(DEFAULT_DEBOUNCE_TIME, undefined, { leading: true, trailing: true }),
                        tap(() => this.zone.runOutsideAngular(() => this.removeOverlapping()))
                    );
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private removeOverlapping() {
        const elements = [...(this.document?.querySelectorAll(".segment-pin").values() ?? [])] as HTMLDivElement[];

        MapLabelsUtils.removeOverlapping(elements, {
            svgLineSelector: ".line-connector line",
            buffer: 4,
        });
    }

    private getPlansChanges(previousRoutesList: RouteData<T>[], currentRoutesList: RouteData<T>[]): PlansChanges<T> {
        const previousRoutesMap = previousRoutesList.reduce<{ [planId: string]: RouteData<T> }>(
            (result, route) => (route.data?.planId ? { ...result, [route.data.planId]: route } : result),
            {}
        );
        const currentRoutesMap = currentRoutesList.reduce<{ [planId: string]: RouteData<T> }>(
            (result, route) => (route.data?.planId ? { ...result, [route.data.planId]: route } : result),
            {}
        );

        const plansToRemove = new Set<string>();
        const plansToAdd = new Set<string>();
        for (const [previousPlanId, previousRoute] of Object.entries(previousRoutesMap)) {
            if (currentRoutesMap[previousPlanId] && currentRoutesMap[previousPlanId].isSelected === previousRoute.isSelected) {
                delete currentRoutesMap[previousPlanId];
            } else {
                plansToRemove.add(previousPlanId);
            }
        }

        for (const planId of Object.keys(currentRoutesMap)) {
            plansToAdd.add(planId);
        }

        return {
            toAdd: plansToAdd,
            toRemove: plansToRemove,
            previousRoutesList,
            currentRoutesList,
        };
    }
}
