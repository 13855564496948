import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, finalize, tap } from "rxjs";
import { catchError } from "rxjs/operators";
import { DeviceHistory, Installation, NetworkMonitoringError, Page, Sector } from "../models/network-monitoring.models";
import { DEVICE_VIOLATION_HISTORY_DEFAULT_ELEMENTS_NUMBER, NetworkMonitoringApiService } from "../services/network-monitoring-api.service";
import { NetworkMonitoringActions as Actions } from "./network-monitoring.actions";

const DTM_PREFIX = "DTM";

interface NetworkMonitoringStateModel {
    isProcessing: boolean;
    installations: Installation[] | undefined;
    installationsError: NetworkMonitoringError | undefined;
    selectedInstallation: Installation | undefined;
    selectedAerialId: string | undefined;
    currentSectorList: Sector[] | undefined;
    deviceHistoryList: DeviceHistory[] | undefined;
    updateNoteError: NetworkMonitoringError | undefined;
    deviceHistoryListError: NetworkMonitoringError | undefined;
    deviceHistoryPages: Page | undefined;
    isHistoryFullyLoaded: boolean;
    isInitialHistoryFetch: boolean;
}

const defaultState: NetworkMonitoringStateModel = {
    isProcessing: false,
    installations: undefined,
    installationsError: undefined,
    selectedInstallation: undefined,
    selectedAerialId: undefined,
    currentSectorList: undefined,
    deviceHistoryList: undefined,
    updateNoteError: undefined,
    deviceHistoryListError: undefined,
    deviceHistoryPages: undefined,
    isHistoryFullyLoaded: false,
    isInitialHistoryFetch: false,
};

@State({
    name: "networkMonitoringState",
    defaults: defaultState,
})
@Injectable()
export class NetworkMonitoringState {
    constructor(private readonly networkMonitoringApi: NetworkMonitoringApiService) {}

    @Selector()
    public static installations(state: NetworkMonitoringStateModel): Installation[] | undefined {
        return state.installations;
    }

    @Selector()
    public static installationsError(state: NetworkMonitoringStateModel): NetworkMonitoringError | undefined {
        return state.installationsError;
    }

    @Selector()
    public static selectedInstallation(state: NetworkMonitoringStateModel): Installation | undefined {
        return state.selectedInstallation;
    }

    @Selector()
    public static selectedAerialId(state: NetworkMonitoringStateModel): string | undefined {
        return state.selectedAerialId;
    }

    @Selector()
    public static currentSectorList(state: NetworkMonitoringStateModel): Sector[] | undefined {
        return state.currentSectorList;
    }

    @Selector()
    public static deviceHistoryList(state: NetworkMonitoringStateModel): DeviceHistory[] | undefined {
        return state.deviceHistoryList;
    }

    @Selector()
    public static isProcessing(state: NetworkMonitoringStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static isDeviceHistoryFullyLoaded(state: NetworkMonitoringStateModel): boolean {
        return state.isHistoryFullyLoaded;
    }

    @Selector()
    public static updateNoteError(state: NetworkMonitoringStateModel): NetworkMonitoringError | undefined {
        return state.updateNoteError;
    }

    @Selector()
    public static deviceHistoryListError(state: NetworkMonitoringStateModel): NetworkMonitoringError | undefined {
        return state.deviceHistoryListError;
    }

    @Action(Actions.GetInstallations)
    public getInstallations(context: StateContext<NetworkMonitoringStateModel>) {
        context.patchState({ isProcessing: true, installationsError: undefined });

        return this.networkMonitoringApi.getInstallations().pipe(
            tap((installations) => {
                context.patchState({ installations });
            }),
            catchError((installationsError) => {
                context.patchState({ installationsError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(Actions.SelectInstallation)
    public selectInstallation(context: StateContext<NetworkMonitoringStateModel>, action: Actions.SelectInstallation) {
        context.patchState({ selectedInstallation: undefined, selectedAerialId: undefined });
        const installations = context.getState().installations;

        if (!installations) {
            return;
        }

        if (action.id.includes(DTM_PREFIX)) {
            context.patchState({
                selectedInstallation: installations.find((installation) => installation.dtmName.includes(action.id)),
            });

            return;
        }

        const aerial = installations
            .map((installation) => installation.aerials)
            .flat(1)
            .filter((item) => item.id === action.id)[0];

        context.patchState({
            selectedInstallation: installations.find((installation) => installation.dtmName.includes(aerial.dtmName)),
            selectedAerialId: action.id,
            currentSectorList: aerial.sectors,
        });

        if (!context.getState().isInitialHistoryFetch) {
            context.dispatch(new Actions.GetDeviceHistory(aerial.sectors[0].mountedDevicesHistory[0].id));
            context.patchState({ isInitialHistoryFetch: true });
        }
    }

    @Action(Actions.GetDeviceHistory)
    public getDeviceHistory(context: StateContext<NetworkMonitoringStateModel>, action: Actions.GetDeviceHistory) {
        context.patchState({ isProcessing: true, isHistoryFullyLoaded: false, deviceHistoryListError: undefined, deviceHistoryList: [] });

        return this.networkMonitoringApi.getDeviceHistory(action.deviceId).pipe(
            tap((response) => {
                context.patchState({
                    deviceHistoryList: response.deviceHistory,
                    deviceHistoryPages: response.pageable,
                    isHistoryFullyLoaded: response.pageable.totalElements <= DEVICE_VIOLATION_HISTORY_DEFAULT_ELEMENTS_NUMBER,
                });
            }),
            catchError((deviceHistoryListError) => {
                context.patchState({ deviceHistoryListError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(Actions.UpdateSignalViolationNote)
    public updateSignalViolationNote(context: StateContext<NetworkMonitoringStateModel>, action: Actions.UpdateSignalViolationNote) {
        context.patchState({ isProcessing: true, updateNoteError: undefined });

        return this.networkMonitoringApi.updateSignalViolationNote(action.noteData).pipe(
            tap((updatedHistoryData) => {
                const deviceHistoryList = context.getState().deviceHistoryList;

                context.patchState({
                    deviceHistoryList:
                        deviceHistoryList &&
                        deviceHistoryList.map((historyData) =>
                            historyData.signalViolationId === updatedHistoryData.signalViolationId
                                ? {
                                      ...historyData,
                                      note: {
                                          supervisorName: updatedHistoryData.note.person,
                                          modifiedAt: new Date(updatedHistoryData.note.modifiedAt),
                                          content: updatedHistoryData.note.content,
                                      },
                                  }
                                : historyData
                        ),
                });
            }),
            catchError((updateNoteError) => {
                context.patchState({ updateNoteError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(Actions.LoadMoreDeviceHistory)
    public loadMoreDeviceHistory(context: StateContext<NetworkMonitoringStateModel>, action: Actions.LoadMoreDeviceHistory) {
        context.patchState({ isProcessing: true, deviceHistoryListError: undefined });
        const { deviceHistoryList, deviceHistoryPages } = context.getState();

        if (!deviceHistoryPages) {
            return;
        }

        return this.networkMonitoringApi.getDeviceHistory(action.deviceId, deviceHistoryPages.page + 1).pipe(
            tap((response) => {
                if (!response.deviceHistory.length) {
                    context.patchState({ isHistoryFullyLoaded: true });

                    return;
                }

                context.patchState({
                    deviceHistoryList: [...(deviceHistoryList ?? []), ...response.deviceHistory],
                    deviceHistoryPages: response.pageable,
                });
            }),
            catchError((deviceHistoryListError) => {
                context.patchState({ deviceHistoryListError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }
}
