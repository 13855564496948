<ng-container
    *ngrxLet="{
        isEditMode: isEditMode$,
        activeZoneList: activeZoneList$,
        draftZoneList: draftZoneList$,
        selectedZoneId: selectedZoneId$,
        zoneDetails: zoneDetails$,
        draftZoneDetails: draftZoneDetails$,
        archiveZoneList: archiveZoneList$,
        availableZones: availableZones$,
    } as vm"
>
    <div class="panel-heading">
        <h2>{{ "dtmSupOperationalSituation.temporaryZones.headerTextValueSelect" | transloco : { value: vm.isEditMode } }}</h2>
        <button class="button-secondary" type="button" (click)="changeEditMode()" *ngIf="!vm.isEditMode">
            <dtm-ui-icon name="add"></dtm-ui-icon>
            {{ "dtmSupOperationalSituation.temporaryZones.openDraftFormButtonLabel" | transloco }}
        </button>
    </div>
    <supervisor-shared-lib-temporary-zones-form
        dtmUiMarkValueAccessorControlsAsTouched
        [mapEntity]="mapEntity$ | ngrxPush"
        [isElevationProcessing]="isElevationProcessing$ | ngrxPush"
        [formControl]="temporaryZoneControl"
        [elevation]="elevation$ | ngrxPush"
        [geographicZones]="vm.availableZones"
        *ngIf="vm.isEditMode"
    ></supervisor-shared-lib-temporary-zones-form>

    <div class="dtm-tabs" *ngIf="!vm.isEditMode">
        <mat-tab-group
            [selectedIndex]="selectedTab$ | ngrxPush"
            color="accent"
            dynamicHeight
            disablePagination
            (selectedTabChange)="tabChange.emit($event.index)"
        >
            <mat-tab [label]="'dtmSupOperationalSituation.temporaryZones.currentlyValidLabel' | transloco">
                <supervisor-shared-lib-zone-filters
                    [geographicZones]="vm.availableZones"
                    (filtersChange)="zonesFiltersChange.emit({ zoneFiltersData: $event, zoneType: TemporaryZoneType.Zone })"
                ></supervisor-shared-lib-zone-filters>
                <div class="empty-list" *ngIf="!vm.activeZoneList?.length">
                    {{ "dtmSupOperationalSituation.temporaryZones.emptyPublishedZoneListLabel" | transloco }}
                </div>
                <div class="zone-list">
                    <supervisor-shared-lib-zone-info
                        *ngFor="let activeZone of vm.activeZoneList | ngrxPush"
                        isActive
                        canPublish
                        canClone
                        [zone]="activeZone"
                        [isZoneSelected]="vm.selectedZoneId === activeZone.id"
                        [zoneDetails]="vm.zoneDetails"
                        (click)="zoneSelect.emit(activeZone.id)"
                        (zoneManage)="zoneFinish.emit($event)"
                        (zoneZoom)="zoneZoom.emit($event)"
                        (zoneMark)="getTemporaryZoneDetails($event, TemporaryZoneType.Zone)"
                        (zoneClone)="zoneClone.emit($event)"
                    ></supervisor-shared-lib-zone-info>
                </div>
            </mat-tab>
            <mat-tab [label]="'dtmSupOperationalSituation.temporaryZones.draftLabel' | transloco">
                <supervisor-shared-lib-zone-filters
                    [geographicZones]="vm.availableZones"
                    (filtersChange)="zonesFiltersChange.emit({ zoneFiltersData: $event, zoneType: TemporaryZoneType.Draft })"
                ></supervisor-shared-lib-zone-filters>
                <div class="empty-list" *ngIf="!vm.draftZoneList?.length">
                    {{ "dtmSupOperationalSituation.temporaryZones.emptyDraftZoneListLabel" | transloco }}
                </div>
                <div class="zone-list">
                    <supervisor-shared-lib-zone-info
                        *ngFor="let draftZone of vm.draftZoneList | ngrxPush"
                        canEdit
                        canPublish
                        [zone]="draftZone"
                        [isZoneSelected]="vm.selectedZoneId === draftZone.id"
                        [zoneDetails]="vm.draftZoneDetails"
                        (click)="draftSelect.emit(draftZone.id)"
                        (zoneDelete)="draftDelete.emit($event)"
                        (zoneManage)="zonePublish.emit($event)"
                        (zoneZoom)="zoneZoom.emit($event)"
                        (zoneMark)="getTemporaryZoneDetails($event, TemporaryZoneType.Draft)"
                    ></supervisor-shared-lib-zone-info>
                </div>
            </mat-tab>
            <mat-tab [label]="'dtmSupOperationalSituation.temporaryZones.archivalLabel' | transloco">
                <supervisor-shared-lib-zone-filters
                    [geographicZones]="vm.availableZones"
                    (filtersChange)="zonesFiltersChange.emit({ zoneFiltersData: $event, zoneType: TemporaryZoneType.Archive })"
                ></supervisor-shared-lib-zone-filters>
                <div class="empty-list" *ngIf="!vm.archiveZoneList?.length">
                    {{ "dtmSupOperationalSituation.temporaryZones.emptyArchivedZoneListLabel" | transloco }}
                </div>
                <div class="zone-list">
                    <supervisor-shared-lib-zone-info
                        *ngFor="let archiveZone of vm.archiveZoneList"
                        [zone]="archiveZone"
                        [isZoneSelected]="vm.selectedZoneId === archiveZone.id"
                        [zoneDetails]="vm.zoneDetails"
                        (click)="zoneSelect.emit(archiveZone.id)"
                        (zoneManage)="zonePublish.emit($event)"
                        (zoneZoom)="zoneZoom.emit($event)"
                    ></supervisor-shared-lib-zone-info>
                    <button
                        *ngIf="!(isArchivedZonesFullyLoaded$ | ngrxPush)"
                        type="button"
                        class="button-tertiary"
                        (click)="loadMore.emit()"
                    >
                        {{
                            "dtmSupOperationalSituation.temporaryZones.loadMoreZonesButtonLabel"
                                | transloco : { value: DEFAULT_ARCHIVE_ZONES_LIST_SIZE }
                        }}
                    </button>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</ng-container>
