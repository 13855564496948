import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, map, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Installation, NetworkMonitoringErrorType, PageableDeviceHistory, SignalViolationData } from "../models/network-monitoring.models";
import { NETWORK_MONITORING_ENDPOINTS, NetworkMonitoringEndpoints } from "../network-monitoring.tokens";
import {
    DeviceHistoryContentResponseBody,
    DeviceHistoryResponseBody,
    InstallationResponseBody,
    convertDeviceHistoryResponseBodyToDeviceHistory,
    convertInstallationResponseBodyToInstallationList,
} from "./network-monitoring-api.converters";

export const DEVICE_VIOLATION_HISTORY_DEFAULT_ELEMENTS_NUMBER = 5;

@Injectable()
export class NetworkMonitoringApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(NETWORK_MONITORING_ENDPOINTS) private readonly endpoints: NetworkMonitoringEndpoints
    ) {}

    public getInstallations(): Observable<Installation[]> {
        return this.httpClient.get<{ content: InstallationResponseBody[] }>(this.endpoints.getInstallations).pipe(
            map((response) => convertInstallationResponseBodyToInstallationList(response.content)),
            catchError(() => throwError(() => ({ type: NetworkMonitoringErrorType.CannotGetInstallations })))
        );
    }

    public getDeviceHistory(deviceId: string, page?: number): Observable<PageableDeviceHistory> {
        let params = new HttpParams().set("deviceId", deviceId).set("size", DEVICE_VIOLATION_HISTORY_DEFAULT_ELEMENTS_NUMBER);

        if (page) {
            params = params.append("page", page);
        }

        return this.httpClient.get<DeviceHistoryResponseBody>(this.endpoints.getDeviceHistory, { params }).pipe(
            map((response) => convertDeviceHistoryResponseBodyToDeviceHistory(response)),
            catchError(() => throwError(() => ({ type: NetworkMonitoringErrorType.CannotGetDeviceHistory })))
        );
    }

    public updateSignalViolationNote(noteData: SignalViolationData): Observable<DeviceHistoryContentResponseBody> {
        return this.httpClient
            .put<DeviceHistoryContentResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.updateViolationNote, {
                    installationId: noteData.installationId,
                    sectorId: noteData.sectorId,
                    signalViolationId: noteData.signalViolationId,
                }),
                {
                    person: noteData.supervisorName,
                    content: noteData.note,
                }
            )
            .pipe(catchError(() => throwError(() => ({ type: NetworkMonitoringErrorType.CannotUpdateNote }))));
    }
}
