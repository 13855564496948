<ng-container
    *ngrxLet="{
        isShown: isShown$,
        areLabelsVisible: areLabelsVisible$,
    } as vm"
>
    <ac-layer acFor="let entity of flightAreaEntities$" [context]="this" [show]="vm.isShown">
        <ac-polygon-desc
            props="{
                hierarchy: entity.positions,
                material: entity.style.fill
            }"
        >
        </ac-polygon-desc>
        <ac-polyline-desc
            props="{
                positions: entity.positions,
                width: entity.style.outlineWidth,
                material: entity.style.outline
            }"
        >
        </ac-polyline-desc>
    </ac-layer>

    <ac-layer acFor="let entity of pathEntities$" [context]="this" [show]="vm.isShown">
        <ac-polyline-desc
            props="{
                positions: entity.positions,
                width: entity.style.outlineWidth,
                material: entity.style.outline
            }"
        >
        </ac-polyline-desc>
    </ac-layer>
    <ac-layer acFor="let entity of waypointEntities$" [context]="this" [show]="vm.isShown">
        <ac-point-desc
            props="{
                position: entity.position,
                pixelSize: 8,
                color: entity.style.fill,
                show: entity.show
            }"
        >
        </ac-point-desc>
        <ac-html-desc props="{ position: entity.position }">
            <ng-template let-pin>
                <dtm-ui-icon name="flight-takeoff" class="pin" *ngIf="vm.isShown && pin.start && vm.areLabelsVisible"></dtm-ui-icon>
                <dtm-ui-icon name="flight-land" class="pin" *ngIf="vm.isShown && pin.landing && vm.areLabelsVisible"></dtm-ui-icon>
            </ng-template>
        </ac-html-desc>
    </ac-layer>
    <ac-layer acFor="let entity of pinEntities$" [context]="this" [show]="vm.isShown">
        <ac-html-desc props="{ position: entity.position, show: entity.show }">
            <ng-template let-pin>
                <div class="mission-pin" *ngIf="vm.isShown && !vm.areLabelsVisible && pin.data.index === 0">
                    <dtm-ui-icon name="map-pin-fill" class="pin-icon"></dtm-ui-icon>
                    <button type="button" class="button-icon map-icon" (click)="missionSelect.emit(pin.planId)">
                        <dtm-ui-icon name="road-map"></dtm-ui-icon>
                    </button>
                </div>
                <ng-container *ngIf="vm.areLabelsVisible && vm.isShown">
                    <svg width="100" height="100" viewBox="-50 -50 100 100" class="line-connector">
                        <line [attr.x1]="0" [attr.y1]="0" [attr.x2]="0" [attr.y2]="-50" />
                        <circle r="4" cx="0" cy="0" />
                    </svg>
                    <div class="segment-pin" *ngIf="vm.isShown" [class.main]="pin.data.isSelected">
                        <ng-container *ngIf="pin.data.flightArea | invoke : getHeightRangeValues as heightRangeValues">
                            <div class="header">
                                <ng-template
                                    [ngTemplateOutlet]="heightLabelTemplate"
                                    [ngTemplateOutletContext]="{
                                        agl: heightRangeValues.maxHeightAgl,
                                        amsl: heightRangeValues.maxHeightAmsl
                                    }"
                                ></ng-template>
                            </div>
                            <div class="sub header">
                                <ng-template
                                    [ngTemplateOutlet]="heightLabelTemplate"
                                    [ngTemplateOutletContext]="{
                                        agl: heightRangeValues.minHeightAgl,
                                        amsl: heightRangeValues.minHeightAmsl,
                                        isOnTheGround: heightRangeValues.isOnTheGround,
                                    }"
                                ></ng-template>
                            </div>
                        </ng-container>

                        <ng-template #heightLabelTemplate let-agl="agl" let-amsl="amsl" let-isOnTheGround="isOnTheGround">
                            <ng-container *ngIf="isOnTheGround">
                                {{ "dtmMapCesium.groundLabel" | transloco }}
                            </ng-container>
                            <ng-container *ngIf="!isOnTheGround">
                                {{ "dtmMapCesium.aglLabel" | transloco : { height: agl | localizeNumber : { maximumFractionDigits: 0 } } }}
                                <p>
                                    ({{
                                        "dtmMapCesium.amslLabel"
                                            | transloco
                                                : {
                                                      height: amsl | localizeNumber : { maximumFractionDigits: 0 }
                                                  }
                                    }})
                                </p>
                            </ng-container>
                        </ng-template>
                    </div>
                </ng-container>
            </ng-template>
        </ac-html-desc>
    </ac-layer>
</ng-container>
