<dtm-ui-expandable-panel
    *ngrxLet="{ aerial: aerial$, selectedAerialId: selectedAerialId$ } as vm"
    [hasHeaderSeparator]="false"
    [isExpanded]="vm.aerial.id === vm.selectedAerialId"
    (expandedChange)="$event && aerialExpand.emit(vm.aerial.id)"
>
    <div headerSlot>
        <div class="installation-header">
            <div class="installation-name">
                <dtm-ui-icon name="base-station"></dtm-ui-icon>
                {{ vm.aerial.name }}
            </div>
            <supervisor-shared-lib-installation-status
                [status]="vm.aerial.sectors | invoke : getSectorStatuses"
            ></supervisor-shared-lib-installation-status>
        </div>
    </div>
    <div class="installation-info">
        <dtm-ui-label-value
            class="id"
            [label]="'dtmSupNetworkMonitoring.aerialInfoPanel.idLabel' | transloco"
            [value]="vm.aerial.id"
        ></dtm-ui-label-value>
        <dtm-ui-label-value [label]="'dtmSupNetworkMonitoring.aerialInfoPanel.addressLabel' | transloco">
            {{ vm.aerial.address.streetName }} {{ vm.aerial.address.houseNumber }}, {{ vm.aerial.address.postCode }}
            {{ vm.aerial.address.city }}
        </dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmSupNetworkMonitoring.aerialInfoPanel.startDateLabel' | transloco"
            [value]="vm.aerial.installationDate | localizeDate"
        ></dtm-ui-label-value>
    </div>
    <div *ngFor="let sector of vm.aerial.sectors" class="details">
        <div class="sector-name">
            <supervisor-shared-lib-installation-status [status]="sector.sectorStatus"></supervisor-shared-lib-installation-status>
            {{ sector.name }}:
        </div>
        <div class="days">
            <dtm-ui-icon name="calendar-event"></dtm-ui-icon>
            {{ "dtmSupNetworkMonitoring.networkMonitoringContainer.daysLabel" | transloco }}
        </div>
        <div class="violation-time" [class.is-violated]="sector.last7DaysViolationInMinutes">
            <dtm-ui-icon
                class="violation"
                [name]="sector.last7DaysViolationInMinutes ? 'flashlight-filled' : 'flashlight-outline'"
            ></dtm-ui-icon>
            {{
                "dtmSupNetworkMonitoring.networkMonitoringContainer.minValueLabel"
                    | transloco : { value: sector.last7DaysViolationInMinutes }
            }}
        </div>

        <div class="map">
            <button type="button" class="button-icon">
                <dtm-ui-icon name="road-map"></dtm-ui-icon>
            </button>
        </div>
    </div>
</dtm-ui-expandable-panel>
