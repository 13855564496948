import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { supPermissionGuard } from "../shared/guards/sup-permission.guard";
import { WorkspacePermission } from "../sup-user/index";
import { PlannedMissionContainerComponent } from "./components/planned-mission-container/planned-mission-container.component";

const routes: Routes = [
    {
        path: "planned-missions",
        canActivate: [DtmRoleGuard, supPermissionGuard([WorkspacePermission.PlannedMissionsRead])],
        component: PlannedMissionContainerComponent,
        data: {
            titleTranslationKey: "dtmSupPlannedMissions.routeTitles.plannedMissions",
            className: "no-padding",
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PlannedMissionsRoutingModule {}
