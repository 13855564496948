import { PortalModule } from "@angular/cdk/portal";
import { DOCUMENT } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, Inject, NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthState, KEYCLOAK_CONFIG, SharedAuthModule } from "@dtm-frontend/shared/auth";
import { AZURE_MAPS_SUBSCRIPTION_KEY, SHARED_MAP_ENDPOINTS } from "@dtm-frontend/shared/map";
import { CesiumMapModule } from "@dtm-frontend/shared/map/cesium";
import { FLIGHT_TRACKING_ENDPOINTS, FlightTrackingModule } from "@dtm-frontend/shared/map/flight-tracking";
import { WEATHER_ENDPOINTS, WeatherModule } from "@dtm-frontend/shared/map/geo-weather";
import { GEO_ZONES_ENDPOINTS, GeoZonesModule } from "@dtm-frontend/shared/map/geo-zones";
import { MISSION_SEARCH_ENDPOINTS, MissionSearchModule, UAV_APP_URL } from "@dtm-frontend/shared/mission";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import {
    I18nRootModule,
    LANGUAGE_CONFIGURATION,
    LanguageCode,
    TRANSLATION_ENDPOINTS,
    TranslationHelperService,
    getTranslocoInlineLoader,
} from "@dtm-frontend/shared/ui/i18n";
import { VERSION_DATA_ENDPOINTS, VersionModule } from "@dtm-frontend/shared/ui/version";
import { FunctionUtils, Logger, LoggerModule, RxjsUtils } from "@dtm-frontend/shared/utils";
import { SharedWebsocketModule, SharedWebsocketModuleConfig, WEBSOCKET_CONFIGURATION } from "@dtm-frontend/shared/websocket";
import {
    ADDING_PERMISSIONS_ENDPOINTS,
    AddingPermissionsModule,
    CapabilitiesActions,
    GLOBAL_ALARM_ENDPOINTS,
    GlobalAlarmModule,
    IS_OLD_DTM_SUP,
    NETWORK_MONITORING_ENDPOINTS,
    NetworkMonitoringModule,
    OPERATIONAL_SITUATION_ENDPOINTS,
    OperationalSituationModule,
    PLANNED_MISSION_ENDPOINTS,
    PlannedMissionsModule,
    SHARED_ENDPOINTS,
    SUP_SOUND_DEFINITIONS,
    SupUserModule,
} from "@dtm-frontend/supervisor-shared-lib";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsStoragePluginModule, StorageOption } from "@ngxs/storage-plugin";
import { NgxsModule, Store } from "@ngxs/store";
import { KeycloakEventType, KeycloakService } from "keycloak-angular";
import { finalize, forkJoin, lastValueFrom } from "rxjs";
import { filter, first, switchMap, tap } from "rxjs/operators";
import { DtmSupervisorClientEnvironment } from "../environments/environment.model";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { HeaderComponent } from "./components/header/header.component";
import { LazyRouterOutletComponent } from "./components/lazy-router-outlet/lazy-router-outlet.component";
import { MenuComponent } from "./components/menu/menu.component";
import { ActionsHandler } from "./services/actions.handler";

Logger.initialize("https://bef3251b7724fdae73da54d075b8a3bc@sentry.pansa.cloud/6");

export function createAppModule(environment: DtmSupervisorClientEnvironment) {
    @NgModule({
        declarations: [AppComponent, DashboardComponent, LazyRouterOutletComponent, HeaderComponent, MenuComponent],
        imports: [
            NgxsModule.forRoot([], {
                // NOTE: must be first because of https://github.com/ngxs/store/issues/375
                developmentMode: !environment.production,
            }),
            AppRoutingModule,
            BrowserAnimationsModule,
            BrowserModule,
            HttpClientModule,
            MatButtonModule,
            MatCardModule,
            MatIconModule,
            MatMenuModule,
            NgxsStoragePluginModule.forRoot({
                key: ["ui"],
                storage: StorageOption.LocalStorage,
            }),
            NgxsReduxDevtoolsPluginModule.forRoot({
                disabled: environment.production,
                name: "DtmSupClient",
            }),
            PortalModule,
            LetModule,
            PushModule,
            LoggerModule.forRoot(environment.name, !environment.production),
            SharedAuthModule.forRoot({
                bearerExcludedUrls: [...environment.bearerExcludedUrls],
            }),
            I18nRootModule.forRoot({
                developmentMode: !environment.production,
                ...LANGUAGE_CONFIGURATION,
            }),
            SharedUiModule.forRoot({ soundDefinitions: SUP_SOUND_DEFINITIONS }),
            VersionModule.forRoot(),
            CesiumMapModule,
            MatTooltipModule,
            PlannedMissionsModule.forRoot(),
            OperationalSituationModule.forRoot(),
            SharedWebsocketModule.forRoot(),
            NetworkMonitoringModule.forRoot(),
            GlobalAlarmModule.forRoot(),
            AddingPermissionsModule.forRoot(),
            GeoZonesModule.forRoot(),
            WeatherModule.forRoot(),
            MissionSearchModule.forRoot(),
            FlightTrackingModule.forRoot(),
            SupUserModule.forDtmSupervisor(),
        ],
        providers: [
            {
                provide: VERSION_DATA_ENDPOINTS,
                useValue: environment.versionDataEndpoints,
            },
            {
                provide: PLANNED_MISSION_ENDPOINTS,
                useValue: environment.plannedMissionEndpoints,
            },
            {
                provide: SHARED_ENDPOINTS,
                useValue: environment.sharedEndpoints,
            },
            {
                provide: OPERATIONAL_SITUATION_ENDPOINTS,
                useValue: environment.operationalSituationEndpoints,
            },
            {
                provide: NETWORK_MONITORING_ENDPOINTS,
                useValue: environment.networkMonitoringEndpoints,
            },
            {
                provide: GLOBAL_ALARM_ENDPOINTS,
                useValue: environment.globalAlarmEndpoints,
            },
            {
                provide: APP_INITIALIZER,
                useFactory: () => FunctionUtils.noop,
                deps: [ActionsHandler],
                multi: true,
            },
            {
                provide: TRANSLOCO_SCOPE,
                multi: true,
                useValue: {
                    scope: "dtmSupClient",
                    loader: getTranslocoInlineLoader((language: LanguageCode) => import(`../assets/i18n/${language}.json`)),
                },
            },
            {
                provide: SHARED_MAP_ENDPOINTS,
                useValue: environment.sharedMapEndpoints,
            },
            {
                provide: AZURE_MAPS_SUBSCRIPTION_KEY,
                useValue: environment.azureMapsSubscriptionKey,
            },
            {
                provide: MISSION_SEARCH_ENDPOINTS,
                useValue: environment.missionSearchEndpoints,
            },
            {
                provide: UAV_APP_URL,
                useValue: environment.uavAppUrl,
            },
            {
                provide: WEBSOCKET_CONFIGURATION,
                useFactory: (store: Store): SharedWebsocketModuleConfig => ({
                    endpoint: environment.webSocketEndpoint,
                    authorizationTokenProvider: async () =>
                        await lastValueFrom(store.select(AuthState.token).pipe(RxjsUtils.filterFalsy(), first())),
                }),
                deps: [Store],
            },
            {
                provide: KEYCLOAK_CONFIG,
                useValue: environment.keycloakConfig,
            },
            {
                provide: GEO_ZONES_ENDPOINTS,
                useValue: environment.geoZonesEndpoints,
            },
            {
                provide: ADDING_PERMISSIONS_ENDPOINTS,
                useValue: environment.addingPermissionsEndpoints,
            },
            {
                provide: WEATHER_ENDPOINTS,
                useValue: environment.weatherEndpoints,
            },
            {
                provide: TRANSLATION_ENDPOINTS,
                useValue: environment.translationEndpoints,
            },
            { provide: FLIGHT_TRACKING_ENDPOINTS, useValue: environment.flightTrackingEndpoints },
            {
                provide: IS_OLD_DTM_SUP,
                useValue: true,
            },
        ],
        bootstrap: [AppComponent],
    })
    class AppModule {
        constructor(
            keycloakService: KeycloakService,
            store: Store,
            translocoHelper: TranslationHelperService,
            titleService: Title,
            @Inject(DOCUMENT) document: Document
        ) {
            // TODO: is this a good place for some initializing tasks? Maybe it should be extracted somewhere, use APP_INITIALIZER?
            const gotGlobalCapabilities$ = keycloakService.keycloakEvents$.pipe(
                filter((event) => event?.type === KeycloakEventType.OnReady),
                first(),
                switchMap(() => store.dispatch([new CapabilitiesActions.GetCapabilities()]))
            );

            const gotTitle$ = translocoHelper
                .waitForTranslation("dtmSupClient.appTitle")
                .pipe(first(), tap(titleService.setTitle.bind(titleService)));

            forkJoin([gotGlobalCapabilities$, gotTitle$])
                .pipe(
                    first(),
                    finalize(() => {
                        document.getElementById("loader")?.remove();
                    })
                )
                .subscribe();
        }
    }

    return AppModule;
}
