import { Injectable } from "@angular/core";
import { GeoZonesActions } from "@dtm-frontend/shared/map/geo-zones";
import {
    AuthorityAcceptationStatus,
    EvaluationIssueStatus,
    MissionPlanAnalysisIssueBase,
    MissionPlanAnalysisIssueStatus,
    MissionPlanAnalysisStatus,
    MissionPlanDataAndCapabilities,
    MissionUtils,
} from "@dtm-frontend/shared/mission";
import { MissionPlanRoute, SoundsActions } from "@dtm-frontend/shared/ui";
import { FunctionUtils } from "@dtm-frontend/shared/utils";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { saveAs } from "file-saver";
import { EMPTY, Subject, finalize, firstValueFrom, forkJoin, merge, takeUntil } from "rxjs";
import { catchError, first, tap } from "rxjs/operators";
import { MissionNotification, MissionsEvents, SupSoundType } from "../../shared";
import { DtmAreasState } from "../../shared/state/dtm-areas.state";
import { AuthorityUnitType, SupUserState } from "../../sup-user";
import {
    Mission,
    MissionFilters,
    MissionProcessingPhase,
    MissionStatusType,
    NearbyMissionFilters,
    PlannedMissionError,
} from "../models/mission.models";
import { PlannedMissionApiService } from "../services/planned-mission-api.service";
import { MissionActions } from "./mission.actions";

interface MissionPhaseFilters {
    [key: string]: MissionFilters;
}

const ISSUES_STATUS_SORTING_ORDER = [
    MissionPlanAnalysisIssueStatus.FatalError,
    MissionPlanAnalysisIssueStatus.Error,
    MissionPlanAnalysisIssueStatus.Hold,
    MissionPlanAnalysisIssueStatus.Warning,
    MissionPlanAnalysisIssueStatus.Info,
    MissionPlanAnalysisIssueStatus.Success,
    EvaluationIssueStatus.Rejected,
    EvaluationIssueStatus.Suppressed,
    EvaluationIssueStatus.Open,
] as const;

const filterByPhase = (missionList: Mission[], phase: MissionProcessingPhase): Mission[] =>
    missionList.filter((mission) => mission.phase === phase);

const defaultMissionFilters: MissionFilters = {
    sort: "flightStartAtMin,asc",
    phase: null,
    dtmArea: [],
};

const defaultMissionPhaseFilters: MissionPhaseFilters = {
    [MissionProcessingPhase.Waiting]: defaultMissionFilters,
    [MissionProcessingPhase.Accepted]: defaultMissionFilters,
    [MissionProcessingPhase.Rejected]: defaultMissionFilters,
};

interface MissionStateModel {
    acceptedMissions: Mission[] | undefined;
    rejectedMissions: Mission[] | undefined;
    waitingMissions: Mission[] | undefined;
    missionPlanRoute: MissionPlanRoute | undefined;
    nearbyMissionRoute: MissionPlanRoute | undefined;
    missionListError: PlannedMissionError | undefined;
    acceptanceMissionError: PlannedMissionError | undefined;
    rejectionMissionError: PlannedMissionError | undefined;
    updatePriorityError: PlannedMissionError | undefined;
    missionPlanDataError: PlannedMissionError | undefined;
    nearbyMissionDataError: PlannedMissionError | undefined;
    airspaceError: PlannedMissionError | undefined;
    planAnalysisError: PlannedMissionError | undefined;
    isProcessing: boolean;
    isMissionListProcessing: boolean;
    isPlanRouteProcessing: boolean;
    isNearbyMissionRouteProcessing: boolean;
    missionPlanRouteId: string | undefined;
    nearbyMissionRouteId: string | undefined;
    missionPlanRouteError: PlannedMissionError | undefined;
    nearbyMissionRouteError: PlannedMissionError | undefined;
    updateSupervisorNoteError: PlannedMissionError | undefined;
    attachmentError: PlannedMissionError | undefined;
    nearbyMissionsError: PlannedMissionError | undefined;
    missionFilters: MissionPhaseFilters;
    areNewMissionsAvailable: boolean;
    areNearbyMissionsAvailable: boolean;
    nearbyMissions: Mission[] | undefined;
    currentPlanDataAndCapabilities: MissionPlanDataAndCapabilities | undefined;
    currentNearbyMissionDataAndCapabilities: MissionPlanDataAndCapabilities | undefined;
    missionPlanAnalysisStatus: MissionPlanAnalysisStatus | undefined;
    nearbyMissionAnalysisStatus: MissionPlanAnalysisStatus | undefined;
    defaultMissionFilters: MissionFilters | undefined;
    lastNearbyMissionsFilters: NearbyMissionFilters | undefined;
}

const defaultState: MissionStateModel = {
    missionListError: undefined,
    waitingMissions: undefined,
    rejectedMissions: undefined,
    missionPlanRoute: undefined,
    nearbyMissionRoute: undefined,
    acceptedMissions: undefined,
    updatePriorityError: undefined,
    missionPlanDataError: undefined,
    nearbyMissionDataError: undefined,
    acceptanceMissionError: undefined,
    rejectionMissionError: undefined,
    updateSupervisorNoteError: undefined,
    airspaceError: undefined,
    planAnalysisError: undefined,
    attachmentError: undefined,
    isProcessing: false,
    isMissionListProcessing: false,
    missionPlanRouteId: undefined,
    nearbyMissionRouteId: undefined,
    isPlanRouteProcessing: false,
    missionPlanRouteError: undefined,
    nearbyMissionRouteError: undefined,
    nearbyMissionsError: undefined,
    missionFilters: defaultMissionPhaseFilters,
    areNewMissionsAvailable: false,
    areNearbyMissionsAvailable: false,
    nearbyMissions: undefined,
    isNearbyMissionRouteProcessing: false,
    currentPlanDataAndCapabilities: undefined,
    missionPlanAnalysisStatus: undefined,
    currentNearbyMissionDataAndCapabilities: undefined,
    nearbyMissionAnalysisStatus: undefined,
    defaultMissionFilters: undefined,
    lastNearbyMissionsFilters: undefined,
};

@State<MissionStateModel>({
    name: "mission",
    defaults: defaultState,
})
@Injectable()
export class MissionState {
    private stopPlannedMission$: Subject<void> | undefined;
    private stopNearbyMission$: Subject<void> | undefined;

    constructor(private readonly missionApi: PlannedMissionApiService, private readonly store: Store) {}

    @Selector()
    public static waitingMissions(state: MissionStateModel): Mission[] | undefined {
        return state.waitingMissions;
    }

    @Selector()
    public static rejectedMissions(state: MissionStateModel): Mission[] | undefined {
        return state.rejectedMissions;
    }

    @Selector()
    public static acceptedMissions(state: MissionStateModel): Mission[] | undefined {
        return state.acceptedMissions;
    }

    @Selector()
    public static isProcessing(state: MissionStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static isMissionListProcessing(state: MissionStateModel): boolean {
        return state.isMissionListProcessing;
    }

    @Selector()
    public static isPlanRouteProcessing(state: MissionStateModel): boolean {
        return state.isPlanRouteProcessing;
    }

    @Selector()
    public static selectedMissionPlanRoute(state: MissionStateModel): MissionPlanRoute | undefined {
        return state.missionPlanRoute;
    }

    @Selector()
    public static missionPlanRouteError(state: MissionStateModel): PlannedMissionError | undefined {
        return state.missionPlanRouteError;
    }

    @Selector()
    public static acceptanceMissionError(state: MissionStateModel): PlannedMissionError | undefined {
        return state.acceptanceMissionError;
    }

    @Selector()
    public static rejectionMissionError(state: MissionStateModel): PlannedMissionError | undefined {
        return state.rejectionMissionError;
    }

    @Selector()
    public static updatePriorityError(state: MissionStateModel): PlannedMissionError | undefined {
        return state.updatePriorityError;
    }

    @Selector()
    public static missionPlanDataError(state: MissionStateModel): PlannedMissionError | undefined {
        return state.missionPlanDataError;
    }

    @Selector()
    public static nearbyMissionDataError(state: MissionStateModel): PlannedMissionError | undefined {
        return state.nearbyMissionDataError;
    }

    @Selector()
    public static areNewMissionsAvailable(state: MissionStateModel): boolean {
        return state.areNewMissionsAvailable;
    }

    @Selector()
    public static areNearbyMissionsAvailable(state: MissionStateModel): boolean {
        return state.areNearbyMissionsAvailable;
    }

    @Selector()
    public static nearbyMissions(state: MissionStateModel): Mission[] | undefined {
        return state.nearbyMissions;
    }

    @Selector()
    public static missionDefaultFilters(state: MissionStateModel): MissionFilters | undefined {
        return state.defaultMissionFilters;
    }

    @Selector()
    public static isNearbyMissionRouteProcessing(state: MissionStateModel): boolean {
        return state.isNearbyMissionRouteProcessing;
    }

    @Selector()
    public static selectedNearbyMissionRoute(state: MissionStateModel): MissionPlanRoute | undefined {
        return state.nearbyMissionRoute;
    }

    @Selector()
    public static nearbyMissionsError(state: MissionStateModel): PlannedMissionError | undefined {
        return state.nearbyMissionsError;
    }

    @Selector()
    public static updateSupervisorNoteError(state: MissionStateModel): PlannedMissionError | undefined {
        return state.updateSupervisorNoteError;
    }

    @Selector()
    public static nearbyMissionRouteError(state: MissionStateModel): PlannedMissionError | undefined {
        return state.nearbyMissionRouteError;
    }

    @Selector()
    public static attachmentError(state: MissionStateModel): PlannedMissionError | undefined {
        return state.attachmentError;
    }

    @Selector()
    public static currentMissionPlanData(state: MissionStateModel): MissionPlanDataAndCapabilities | undefined {
        return state.currentPlanDataAndCapabilities;
    }

    @Selector()
    public static currentNearbyMissionData(state: MissionStateModel): MissionPlanDataAndCapabilities | undefined {
        return state.currentNearbyMissionDataAndCapabilities;
    }

    @Selector()
    public static currentPlanAnalysisStatus(state: MissionStateModel): MissionPlanAnalysisStatus | undefined {
        return state.missionPlanAnalysisStatus;
    }

    @Selector()
    public static currentPlanId(state: MissionStateModel): string | undefined {
        return state.currentPlanDataAndCapabilities?.plan.id;
    }

    @Selector()
    public static missionAnalysisDataError(state: MissionStateModel): boolean {
        return !!state.planAnalysisError || !!state.airspaceError;
    }

    @Action(MissionActions.PlannedMissionsWatch)
    public plannedMissionWatch(context: StateContext<MissionStateModel>) {
        this.stopPlannedMission$ = new Subject<void>();

        return this.missionApi.startPlannedMissionsWatch().pipe(
            tap((notification) => {
                const authorityUnitType = this.store.selectSnapshot(SupUserState.selectedWorkspace)?.authorityUnit?.type;

                if (notification?.type === MissionsEvents.ManualPlanVerificationSubmittedEvent) {
                    context.patchState({ areNewMissionsAvailable: true });
                    context.dispatch(
                        authorityUnitType === AuthorityUnitType.Utm
                            ? new SoundsActions.PlaySound(SupSoundType.PlannedMissionUtmNotification)
                            : new SoundsActions.PlaySound(SupSoundType.PlannedMissionNotification)
                    );
                }
                this.handleMissionNotification(context, notification);
            }),
            takeUntil(this.stopPlannedMission$)
        );
    }

    @Action(MissionActions.PlannedMissionsWatchByAuthorityUnit)
    public plannedMissionWatchByAuthorityUnit(
        context: StateContext<MissionStateModel>,
        { unitId, authorityUnitsZones }: MissionActions.PlannedMissionsWatchByAuthorityUnit
    ) {
        this.stopPlannedMission$ = new Subject<void>();

        if (authorityUnitsZones.length) {
            const combinedWsStreams = authorityUnitsZones.map((zone) => this.missionApi.startPlannedMissionsWatchByZone(zone));

            return merge(...combinedWsStreams).pipe(
                tap((notification) => this.handleMissionNotification(context, notification)),
                takeUntil(this.stopPlannedMission$)
            );
        }

        return this.missionApi.startPlannedMissionsWatchByAuthorityUnitId(unitId).pipe(
            tap((notification) => this.handleMissionNotification(context, notification)),
            takeUntil(this.stopPlannedMission$)
        );
    }

    private handleMissionNotification(context: StateContext<MissionStateModel>, notification?: MissionNotification) {
        if (!notification) {
            return;
        }

        switch (notification.type) {
            case MissionsEvents.ManualPlanVerificationSubmittedEvent:
                context.dispatch(new MissionActions.GetMissionPlanList(MissionProcessingPhase.Waiting, notification.planId));
                break;
            case MissionsEvents.MissionAcceptedEvent:
            case MissionsEvents.ManualPlanVerificationPartiallyAcceptedEvent:
                context.dispatch([
                    new MissionActions.GetMissionPlanList(MissionProcessingPhase.Waiting),
                    new MissionActions.GetMissionPlanList(MissionProcessingPhase.Accepted),
                ]);
                break;
            case MissionsEvents.PlanSubmissionTimeoutEvent:
                context.dispatch([
                    new MissionActions.GetMissionPlanList(MissionProcessingPhase.Accepted),
                    new MissionActions.GetMissionPlanList(MissionProcessingPhase.Waiting),
                    new MissionActions.GetMissionPlanList(MissionProcessingPhase.Rejected),
                ]);
                break;
            case MissionsEvents.PlanVerificationCompletedEvent:
                if (notification.isMissionForbidden) {
                    context.dispatch([
                        new MissionActions.GetMissionPlanList(MissionProcessingPhase.Accepted),
                        new MissionActions.GetMissionPlanList(MissionProcessingPhase.Waiting),
                        new MissionActions.GetMissionPlanList(MissionProcessingPhase.Rejected),
                    ]);
                }
                break;
            case MissionsEvents.MissionRejectedEvent:
                context.dispatch([
                    new MissionActions.GetMissionPlanList(MissionProcessingPhase.Accepted),
                    new MissionActions.GetMissionPlanList(MissionProcessingPhase.Rejected),
                ]);
                break;
            default:
                return;
        }
    }

    @Action(MissionActions.NearbyMissionsWatch)
    public nearbyMissionWatch(context: StateContext<MissionStateModel>, action: MissionActions.NearbyMissionsWatch) {
        this.stopNearbyMission$?.next();
        this.stopNearbyMission$?.complete();
        this.stopNearbyMission$ = new Subject<void>();

        return this.missionApi.startNearbyMissionsWatch(action.planId).pipe(
            tap(() => {
                context.patchState({ areNearbyMissionsAvailable: true });
                context.dispatch(new MissionActions.GetNearbyMissions(action.planId));
            }),
            takeUntil(this.stopNearbyMission$)
        );
    }

    @Action(MissionActions.StopPlannedMissionsWatch)
    public stopPlannedMissionsWatch() {
        this.stopPlannedMission$?.next();
        this.stopPlannedMission$?.complete();
    }

    @Action(MissionActions.StopNearbyMissionsWatch)
    public stopNearbyMissionsWatch(context: StateContext<MissionStateModel>) {
        const previousRouteId = context.getState().missionPlanRouteId;

        if (!previousRouteId) {
            return;
        }

        this.stopNearbyMission$?.next();
        this.stopNearbyMission$?.complete();
    }

    @Action(MissionActions.ClearNewMissionAvailability)
    public clearNewMissionAvailability(context: StateContext<MissionStateModel>) {
        context.patchState({ areNewMissionsAvailable: false });
    }

    @Action(MissionActions.ClearNearbyMissionAvailability)
    public clearNearbyMissionAvailability(context: StateContext<MissionStateModel>) {
        context.patchState({ areNearbyMissionsAvailable: false });
    }

    @Action(MissionActions.GetAllMissions)
    public getAllMissions(context: StateContext<MissionStateModel>) {
        context.patchState({ isMissionListProcessing: true });

        return forkJoin([
            context.dispatch(new MissionActions.GetMissionPlanList(MissionProcessingPhase.Waiting)),
            context.dispatch(new MissionActions.GetMissionPlanList(MissionProcessingPhase.Accepted)),
            context.dispatch(new MissionActions.GetMissionPlanList(MissionProcessingPhase.Rejected)),
        ]).pipe(
            finalize(() => {
                context.patchState({ isMissionListProcessing: false });
            })
        );
    }

    @Action(MissionActions.GetMissionPlanList)
    public async getMissionPlansList(context: StateContext<MissionStateModel>, action: MissionActions.GetMissionPlanList) {
        await firstValueFrom(this.store.select(DtmAreasState.dtmAreas).pipe(first(FunctionUtils.isTruthy)));
        context.patchState({ missionListError: undefined, missionPlanRoute: undefined, missionPlanRouteId: undefined });

        let filtersState = context.getState().missionFilters[action.missionPhase];
        const authorityUnit = this.store.selectSnapshot(SupUserState.selectedWorkspace)?.authorityUnit;

        if (authorityUnit) {
            return this.getUtmMissionPlanList(context, action);
        }

        // TODO:DTM-5282  when DTM supervisor will be removed getUtmMissionPlanList should replace getMissionPlanList completely
        if (filtersState && !filtersState.dtmArea?.length) {
            // NOTE: when no DTMs in filters use all available
            const dtmAreas = this.store.selectSnapshot(DtmAreasState.dtmAreas);
            filtersState = { ...filtersState, dtmArea: dtmAreas ?? [] };
        }
        const defaultMissionFiltersState = context.getState().defaultMissionFilters;

        return this.missionApi.getMissionList(filtersState ?? defaultMissionFiltersState, action.missionPhase).pipe(
            tap((missionList) => {
                switch (action.missionPhase) {
                    case MissionProcessingPhase.Waiting:
                        context.patchState({ waitingMissions: missionList });
                        break;
                    case MissionProcessingPhase.Rejected:
                        context.patchState({ rejectedMissions: missionList });
                        break;
                    case MissionProcessingPhase.Accepted:
                        context.patchState({ acceptedMissions: missionList });
                        break;
                }
            }),
            catchError((missionListError) => {
                context.patchState({
                    missionListError,
                });

                return EMPTY;
            })
        );
    }

    public async getUtmMissionPlanList(context: StateContext<MissionStateModel>, action: MissionActions.GetMissionPlanList) {
        const filtersState = context.getState().missionFilters[action.missionPhase];
        const authorityUnit = this.store.selectSnapshot(SupUserState.selectedWorkspace)?.authorityUnit;
        const authorityUnitZones = this.store.selectSnapshot(SupUserState.selectedWorkspace)?.authorityUnitZones;
        const authorityUnitType = authorityUnit?.type;

        const filters = { ...filtersState };
        filters.authorityUnits = authorityUnit?.id ? [authorityUnit.id] : [];
        if (filtersState && !filtersState.dtmArea?.length) {
            // NOTE: when no DTMs in filters use all available
            filters.zoneDesignators = authorityUnitZones?.map(({ designator }) => designator) ?? [];
        } else {
            filters.zoneDesignators = filtersState.dtmArea
                ?.map((dtmArea) => authorityUnitZones?.find((unit) => unit.designator === dtmArea)?.designator)
                .filter(FunctionUtils.isTruthy);
        }

        delete filters.dtmArea;

        const defaultMissionFiltersState = context.getState().defaultMissionFilters;

        let authorityUnitAcceptationStatus;

        switch (action.missionPhase) {
            case MissionProcessingPhase.Accepted:
                authorityUnitAcceptationStatus = AuthorityAcceptationStatus.Resolved;
                break;
            case MissionProcessingPhase.Waiting:
                authorityUnitAcceptationStatus = AuthorityAcceptationStatus.Open;
                break;
            // NOTE: MissionProcessingPhase.Rejected should ignore authorityUnitAcceptationStatus as all rejected missions should be shown
            // despite their acceptation status for the authority unit
            default:
        }

        return this.missionApi
            .getPlanList(filters ?? defaultMissionFiltersState, authorityUnitAcceptationStatus, action.missionPhase, authorityUnit)
            .pipe(
                tap((missionList) => {
                    switch (action.missionPhase) {
                        case MissionProcessingPhase.Waiting:
                            context.patchState({ waitingMissions: missionList });

                            if (
                                missionList.find(
                                    (mission) => mission.phase === MissionProcessingPhase.Waiting && mission.id === action.submittedPlanId
                                )
                            ) {
                                context.patchState({ areNewMissionsAvailable: true });
                                context.dispatch(
                                    authorityUnitType === AuthorityUnitType.Utm
                                        ? new SoundsActions.PlaySound(SupSoundType.PlannedMissionUtmNotification)
                                        : new SoundsActions.PlaySound(SupSoundType.PlannedMissionNotification)
                                );
                            }
                            break;
                        case MissionProcessingPhase.Rejected:
                            context.patchState({ rejectedMissions: missionList });
                            break;
                        case MissionProcessingPhase.Accepted:
                            context.patchState({ acceptedMissions: missionList });
                            break;
                    }
                }),
                catchError((missionListError) => {
                    context.patchState({
                        missionListError,
                    });

                    return EMPTY;
                })
            );
    }

    @Action(MissionActions.UpdateMissionFilters)
    public updateMissionFilters(context: StateContext<MissionStateModel>, action: MissionActions.UpdateMissionFilters) {
        const { phase, ...filters } = action.missionFilters;
        const missionFiltersState = context.getState().missionFilters;

        if (!phase) {
            return;
        }

        context.patchState({
            missionFilters: {
                ...missionFiltersState,
                [phase]: filters,
            },
        });

        context.dispatch(new MissionActions.GetMissionPlanList(phase));
    }

    @Action(MissionActions.ChangeMissionPhase)
    public changeMissionPhase(context: StateContext<MissionStateModel>, { phasePayloadData }: MissionActions.ChangeMissionPhase) {
        context.patchState({ isProcessing: true, acceptanceMissionError: undefined, rejectionMissionError: undefined });

        return this.missionApi.changeMissionPhase(phasePayloadData, this.getAuthorityUnitId()).pipe(
            catchError((changePhaseError) => {
                if (phasePayloadData.missionPhase === MissionProcessingPhase.Accepted) {
                    context.patchState({ acceptanceMissionError: changePhaseError });

                    return EMPTY;
                }

                context.patchState({ rejectionMissionError: changePhaseError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(MissionActions.RejectMission)
    public rejectMission(
        context: StateContext<MissionStateModel>,
        { missionId, justification, reviewerUnitId }: MissionActions.RejectMission
    ) {
        context.patchState({ isProcessing: true, rejectionMissionError: undefined });

        return this.missionApi.rejectMission(missionId, justification, reviewerUnitId).pipe(
            catchError((changePhaseError) => {
                context.patchState({ rejectionMissionError: changePhaseError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(MissionActions.PartitionMissionsByPhase)
    public updateMissionsByPhase(
        context: StateContext<MissionStateModel>,
        { missionList, phase }: MissionActions.PartitionMissionsByPhase
    ) {
        switch (phase) {
            case MissionProcessingPhase.Waiting:
                context.patchState({ waitingMissions: filterByPhase(missionList, MissionProcessingPhase.Waiting) });
                break;
            case MissionProcessingPhase.Rejected:
                context.patchState({ rejectedMissions: filterByPhase(missionList, MissionProcessingPhase.Rejected) });
                break;
            case MissionProcessingPhase.Accepted:
                context.patchState({ acceptedMissions: filterByPhase(missionList, MissionProcessingPhase.Accepted) });
                break;
        }
    }

    @Action(MissionActions.GetMissionRoute)
    public getMissionRoute(context: StateContext<MissionStateModel>, action: MissionActions.GetMissionRoute) {
        const previousRouteId = context.getState().missionPlanRouteId;
        context.patchState({ missionPlanRoute: undefined });

        if (previousRouteId === action.routeId) {
            return;
        }

        context.patchState({ isPlanRouteProcessing: true, missionPlanRouteId: action.routeId });

        return this.missionApi.getMissionRoute(action.routeId).pipe(
            tap((route) => {
                context.patchState({ missionPlanRoute: route });
            }),
            catchError((missionPlanRouteError) => {
                context.patchState({ missionPlanRouteError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isPlanRouteProcessing: false }))
        );
    }

    @Action(MissionActions.SetDefaultMissionFilters)
    public setDtmAreas(context: StateContext<MissionStateModel>, { dtmAreas }: MissionActions.SetDefaultMissionFilters) {
        const defaultFilters: MissionFilters = { ...defaultMissionFilters, dtmArea: dtmAreas };
        context.patchState({
            defaultMissionFilters: defaultFilters,
            missionFilters: {
                [MissionProcessingPhase.Waiting]: defaultFilters,
                [MissionProcessingPhase.Accepted]: defaultFilters,
                [MissionProcessingPhase.Rejected]: defaultFilters,
            },
        });
    }

    @Action(MissionActions.ClearMissionData)
    public clearMissionData(context: StateContext<MissionStateModel>) {
        context.patchState({
            missionPlanRoute: undefined,
            missionPlanRouteId: undefined,
        });
    }

    @Action(MissionActions.UpdateMissionPriority)
    public updatePriority(context: StateContext<MissionStateModel>, { priorityData }: MissionActions.UpdateMissionPriority) {
        context.patchState({ isProcessing: true, updatePriorityError: undefined });

        return this.missionApi.updateMissionPriority(priorityData, this.getAuthorityUnitId()).pipe(
            tap(() => {
                const missions = context.getState().waitingMissions;

                if (!missions) {
                    return;
                }

                const updatedMission = missions
                    .filter(({ systemVerificationId }) => systemVerificationId === priorityData.systemVerificationId)
                    .map((mission) => ({ ...mission, priority: priorityData.priority }))[0];

                context.patchState({
                    waitingMissions: missions.map((mission: Mission) =>
                        mission.systemVerificationId === priorityData.systemVerificationId ? { ...updatedMission } : mission
                    ),
                });
            }),
            catchError((updatePriorityError) => {
                context.patchState({ updatePriorityError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(MissionActions.GetNearbyMissionRoute)
    public geNearbyMissionRoute(context: StateContext<MissionStateModel>, action: MissionActions.GetNearbyMissionRoute) {
        const previousRouteId = context.getState().nearbyMissionRouteId;
        context.patchState({ nearbyMissionRoute: undefined, nearbyMissionRouteError: undefined });

        if (previousRouteId === action.routeId) {
            return;
        }

        context.patchState({ isNearbyMissionRouteProcessing: true, nearbyMissionRouteId: action.routeId });

        return this.missionApi.getMissionRoute(action.routeId).pipe(
            tap((route) => {
                context.patchState({ nearbyMissionRoute: route });
            }),
            catchError((nearbyMissionRouteError) => {
                context.patchState({ nearbyMissionRouteError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isNearbyMissionRouteProcessing: false }))
        );
    }

    @Action(MissionActions.GetNearbyMissions)
    public getNearbyMissions(context: StateContext<MissionStateModel>, { planId, nearbyMissionFilters }: MissionActions.GetNearbyMissions) {
        context.patchState({ nearbyMissionsError: undefined });
        context.dispatch(new MissionActions.NearbyMissionsWatch(planId));
        const filters = nearbyMissionFilters ?? context.getState().lastNearbyMissionsFilters;
        const authorityUnit = this.store.selectSnapshot(SupUserState.selectedWorkspace)?.authorityUnit;

        if (nearbyMissionFilters) {
            context.patchState({ lastNearbyMissionsFilters: nearbyMissionFilters });
        }

        return this.missionApi.getNearbyMissions(planId, filters, authorityUnit).pipe(
            tap((nearbyMissions) => {
                context.patchState({ nearbyMissions });
            }),
            catchError((nearbyMissionsError) => {
                context.patchState({ nearbyMissionsError });

                return EMPTY;
            })
        );
    }

    @Action(MissionActions.UpdateSupervisorNote)
    public updateSupervisorNote(context: StateContext<MissionStateModel>, action: MissionActions.UpdateSupervisorNote) {
        context.patchState({ isProcessing: true });

        return this.missionApi.updateSupervisorNote(action.noteData, this.getAuthorityUnitId()).pipe(
            tap(() => {
                context.dispatch(new MissionActions.GetMissionPlanList(MissionProcessingPhase.Waiting));
            }),
            catchError((updateSupervisorNoteError) => {
                context.patchState({ updateSupervisorNoteError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(MissionActions.GetRequestPilotAttachment)
    public getRequestPilotAttachment(context: StateContext<MissionStateModel>, action: MissionActions.GetRequestPilotAttachment) {
        context.patchState({ attachmentError: undefined });

        return this.missionApi.getRequestPilotAttachment(action.attachmentId, action.selectedPlanId).pipe(
            tap((blob: Blob) => saveAs(blob)),
            catchError((attachmentError) => {
                context.patchState({ attachmentError });

                return EMPTY;
            })
        );
    }

    @Action(MissionActions.GetMissionPlanData)
    public getPlanData(context: StateContext<MissionStateModel>, { planId }: MissionActions.GetMissionPlanData) {
        context.patchState({ isPlanRouteProcessing: true, missionPlanDataError: undefined });
        const authorityUnit = this.store.selectSnapshot(SupUserState.selectedWorkspace)?.authorityUnit;

        return this.missionApi.getMissionPlanData(planId, authorityUnit).pipe(
            tap((currentPlanDataAndCapabilities) => {
                context.patchState({
                    currentPlanDataAndCapabilities,
                });

                context.dispatch(new MissionActions.GetMissionPlanAnalysis(planId, MissionStatusType.Plan));
            }),
            catchError((missionPlanDataError) => {
                context.patchState({
                    missionPlanDataError,
                });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({ isPlanRouteProcessing: false });
            })
        );
    }

    @Action(MissionActions.GetNearbyMissionData)
    public getNearbyMissionData(context: StateContext<MissionStateModel>, { planId }: MissionActions.GetMissionPlanData) {
        context.patchState({
            isProcessing: true,
            nearbyMissionDataError: undefined,
            nearbyMissionAnalysisStatus: undefined,
            currentNearbyMissionDataAndCapabilities: undefined,
        });

        const authorityUnit = this.store.selectSnapshot(SupUserState.selectedWorkspace)?.authorityUnit;

        return this.missionApi.getMissionPlanData(planId, authorityUnit).pipe(
            tap((currentNearbyMissionDataAndCapabilities) => {
                context.patchState({
                    currentNearbyMissionDataAndCapabilities,
                });

                context.dispatch(new MissionActions.GetMissionPlanAnalysis(planId, MissionStatusType.Mission));
            }),
            catchError((nearbyMissionDataError) => {
                context.patchState({
                    nearbyMissionDataError,
                });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({ isProcessing: false });
            })
        );
    }

    @Action(MissionActions.SearchAirspaceElements)
    public searchAirspaceElements(context: StateContext<MissionStateModel>, { options }: MissionActions.SearchAirspaceElements) {
        context.patchState({ isProcessing: true });

        return this.missionApi.searchAirspaceElements(options).pipe(
            tap((elements) => {
                this.store.dispatch(new GeoZonesActions.SetCustomElements(elements));
            }),
            catchError((error) => {
                context.patchState({
                    airspaceError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(MissionActions.UpdateMissionPlanAnalysisStatus)
    public updateMissionPlanAnalysisStatus(
        context: StateContext<MissionStateModel>,
        action: MissionActions.UpdateMissionPlanAnalysisStatus
    ) {
        const { missionPlanAnalysisStatus, missionPlanRoute } = context.getState();

        if (
            missionPlanAnalysisStatus &&
            missionPlanAnalysisStatus.systemVerificationId === action.status.systemVerificationId &&
            missionPlanAnalysisStatus.version >= action.status.version
        ) {
            return;
        }

        this.sortAnalysisIssues(action.status.airspace?.issues);
        this.sortAnalysisIssues(action.status.traffic?.issues);
        this.sortAnalysisIssues(action.status.caaPermit?.issues);
        this.sortAnalysisIssues(action.status.evaluation?.issues);

        context.dispatch([MissionActions.ClearAirspaceElements]);

        if (
            missionPlanRoute &&
            ((action.status.airspace.zoneIssues && Object.keys(action.status.airspace.zoneIssues).length) ||
                (action.status.evaluation.zoneIssues && Object.keys(action.status.evaluation.zoneIssues).length))
        ) {
            const waypoints = MissionUtils.convertRouteToWaypoints(missionPlanRoute);
            const timeRange = MissionUtils.getTimeRangeFromWaypointsWithSection(waypoints);

            context.dispatch(
                new MissionActions.SearchAirspaceElements({
                    designators: [
                        ...Object.keys(action.status.airspace.zoneIssues ?? {}),
                        ...Object.keys(action.status.evaluation.zoneIssues ?? {}),
                    ],
                    scope: {
                        startTime: timeRange.min,
                        endTime: timeRange.max,
                    },
                    includeInformation: true,
                })
            );
        }

        if (action.type === MissionStatusType.Plan) {
            context.patchState({
                missionPlanAnalysisStatus: action.status,
            });

            return;
        }

        context.patchState({
            nearbyMissionAnalysisStatus: action.status,
        });
    }

    @Action(MissionActions.GetMissionPlanAnalysis)
    public getMissionPlanAnalysis(context: StateContext<MissionStateModel>, action: MissionActions.GetMissionPlanAnalysis) {
        context.patchState({ isProcessing: true });

        return this.missionApi.getCurrentMissionPlanVerification(action.planId).pipe(
            tap((firstStatus) => {
                context.patchState({
                    isProcessing: false,
                });
                context.dispatch(new MissionActions.UpdateMissionPlanAnalysisStatus(firstStatus, action.type));
            }),
            catchError((planAnalysisError) => {
                context.patchState({
                    planAnalysisError,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(MissionActions.ClearMissionAnalysisAndCapabilities)
    public clearMissionAnalysis(context: StateContext<MissionStateModel>) {
        context.patchState({ missionPlanAnalysisStatus: undefined, currentPlanDataAndCapabilities: undefined });
    }

    private sortAnalysisIssues(issues?: (MissionPlanAnalysisIssueBase & { status: string })[]) {
        issues?.sort((left, right) => {
            if (left.codename !== right.codename) {
                return left.codename.localeCompare(right.codename);
            }

            const leftWeight = ISSUES_STATUS_SORTING_ORDER.findIndex((item) => item === left.status);
            const rightWeight = ISSUES_STATUS_SORTING_ORDER.findIndex((item) => item === right.status);

            if (leftWeight !== rightWeight) {
                return leftWeight - rightWeight;
            }

            return left.translationId.localeCompare(right.translationId);
        });
    }

    private getAuthorityUnitId() {
        return this.store.selectSnapshot(SupUserState.selectedWorkspace)?.authorityUnit.id;
    }
}
