import { Page, PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM, PhoneNumber } from "@dtm-frontend/shared/ui";
import { AddingPermissionsFilterFormKeys, PilotOperatorQualification } from "./adding-permissions.models";

export enum AddingPermissionsPilotErrorType {
    CannotGetAddingPermissionsPilotList = "CannotGetAddingPermissionsPilotList",
    CannotSetAddingPermissionsSuperPilotPermission = "CannotSetAddingPermissionsSuperPilotPermission",
    CannotRemoveAddingPermissionsSuperPilotPermission = "CannotRemoveAddingPermissionsSuperPilotPermission",
}

export interface AddingPermissionsPilotListWithPages extends Page {
    content: AddingPermissionsPilot[];
}

export interface AddingPermissionsPilotError {
    type: AddingPermissionsPilotErrorType;
}

export enum AddingPermissionsPilotFilterFormKeys {
    SuperPilot = "superPilot",
}

export interface AddingPermissionsPilotFilterParams {
    [PAGE_NUMBER_QUERY_PARAM]?: number;
    [PAGE_SIZE_QUERY_PARAM]?: number;
    [AddingPermissionsFilterFormKeys.ActiveTabIndex]?: number;
    [AddingPermissionsFilterFormKeys.SearchPhrase]?: string;
    [AddingPermissionsPilotFilterFormKeys.SuperPilot]?: boolean;
}

export interface AddingPermissionsPilot {
    name: string;
    operatorNumber: string;
    pilotNumber: string;
    id: string;
    email: string;
    phoneNumber: PhoneNumber;
    qualifications?: PilotOperatorQualification[];
    [AddingPermissionsPilotFilterFormKeys.SuperPilot]: boolean;
}
