<ng-container *ngrxLet="data.isReasonProcessing$ as isProcessing">
    <div class="dialog-header">
        <h2 mat-dialog-title>{{ "dtmSupOperationalSituation.rejectMissionDialog.header" | transloco }}</h2>
        <button type="button" class="button-icon" [matDialogClose]="false" [disabled]="isProcessing">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <p class="sub-header">{{ "dtmSupOperationalSituation.rejectMissionDialog.subHeader" | transloco }}</p>
        <dtm-ui-textarea-field [maxLength]="MAX_REASON_LENGTH">
            <label>{{ "dtmSupOperationalSituation.rejectMissionDialog.informationLabel" | transloco }}</label>
            <textarea matInput [formControl]="reasonControl"></textarea>
            <div class="field-error" *dtmUiFieldHasError="reasonControl; name: ['required', 'pattern']">
                {{ "dtmSupOperationalSituation.rejectMissionDialog.requiredValidationError" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="reasonControl; name: 'minlength'; error as error">
                {{
                    "dtmSupOperationalSituation.rejectMissionDialog.informationMinLengthValueError"
                        | transloco : { minLength: error.requiredLength }
                }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="reasonControl; name: 'maxlength'; error as error">
                {{
                    "dtmSupOperationalSituation.rejectMissionDialog.informationMaxLengthValueError"
                        | transloco : { maxLength: error.requiredLength }
                }}
            </div>
        </dtm-ui-textarea-field>
    </div>
    <div mat-dialog-actions align="end">
        <button type="button" class="button-secondary" [mat-dialog-close]="false" [disabled]="isProcessing">
            {{ "dtmSupOperationalSituation.rejectMissionDialog.cancelButtonLabel" | transloco }}
        </button>
        <button type="button" class="button-warn" (click)="confirm()" [disabled]="isProcessing">
            {{ "dtmSupOperationalSituation.rejectMissionDialog.rejectButtonLabel" | transloco }}
        </button>
    </div>
</ng-container>
