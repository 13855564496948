import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { GeolocationService, WebGeolocationService } from "@dtm-frontend/shared/map";
import { CesiumMapModule } from "@dtm-frontend/shared/map/cesium";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { AngularCesiumModule } from "@pansa/ngx-cesium";
import { AlarmSliderComponent } from "./components/alarm-slider/alarm-slider.component";
import { DraftAreaLayerComponent } from "./components/draft-area-layer/draft-area-layer.component";
import { DtmAreaComponent } from "./components/dtm-area/dtm-area.component";
import { MessageComponent } from "./components/message/message.component";
import { MissionMapComponent } from "./components/mission-map/mission-map.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { CapabilitiesApiService } from "./services/capabilities-api.service";
import { CapabilitiesState } from "./state/capabilities.store";

@NgModule({
    imports: [
        CommonModule,
        SharedUiModule,
        LetModule,
        PushModule,
        SharedI18nModule,
        SharedUiModule,
        AngularCesiumModule,
        CesiumMapModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        NgxsModule.forFeature([CapabilitiesState]),
    ],
    declarations: [
        DtmAreaComponent,
        MissionMapComponent,
        SidebarComponent,
        MessageComponent,
        AlarmSliderComponent,
        DraftAreaLayerComponent,
    ],
    exports: [DtmAreaComponent, MissionMapComponent, SidebarComponent, MessageComponent, AlarmSliderComponent],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmSupervisorLibShared",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: GeolocationService,
            useClass: WebGeolocationService,
        },
    ],
})
export class SharedSupervisorClientModule {
    public static forRoot(): ModuleWithProviders<SharedSupervisorClientModule> {
        return {
            ngModule: SharedSupervisorClientModule,
            providers: [
                {
                    provide: CapabilitiesApiService,
                    useClass: CapabilitiesApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<SharedSupervisorClientModule> {
        return {
            ngModule: SharedSupervisorClientModule,
            providers: [
                {
                    provide: CapabilitiesApiService,
                    useValue: null,
                },
            ],
        };
    }
}
