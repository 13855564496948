import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from "@angular/router";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, of } from "rxjs";
import { distinctUntilChanged, mapTo, switchMap, timeoutWith } from "rxjs/operators";

const MINIMUM_LOAD_TIME_MILLISECONDS = 600;
const MINIMUM_LOADER_DISPLAY_TIME_MILLISECONDS = 1000;

interface LazyRouterOutletComponentState {
    loadingStatus: {
        [url: string]: boolean;
    };
    currentUrl: string;
    previousUrl: string;
}

@UntilDestroy()
@Component({
    selector: "dtm-sup-client-lazy-router-outlet",
    templateUrl: "./lazy-router-outlet.component.html",
    styleUrls: ["./lazy-router-outlet.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class LazyRouterOutletComponent implements OnInit {
    private readonly currentUrl$ = this.localStore.selectByKey("currentUrl").pipe(distinctUntilChanged());
    private readonly isLoadingCurrentUrl$ = this.localStore.select((state) => state.loadingStatus[state.currentUrl] === true);
    public readonly isLoading$: Observable<boolean> = this.currentUrl$.pipe(
        mapTo(false),
        timeoutWith(MINIMUM_LOAD_TIME_MILLISECONDS, this.isLoadingCurrentUrl$),
        switchMap((isLoading) => {
            if (!isLoading) {
                return of(false);
            }

            return of(true).pipe(timeoutWith(MINIMUM_LOADER_DISPLAY_TIME_MILLISECONDS, this.isLoadingCurrentUrl$));
        })
    );

    constructor(private readonly router: Router, private readonly localStore: LocalComponentStore<LazyRouterOutletComponentState>) {
        this.localStore.setState({ loadingStatus: {}, currentUrl: "", previousUrl: "" });
    }

    public ngOnInit() {
        this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.localStore.patchState((state) => ({
                    loadingStatus: { ...state.loadingStatus, [event.url]: true },
                    previousUrl: state.currentUrl,
                    currentUrl: event.url,
                }));
            } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
                this.localStore.patchState((state) => ({
                    loadingStatus: { ...state.loadingStatus, [event.url]: false },
                    currentUrl: event instanceof NavigationEnd ? state.currentUrl : state.previousUrl,
                    previousUrl: "",
                }));
            }
        });
    }
}
