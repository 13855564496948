import { Inject, Injectable, Optional } from "@angular/core";
import { Router } from "@angular/router";
import { AuthState } from "@dtm-frontend/shared/auth";
import { FlightTrackingActions } from "@dtm-frontend/shared/map/flight-tracking";
import { AirspaceElement } from "@dtm-frontend/shared/map/geo-zones";
import { UIActions, reloadComponent } from "@dtm-frontend/shared/ui";
import { LOCAL_STORAGE, RxjsUtils } from "@dtm-frontend/shared/utils";
import { Action, Selector, State, StateContext, Store, createSelector } from "@ngxs/store";
import { EMPTY, firstValueFrom, switchMap, tap } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { OperationalSituationActions } from "../../operational-situation/state/operational-situation.actions";
import { TemporaryZonesActions } from "../../operational-situation/state/temporary-zones.actions";
import { SupUserErrorType, SupUserProfile, SupWorkspace } from "../models/sup-user.models";
import { SupUserApiService } from "../services/sup-user-api.service";
import { SupUserActions } from "./sup-user.actions";

interface SupUserStateModel {
    userProfile: SupUserProfile | undefined;
    userProfileError: SupUserErrorType | undefined;
    selectedWorkspace: SupWorkspace | undefined;
    dtmZonesData: AirspaceElement[];
    isDtmZoneDataLoading: boolean;
}

const defaultState: SupUserStateModel = {
    userProfile: undefined,
    userProfileError: undefined,
    selectedWorkspace: undefined,
    dtmZonesData: [],
    isDtmZoneDataLoading: false,
};

const WORKSPACE_ID_LOCAL_STORAGE_KEY = "utmSup-selectedWorkspaceId";

@State<SupUserStateModel>({
    name: "supUser",
    defaults: defaultState,
})
@Injectable()
export class SupUserState {
    constructor(
        private readonly supUserApiService: SupUserApiService,
        private readonly store: Store,
        @Optional() @Inject(LOCAL_STORAGE) private readonly localStorage: Storage | undefined,
        private readonly router: Router
    ) {}

    @Selector()
    public static userProfile(state: SupUserStateModel): SupUserProfile | undefined {
        return state.userProfile;
    }

    @Selector()
    public static selectedWorkspace(state: SupUserStateModel): SupWorkspace | undefined {
        return state.selectedWorkspace;
    }

    public static dtmZoneData(name: string) {
        return createSelector([SupUserState], (state: SupUserStateModel) => state.dtmZonesData.find((zone) => zone.designator === name));
    }

    @Selector()
    public static dtmZonesData(state: SupUserStateModel): AirspaceElement[] {
        return state.dtmZonesData;
    }

    @Selector()
    public static isDtmZoneDataLoading(state: SupUserStateModel): boolean {
        return state.isDtmZoneDataLoading;
    }

    @Action(SupUserActions.FetchUtmUserProfile)
    public async fetchSupUserProfile(context: StateContext<SupUserStateModel>) {
        const userId = await firstValueFrom(this.store.select(AuthState.userId).pipe(RxjsUtils.filterFalsy()));
        context.patchState({ userProfileError: undefined });

        return this.supUserApiService.fetchSupUserProfile(userId).pipe(
            switchMap((user) => {
                const localStorageSelectedWorkspaceId = this.localStorage?.getItem(WORKSPACE_ID_LOCAL_STORAGE_KEY);
                const selectedWorkspace =
                    context.getState().selectedWorkspace ??
                    user.workspaces.find((workspace) => workspace.id === localStorageSelectedWorkspaceId) ??
                    user.workspaces[0];
                context.patchState({ userProfile: user });

                const zones = user.workspaces.flatMap((workspace) => workspace.authorityUnitZones.map((zone) => zone.designator));

                return context.dispatch([
                    new SupUserActions.SetSelectedWorkspace(selectedWorkspace.id),
                    new SupUserActions.GetUserDtmZonesData(zones),
                ]);
            }),

            catchError((error) => {
                context.patchState({ userProfileError: error });

                return EMPTY;
            })
        );
    }

    @Action(SupUserActions.GetUserDtmZonesData)
    public getUserDtmZonesData(context: StateContext<SupUserStateModel>, { dtmNames }: SupUserActions.GetUserDtmZonesData) {
        context.patchState({ isDtmZoneDataLoading: true });

        const dtmZonesData = context.getState().dtmZonesData;
        const notFetchedZonesDataNames = dtmNames.filter((name) => !dtmZonesData.some((zone) => zone.designator === name));

        if (!notFetchedZonesDataNames.length) {
            context.patchState({ isDtmZoneDataLoading: false });

            return EMPTY;
        }

        return this.supUserApiService.getUserDtmZonesData({ designators: notFetchedZonesDataNames }).pipe(
            tap((data) => {
                context.patchState({
                    dtmZonesData: [...dtmZonesData, ...data.airspaceElements],
                });
            }),
            catchError((error) => {
                context.patchState({ userProfileError: error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({ isDtmZoneDataLoading: false });
            })
        );
    }

    @Action(SupUserActions.SetSelectedWorkspace)
    public setSelectedWorkspace(context: StateContext<SupUserStateModel>, { workspaceId }: SupUserActions.SetSelectedWorkspace) {
        const userProfile = context.getState().userProfile;
        const selectedWorkspace = userProfile?.workspaces.find((workspace) => workspace.id === workspaceId);

        if (!selectedWorkspace) {
            return;
        }

        const previousSelectedWorkspace = context.getState().selectedWorkspace;

        context.patchState({ selectedWorkspace });
        this.localStorage?.setItem(WORKSPACE_ID_LOCAL_STORAGE_KEY, workspaceId);

        if (previousSelectedWorkspace) {
            this.clearOperationalData();
            reloadComponent(this.router);
        }
    }

    private async clearOperationalData() {
        this.store.dispatch([
            new UIActions.SetMenuCollapsedState(true),
            OperationalSituationActions.StopTacticalMissionsWatch,
            OperationalSituationActions.ClearMapEntities,
            OperationalSituationActions.StopSectionDeactivationEventWatch,
            OperationalSituationActions.ClearMissionAnalysisAndCapabilities,
            TemporaryZonesActions.ClearSelectedZoneArea,
            new OperationalSituationActions.UpdateSelectedDtmName(undefined),
            FlightTrackingActions.CleanUpFlightTrackingState,
            OperationalSituationActions.StopFlightPositionUpdatesWatch,
        ]);
    }
}
