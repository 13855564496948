import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Capabilities, CapabilitiesErrorType } from "../models/shared-supervisor-client.models";
import { SHARED_ENDPOINTS, SharedEndpoints } from "../shared.tokens";

@Injectable({
    providedIn: "root",
})
export class CapabilitiesApiService {
    constructor(private readonly httpClient: HttpClient, @Inject(SHARED_ENDPOINTS) private readonly endpoints: SharedEndpoints) {}

    public getCapabilities(): Observable<Capabilities> {
        return this.httpClient
            .get<Capabilities>(this.endpoints.getCapabilities)
            .pipe(catchError(() => throwError(() => ({ type: CapabilitiesErrorType.CannotGetCapabilities }))));
    }
}
