export namespace SupUserActions {
    export class FetchUtmUserProfile {
        public static readonly type = "[UtmUser] Fetch Utm User Profile";
    }

    export class GetUserDtmZonesData {
        public static readonly type = "[UtmUser] Get User Dtm Zones Data";

        constructor(public dtmNames: string[]) {}
    }

    export class SetSelectedWorkspace {
        public static readonly type = "[UtmUser] Set Selected Workspace";

        constructor(public workspaceId: string) {}
    }
}
