import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LocalComponentStore, MAX_TEXT_LENGTH, ONLY_WHITE_SPACES_VALIDATION_PATTERN, RxjsUtils } from "@dtm-frontend/shared/utils";
import { DeviceHistory, SignalViolationType } from "../../models/network-monitoring.models";

interface EventTileComponentState {
    deviceHistory: DeviceHistory | undefined;
    isFormVisible: boolean;
}

interface NoteForm {
    supervisorName: FormControl<string>;
    note: FormControl<string>;
}

export interface SignalViolationData {
    supervisorName: string;
    note: string;
    signalViolationId: string;
    installationId: string;
    sectorId: string;
}

const NOTE_MAX_LENGTH = 500;

@Component({
    selector: "supervisor-shared-lib-event-tile[deviceHistory]",
    templateUrl: "./event-tile.component.html",
    styleUrls: ["./event-tile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class EventTileComponent {
    @Input() public set deviceHistory(value: DeviceHistory | undefined) {
        this.localStore.patchState({ deviceHistory: value });

        if (value?.note) {
            this.noteForm.patchValue({ supervisorName: value.note.supervisorName, note: value.note.content }, { emitEvent: false });
        }
    }

    @Output() public readonly formSave = new EventEmitter<SignalViolationData>();

    protected readonly SignalViolationType = SignalViolationType;

    protected readonly deviceHistory$ = this.localStore.selectByKey("deviceHistory").pipe(RxjsUtils.filterFalsy());
    protected readonly isFormVisible$ = this.localStore.selectByKey("isFormVisible");
    protected readonly noteForm = new FormGroup<NoteForm>({
        supervisorName: new FormControl("", {
            nonNullable: true,
            validators: [
                Validators.required,
                Validators.maxLength(MAX_TEXT_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
        }),
        note: new FormControl("", {
            nonNullable: true,
            validators: [
                Validators.required,
                Validators.maxLength(NOTE_MAX_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
        }),
    });
    protected readonly NOTE_MAX_LENGTH = NOTE_MAX_LENGTH;

    constructor(private readonly localStore: LocalComponentStore<EventTileComponentState>) {
        this.localStore.setState({ deviceHistory: undefined, isFormVisible: false });
    }

    protected changeFormVisibility(): void {
        this.localStore.patchState(({ isFormVisible }) => ({ isFormVisible: !isFormVisible }));
    }

    protected saveForm(): void {
        const deviceHistory = this.localStore.selectSnapshotByKey("deviceHistory");

        if (!deviceHistory) {
            return;
        }

        if (this.noteForm.invalid) {
            this.noteForm.markAllAsTouched();

            return;
        }

        this.formSave.emit({
            ...this.noteForm.getRawValue(),
            signalViolationId: deviceHistory.signalViolationId,
            installationId: deviceHistory?.installationId,
            sectorId: deviceHistory.sectorId,
        });

        this.changeFormVisibility();
    }
}
