<ng-container *ngrxLet="data.isProcessing as isProcessing">
    <div class="dialog-header">
        <span mat-dialog-title>{{ "dtmSupOperationalSituation.missionTimeChangeDialog.header" | transloco }}</span>
        <button type="button" class="button-icon" [matDialogClose]="false" [disabled]="isProcessing">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <p class="sub-header">
            {{ "dtmSupOperationalSituation.missionTimeChangeDialog.subHeader" | transloco : { value: this.data.missionName } }}
        </p>
        <div class="change-time-form" [formGroup]="overrideTimeForm">
            <dtm-ui-time-field
                [minTime]="data.startTime"
                [maxTime]="data.finishTime | invoke : getStartTimeMax"
                class="start-time"
                formControlName="startAt"
                required
            >
                <label>{{ "dtmSupOperationalSituation.missionTimeChangeDialog.startControlLabel" | transloco }} </label>
                <div class="field-error" *dtmUiFieldHasError="overrideTimeForm.controls.startAt; name: 'min'">
                    {{
                        "dtmSupOperationalSituation.missionTimeChangeDialog.startTimeMinErrorMessage"
                            | transloco : { time: data.startTime | localizeDate : { timeStyle: "short" } }
                    }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="overrideTimeForm.controls.startAt; name: 'max'">
                    {{
                        "dtmSupOperationalSituation.missionTimeChangeDialog.startTimeMaxErrorMessage"
                            | transloco : { time: data.finishTime | invoke : getStartTimeMax | localizeDate : { timeStyle: "short" } }
                    }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="overrideTimeForm.controls.startAt; name: 'required'">
                    {{ "dtmSupOperationalSituation.missionTimeChangeDialog.requiredFieldErrorHint" | transloco }}
                </div>
            </dtm-ui-time-field>
            <dtm-ui-time-field
                [minTime]="data.startTime | invoke : getFinishTimeMin"
                [maxTime]="data.finishTime"
                class="finish-time"
                formControlName="finishAt"
                required
            >
                <label>{{ "dtmSupOperationalSituation.missionTimeChangeDialog.landingControlLabel" | transloco }} </label>
                <div class="field-error" *dtmUiFieldHasError="overrideTimeForm.controls.finishAt; name: 'min'">
                    {{
                        "dtmSupOperationalSituation.missionTimeChangeDialog.finishMinTimeError"
                            | transloco : { time: data.startTime | invoke : getFinishTimeMin | localizeDate : { timeStyle: "short" } }
                    }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="overrideTimeForm.controls.finishAt; name: 'max'">
                    {{
                        "dtmSupOperationalSituation.missionTimeChangeDialog.finishMaxTimeError"
                            | transloco : { time: data.finishTime | localizeDate : { timeStyle: "short" } }
                    }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="overrideTimeForm.controls.finishAt; name: 'required'">
                    {{ "dtmSupOperationalSituation.missionTimeChangeDialog.requiredFieldErrorHint" | transloco }}
                </div>
            </dtm-ui-time-field>
            <dtm-ui-textarea-field class="information" [maxLength]="MAX_REASON_LENGTH">
                <label>{{ "dtmSupOperationalSituation.missionTimeChangeDialog.informationLabel" | transloco }}</label>
                <textarea matInput formControlName="information"></textarea>
                <div class="field-error" *dtmUiFieldHasError="overrideTimeForm.controls.information; name: ['required', 'pattern']">
                    {{ "dtmSupOperationalSituation.missionTimeChangeDialog.changeMissionTimeReason" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="overrideTimeForm.controls.information; name: 'minlength'; error as error">
                    {{
                        "dtmSupOperationalSituation.missionTimeChangeDialog.informationMinLengthValueError"
                            | transloco : { minLength: error.requiredLength }
                    }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="overrideTimeForm.controls.information; name: 'maxlength'; error as error">
                    {{
                        "dtmSupOperationalSituation.missionTimeChangeDialog.informationMaxLengthValueError"
                            | transloco : { maxLength: error.requiredLength }
                    }}
                </div>
            </dtm-ui-textarea-field>
        </div>
    </div>
    <div mat-dialog-actions align="end">
        <button type="button" class="button-secondary" [mat-dialog-close]="false" [disabled]="isProcessing">
            {{ "dtmSupOperationalSituation.missionTimeChangeDialog.cancelButtonLabel" | transloco }}
        </button>
        <button type="button" class="button-primary" (click)="confirm()" [disabled]="isProcessing">
            {{ "dtmSupOperationalSituation.missionTimeChangeDialog.rejectButtonLabel" | transloco }}
        </button>
    </div>
</ng-container>
