import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AirspaceElement, AirspaceElementsInfo } from "@dtm-frontend/shared/map/geo-zones";
import {
    MissionContextType,
    MissionDataSimple,
    MissionPlanAnalysisStatus,
    MissionPlanDataAndCapabilities,
} from "@dtm-frontend/shared/mission";
import { MissionPlanRoute } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { WorkspacePermission } from "../../../sup-user";
import { Mission, NearbyMissionFilters } from "../../models/mission.models";

interface NearbyMissionsComponentState {
    otherMissions: Mission[];
    otherDefaultsFilters: NearbyMissionFilters | undefined;
    isSelectedMission: boolean;
    selectedNearbyMissionId: string | undefined;
    isNearbyMissionRouteProcessing: boolean;
    nearbyMissionRoute: MissionPlanRoute | undefined;
    areNearbyMissionsAvailable: boolean;
    currentNearbyMissionData: MissionPlanDataAndCapabilities | undefined;
    zones: AirspaceElementsInfo | undefined;
    isProcessing: boolean;
    currentNearbyMissionAnalysisStatus: MissionPlanAnalysisStatus | undefined;
    workspacePermissions: WorkspacePermission[] | undefined;
}

@Component({
    selector: "supervisor-shared-lib-nearby-missions[otherMissions][nearbyDefaultsFilters][isSelectedMission]",
    templateUrl: "./nearby-missions.component.html",
    styleUrls: ["./nearby-missions.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class NearbyMissionsComponent {
    @Input() public set isSelectedMission(value: BooleanInput) {
        this.localStore.patchState({ isSelectedMission: coerceBooleanProperty(value) });
    }
    @Input() public set otherMissions(value: Mission[] | undefined) {
        this.localStore.patchState({ otherMissions: value ?? [] });
    }
    @Input() public set nearbyDefaultsFilters(value: NearbyMissionFilters | undefined) {
        this.localStore.patchState({ otherDefaultsFilters: value });
    }
    @Input() public set selectedNearbyMissionId(value: string | undefined) {
        this.localStore.patchState({ selectedNearbyMissionId: value });
    }
    @Input() public set isNearbyMissionRouteProcessing(value: BooleanInput) {
        this.localStore.patchState({ isNearbyMissionRouteProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set nearbyMissionRoute(value: MissionPlanRoute | undefined) {
        this.localStore.patchState({ nearbyMissionRoute: value });
    }
    @Input() public set areNewMissionsAvailable(value: BooleanInput) {
        this.localStore.patchState({ areNearbyMissionsAvailable: coerceBooleanProperty(value) });
    }
    @Input() public set currentNearbyMissionData(value: MissionPlanDataAndCapabilities | undefined) {
        this.localStore.patchState({ currentNearbyMissionData: value });
    }
    @Input() public set zones(value: AirspaceElementsInfo | undefined) {
        this.localStore.patchState({ zones: value });
    }
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set currentNearbyMissionAnalysisStatus(value: MissionPlanAnalysisStatus | undefined) {
        this.localStore.patchState({ currentNearbyMissionAnalysisStatus: value });
    }
    @Input() public set workspacePermissions(value: WorkspacePermission[] | undefined) {
        this.localStore.patchState({ workspacePermissions: value });
    }

    @Output() public readonly filtersChange = new EventEmitter<NearbyMissionFilters>();
    @Output() public readonly nearbyMissionSelect = new EventEmitter<Mission>();
    @Output() public readonly nearbyMissionAvailabilityClear = new EventEmitter<void>();
    @Output() public readonly routeZoom = new EventEmitter<string | undefined>();
    @Output() public readonly zoneSelect = new EventEmitter<AirspaceElement>();

    protected readonly MissionContextType = MissionContextType;

    protected readonly otherMissions$ = this.localStore.selectByKey("otherMissions");
    protected readonly otherDefaultsFilters$ = this.localStore.selectByKey("otherDefaultsFilters");
    protected readonly isSelectedMission$ = this.localStore.selectByKey("isSelectedMission");
    protected readonly selectedNearbyMissionId$ = this.localStore.selectByKey("selectedNearbyMissionId");
    protected readonly isNearbyMissionRouteProcessing$ = this.localStore.selectByKey("isNearbyMissionRouteProcessing");
    protected readonly nearbyMissionRoute$ = this.localStore.selectByKey("nearbyMissionRoute");
    protected readonly currentNearbyMissionData$ = this.localStore.selectByKey("currentNearbyMissionData");
    protected readonly areNearbyMissionsAvailable$ = this.localStore.selectByKey("areNearbyMissionsAvailable");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly zones$ = this.localStore.selectByKey("zones");
    protected readonly currentNearbyMissionAnalysisStatus$ = this.localStore.selectByKey("currentNearbyMissionAnalysisStatus");
    protected readonly workspacePermissions$ = this.localStore.selectByKey("workspacePermissions");

    constructor(private readonly localStore: LocalComponentStore<NearbyMissionsComponentState>) {
        this.localStore.setState({
            otherMissions: [],
            otherDefaultsFilters: undefined,
            isSelectedMission: false,
            selectedNearbyMissionId: undefined,
            isNearbyMissionRouteProcessing: false,
            nearbyMissionRoute: undefined,
            areNearbyMissionsAvailable: false,
            currentNearbyMissionData: undefined,
            zones: undefined,
            isProcessing: false,
            currentNearbyMissionAnalysisStatus: undefined,
            workspacePermissions: undefined,
        });
    }

    protected prepareMissionDataSimple(
        missionPlanData: MissionPlanDataAndCapabilities | undefined,
        route: MissionPlanRoute | undefined,
        operatorName: string
    ): MissionDataSimple | undefined {
        if (!missionPlanData) {
            return undefined;
        }

        const { plan, flightPurposes } = missionPlanData;

        return {
            isRoutePathBased: !!route?.isPathBased,
            flightStartAtMin: plan.flightStartAtMin,
            flightStartAtMax: plan.flightStartAtMax,
            flightFinishAtMin: plan.flightFinishAtMin,
            flightFinishAtMax: plan.flightFinishAtMax,
            phase: plan.phase,
            distance: route?.estimatedDistance,
            operatorName,
            pilotName: plan.capabilities.pilotName,
            uavName:
                plan.capabilities.uavName && plan.capabilities.setupName
                    ? `${plan.capabilities.uavName} (${plan.capabilities.setupName})`
                    : undefined,
            uavSerialNumbers: plan.uav.serialNumbers ?? [],
            trackersIdentifiers: plan.capabilities.trackersIdentifiers ?? [],
            category: plan.category,
            flightPurpose: {
                nameTranslationKey: flightPurposes?.find((purpose) => purpose.id === plan.flightPurpose?.id)?.name ?? "",
                comment: plan.flightPurpose?.comment ?? undefined,
            },
            additionalCrew: plan.capabilities.additionalCrew,
        };
    }
}
