import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { WeatherActions } from "@dtm-frontend/shared/map/geo-weather";
import {
    DeviceSize,
    DeviceSizeService,
    RouteDataService,
    SwipeDirection,
    TouchScreenService,
    UIActions,
    UIState,
} from "@dtm-frontend/shared/ui";
import { DtmArea, DtmName, GlobalAlarmActions, MissionActions } from "@dtm-frontend/supervisor-shared-lib";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { filter, map } from "rxjs/operators";

// TODO: DTM-5170 - Temporary solution
const DTM_AREAS: DtmArea[] = [
    {
        id: "b215776e-4ae3-4fc0-987d-7b7b910ec4ee",
        name: DtmName.Lublin,
        isAlarm: false,
        dronesNumber: 12,
        bvlosNumber: 7,
        isLeavingArea: true,
        isMissionConflict: false,
        isAdsb: false,
        wind: 30,
        rain: 2,
        temperature: 11,
    },
    {
        id: "c507bca1-18f1-4b3a-ad91-d5b5b60ce332",
        name: DtmName.Gliwice,
        isAlarm: true,
        dronesNumber: 76,
        bvlosNumber: 34,
        isLeavingArea: false,
        isMissionConflict: false,
        isAdsb: true,
        wind: 24,
        rain: 8,
        temperature: 22,
    },
    {
        id: "88075ce8-8abe-42c2-9f56-b673ce449977",
        name: DtmName.Nadarzyn,
        isAlarm: false,
        dronesNumber: 10,
        bvlosNumber: 1,
        isLeavingArea: true,
        isMissionConflict: true,
        isAdsb: true,
        wind: 2,
        rain: 1,
        temperature: 23,
    },
];

@UntilDestroy()
@Component({
    selector: "dtm-sup-client-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    protected readonly isSidebarCollapsed$ = this.store.select(UIState.isMenuCollapsed);
    protected readonly routeTitle$ = this.routeDataService.routeTitle$;
    protected readonly className$ = this.routeDataService.routeData$.pipe(map((routeData) => routeData?.className));

    constructor(
        private readonly store: Store,
        private readonly touchScreenService: TouchScreenService,
        private readonly deviceSizeService: DeviceSizeService,
        private readonly routeDataService: RouteDataService
    ) {}

    public ngOnInit(): void {
        this.observeOnDeviceResize();
        this.observeOnSwipe();
        this.store.dispatch([
            new MissionActions.SetDtmAreas(DTM_AREAS),
            GlobalAlarmActions.GlobalAlarmWatch,
            new WeatherActions.SetSupportedWeatherZones(DTM_AREAS.map(({ name }) => name)),
        ]);
    }

    public toggleSidebar() {
        this.setMenuCollapsedState(!this.store.selectSnapshot(UIState.isMenuCollapsed));
    }

    public collapseMenu(): void {
        if (this.deviceSizeService.isSize(DeviceSize.SmartphoneWide, DeviceSize.Smartphone)) {
            this.setMenuCollapsedState(true);
        }
    }

    private observeOnDeviceResize() {
        this.deviceSizeService
            .getSizeObservable(DeviceSize.Desktop, DeviceSize.DesktopWide)
            .pipe(untilDestroyed(this))
            .subscribe((isDesktopDevice) => {
                this.setMenuCollapsedState(!isDesktopDevice);
            });
    }

    private observeOnSwipe() {
        this.touchScreenService.swipe$
            .pipe(
                filter(() => this.deviceSizeService.isSize(DeviceSize.SmartphoneWide, DeviceSize.Smartphone)),
                untilDestroyed(this)
            )
            .subscribe((direction: SwipeDirection) => {
                if (direction === SwipeDirection.LEFT) {
                    this.setMenuCollapsedState(true);
                } else if (direction === SwipeDirection.RIGHT) {
                    this.setMenuCollapsedState(false);
                }
            });
    }

    private setMenuCollapsedState(isCollapsed: boolean) {
        this.store.dispatch(new UIActions.SetMenuCollapsedState(isCollapsed));
    }
}
