import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { MissionStatus } from "../../../models/operational.situation.models";
import { MissionStatusFilterConfig, filterConfiguration } from "./mission-status.filter.config";

interface MissionStatusFilterComponentState {
    activeFilter: MissionStatus;
}

@Component({
    selector: "supervisor-shared-lib-mission-status-filter",
    templateUrl: "./mission-status-filter.component.html",
    styleUrls: ["./mission-status-filter.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionStatusFilterComponent {
    @Input() public set defaultFilter(value: MissionStatus | undefined) {
        if (value) {
            this.localStore.patchState({ activeFilter: value });
        }
    }

    @Output() protected readonly missionStatusChanged = new EventEmitter<MissionStatus>();

    protected readonly filterConfiguration: MissionStatusFilterConfig[] = filterConfiguration;
    protected readonly activeFilter$ = this.localStore.selectByKey("activeFilter");
    protected readonly MissionStatusFilter = MissionStatus;

    constructor(private readonly localStore: LocalComponentStore<MissionStatusFilterComponentState>) {
        this.localStore.setState({
            activeFilter: MissionStatus.All,
        });
    }

    protected selectStatus(status: MissionStatus) {
        this.localStore.patchState({ activeFilter: status });
        this.missionStatusChanged.emit(status);
    }
}
