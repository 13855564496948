<div
    class="zones-form"
    [formGroup]="temporaryZonesForm"
    *ngrxLet="{
        isPhoneNumberVisible: isPhoneNumberVisible$,
        isNoteFormVisible: isNoteFormVisible$,
        mapEntity: mapEntity$,
        elevation: elevation$,
        canDeletePhoneNumber: canDeletePhoneNumber$,
        geographicZones: geographicZones$
    } as vm"
>
    <dtm-ui-input-field>
        <label>{{ "dtmSupOperationalSituation.temporaryZonesForm.zoneNameLabel" | transloco }}</label>
        <input matInput formControlName="zoneName" type="text" />
        <div class="field-error" *dtmUiFieldHasError="temporaryZonesForm.controls.zoneName; name: ['required', 'pattern']">
            {{ "dtmSupOperationalSituation.temporaryZonesForm.requiredFieldError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="temporaryZonesForm.controls.zoneName; name: 'maxlength'; error as error">
            {{ "dtmSupOperationalSituation.temporaryZonesForm.zoneNameMaxLengthError" | transloco : { max: error.requiredLength } }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-select-field
        (selectionChange)="updateRestrictionList($event.value)"
        formControlName="zoneType"
        [placeholder]="'dtmSupOperationalSituation.temporaryZonesForm.chooseLabel' | transloco"
    >
        <label>{{ "dtmSupOperationalSituation.temporaryZonesForm.zoneTypeLabel" | transloco }}</label>
        <dtm-ui-select-option *ngFor="let zone of vm.geographicZones" [value]="zone">
            {{ "dtmSupOperationalSituation.temporaryZonesForm.zoneTypeValueSelect" | transloco : { value: zone } }}
        </dtm-ui-select-option>
        <div class="field-error" *dtmUiFieldHasError="temporaryZonesForm.controls.zoneType; name: 'required'">
            {{ "dtmSupOperationalSituation.temporaryZonesForm.requiredFieldError" | transloco }}
        </div>
    </dtm-ui-select-field>
    <dtm-ui-expandable-panel isExpanded [hasHeaderSeparator]="false">
        <h2 class="section-title" headerSlot>
            {{ "dtmSupOperationalSituation.temporaryZonesForm.coordinatesLabel" | transloco }}
        </h2>
        <div class="hint" *ngIf="!vm.mapEntity">
            <dtm-ui-icon name="information-fill"></dtm-ui-icon>
            <span>
                {{ "dtmSupOperationalSituation.temporaryZonesForm.coordinatesHint" | transloco }}
            </span>
        </div>
        <div class="figure" *ngIf="vm.mapEntity && vm.mapEntity.type !== MapEntityType.Polyline3D">
            <dtm-ui-label-value
                *ngIf="serializableMapEntity$ | ngrxPush as serializableMapEntity"
                [label]="'dtmSupOperationalSituation.temporaryZonesForm.centerCoordinatesLabel' | transloco"
            >
                {{ serializableMapEntity.longitude | number : "1.0-4" | localizeNumber }};
                {{ serializableMapEntity.latitude | number : "1.0-4" | localizeNumber }}
            </dtm-ui-label-value>
            <dtm-ui-label-value
                *ngIf="vm.mapEntity.type === MapEntityType.Cylinder"
                [label]="'dtmSupOperationalSituation.temporaryZonesForm.radiusLabel' | transloco"
                [value]="
                    'dtmSupOperationalSituation.temporaryZonesForm.radiusValue'
                        | transloco : { value: vm.mapEntity.radius | roundNumber | localizeNumber }
                "
            ></dtm-ui-label-value>
        </div>
        <ng-container *ngIf="vm.mapEntity && vm.mapEntity.type === MapEntityType.Polyline3D">
            <div class="position-point" *ngFor="let position of vm.mapEntity.positions | invoke : convertPolylinePoints; let index = index">
                <dtm-ui-icon name="dot-middle"></dtm-ui-icon>
                <h2>{{ "dtmSupOperationalSituation.temporaryZonesForm.pointLabel" | transloco : { value: index + 1 } }}</h2>
                <span>
                    {{ position.latitude | number : "1.0-4" | localizeNumber }};
                    {{ position.longitude | number : "1.0-4" | localizeNumber }}
                </span>
            </div>
        </ng-container>
    </dtm-ui-expandable-panel>
    <dtm-ui-loader-container [isShown]="isElevationProcessing$ | ngrxPush">
        <dtm-ui-expandable-panel class="elevation-panel" isExpanded [hasHeaderSeparator]="false">
            <h2 class="section-title" headerSlot>
                {{ "dtmSupOperationalSituation.temporaryZonesForm.upperHeightLabel" | transloco }}
            </h2>
            <div class="column-gap">
                <dtm-ui-label-value
                    *ngIf="vm.elevation"
                    [label]="'dtmSupOperationalSituation.temporaryZonesForm.terrainHeightLabel' | transloco"
                >
                    {{
                        "dtmSupOperationalSituation.temporaryZonesForm.amslRangeLabel"
                            | transloco
                                : {
                                      min: vm.elevation.min | localizeNumber : { maximumFractionDigits: 0 },
                                      max: vm.elevation.max | localizeNumber : { maximumFractionDigits: 0 }
                                  }
                    }}
                </dtm-ui-label-value>
                <div class="hint" *ngIf="!vm.elevation">
                    <dtm-ui-icon name="information-fill"></dtm-ui-icon>
                    {{ "dtmSupOperationalSituation.temporaryZonesForm.elevationHint" | transloco }}
                </div>
                <dtm-ui-expanded-number-input
                    formControlName="maxAltitude"
                    [valueSuffix]="'dtmSupOperationalSituation.temporaryZonesForm.amslSuffix' | transloco"
                >
                    <div class="field-error" *dtmUiFieldHasError="temporaryZonesForm.controls.maxAltitude; name: 'required'">
                        {{ "dtmSupOperationalSituation.temporaryZonesForm.requiredFieldError" | transloco }}
                    </div>
                    <div class="field-error" *dtmUiFieldHasError="temporaryZonesForm.controls.maxAltitude; name: 'min'; error as error">
                        {{
                            "dtmSupOperationalSituation.temporaryZonesForm.altitudeMinValue"
                                | transloco : { value: error.min | number : "1.0-4" | localizeNumber }
                        }}
                    </div>
                </dtm-ui-expanded-number-input>
            </div>
        </dtm-ui-expandable-panel>
    </dtm-ui-loader-container>
    <dtm-ui-loader-container [isShown]="isElevationProcessing$ | ngrxPush">
        <dtm-ui-expandable-panel class="elevation-panel" isExpanded [hasHeaderSeparator]="false">
            <h2 class="section-title" headerSlot>
                {{ "dtmSupOperationalSituation.temporaryZonesForm.lowerHeightLabel" | transloco }}
            </h2>
            <div class="column-gap">
                <section class="tile-container">
                    <div
                        class="selectable-tile"
                        [class.selected]="temporaryZonesForm.controls.minAltitudeType.value === AltitudeType.Ground"
                        [class.disabled]="temporaryZonesForm.controls.minAltitudeType.disabled"
                        tabindex="0"
                        (click)="setMinAltitudeType(AltitudeType.Ground)"
                    >
                        <span class="selectable-tile-label">{{
                            "dtmSupOperationalSituation.temporaryZonesForm.groundLabel" | transloco
                        }}</span>
                    </div>
                    <div
                        class="selectable-tile"
                        tabindex="0"
                        [class.selected]="temporaryZonesForm.controls.minAltitudeType.value === AltitudeType.Value"
                        [class.disabled]="temporaryZonesForm.controls.minAltitudeType.disabled"
                        (click)="setMinAltitudeType(AltitudeType.Value)"
                    >
                        <span class="selectable-tile-label">{{
                            "dtmSupOperationalSituation.temporaryZonesForm.valueLabel" | transloco
                        }}</span>
                    </div>
                </section>
                <dtm-ui-expanded-number-input
                    *ngIf="temporaryZonesForm.controls.minAltitudeType.value === AltitudeType.Value"
                    formControlName="minAltitude"
                    [valueSuffix]="'dtmSupOperationalSituation.temporaryZonesForm.amslSuffix' | transloco"
                >
                    <div class="field-error" *dtmUiFieldHasError="temporaryZonesForm.controls.minAltitude; name: 'required'">
                        {{ "dtmSupOperationalSituation.temporaryZonesForm.requiredFieldError" | transloco }}
                    </div>
                    <div class="field-error" *dtmUiFieldHasError="temporaryZonesForm.controls.minAltitude; name: 'min'; error as error">
                        {{ "dtmSupOperationalSituation.temporaryZonesForm.altitudeMinValue" | transloco : { value: error.min } }}
                    </div>
                    <div class="field-error" *dtmUiFieldHasError="temporaryZonesForm.controls.minAltitude; name: 'max'; error as error">
                        {{ "dtmSupOperationalSituation.temporaryZonesForm.altitudeMaxValue" | transloco : { value: error.max } }}
                    </div>
                </dtm-ui-expanded-number-input>
            </div>
        </dtm-ui-expandable-panel>
    </dtm-ui-loader-container>
    <dtm-ui-expandable-panel isExpanded [hasHeaderSeparator]="false" *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder">
        <h2 class="section-title" headerSlot>
            {{ "dtmSupOperationalSituation.temporaryZonesForm.zoneActiveTime" | transloco }}
        </h2>
        <div class="zone-time-range">
            <dtm-ui-date-field>
                <label>{{ "dtmSupOperationalSituation.temporaryZonesForm.zoneStartDateLabel" | transloco }}</label>
                <input
                    matInput
                    formControlName="zoneStartDate"
                    [min]="TODAY_DATE"
                    [max]="temporaryZonesForm.controls.zoneEndDate.value"
                    [matDatepicker]="startDatePicker"
                    (dateChange)="resetZoneStartTime()"
                    [placeholder]="datePickerPlaceholder"
                />
                <div class="field-error" *dtmUiFieldHasError="temporaryZonesForm.controls.zoneStartDate; name: 'required'">
                    {{ "dtmSupOperationalSituation.temporaryZonesForm.requiredFieldError" | transloco }}
                </div>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="temporaryZonesForm.controls.zoneStartDate; name: 'matDatepickerMin'; error as error"
                >
                    {{
                        "dtmSupOperationalSituation.temporaryZonesForm.minZoneDateStartErrorHint"
                            | transloco : { minDate: error.min | localizeDate }
                    }}
                </div>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="temporaryZonesForm.controls.zoneStartDate; name: 'matDatepickerMax'; error as error"
                >
                    {{ "dtmSupOperationalSituation.temporaryZonesForm.maxZoneDateStartErrorHint" | transloco }}
                </div>
                <mat-datepicker #startDatePicker></mat-datepicker>
            </dtm-ui-date-field>
            <dtm-ui-time-field [maxTime]="temporaryZonesForm.controls.zoneEndDate.value" formControlName="zoneStartTime">
                <label>{{ "dtmSupOperationalSituation.temporaryZonesForm.validityPeriod" | transloco }} </label>
                <div class="field-error" *dtmUiFieldHasError="temporaryZonesForm.controls.zoneStartTime; name: 'max'; error as error">
                    {{
                        "dtmSupOperationalSituation.temporaryZonesForm.maxTimeError"
                            | transloco : { maxTime: error.max | localizeDate : { timeStyle: "short" } }
                    }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="temporaryZonesForm.controls.zoneStartTime; name: 'required'">
                    {{ "dtmSupOperationalSituation.temporaryZonesForm.requiredFieldError" | transloco }}
                </div>
            </dtm-ui-time-field>
        </div>
        <div class="zone-time-range">
            <dtm-ui-date-field>
                <label>{{ "dtmSupOperationalSituation.temporaryZonesForm.zoneEndDateLabel" | transloco }}</label>
                <input
                    matInput
                    formControlName="zoneEndDate"
                    [min]="temporaryZonesForm.controls.zoneStartDate.value"
                    [matDatepicker]="endDatePicker"
                    (dateChange)="resetZoneEndTime()"
                    [placeholder]="datePickerPlaceholder"
                />
                <div class="field-error" *dtmUiFieldHasError="temporaryZonesForm.controls.zoneEndDate; name: 'required'">
                    {{ "dtmSupOperationalSituation.temporaryZonesForm.requiredFieldError" | transloco }}
                </div>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="temporaryZonesForm.controls.zoneEndDate; name: 'matDatepickerMin'; error as error"
                >
                    {{ "dtmSupOperationalSituation.temporaryZonesForm.minZoneDateEndErrorHint" | transloco }}
                </div>
                <mat-datepicker #endDatePicker></mat-datepicker>
            </dtm-ui-date-field>
            <dtm-ui-time-field [minTime]="temporaryZonesForm.controls.zoneStartDate.value" formControlName="zoneEndTime">
                <label>{{ "dtmSupOperationalSituation.temporaryZonesForm.validityPeriod" | transloco }} </label>
                <div class="field-error" *dtmUiFieldHasError="temporaryZonesForm.controls.zoneEndTime; name: 'min'; error as error">
                    {{
                        "dtmSupOperationalSituation.temporaryZonesForm.minTimeError"
                            | transloco : { minTime: error.min | localizeDate : { timeStyle: "short" } }
                    }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="temporaryZonesForm.controls.zoneEndTime; name: 'required'">
                    {{ "dtmSupOperationalSituation.temporaryZonesForm.requiredFieldError" | transloco }}
                </div>
            </dtm-ui-time-field>
        </div>
        <div class="validity-periods">
            <h2 class="section-title" headerSlot>
                {{ "dtmSupOperationalSituation.temporaryZonesForm.timePeriodLabel" | transloco }}
            </h2>
            <mat-slide-toggle formControlName="isValidityPeriod"></mat-slide-toggle>
        </div>
        <div class="zone-activity-type" *ngIf="temporaryZonesForm.controls.isValidityPeriod.value">
            <label>{{ "dtmSupOperationalSituation.temporaryZonesForm.dailyActivityLabel" | transloco }} </label>
            <section class="tile-container">
                <div
                    class="selectable-tile"
                    tabindex="0"
                    [class.selected]="temporaryZonesForm.controls.activityType.value === ActivityZoneType.Constant"
                    (click)="setActivityType(ActivityZoneType.Constant)"
                >
                    {{ "dtmSupOperationalSituation.temporaryZonesForm.constantTileLabel" | transloco }}
                </div>
                <!--TODO: DTM-4141-->
                <div
                    class="selectable-tile"
                    tabindex="0"
                    [class.selected]="temporaryZonesForm.controls.activityType.value === ActivityZoneType.Variable"
                    [class.disabled]="!temporaryZonesForm.controls.zoneStartDate.value || !temporaryZonesForm.controls.zoneEndDate.value"
                    (click)="setActivityType(ActivityZoneType.Variable)"
                >
                    {{ "dtmSupOperationalSituation.temporaryZonesForm.variableTileLabel" | transloco }}
                </div>
            </section>
            <div
                class="zone-time-range"
                formGroupName="dailyActivation"
                *ngIf="temporaryZonesForm.controls.activityType.value === ActivityZoneType.Constant"
            >
                <dtm-ui-time-field
                    formControlName="startTime"
                    [maxTime]="temporaryZonesForm.controls.dailyActivation.controls.endTime.value"
                    (change)="patchDateValue(temporaryZonesForm.controls.dailyActivation.controls.startTime)"
                >
                    <label>{{ "dtmSupOperationalSituation.temporaryZonesForm.startTimeRangeLabel" | transloco }} </label>
                    <div
                        class="field-error"
                        *dtmUiFieldHasError="temporaryZonesForm.controls.dailyActivation.controls.startTime; name: 'max'; error as error"
                    >
                        {{
                            "dtmSupOperationalSituation.temporaryZonesForm.maxTimeError"
                                | transloco : { maxTime: error.max | localizeDate : { timeStyle: "short" } }
                        }}
                    </div>
                    <div
                        class="field-error"
                        *dtmUiFieldHasError="temporaryZonesForm.controls.dailyActivation.controls.startTime; name: 'required'"
                    >
                        {{ "dtmSupOperationalSituation.temporaryZonesForm.requiredFieldError" | transloco }}
                    </div>
                </dtm-ui-time-field>
                <dtm-ui-time-field
                    formControlName="endTime"
                    [minTime]="temporaryZonesForm.controls.dailyActivation.controls.startTime.value"
                    (change)="patchDateValue(temporaryZonesForm.controls.dailyActivation.controls.endTime)"
                >
                    <label>{{ "dtmSupOperationalSituation.temporaryZonesForm.endTimeRangeLabel" | transloco }} </label>
                    <div
                        class="field-error"
                        *dtmUiFieldHasError="temporaryZonesForm.controls.dailyActivation.controls.endTime; name: 'min'; error as error"
                    >
                        {{
                            "dtmSupOperationalSituation.temporaryZonesForm.minTimeError"
                                | transloco : { minTime: error.min | localizeDate : { timeStyle: "short" } }
                        }}
                    </div>
                    <div
                        class="field-error"
                        *dtmUiFieldHasError="temporaryZonesForm.controls.dailyActivation.controls.endTime; name: 'required'"
                    >
                        {{ "dtmSupOperationalSituation.temporaryZonesForm.requiredFieldError" | transloco }}
                    </div>
                </dtm-ui-time-field>
            </div>
            <div
                class="weekly-activation"
                formArrayName="weeklyActivationList"
                *ngIf="temporaryZonesForm.controls.activityType.value === ActivityZoneType.Variable"
            >
                <div
                    *ngFor="let weeklyActivationForm of temporaryZonesForm.controls.weeklyActivationList.controls; let index = index"
                    [formGroupName]="index"
                >
                    <div class="weekday-container">
                        <div class="weekday" [class.disabled]="!weeklyActivationForm.controls.isActivated.value">
                            {{ weeklyActivationForm.value.rangeDate | localizeDate : { weekday: "long" } }}
                        </div>
                        <dtm-ui-checkbox-field
                            (change)="changeWeekDayFormActivity($event, index)"
                            formControlName="isActivated"
                        ></dtm-ui-checkbox-field>
                    </div>
                    <div class="zone-time-range">
                        <dtm-ui-time-field
                            [maxTime]="weeklyActivationForm.controls.endTime.value"
                            formControlName="startTime"
                            (change)="patchDateValue(weeklyActivationForm.controls.startTime)"
                        >
                            <label>{{ "dtmSupOperationalSituation.temporaryZonesForm.startTimeRangeLabel" | transloco }} </label>
                            <div
                                class="field-error"
                                *dtmUiFieldHasError="weeklyActivationForm.controls.startTime; name: 'max'; error as error"
                            >
                                {{
                                    "dtmSupOperationalSituation.temporaryZonesForm.maxTimeError"
                                        | transloco : { maxTime: error.max | localizeDate : { timeStyle: "short" } }
                                }}
                            </div>
                            <div class="field-error" *dtmUiFieldHasError="weeklyActivationForm.controls.startTime; name: 'required'">
                                {{ "dtmSupOperationalSituation.temporaryZonesForm.requiredFieldError" | transloco }}
                            </div>
                        </dtm-ui-time-field>
                        <dtm-ui-time-field
                            [minTime]="weeklyActivationForm.controls.startTime.value"
                            formControlName="endTime"
                            (change)="patchDateValue(weeklyActivationForm.controls.endTime)"
                        >
                            <label>{{ "dtmSupOperationalSituation.temporaryZonesForm.endTimeRangeLabel" | transloco }} </label>
                            <div
                                class="field-error"
                                *dtmUiFieldHasError="weeklyActivationForm.controls.endTime; name: 'min'; error as error"
                            >
                                {{
                                    "dtmSupOperationalSituation.temporaryZonesForm.minTimeError"
                                        | transloco : { minTime: error.min | localizeDate : { timeStyle: "short" } }
                                }}
                            </div>
                            <div class="field-error" *dtmUiFieldHasError="weeklyActivationForm.controls.endTime; name: 'required'">
                                {{ "dtmSupOperationalSituation.temporaryZonesForm.requiredFieldError" | transloco }}
                            </div>
                        </dtm-ui-time-field>
                    </div>
                </div>
            </div>
        </div>
    </dtm-ui-expandable-panel>
    <dtm-ui-expandable-panel isExpanded [hasHeaderSeparator]="false" *ngIf="temporaryZonesForm.controls.zoneType.value">
        <h2 class="section-title" headerSlot>
            {{
                "dtmSupOperationalSituation.temporaryZonesForm.restrictionsLabelValueSelect"
                    | transloco : { value: temporaryZonesForm.controls.zoneType.value }
            }}
        </h2>
        <ng-container
            [ngTemplateOutlet]="
                temporaryZonesForm.controls.zoneType.value === GeographicalZone.LocalInformation ? infoZoneTemplate : basicZoneTemplate
            "
        >
        </ng-container>
        <dtm-ui-textarea-field [maxLength]="MAX_TEXTAREA_DESCRIPTION_LENGTH">
            <label>
                {{ "dtmSupOperationalSituation.temporaryZonesForm.zoneDescriptionLabel" | transloco }}
                <ng-container *ngIf="temporaryZonesForm.controls.zoneType.value !== GeographicalZone.LocalInformation">
                    {{ "dtmSupOperationalSituation.temporaryZonesForm.optionalLabel" | transloco }}
                </ng-container>
            </label>
            <textarea [rows]="4" formControlName="zoneDescription" matInput></textarea>
            <div class="field-error" *dtmUiFieldHasError="temporaryZonesForm.controls.zoneDescription; name: 'maxlength'; error as error">
                {{
                    "dtmSupOperationalSituation.temporaryZonesForm.zoneDescriptionMaxLengthError"
                        | transloco : { maxLength: error.requiredLength }
                }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="temporaryZonesForm.controls.zoneDescription; name: 'required'">
                {{ "dtmSupOperationalSituation.temporaryZonesForm.requiredFieldError" | transloco }}
            </div>
        </dtm-ui-textarea-field>
        <ng-template #basicZoneTemplate>
            <dtm-ui-select-field
                *ngIf="temporaryZonesForm.controls.zoneType.value !== GeographicalZone.DRAI"
                formControlName="restriction"
                [placeholder]="'dtmSupOperationalSituation.temporaryZonesForm.chooseRestrictionPlaceholder' | transloco"
            >
                <dtm-ui-select-option *ngFor="let restrictionType of restrictionTypeList$ | ngrxPush" [value]="restrictionType">
                    {{ "dtmSupOperationalSituation.temporaryZonesForm.restrictionLabel" | transloco : { value: restrictionType } }}
                </dtm-ui-select-option>
                <div class="field-error" *dtmUiFieldHasError="temporaryZonesForm.controls.restriction; name: 'required'">
                    {{ "dtmSupOperationalSituation.temporaryZonesForm.requiredFieldError" | transloco }}
                </div>
            </dtm-ui-select-field>
        </ng-template>
        <ng-template #infoZoneTemplate>
            <dtm-ui-select-field
                formControlName="infoZoneType"
                [placeholder]="'dtmSupOperationalSituation.temporaryZonesForm.chooseTypePlaceholder' | transloco"
                (selectionChange)="displayZoneObjectType($event.value)"
            >
                <label>{{ "dtmSupOperationalSituation.temporaryZonesForm.spatialInformationLabel" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let infoZoneType of infoZoneValues" [value]="infoZoneType.type">
                    <div class="select-value">
                        <dtm-ui-icon [name]="infoZoneType.icon"></dtm-ui-icon>
                        {{ "dtmSupOperationalSituation.zoneInfo.infoZoneType" | transloco : { value: infoZoneType.type } }}
                    </div>
                </dtm-ui-select-option>
                <div class="field-error" *dtmUiFieldHasError="temporaryZonesForm.controls.infoZoneType; name: 'required'">
                    {{ "dtmSupOperationalSituation.temporaryZonesForm.requiredFieldError" | transloco }}
                </div>
            </dtm-ui-select-field>

            <dtm-ui-select-field
                *ngIf="temporaryZonesForm.controls.infoZoneType.value | invoke : isGatheringType"
                formControlName="zoneObjectType"
                [placeholder]="'dtmSupOperationalSituation.temporaryZonesForm.chooseTypePlaceholder' | transloco"
            >
                <label>{{ "dtmSupOperationalSituation.temporaryZonesForm.objectTypeLabel" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let type of ZoneObjectType | keyvalue" [value]="type.value">
                    <div class="select-value">
                        {{ "dtmSupOperationalSituation.zoneInfo.zoneObjectValueLabel" | transloco : { value: type.value } }}
                    </div>
                </dtm-ui-select-option>
                <div class="field-error" *dtmUiFieldHasError="temporaryZonesForm.controls.zoneObjectType; name: 'required'">
                    {{ "dtmSupOperationalSituation.temporaryZonesForm.requiredFieldError" | transloco }}
                </div>
            </dtm-ui-select-field>
        </ng-template>
    </dtm-ui-expandable-panel>
    <dtm-ui-expandable-panel
        isExpanded
        [isDisabled]="!vm.isPhoneNumberVisible"
        [isExpanded]="vm.isPhoneNumberVisible"
        [hasHeaderSeparator]="false"
    >
        <h2 class="section-title action-title" headerSlot>
            {{ "dtmSupOperationalSituation.temporaryZonesForm.managerContactLabel" | transloco }}
            <button
                type="button"
                class="button-secondary action-button"
                (click)="$event.stopPropagation(); changePhoneNumberFormVisibility(false)"
                *ngIf="!vm.isPhoneNumberVisible"
            >
                <dtm-ui-icon name="add"></dtm-ui-icon>
                {{ "dtmSupOperationalSituation.temporaryZonesForm.addButtonLabel" | transloco }}
            </button>
            <button
                type="button"
                class="button-icon action-button"
                (click)="$event.stopPropagation(); changePhoneNumberFormVisibility(true)"
                *ngIf="vm.isPhoneNumberVisible && vm.canDeletePhoneNumber"
            >
                <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
            </button>
        </h2>
        <dtm-ui-phone-number-field formControlName="phoneNumber">
            <label>{{ "dtmSupOperationalSituation.temporaryZonesForm.phoneNumberLabel" | transloco }}</label>
            <div class="field-error" *dtmUiFieldHasError="temporaryZonesForm.controls.phoneNumber; name: 'required'">
                {{ "dtmSupOperationalSituation.temporaryZonesForm.requiredFieldError" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="temporaryZonesForm.controls.phoneNumber; name: 'invalidNumber'">
                {{ "dtmSupOperationalSituation.temporaryZonesForm.invalidPhoneNumberErrorHint" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="temporaryZonesForm.controls.phoneNumber; name: 'invalidNumberType'">
                {{ "dtmSupOperationalSituation.temporaryZonesForm.invalidNumberTypeErrorHint" | transloco }}
            </div>
        </dtm-ui-phone-number-field>
    </dtm-ui-expandable-panel>
    <dtm-ui-expandable-panel
        isExpanded
        [isDisabled]="!vm.isNoteFormVisible"
        [isExpanded]="vm.isNoteFormVisible"
        [hasHeaderSeparator]="false"
    >
        <div class="section-title action-title" headerSlot>
            <dtm-ui-icon name="draft"></dtm-ui-icon>
            {{ "dtmSupOperationalSituation.temporaryZonesForm.internalDescriptionLabel" | transloco }}
            <button
                type="button"
                class="button-secondary action-button"
                (click)="$event.stopPropagation(); changeNoteFormVisibility(false)"
                *ngIf="!vm.isNoteFormVisible"
            >
                <dtm-ui-icon name="add"></dtm-ui-icon>
                {{ "dtmSupOperationalSituation.temporaryZonesForm.addButtonLabel" | transloco }}
            </button>
            <button
                type="button"
                class="button-icon action-button"
                (click)="$event.stopPropagation(); changeNoteFormVisibility(true)"
                *ngIf="vm.isNoteFormVisible"
            >
                <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
            </button>
        </div>
        <dtm-ui-textarea-field [maxLength]="MAX_TEXTAREA_DESCRIPTION_LENGTH">
            <label>{{ "dtmSupOperationalSituation.temporaryZonesForm.addNoteLabel" | transloco }}</label>
            <textarea [rows]="4" formControlName="note" matInput></textarea>
            <div class="field-error" *dtmUiFieldHasError="temporaryZonesForm.controls.note; name: 'maxlength'; error as error">
                {{
                    "dtmSupOperationalSituation.temporaryZonesForm.noteDescriptionMaxLengthError"
                        | transloco : { maxLength: error.requiredLength }
                }}
            </div>
        </dtm-ui-textarea-field>
    </dtm-ui-expandable-panel>
</div>
