import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { GeoJSON, IconName } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { GeographicalZone, InfoZone } from "../../models/operational.situation.models";
import { BasicTemporaryZone, TemporaryZone, infoZoneIconMap } from "../../models/temporary-zones.models";

interface ZoneInfoComponentState {
    isExpanded: boolean;
    isActive: boolean;
    canEdit: boolean;
    canPublish: boolean;
    canClone: boolean;
    zone: BasicTemporaryZone | undefined;
    isSelected: boolean;
    zoneDetails: TemporaryZone | undefined;
}

@Component({
    selector: "supervisor-shared-lib-zone-info[zone]",
    templateUrl: "./zone-info.component.html",
    styleUrls: ["./zone-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class ZoneInfoComponent {
    @Input() public set zone(value: BasicTemporaryZone | undefined) {
        this.localStore.patchState({ zone: value });
    }
    @Input() public set zoneDetails(value: TemporaryZone | undefined) {
        this.localStore.patchState({ zoneDetails: value });
    }
    @Input() public set isZoneSelected(value: BooleanInput) {
        this.localStore.patchState({ isSelected: coerceBooleanProperty(value) });

        if (!value) {
            this.localStore.patchState({ isExpanded: false });
        }
    }
    @Input() public set isActive(value: BooleanInput) {
        this.localStore.patchState({ isActive: coerceBooleanProperty(value) });
    }
    @Input() public set canEdit(value: BooleanInput) {
        this.localStore.patchState({ canEdit: coerceBooleanProperty(value) });
    }
    @Input() public set canPublish(value: BooleanInput) {
        this.localStore.patchState({ canPublish: coerceBooleanProperty(value) });
    }
    @Input() public set canClone(value: BooleanInput) {
        this.localStore.patchState({ canClone: coerceBooleanProperty(value) });
    }

    @Output() public readonly zoneDelete = new EventEmitter<string>();
    @Output() public readonly zoneManage = new EventEmitter<string>();
    @Output() public readonly zoneZoom = new EventEmitter<GeoJSON>();
    @Output() public readonly zoneMark = new EventEmitter<string>();
    @Output() public readonly zoneClone = new EventEmitter<string>();

    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly isActive$ = this.localStore.selectByKey("isActive");
    protected readonly canEdit$ = this.localStore.selectByKey("canEdit");
    protected readonly canPublish$ = this.localStore.selectByKey("canPublish");
    protected readonly canClone$ = this.localStore.selectByKey("canClone");
    protected readonly isSelected$ = this.localStore.selectByKey("isSelected");
    protected readonly zoneDetails$ = this.localStore.selectByKey("zoneDetails");
    protected readonly zone$ = this.localStore.selectByKey("zone").pipe(RxjsUtils.filterFalsy());

    protected readonly GeographicalZone = GeographicalZone;

    constructor(private readonly localStore: LocalComponentStore<ZoneInfoComponentState>) {
        this.localStore.setState({
            isExpanded: false,
            zone: undefined,
            canEdit: false,
            canClone: false,
            canPublish: false,
            isSelected: false,
            zoneDetails: undefined,
            isActive: false,
        });
    }

    protected expandContent(zoneId: string): void {
        this.localStore.patchState(({ isExpanded }) => ({ isExpanded: !isExpanded }));
        this.zoneMark.emit(zoneId);
    }

    protected removeDash(zoneType: string): string {
        return zoneType.replace("-", "");
    }

    protected getInfoZoneTypeIcon(infoZoneType: InfoZone | undefined): IconName {
        return infoZoneIconMap.filter((zoneType) => zoneType.type === infoZoneType)[0].icon;
    }
}
