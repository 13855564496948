import { MissionStatus } from "../../../models/operational.situation.models";

export interface MissionStatusFilterConfig {
    translationKey: string;
    status: MissionStatus;
    iconName?: string;
}

export const filterConfiguration: MissionStatusFilterConfig[] = [
    {
        translationKey: "dtmSupOperationalSituation.missionStatusFilters.allFilterLabel",
        status: MissionStatus.All,
    },
    {
        translationKey: "dtmSupOperationalSituation.missionStatusFilters.inRealizationLabel",
        status: MissionStatus.Started,
        iconName: "play-circle",
    },
    {
        translationKey: "dtmSupOperationalSituation.missionStatusFilters.activatedLabel",
        status: MissionStatus.Activated,
        iconName: "map-pin",
    },
];
