<div *ngrxLet="{ selectedWorkspace: selectedWorkspace$, userName: userName$, userProfile: userProfile$ } as vm">
    <button type="button" class="user-button selected-user" [matMenuTriggerFor]="workspaceMenuLabel">
        <dtm-ui-avatar
            [fullName]="vm.selectedWorkspace?.name"
            [label]="vm.userName"
            [sourceUrl]="vm.selectedWorkspace?.imageUrl"
        ></dtm-ui-avatar>
    </button>
    <mat-menu #workspaceMenuLabel="matMenu" xPosition="before">
        <button
            mat-menu-item
            type="button"
            *ngFor="let workspace of vm.userProfile?.workspaces"
            [disabled]="vm.selectedWorkspace === workspace"
            (click)="workspaceChange.emit(workspace.id)"
        >
            <dtm-ui-avatar [fullName]="workspace.name" [sourceUrl]="workspace.imageUrl"></dtm-ui-avatar>
        </button>
        <button mat-menu-item class="separated" type="button" (click)="logout.emit()">
            <dtm-ui-icon name="logout-circle"></dtm-ui-icon>
            <span>{{ "dtmSupervisorLibShared.header.logoutMenuItemLabel" | transloco }}</span>
        </button>
    </mat-menu>
</div>
