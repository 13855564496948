export namespace DtmAreasActions {
    export class SetDtmAreas {
        public static readonly type = "[SUP shared] Set dtm areas";
        constructor(public readonly dtmAreas: string[]) {}
    }

    export class GetDtmStats {
        public static readonly type = "[SUP shared] Get dtm stats";
        constructor(public readonly dtmNames: string[], public readonly authorityUnitId?: string) {}
    }
}
