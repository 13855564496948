import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { FileUploadErrorType, FilesUploadApi } from "@dtm-frontend/shared/ui";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { EMPTY, throwError } from "rxjs";
import { PLANNED_MISSION_ENDPOINTS, PlannedMissionEndpoints } from "../../planned-missions.tokens";
import { MissionState } from "../../state/mission.state";

@Injectable()
export class MissionAttachmentsService implements FilesUploadApi {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly store: Store,
        @Inject(PLANNED_MISSION_ENDPOINTS) private readonly endpoints: PlannedMissionEndpoints
    ) {}

    public uploadFile() {
        return EMPTY;
    }

    public getFile(fileId: string) {
        const currentPlanId = this.store.selectSnapshot(MissionState.currentPlanId);

        if (!currentPlanId) {
            return throwError(() => new Error("Invalid plan id"));
        }

        return this.httpClient.get(StringUtils.replaceInTemplate(this.endpoints.getFile, { fileId, planId: currentPlanId }), {
            responseType: "blob",
        });
    }

    public getFilesCompressed() {
        return EMPTY;
    }

    public manageUploadError(): { type: FileUploadErrorType } {
        return { type: FileUploadErrorType.Unknown };
    }
}
