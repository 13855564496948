import { coerceBooleanProperty, coerceNumberProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, forwardRef } from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { distinctUntilChanged, first, map } from "rxjs/operators";
import { DmsCoordinatesUtils } from "../..";
import {
    DmsCoordinates,
    DmsDirectionCoordinates,
    GeographicCoordinatesDirection,
    GeographicCoordinatesType,
    HemisphereSign,
} from "../../models/dms-coordinates-input.models";

interface DmsCoordinatesInputComponentState {
    inputLabel: string;
    coordinates: number | null;
    geographicCoordinatesType: GeographicCoordinatesType;
    geographicalDirections: GeographicCoordinatesDirection[];
    isActivated: boolean;
    isClearable: boolean;
    fixedDirection: GeographicCoordinatesDirection | null;
}

const MAX_MINUTES_VALUE = 59;
const MAX_SECONDS_VALUE = 59;
const MAX_LONGITUDE = 180;
const MAX_LATITUDE = 90;

@UntilDestroy()
@Component({
    selector: "dtm-ui-dms-coordinates-input",
    templateUrl: "./dms-coordinates-input.component.html",
    styleUrls: ["./dms-coordinates-input.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DmsCoordinatesInputComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DmsCoordinatesInputComponent),
            multi: true,
        },
    ],
})
export class DmsCoordinatesInputComponent implements ControlValueAccessor, Validator, OnInit {
    protected maxDegreesValue = 0;
    private disabledValue = false;

    protected readonly inputLabel$ = this.localStore.selectByKey("inputLabel");
    protected readonly geographicalDirections$ = this.localStore.selectByKey("geographicalDirections");
    protected readonly isLongitudeType$ = this.localStore
        .selectByKey("geographicCoordinatesType")
        .pipe(map((coordinatesType) => coordinatesType === GeographicCoordinatesType.Longitude));
    protected readonly isActivated$ = this.localStore.selectByKey("isActivated");
    protected readonly isClearable$ = this.localStore.selectByKey("isClearable");
    protected readonly fixedDirection$ = this.localStore.selectByKey("fixedDirection");

    protected readonly dmsFormGroup = new FormGroup({
        degrees: new FormControl<string>("", {
            validators: [
                Validators.required,
                Validators.min(0),
                (control: AbstractControl) => Validators.max(this.maxDegreesValue)(control),
            ],
        }),
        minutes: new FormControl<string>("", {
            validators: [Validators.required, Validators.min(0), Validators.max(MAX_MINUTES_VALUE)],
        }),
        seconds: new FormControl<string>("", {
            validators: [Validators.required, Validators.min(0), Validators.max(MAX_SECONDS_VALUE)],
        }),
        direction: new FormControl<GeographicCoordinatesDirection | null>(null, { validators: [Validators.required] }),
    });

    @Input() public set inputLabel(value: string) {
        this.localStore.patchState({ inputLabel: value });
    }
    @Input({ required: true }) public set geographicCoordinatesType(value: GeographicCoordinatesType) {
        this.localStore.patchState({ geographicCoordinatesType: value });
        this.setGeographicalDirections(value);
    }
    @Input() public set coordinates(value: number) {
        this.localStore.patchState({ coordinates: value });

        if (FunctionUtils.isNullOrUndefined(value)) {
            this.dmsFormGroup.controls.degrees.reset();
            this.dmsFormGroup.controls.minutes.reset();
            this.dmsFormGroup.controls.seconds.reset();

            return;
        }

        const type = this.localStore.selectSnapshotByKey("geographicCoordinatesType");
        const dmsCoordinates = DmsCoordinatesUtils.convertDecimalDegreesToDmsCoordinates(value);
        const dmsCoordinatesWithDirection = DmsCoordinatesUtils.addDirectionToDmsCoordinates(dmsCoordinates, type);

        this.dmsFormGroup.setValue(
            {
                degrees: dmsCoordinatesWithDirection.degrees.toString(),
                minutes: this.addLeadingZeros(dmsCoordinatesWithDirection.minutes),
                seconds: this.addLeadingZeros(dmsCoordinatesWithDirection.seconds),
                direction: dmsCoordinatesWithDirection.direction,
            },
            { emitEvent: false }
        );
    }

    @Input()
    public get disabled(): boolean {
        return this.disabledValue;
    }
    public set disabled(value: boolean) {
        this.disabledValue = coerceBooleanProperty(value);

        if (this.disabledValue) {
            this.dmsFormGroup.disable();
        } else {
            this.dmsFormGroup.enable();
        }
    }

    @Input() public set isClearable(value: boolean) {
        this.localStore.patchState({ isClearable: value });
    }

    @Input() public set fixedDirection(value: GeographicCoordinatesDirection | null) {
        this.localStore.patchState({ fixedDirection: value });
        this.dmsFormGroup.controls.direction.setValue(value);
    }

    // NOTE: Event emitted when value has been changed by the user.
    @Output() public coordinatesChange = new EventEmitter<void>();

    protected propagateTouch = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;
    private propagateChange: (value: number) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<DmsCoordinatesInputComponentState>) {
        this.localStore.setState({
            inputLabel: "",
            coordinates: null,
            geographicCoordinatesType: GeographicCoordinatesType.Latitude,
            geographicalDirections: [],
            isActivated: false,
            isClearable: false,
            fixedDirection: null,
        });
    }

    public ngOnInit(): void {
        this.listenToFormValueChanges();
    }

    public registerOnChange(fn: (value: number) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public writeValue(value: number): void {
        this.coordinates = value;
    }

    public validate(): ValidationErrors | null {
        if (this.dmsFormGroup.invalid) {
            return {
                coordinates: true,
            };
        }

        return null;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public updateDirection(value: GeographicCoordinatesDirection): void {
        this.dmsFormGroup.controls.direction.setValue(value);
        this.coordinatesChange.emit();
    }

    protected reformatControlValue(formControl: FormControl): void {
        if (!formControl.value) {
            return;
        }

        formControl.patchValue(this.addLeadingZeros(formControl.value), { emitEvent: false });
    }

    protected pasteCoordinates(event: ClipboardEvent): void {
        event.preventDefault();
        const clipboardData = event.clipboardData?.getData("text/plain");

        if (!clipboardData) {
            return;
        }

        const clipboardNumericValue = +clipboardData.replace(/,/g, ".").replace(/[^-0-9.]/g, "");

        if (isNaN(clipboardNumericValue)) {
            return;
        }

        if (clipboardData.includes(".")) {
            this.pasteDecimalCoordinates(clipboardNumericValue);
        } else {
            this.pasteDMSCoordinates(clipboardNumericValue.toString());
        }

        this.coordinatesChange.emit();
    }

    protected clearCoordinates(): void {
        this.dmsFormGroup.controls.degrees.reset();
        this.dmsFormGroup.controls.minutes.reset();
        this.dmsFormGroup.controls.seconds.reset();
        this.dmsFormGroup.markAsTouched();
    }

    protected tryChangeFocus(event: KeyboardEvent, inputTargets: { previousInput?: HTMLInputElement; nextInput?: HTMLInputElement }): void {
        const eventTarget = event.target as HTMLInputElement;
        const caretPosition = eventTarget.selectionStart;
        const value = eventTarget.value;
        const maxInputLength = eventTarget.maxLength;
        const isNumberKeyPressed = !isNaN(coerceNumberProperty(event.key));

        if (inputTargets.nextInput && isNumberKeyPressed && caretPosition === maxInputLength) {
            inputTargets.nextInput.focus();

            return;
        }

        if (inputTargets.previousInput && event.code === "Backspace" && caretPosition === 0 && !value) {
            inputTargets.previousInput.focus();
        }
    }

    protected tryEmitCoordinatesChange(): void {
        if (this.dmsFormGroup.invalid) {
            return;
        }

        this.coordinatesChange.emit();
    }

    private addLeadingZeros(value: number | string): string {
        return value.toString().padStart(2, "0");
    }

    private pasteDecimalCoordinates(value: number): void {
        const type = this.localStore.selectSnapshotByKey("geographicCoordinatesType");
        const dmsCoordinates = DmsCoordinatesUtils.convertDecimalDegreesToDmsCoordinates(value);
        const dmsWithDirection = DmsCoordinatesUtils.addDirectionToDmsCoordinates(dmsCoordinates, type);

        this.dmsFormGroup.patchValue({
            degrees: dmsWithDirection.degrees.toString(),
            minutes: this.addLeadingZeros(dmsWithDirection.minutes),
            seconds: this.addLeadingZeros(dmsWithDirection.seconds),
            direction: dmsWithDirection.direction,
        });
    }

    private pasteDMSCoordinates(value: string): void {
        this.dmsFormGroup.patchValue({
            degrees: value.slice(0, -4), // eslint-disable-line no-magic-numbers
            minutes: value.slice(-4, -2), // eslint-disable-line no-magic-numbers
            seconds: value.slice(-2), // eslint-disable-line no-magic-numbers
        });
    }

    private listenToFormValueChanges(): void {
        this.dmsFormGroup.valueChanges.pipe(first(), untilDestroyed(this)).subscribe(() => {
            this.localStore.patchState({
                isActivated: true,
            });
        });

        this.dmsFormGroup.valueChanges
            .pipe(
                distinctUntilChanged((oldValue, newValue) => equal(oldValue, newValue)),
                untilDestroyed(this)
            )
            .subscribe((value) => {
                if (!this.validate() && value.direction) {
                    const dms: DmsDirectionCoordinates = {
                        degrees: Number(value.degrees),
                        minutes: Number(value.minutes),
                        seconds: Number(value.seconds),
                        direction: value.direction,
                    };
                    const dmsCoordinatesWithoutDirection = this.removeDirectionFromCoordinates(dms);
                    this.propagateChange(DmsCoordinatesUtils.convertDmsCoordinatesToDecimalDegrees(dmsCoordinatesWithoutDirection));
                }

                this.onValidationChange();
            });
    }

    private setGeographicalDirections(type: GeographicCoordinatesType): void {
        if (type === GeographicCoordinatesType.Latitude) {
            this.maxDegreesValue = MAX_LATITUDE;
            this.localStore.patchState({
                geographicalDirections: [GeographicCoordinatesDirection.North, GeographicCoordinatesDirection.South],
            });
            this.dmsFormGroup.controls.direction.setValue(GeographicCoordinatesDirection.North);
        } else {
            this.maxDegreesValue = MAX_LONGITUDE;
            this.localStore.patchState({
                geographicalDirections: [GeographicCoordinatesDirection.East, GeographicCoordinatesDirection.West],
            });
            this.dmsFormGroup.controls.direction.setValue(GeographicCoordinatesDirection.East);
        }
    }

    private removeDirectionFromCoordinates(coordinatesWithDirection: DmsDirectionCoordinates): DmsCoordinates {
        const hemisphereSign: HemisphereSign =
            coordinatesWithDirection.direction === GeographicCoordinatesDirection.South ||
            coordinatesWithDirection.direction === GeographicCoordinatesDirection.West
                ? HemisphereSign.Negative
                : HemisphereSign.Positive;

        return {
            degrees: coordinatesWithDirection.degrees * hemisphereSign,
            minutes: coordinatesWithDirection.minutes,
            seconds: coordinatesWithDirection.seconds,
        };
    }
}
