export namespace GlobalAlarmActions {
    export class GlobalAlarmWatch {
        public static readonly type = "[SUP - Shared] Global alarm watch";
    }

    export class ZonesAlarmWatch {
        public static readonly type = "[SUP - Shared] Zones alarm watch";
        constructor(public zones: string[]) {}
    }

    export class DeleteAlarm {
        public static readonly type = "[SUP - Shared] Delete global alarm";
        constructor(public alarmId: string) {}
    }
}
