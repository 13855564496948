<mat-tab-group class="dtm-tabs" color="accent" dynamicHeight disablePagination (selectedTabChange)="tabSelect($event.index)">
    <mat-tab *ngFor="let sector of sectorList$ | ngrxPush" [label]="sector.name">
        <div class="details">
            <div class="sector-name">
                {{ sector.name }}
            </div>
            <dtm-ui-label-value
                class="sector-id"
                [label]="'dtmSupNetworkMonitoring.unavailabilitySectorDetails.idLabel' | transloco"
                [value]="sector.id"
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                class="azimuth"
                [label]="'dtmSupNetworkMonitoring.unavailabilitySectorDetails.azimuthLabel' | transloco"
                [value]="'dtmSupNetworkMonitoring.unavailabilitySectorDetails.azimuthDegreeValue' | transloco : { value: sector.azimuth }"
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                class="vision-range"
                [label]="'dtmSupNetworkMonitoring.unavailabilitySectorDetails.visionRangeLabel' | transloco"
                [value]="
                    'dtmSupNetworkMonitoring.unavailabilitySectorDetails.visionRangeDegree'
                        | transloco : { min: sector.minViewAngle, max: sector.maxViewAngle }
                "
            ></dtm-ui-label-value>
            <div class="total-unavailability">
                <span>{{ "dtmSupNetworkMonitoring.unavailabilitySectorDetails.unavailabilityDevice" | transloco }}</span>
                {{
                    "dtmSupNetworkMonitoring.unavailabilitySectorDetails.totalUnavailabilityValue"
                        | transloco : { value: sector.totalDaysViolationInMinutes }
                }}
            </div>
            <dtm-ui-label-value
                class="last-unavailability-days"
                [label]="'dtmSupNetworkMonitoring.unavailabilitySectorDetails.lastDaysValue' | transloco : { value: 7 }"
                [value]="
                    'dtmSupNetworkMonitoring.unavailabilitySectorDetails.minutesValue'
                        | transloco : { value: sector.last7DaysViolationInMinutes }
                "
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                class="last-unavailability-days"
                [label]="'dtmSupNetworkMonitoring.unavailabilitySectorDetails.lastDaysValue' | transloco : { value: 60 }"
                [value]="
                    'dtmSupNetworkMonitoring.unavailabilitySectorDetails.minutesValue'
                        | transloco : { value: sector.last60DaysViolationInMinutes }
                "
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                class="last-unavailability-days"
                [label]="'dtmSupNetworkMonitoring.unavailabilitySectorDetails.lastDaysValue' | transloco : { value: 90 }"
                [value]="
                    'dtmSupNetworkMonitoring.unavailabilitySectorDetails.minutesValue'
                        | transloco : { value: sector.last90DaysViolationInMinutes }
                "
            ></dtm-ui-label-value>
            <supervisor-shared-lib-event-history-list
                class="history-list"
                [deviceHistoryList]="deviceHistoryList$ | ngrxPush"
                [mountedDevices]="sector.mountedDevicesHistory"
                [isProcessing]="isProcessing$ | ngrxPush"
                [isDeviceHistoryFullyLoaded]="isDeviceHistoryFullyLoaded$ | ngrxPush"
                (deviceSelect)="deviceSelect.emit($event)"
                (formSave)="formSave.emit($event)"
                (contentLoad)="contentLoad.emit($event)"
            ></supervisor-shared-lib-event-history-list>
        </div>
    </mat-tab>
</mat-tab-group>
