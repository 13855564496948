import { AuthorityUnitType, SupUserProfile, WorkspacePermission } from "../models/sup-user.models";

export interface SupUserProfileResponseBody {
    userId: string;
    workspaces: {
        id: string;
        authorityUnit: {
            id: string;
            type: AuthorityUnitType;
            name: string;
            permissions: AuthorityUnitPermissionsUnion[];
        };
        name: string;
        imageUrl: string;
        manageRestOfCountry: boolean;
        authorityUnitsZones: {
            designator: string;
        }[];
    }[];
}

interface AuthorityUnitPermissions {
    type: PermissionType;
    values: string[];
}

enum PermissionType {
    AuthorityUnitDtmUser = "AuthorityUnitDtmUser",
    AuthorityUnitDevice = "AuthorityUnitDevice",
    AuthorityUnitZone = "AuthorityUnitZone",
    AuthorityUnitMissionPlan = "AuthorityUnitMissionPlan",
    AuthorityUnitMission = "AuthorityUnitMission",
    Unknown = "UNKNOWN",
}

interface AuthorityUnitDtmUser extends AuthorityUnitPermissions {
    type: PermissionType.AuthorityUnitDtmUser;
    values: ("READ" | "MANAGE")[];
}

interface AuthorityUnitDevice extends AuthorityUnitPermissions {
    type: PermissionType.AuthorityUnitDevice;
    values: ("READ" | "MANAGE")[];
}

interface AuthorityUnitZone extends AuthorityUnitPermissions {
    type: PermissionType.AuthorityUnitZone;
    values: ("READ" | "MANAGE_DRAI" | "MANAGE_DRAR" | "MANAGE_DRAP" | "MANAGE_LOCAL")[];
}

interface AuthorityUnitMissionPlan extends AuthorityUnitPermissions {
    type: PermissionType.AuthorityUnitMissionPlan;
    values: ("READ" | "ACCEPT" | "CHANGE_PRIORITY")[];
}

interface AuthorityUnitMission extends AuthorityUnitPermissions {
    type: PermissionType.AuthorityUnitMission;
    values: ("READ" | "MANAGE")[];
}

type AuthorityUnitPermissionsUnion =
    | AuthorityUnitDtmUser
    | AuthorityUnitDevice
    | AuthorityUnitZone
    | AuthorityUnitMissionPlan
    | AuthorityUnitMission;

export function convertSupUserProfileResponseBodyToSupUserProfile(response: SupUserProfileResponseBody): SupUserProfile {
    return {
        userId: response.userId,
        workspaces: response.workspaces.map((workspace) => {
            const permissions: WorkspacePermission[] = [];

            workspace.authorityUnit.permissions.forEach((permission) => {
                switch (permission.type) {
                    case PermissionType.AuthorityUnitDtmUser: {
                        if (permission.values.includes("READ")) {
                            permissions.push(WorkspacePermission.PermissionRead);
                        }
                        if (permission.values.includes("MANAGE")) {
                            permissions.push(WorkspacePermission.PermissionManagement);
                        }
                        break;
                    }
                    case PermissionType.AuthorityUnitDevice: {
                        if (permission.values.includes("READ")) {
                            permissions.push(WorkspacePermission.MonitoringRead);
                        }
                        if (permission.values.includes("MANAGE")) {
                            permissions.push(WorkspacePermission.MonitoringManagement);
                        }
                        break;
                    }
                    case PermissionType.AuthorityUnitZone: {
                        if (permission.values.includes("MANAGE_DRAI")) {
                            permissions.push(WorkspacePermission.ZoneManagementDRAI);
                        }
                        if (permission.values.includes("MANAGE_DRAP")) {
                            permissions.push(WorkspacePermission.ZoneManagementDRAP);
                        }
                        if (permission.values.includes("MANAGE_DRAR")) {
                            permissions.push(WorkspacePermission.ZoneManagementDRAR);
                        }
                        if (permission.values.includes("MANAGE_LOCAL")) {
                            permissions.push(WorkspacePermission.ZoneManagementLocal);
                        }
                        break;
                    }
                    case PermissionType.AuthorityUnitMissionPlan: {
                        if (permission.values.includes("READ")) {
                            permissions.push(WorkspacePermission.PlannedMissionsRead);
                        }
                        if (permission.values.includes("CHANGE_PRIORITY")) {
                            permissions.push(WorkspacePermission.PriorityChanges);
                        }
                        if (permission.values.includes("ACCEPT")) {
                            permissions.push(WorkspacePermission.PlannedMissionsAccept);
                        }
                        break;
                    }
                    case PermissionType.AuthorityUnitMission: {
                        if (permission.values.includes("READ")) {
                            permissions.push(WorkspacePermission.OperationalSituationRead);
                        }
                        if (permission.values.includes("MANAGE")) {
                            permissions.push(WorkspacePermission.OperationalSituationManagement);
                        }
                        break;
                    }
                }
            });

            return {
                id: workspace.id,
                name: workspace.name,
                imageUrl: workspace.imageUrl,
                isManageRestOfCountryAllowed: workspace.manageRestOfCountry,
                authorityUnit: workspace.authorityUnit,
                authorityUnitZones: workspace.authorityUnitsZones,
                permissions,
            };
        }),
    };
}
