import { ModuleWithProviders, NgModule } from "@angular/core";
import { WebsocketService } from "@dtm-frontend/shared/websocket";
import { NgxsModule } from "@ngxs/store";
import { GlobalAlarmApiService } from "./services/global-alarm-api.service";
import { GlobalAlarmState } from "./state/global-alarm.state";

@NgModule({
    imports: [NgxsModule.forFeature([GlobalAlarmState])],
    providers: [
        {
            provide: [GlobalAlarmApiService],
            useValue: undefined,
        },
        {
            provide: WebsocketService,
            useValue: undefined,
        },
    ],
})
export class GlobalAlarmModule {
    public static forRoot(): ModuleWithProviders<GlobalAlarmModule> {
        return {
            ngModule: GlobalAlarmModule,
            providers: [GlobalAlarmApiService, WebsocketService],
        };
    }

    public static forTest(): ModuleWithProviders<GlobalAlarmModule> {
        return {
            ngModule: GlobalAlarmModule,
            providers: [
                {
                    provide: GlobalAlarmApiService,
                    useValue: null,
                },
            ],
        };
    }
}
