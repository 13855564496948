import { GeoJSON } from "@dtm-frontend/shared/ui";
import { DtmName } from "../../planned-missions/models/mission.models";

/* eslint-disable no-magic-numbers */
export const dtmBboxFeaturesMap = new Map<DtmName, GeoJSON>([
    [
        DtmName.Nadarzyn,
        {
            type: "Feature",
            bbox: [20.666922464251616, 51.95888250381741, 20.998846071297894, 52.17090632443905],
            properties: {},
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [20.666922464251616, 51.95888250381741],
                        [20.998846071297894, 51.95888250381741],
                        [20.998846071297894, 52.17090632443905],
                        [20.666922464251616, 52.17090632443905],
                        [20.666922464251616, 51.95888250381741],
                    ],
                ],
            },
        },
    ],
    [
        DtmName.Lublin,
        {
            type: "Feature",
            bbox: [22.16561680077728, 51.238440375710844, 22.735058206204222, 51.37946973218534],
            properties: {},
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [22.16561680077728, 51.238440375710844],
                        [22.735058206204222, 51.238440375710844],
                        [22.735058206204222, 51.37946973218534],
                        [22.16561680077728, 51.37946973218534],
                        [22.16561680077728, 51.238440375710844],
                    ],
                ],
            },
        },
    ],
    [
        DtmName.Gliwice,
        {
            type: "Feature",
            bbox: [18.49976164350216, 50.17918672833943, 18.67475506765554, 50.28617058143524],
            properties: {},
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [18.49976164350216, 50.17918672833943],
                        [18.67475506765554, 50.17918672833943],
                        [18.67475506765554, 50.28617058143524],
                        [18.49976164350216, 50.28617058143524],
                        [18.49976164350216, 50.17918672833943],
                    ],
                ],
            },
        },
    ],
]);

export const fallbackDtmArea: GeoJSON = {
    type: "Feature",
    properties: {},
    geometry: {
        coordinates: [
            [
                [14.262808745410865, 54.54925284764431],
                [14.262808745410865, 50.01340031149985],
                [23.860174535539187, 50.01340031149985],
                [23.860174535539187, 54.54925284764431],
                [14.262808745410865, 54.54925284764431],
            ],
        ],
        type: "Polygon",
    },
};
