<ng-container [formGroup]="missionTypeForm">
    <mat-slide-toggle *ngIf="shouldDisplayCheckinFilter$ | ngrxPush" formControlName="checkin" labelPosition="before">
        <dtm-ui-icon name="map-pin"></dtm-ui-icon>
    </mat-slide-toggle>
    <mat-slide-toggle formControlName="vlos" labelPosition="before">
        <dtm-ui-icon name="eye"></dtm-ui-icon>
    </mat-slide-toggle>
    <mat-slide-toggle formControlName="bvlos" labelPosition="before">
        <dtm-ui-icon name="eye-off"></dtm-ui-icon>
    </mat-slide-toggle>
</ng-container>
