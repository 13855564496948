<div class="filters" [formGroup]="filterForm">
    <h6 class="header">{{ "dtmSupPlannedMissions.otherPlannedMissionsFilters.filtersHeader" | transloco }}</h6>
    <dtm-ui-select-field
        formControlName="missionType"
        multiple
        [placeholder]="'dtmSupPlannedMissions.otherPlannedMissionsFilters.missionTypePlaceholder' | transloco"
    >
        <dtm-ui-select-option *ngFor="let option of MissionType | keyvalue" [value]="option.key">
            {{ option.key }}
        </dtm-ui-select-option>
    </dtm-ui-select-field>
    <dtm-ui-select-field
        formControlName="executionTime"
        [placeholder]="'dtmSupPlannedMissions.otherPlannedMissionsFilters.missionTimePlaceholder' | transloco"
    >
        <dtm-ui-select-option *ngFor="let option of ExecutionTime | keyvalue" [value]="option.key">
            {{ "dtmSupPlannedMissions.otherPlannedMissionsFilters.executionTimeValue" | transloco : { value: option.key } }}
        </dtm-ui-select-option>
    </dtm-ui-select-field>
    <dtm-ui-select-field
        formControlName="missionArea"
        [placeholder]="'dtmSupPlannedMissions.otherPlannedMissionsFilters.areaPlaceholder' | transloco"
    >
        <dtm-ui-select-option *ngFor="let option of MissionArea | keyvalue" [value]="option.value">
            {{ "dtmSupPlannedMissions.otherPlannedMissionsFilters.missionAreaValue" | transloco : { value: option.key } }}
        </dtm-ui-select-option>
    </dtm-ui-select-field>
</div>
