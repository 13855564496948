<mat-card
    *ngrxLet="{
        areFiltersSet: areFiltersSet$,
        missions: missions$,
        areResultsTooLarge: areResultsTooLarge$,
        isResultsListVisible: isResultsListVisible$,
        selectedMission: selectedMission$,
        isProcessing: isProcessing$,
        isMissionZoomButtonVisible: isMissionZoomButtonVisible$
    } as vm"
>
    <dtm-ui-loader-container [isShown]="vm.isProcessing">
        <dtm-ui-card-header>
            {{ "dtmSharedMissionSearch.listContainer.missionsListHeader" | transloco }}
            <ng-container *ngIf="vm.isResultsListVisible" additionalActions>
                <button type="button" class="button-primary" (click)="filterConditionsOpen.emit()">
                    {{ "dtmSharedMissionSearch.listContainer.editFilterButtonLabel" | transloco }}
                </button>
            </ng-container>
        </dtm-ui-card-header>

        <dtm-ui-empty-state [mode]="EmptyStateMode.ListView" *ngIf="!vm.areFiltersSet">
            <ng-container titleSlot>
                {{ "dtmSharedMissionSearch.listContainer.noFilters.title" | transloco }}
            </ng-container>
            <ng-container messageSlot>
                {{ "dtmSharedMissionSearch.listContainer.noFilters.message" | transloco }}
            </ng-container>
            <img imageSlot src="assets/images/mission-search-no-filter.svg" alt="" />
            <button footerSlot type="button" class="button-primary" (click)="filterConditionsOpen.emit()">
                {{ "dtmSharedMissionSearch.listContainer.noFilters.buttonLabel" | transloco }}
            </button>
        </dtm-ui-empty-state>

        <dtm-ui-empty-state [mode]="EmptyStateMode.ListView" *ngIf="vm.areFiltersSet && !vm.missions.length">
            <ng-container titleSlot>
                {{ "dtmSharedMissionSearch.listContainer.emptyState.title" | transloco }}
            </ng-container>
            <ng-container messageSlot>
                {{ "dtmSharedMissionSearch.listContainer.emptyState.message" | transloco }}
            </ng-container>
            <img imageSlot src="assets/images/mission-search-empty-state.svg" alt="" />
            <button footerSlot type="button" class="button-primary" (click)="filterConditionsOpen.emit()">
                {{ "dtmSharedMissionSearch.listContainer.editFilterButtonLabel" | transloco }}
            </button>
        </dtm-ui-empty-state>

        <dtm-ui-empty-state [mode]="EmptyStateMode.ListView" *ngIf="vm.areFiltersSet && vm.areResultsTooLarge">
            <ng-container titleSlot>
                {{ "dtmSharedMissionSearch.listContainer.tooLargeResults.title" | transloco }}
            </ng-container>
            <ng-container messageSlot>
                {{ "dtmSharedMissionSearch.listContainer.tooLargeResults.message" | transloco }}
            </ng-container>
            <img imageSlot src="assets/images/mission-search-empty-state.svg" alt="" />
            <button footerSlot type="button" class="button-primary" (click)="filterConditionsOpen.emit()">
                {{ "dtmSharedMissionSearch.listContainer.editFilterButtonLabel" | transloco }}
            </button>
        </dtm-ui-empty-state>

        <section *ngIf="vm.isResultsListVisible" class="result-container">
            <dtm-ui-select-field class="list-sort" [formControl]="sortByControl" [isClearable]="false">
                <label>
                    {{ "dtmSharedMissionSearch.listContainer.sortByLabel" | transloco }}
                </label>
                <dtm-ui-select-option *ngFor="let sortBy of sortingOptions" [value]="sortBy">
                    {{ "dtmSharedMissionSearch.listContainer.sortByValueLabel" | transloco : { value: sortBy } }}
                </dtm-ui-select-option>
            </dtm-ui-select-field>
            <strong class="missions-amount">
                {{ "dtmSharedMissionSearch.listContainer.missionsAmountLabel" | transloco : { amount: vm.missions.length } }}
            </strong>
            <div class="visibility-switch" *ngIf="!isMapFeatureDisabled">
                <span>{{ "dtmSharedMissionSearch.listContainer.showResultsOnMapLabel" | transloco }}</span>
                <mat-slide-toggle [formControl]="areMissionsVisibleOnMapControl"></mat-slide-toggle>
            </div>
            <ul class="missions-list scroll-shadows">
                <li *ngFor="let mission of vm.missions">
                    <dtm-mission-mission-search-mission-item
                        [mission]="mission"
                        [isSelected]="isMapFeatureDisabled ? false : vm.selectedMission?.id === mission.id"
                        [isMissionZoomButtonVisible]="isMapFeatureDisabled ? false : vm.isMissionZoomButtonVisible"
                        (detailsShow)="detailsShow.emit(mission)"
                        (missionZoom)="missionZoom.emit(mission)"
                        (click)="isMapFeatureDisabled ? detailsShow.emit(mission) : selectMission(mission)"
                    ></dtm-mission-mission-search-mission-item>
                </li>
            </ul>
        </section>
    </dtm-ui-loader-container>
</mat-card>
