<ng-container *ngrxLet="deviceHistoryList$ as deviceHistoryList">
    <h2 class="header">{{ "dtmSupNetworkMonitoring.eventHistoryList.eventHistoryLabel" | transloco }}</h2>
    ({{ deviceHistoryList.length }})
    <section class="list">
        <dtm-ui-select-field [isClearable]="false" [formControl]="deviceControl">
            <label>{{ "dtmSupNetworkMonitoring.eventHistoryList.deviceLabel" | transloco }}</label>
            <dtm-ui-select-option *ngFor="let device of mountedDevices$ | ngrxPush; let index = index" [value]="device.id">
                {{ index + 1 }} ({{ device.id }})
            </dtm-ui-select-option>
        </dtm-ui-select-field>
        <ng-container *ngIf="!deviceHistoryList.length; else listTemplate">
            <div class="no-history">{{ "dtmSupNetworkMonitoring.eventHistoryList.noHistoryInfo" | transloco }}</div>
        </ng-container>
        <ng-template #listTemplate>
            <dtm-ui-loader-container [isShown]="isProcessing$ | ngrxPush">
                <supervisor-shared-lib-event-tile
                    *ngFor="let deviceHistory of deviceHistoryList"
                    [deviceHistory]="deviceHistory"
                    (formSave)="formSave.emit($event)"
                ></supervisor-shared-lib-event-tile>
                <div class="bottom-section" *ngIf="!(isDeviceHistoryFullyLoaded$ | ngrxPush)">
                    <button class="button-tertiary" type="button" (click)="contentLoad.emit(deviceControl.value!)">
                        {{
                            "dtmSupNetworkMonitoring.eventHistoryList.loadMoreButtonLabel"
                                | transloco : { value: DEVICE_VIOLATION_HISTORY_DEFAULT_ELEMENTS_NUMBER }
                        }}
                    </button>
                </div>
            </dtm-ui-loader-container>
        </ng-template>
    </section>
</ng-container>
