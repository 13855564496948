import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { GeoJSON } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { map } from "rxjs";
import { TacticalMissionUtils } from "../../../shared/utils/tactical-mission-utils";
import { Checkin, CheckinStatus } from "../../models/operational.situation.models";

interface CheckinTileComponentState {
    checkin: Checkin | undefined;
    isExpanded: boolean;
    isSelected: boolean;
    missionCount: number | undefined;
}

const NUMBER_OF_ID_CHARACTERS = 5;

@Component({
    selector: "supervisor-shared-lib-checkin-tile",
    templateUrl: "./checkin-tile.component.html",
    styleUrls: ["./checkin-tile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class CheckinTileComponent {
    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }
    @Input() public set isSelected(value: BooleanInput) {
        this.localStore.patchState({ isSelected: coerceBooleanProperty(value) });
    }
    @Input() public set checkin(value: Checkin | undefined) {
        this.localStore.patchState({ checkin: value });
    }
    @Input() public set missionCount(value: number | undefined) {
        this.localStore.patchState({ missionCount: value });
    }

    @Output() public readonly checkinSelect = new EventEmitter<Checkin>();
    @Output() public readonly checkinZoom = new EventEmitter<GeoJSON>();

    protected readonly checkin$ = this.localStore.selectByKey("checkin").pipe(RxjsUtils.filterFalsy());
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly isSelected$ = this.localStore.selectByKey("isSelected");
    protected readonly missionCount$ = this.localStore.selectByKey("missionCount");
    protected readonly missionTimeRange$ = this.localStore.selectByKey("checkin").pipe(
        RxjsUtils.filterFalsy(),
        map(({ flightStartAt, flightFinishAt }) => ({ flightStartAt, flightFinishAt, isPathBased: false }))
    );

    protected readonly CheckinStatus = CheckinStatus;

    constructor(private readonly localStore: LocalComponentStore<CheckinTileComponentState>) {
        this.localStore.setState({
            checkin: undefined,
            isExpanded: false,
            isSelected: false,
            missionCount: undefined,
        });
    }

    protected expandContent(): void {
        this.localStore.patchState(({ isExpanded }) => ({ isExpanded: !isExpanded }));
    }

    protected selectCheckin(mission: Checkin) {
        if (this.localStore.selectSnapshotByKey("isSelected")) {
            return;
        }

        this.checkinSelect.emit(mission);
    }

    protected displayShortId(id: string): string {
        return TacticalMissionUtils.getShortId(id, NUMBER_OF_ID_CHARACTERS);
    }
}
