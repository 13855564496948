import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MissionType } from "@dtm-frontend/shared/mission";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ExecutionTime, MissionArea, NearbyMissionFilters } from "../../../../models/mission.models";

interface FiltersForm {
    missionType: FormControl<MissionType[] | null>;
    executionTime: FormControl<ExecutionTime | null>;
    missionArea: FormControl<MissionArea | null>;
}

@UntilDestroy()
@Component({
    selector: "supervisor-shared-lib-other-planned-mission-filters[defaultFilters]",
    templateUrl: "./nearby-missions-filters.component.html",
    styleUrls: ["./nearby-missions-filters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NearbyMissionsFiltersComponent {
    @Input() public set defaultFilters(value: NearbyMissionFilters | undefined) {
        if (!value) {
            return;
        }

        this.filterForm.patchValue({ ...value }, { emitEvent: false });
    }

    @Output() public readonly filtersChange = new EventEmitter<NearbyMissionFilters>();

    protected readonly MissionType = MissionType;
    protected readonly MissionArea = MissionArea;
    protected readonly ExecutionTime = ExecutionTime;

    protected filterForm = new FormGroup<FiltersForm>({
        missionType: new FormControl(null),
        executionTime: new FormControl(null),
        missionArea: new FormControl(null),
    });

    constructor() {
        this.filterForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.filtersChange.emit(this.filterForm.getRawValue() as NearbyMissionFilters);
        });
    }
}
