import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

export interface MissionTypeFilters {
    vlos: boolean;
    bvlos: boolean;
    checkin?: boolean;
}

interface MissionTypeFilterComponentState {
    shouldDisplayCheckinFilter: boolean;
}

@UntilDestroy()
@Component({
    selector: "supervisor-shared-lib-mission-type-filter",
    templateUrl: "./mission-type-filter.component.html",
    styleUrls: ["./mission-type-filter.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionTypeFilterComponent {
    @Input() public set defaultFilters(value: MissionTypeFilters | undefined) {
        if (value) {
            this.missionTypeForm.patchValue({ ...value }, { emitEvent: false });
        }
    }
    @Input() public set shouldDisplayCheckinFilter(value: BooleanInput) {
        this.localStore.patchState({ shouldDisplayCheckinFilter: coerceBooleanProperty(value) });
    }

    @Output() public readonly filterChanged = new EventEmitter<MissionTypeFilters>();

    protected readonly shouldDisplayCheckinFilter$ = this.localStore.selectByKey("shouldDisplayCheckinFilter");

    protected readonly missionTypeForm = new FormGroup({
        vlos: new FormControl<boolean>(false),
        bvlos: new FormControl<boolean>(false),
        checkin: new FormControl<boolean>(false),
    });

    constructor(private readonly localStore: LocalComponentStore<MissionTypeFilterComponentState>) {
        this.localStore.setState({ shouldDisplayCheckinFilter: false });

        this.missionTypeForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.filterChanged.emit(this.missionTypeForm.getRawValue() as MissionTypeFilters);
        });
    }
}
