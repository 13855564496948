import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FILES_UPLOAD_API_PROVIDER, UploadedFile } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { map } from "rxjs";
import { Mission, MissionAttachment } from "../../models/mission.models";
import { MissionAttachmentsService } from "../../services/files/mission-attachments.service";

interface PilotRequestComponentState {
    mission: Mission | undefined;
}

@Component({
    selector: "supervisor-shared-lib-pilot-request[mission]",
    templateUrl: "./pilot-request.component.html",
    styleUrls: ["./pilot-request.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, { provide: FILES_UPLOAD_API_PROVIDER, useClass: MissionAttachmentsService }],
})
export class PilotRequestComponent {
    @Input() public set mission(value: Mission | undefined) {
        this.localStore.patchState({ mission: value });
    }

    @Output() protected readonly attachmentDownload = new EventEmitter<string>();

    protected readonly pilotRequest$ = this.localStore.selectByKey("mission").pipe(
        map((mission) => ({
            ...mission?.pilotRequest,
            createdAt: mission?.createdDate,
            attachmentList: this.convertMissionAttachmentsToUploadFiles(mission?.pilotRequest?.attachmentList),
        })),
        RxjsUtils.filterFalsy()
    );

    constructor(private readonly localStore: LocalComponentStore<PilotRequestComponentState>) {
        this.localStore.setState({ mission: undefined });
    }

    private convertMissionAttachmentsToUploadFiles(attachmentList: MissionAttachment[] | undefined): UploadedFile[] {
        return attachmentList
            ? attachmentList.map((attachment) => ({
                  id: attachment.fileId,
                  name: attachment.name,
              }))
            : [];
    }
}
