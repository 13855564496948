<ng-container *ngrxLet="{ installations: installations$, selectedTile: selectedTile$ } as vm">
    <div *ngFor="let installation of vm.installations">
        <div class="installations">
            <supervisor-shared-lib-installation-tile
                class="dtm"
                [name]="installation.dtmName"
                [status]="installation.aerials | invoke : getDtmStatus"
                [selected]="vm.selectedTile === installation.dtmName"
                [shouldDisplayIcon]="false"
                reverseName
                bolderName
                (click)="selectItem(installation.dtmName)"
            >
            </supervisor-shared-lib-installation-tile>
            <dtm-ui-icon name="arrow-right-2"></dtm-ui-icon>
            <supervisor-shared-lib-installation-tile
                *ngFor="let aerial of installation.aerials"
                [name]="aerial.name"
                [status]="aerial.sectors | invoke : getSectorStatuses"
                [selected]="vm.selectedTile === aerial.id"
                (click)="selectItem(aerial.id)"
            >
            </supervisor-shared-lib-installation-tile>
        </div>
    </div>
</ng-container>
