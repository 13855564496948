import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import {
    AZURE_MAPS_LAYER_OPTIONS,
    CameraHelperService,
    DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS,
    FlightPositionUpdaterService,
    ViewControl,
} from "@dtm-frontend/shared/map/cesium";
import { TimeSettingOptions, ZoneTimesSetting } from "@dtm-frontend/shared/map/geo-zones";
import { FlightMissionPhase, GeoJSON, MissionPlanRoute, RouteAreaTypeId, RouteData, TimeRange, Trajectory } from "@dtm-frontend/shared/ui";
import { MissionData, Violation } from "@dtm-frontend/shared/ui/tactical";
import { FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AcMapComponent, MapLayerProviderOptions, SceneMode, ViewerConfiguration } from "@pansa/ngx-cesium";
import turfBbox from "@turf/bbox";
import { BBox, Polygon, Properties, feature, featureCollection } from "@turf/helpers";
import { Observable, distinctUntilKeyChanged, filter, firstValueFrom, map, startWith } from "rxjs";
import { Checkin, MissionStatus, ProceedingMission } from "../../../operational-situation/models/operational.situation.models";
import { Mission } from "../../../planned-missions/models/mission.models";
import { dtmBboxFeaturesMap } from "../../utils/dtm-bbox-features-map";

/* eslint-disable @typescript-eslint/no-explicit-any*/
declare const Cesium: any; // TODO: DTM-966

// TODO: DTM-1654 - this will change when API will be ready
const temporaryMockInitialViewData = dtmBboxFeaturesMap.get("DTM Nadarzyn");

interface MissionMapComponentState {
    routeData: RouteData<MissionDataType> | undefined;
    isProcessing: boolean;
    isZoneProcessing: boolean;
    isOtherMissionPanelFolded: boolean;
    trajectories: Map<string, Trajectory[]>;
    violations: Map<string, Violation | undefined> | undefined;
    shouldZoomOnRouteUpdate: boolean;
    area: GeoJSON | undefined;
    checkin: Checkin | undefined;
    checkins: Checkin[] | undefined;
    zoneTimeSettingOptions: TimeSettingOptions | undefined;
    isMeasureToolActive: boolean;
    isMeasureToolEnabled: boolean;
    selectedMission: ProceedingMission | undefined;
    missions: MissionLists | undefined;
    availableZoneDesignators: string[] | undefined;
    isDtmPanelVisible: boolean;
}

type MissionDataType = MissionData | Mission;

const VIEW_CONTROLS: ViewControl[] = [ViewControl.Path, ViewControl.SafetyArea, ViewControl.GroundRiskBuffer];
const VIEW_CONTROLS_DEFAULT_VALUES: Partial<Record<ViewControl, boolean>> = {
    [ViewControl.Path]: true,
    [ViewControl.SafetyArea]: true,
    [ViewControl.GroundRiskBuffer]: true,
};

export interface MissionLists {
    incomingMissions: ProceedingMission[] | undefined;
    proceedingMissions: ProceedingMission[] | undefined;
    finishedMissions: ProceedingMission[] | undefined;
}

@UntilDestroy()
@Component({
    selector: "supervisor-shared-lib-mission-map[routeData][initialViewGeometry]",
    templateUrl: "./mission-map.component.html",
    styleUrls: ["./mission-map.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionMapComponent implements AfterViewInit {
    @Input()
    public set routeData(value: RouteData<MissionDataType> | undefined) {
        this.localStore.patchState({ routeData: value });
    }
    @Input()
    public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input()
    public set isZoneProcessing(value: BooleanInput) {
        this.localStore.patchState({ isZoneProcessing: coerceBooleanProperty(value) });
    }
    @Input()
    public set zoomToRoute(value: RouteData<MissionDataType> | undefined) {
        this.zoomToRouteArea(value);
    }
    @Input()
    public set isOtherMissionPanelFolded(value: BooleanInput) {
        this.localStore.patchState({ isOtherMissionPanelFolded: coerceBooleanProperty(value) });
    }
    @Input()
    public set trajectories(value: Map<string, Trajectory[]> | undefined) {
        this.localStore.patchState({ trajectories: value ?? new Map<string, Trajectory[]>() });
    }
    @Input()
    public set violations(value: Map<string, Violation | undefined> | undefined) {
        this.localStore.patchState({ violations: value });
    }
    @Input()
    public set initialViewGeometry(value: GeoJSON | undefined) {
        this.setInitialViewbox(value);
    }
    @Input()
    public set shouldZoomOnRouteUpdate(value: BooleanInput) {
        this.localStore.patchState({ shouldZoomOnRouteUpdate: coerceBooleanProperty(value) });
    }
    @Input() public set area(value: GeoJSON | undefined) {
        this.localStore.patchState({ area: value });
    }
    @Input() public set checkin(value: Checkin | undefined) {
        this.localStore.patchState({ checkin: value });
    }
    @Input() public set checkins(value: Checkin[] | undefined) {
        this.localStore.patchState({ checkins: value });
    }
    @Input() public set zoneTimeSettingOptions(value: TimeSettingOptions | undefined) {
        this.localStore.patchState({ zoneTimeSettingOptions: value });
    }
    @Input() public set isMeasureToolEnabled(value: BooleanInput) {
        this.localStore.patchState({ isMeasureToolEnabled: coerceBooleanProperty(value) });
    }
    @Input() public set selectedMission(value: ProceedingMission | undefined) {
        this.localStore.patchState({ selectedMission: value });
    }
    @Input() public set missions(value: MissionLists) {
        this.localStore.patchState({ missions: value ?? [] });
    }
    @Input() public set availableZoneDesignators(value: string[] | undefined) {
        this.localStore.patchState({ availableZoneDesignators: value });
    }
    @Input() public set isDtmPanelVisible(value: BooleanInput) {
        this.localStore.patchState({ isDtmPanelVisible: coerceBooleanProperty(value) });
    }

    @Output()
    public visibleAreaChanged: EventEmitter<BBox> = new EventEmitter();
    @Output()
    public readonly flightPositionUpdatesEnrich = new EventEmitter<string>();
    @Output()
    public readonly missionSelected = new EventEmitter<ProceedingMission | undefined>();

    @ViewChild(AcMapComponent) private readonly acMap: AcMapComponent | undefined;

    protected readonly MapLayerProviderOptions = MapLayerProviderOptions;
    protected readonly AZURE_MAPS_LAYER_OPTIONS = AZURE_MAPS_LAYER_OPTIONS;
    protected readonly VIEW_CONTROLS = VIEW_CONTROLS;
    protected readonly VIEW_CONTROLS_DEFAULT_VALUES = VIEW_CONTROLS_DEFAULT_VALUES;
    protected readonly ZoneTimesSetting = ZoneTimesSetting;

    protected readonly routeData$ = this.localStore.selectByKey("routeData");
    protected readonly trajectories$ = this.localStore.selectByKey("trajectories");
    protected readonly violations$ = this.localStore.selectByKey("violations");
    protected readonly area$ = this.localStore.selectByKey("area");
    protected readonly checkin$ = this.localStore.selectByKey("checkin");
    protected readonly checkins$ = this.localStore.selectByKey("checkins");
    protected readonly missionTimeRange$: Observable<TimeRange | undefined> = this.initMissionTimeRange();
    protected readonly zoneTimeSettingOptions$ = this.localStore.selectByKey("zoneTimeSettingOptions");
    protected readonly isMeasureToolActive$ = this.localStore.selectByKey("isMeasureToolActive");
    protected readonly isMeasureToolEnabled$ = this.localStore.selectByKey("isMeasureToolEnabled");
    protected readonly isDtmPanelVisible$ = this.localStore.selectByKey("isDtmPanelVisible");

    protected readonly isPathVisibleControl = new FormControl(true);

    protected zoneSettingsFormControls: Partial<Record<RouteAreaTypeId, FormControl>> = {
        flightArea: new FormControl(true),
        path: this.isPathVisibleControl,
    };
    protected readonly drawingSettingsForm = new FormGroup(this.zoneSettingsFormControls);
    protected readonly drawableFeatures$ = this.initDrawableFeatures();
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly isZoneProcessing$ = this.localStore.selectByKey("isZoneProcessing");
    protected readonly selectedMission$ = this.localStore.selectByKey("selectedMission");
    protected readonly missions$ = this.localStore.selectByKey("missions");
    protected readonly availableZoneDesignators$ = this.localStore.selectByKey("availableZoneDesignators");

    protected readonly flights$ = this.flightPositionUpdater.flightPositionUpdate$.pipe(startWith(undefined));

    constructor(
        viewerConfiguration: ViewerConfiguration,
        protected readonly localStore: LocalComponentStore<MissionMapComponentState>,
        private readonly cameraHelperService: CameraHelperService,
        private readonly flightPositionUpdater: FlightPositionUpdaterService
    ) {
        localStore.setState({
            routeData: undefined,
            isProcessing: true,
            isOtherMissionPanelFolded: false,
            trajectories: new Map(),
            violations: undefined,
            shouldZoomOnRouteUpdate: false,
            area: undefined,
            checkin: undefined,
            isZoneProcessing: false,
            zoneTimeSettingOptions: undefined,
            checkins: undefined,
            isMeasureToolActive: false,
            isMeasureToolEnabled: true,
            selectedMission: undefined,
            missions: undefined,
            availableZoneDesignators: undefined,
            isDtmPanelVisible: false,
        });
        viewerConfiguration.viewerOptions = {
            ...DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS,
            sceneMode: SceneMode.SCENE3D, // TODO:  DTM-4517 Change to SCENE2D when fixed
        };

        this.handleZoomOnDataChanges();
    }

    public ngAfterViewInit(): void {
        // TODO:  DTM-4517 remove when fixed
        if (this.acMap) {
            this.acMap.getCesiumService().getViewer().scene.screenSpaceCameraController.enableTilt = false;
        }
    }

    protected getViolationByTrackerId(
        violations: Map<string, Violation | undefined> | undefined,
        trackerId: string
    ): Violation | undefined {
        return violations?.get(trackerId);
    }

    protected getRoutesFromRouteData(data?: RouteData<MissionDataType>): MissionPlanRoute[] {
        return [data?.route, data?.nearbyMissionsData?.map(({ route }) => route)].flat().filter(FunctionUtils.isTruthy);
    }

    protected updateMeasureToolStatus(isActive: boolean) {
        this.localStore.patchState({ isMeasureToolActive: isActive });
    }

    protected async selectMission(missionId: string | undefined) {
        const {
            incomingMissions = [],
            proceedingMissions = [],
            finishedMissions = [],
        } = (await firstValueFrom(this.missions$.pipe(untilDestroyed(this)))) ?? {};
        const selectedMission = [...proceedingMissions, ...incomingMissions, ...finishedMissions].find(
            (mission) => mission.missionId === missionId
        );
        this.localStore.patchState({ selectedMission });
        this.missionSelected.emit(selectedMission);
    }

    protected getFlightInfo(
        trackerId: string,
        missions: MissionLists | undefined,
        selectedMission: ProceedingMission | undefined
    ): { name?: string; phase?: FlightMissionPhase; isSelected?: boolean; id?: string } {
        const proceedingMissions = missions?.proceedingMissions ?? [];
        let connectedMission = proceedingMissions.find((mission) => mission.trackerId === trackerId);
        const hasMatchingTracker = trackerId === selectedMission?.trackerId;
        if (connectedMission) {
            return {
                name: connectedMission.supMissionIdentifier,
                phase: connectedMission.missionStatus === MissionStatus.Activated ? FlightMissionPhase.Active : FlightMissionPhase.Started,
                isSelected: hasMatchingTracker && connectedMission.missionId === selectedMission?.missionId,
                id: connectedMission.missionId,
            };
        }

        connectedMission = missions?.incomingMissions?.find((mission) => mission.trackerId === trackerId);
        if (connectedMission) {
            return {
                name: connectedMission.supMissionIdentifier,
                phase: FlightMissionPhase.Incoming,
                isSelected: hasMatchingTracker && connectedMission.missionId === selectedMission?.missionId,
                id: connectedMission.missionId,
            };
        }

        // NOTE: for finished missions we want to connect the last finished one
        connectedMission = [...(missions?.finishedMissions ?? [])].reverse().find((mission) => mission.trackerId === trackerId);
        if (connectedMission) {
            return {
                name: connectedMission.supMissionIdentifier,
                phase: FlightMissionPhase.Finished,
                isSelected: hasMatchingTracker && connectedMission.missionId === selectedMission?.missionId,
                id: connectedMission.missionId,
            };
        }

        return {};
    }

    private handleZoomOnDataChanges() {
        this.routeData$
            .pipe(
                filter(() => this.localStore.selectSnapshotByKey("shouldZoomOnRouteUpdate")),
                RxjsUtils.filterFalsy(),
                distinctUntilKeyChanged("uniqueRouteId"),
                map((data) => this.zoomToRouteArea(data))
            )
            .pipe(untilDestroyed(this))
            .subscribe();
    }

    private zoomToRouteArea(data?: RouteData<MissionDataType>) {
        const zoomArea = data?.route.sections
            .map(({ segment, flightZone }) => flightZone?.safetyArea.volume.area ?? segment?.safetyArea.volume.area)
            .filter(FunctionUtils.isTruthy)
            .map((polygon) => feature(polygon));

        if (!zoomArea?.length) {
            return;
        }

        const isOtherMissionPanelFolded = this.localStore.selectSnapshotByKey("isOtherMissionPanelFolded");
        const panelXOffset = 400;

        this.cameraHelperService.flyToGeoJSON(
            featureCollection<Polygon, Properties>(zoomArea),
            undefined,
            undefined,
            isOtherMissionPanelFolded ? 0 : panelXOffset
        );
    }

    private setInitialViewbox(initialGeometry?: GeoJSON) {
        const bbox = turfBbox(initialGeometry ?? temporaryMockInitialViewData);

        Cesium.Camera.DEFAULT_VIEW_FACTOR = 0.01;
        Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(...bbox);
        // TODO:  DTM-4517 remove when fixed
        Cesium.Camera.DEFAULT_OFFSET = new Cesium.HeadingPitchRange(0, -Cesium.Math.PI_OVER_TWO, 0);

        return Cesium.Camera.DEFAULT_VIEW_RECTANGLE;
    }

    private initDrawableFeatures(): Observable<RouteAreaTypeId[]> {
        return this.drawingSettingsForm.valueChanges.pipe(
            startWith(null),
            map(() =>
                Object.entries(this.drawingSettingsForm.value)
                    .filter(([, value]) => value)
                    .map(([key]) => key as RouteAreaTypeId)
            )
        );
    }

    private initMissionTimeRange() {
        return this.localStore.selectByKey("routeData").pipe(
            map((route) => {
                const mission = route?.data;
                if (!mission) {
                    return undefined;
                }

                if ("startTime" in mission) {
                    return {
                        min: mission.startTime.min,
                        max: mission.endTime.max,
                    };
                }

                if ("flightStartAtMin" in mission) {
                    return {
                        min: mission.flightStartAtMin,
                        max: mission.flightStartAtMax,
                    };
                }

                return undefined;
            })
        );
    }
}
