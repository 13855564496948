<div
    class="event-tile"
    [class.has-note]="vm.deviceHistory.note"
    *ngrxLet="{ deviceHistory: deviceHistory$, isFormVisible: isFormVisible$ } as vm"
>
    <div class="violation-start">
        <div class="violation-label">
            {{ vm.deviceHistory.startedAt | localizeDate : { dateStyle: "short", timeStyle: "short" } }}
        </div>
        <div class="violation-start-value">
            {{
                "dtmSupNetworkMonitoring.eventTile.violationStartValueSelect" | transloco : { value: vm.deviceHistory.signalViolationType }
            }}
        </div>
    </div>
    <div class="violation-end">
        <div class="violation-label">
            {{ vm.deviceHistory.finishedAt | localizeDate : { dateStyle: "short", timeStyle: "short" } }}
        </div>
        <div class="violation-end-value" *ngIf="vm.deviceHistory.finishedAt">
            <ng-container *ngIf="!vm.deviceHistory.note; else signalReturnTemplate">
                {{ "dtmSupNetworkMonitoring.eventTile.signalReturnValue" | transloco : { value: vm.deviceHistory.violationTimeInMinutes } }}
            </ng-container>
        </div>
    </div>
    <div class="time-out" *ngIf="vm.deviceHistory.note && vm.deviceHistory.finishedAt">
        <div class="violation-label">{{ "dtmSupNetworkMonitoring.eventTile.timeoutLabel" | transloco }}</div>
        {{ "dtmSupNetworkMonitoring.eventTile.timeoutTime" | transloco : { value: vm.deviceHistory.violationTimeInMinutes } }}
    </div>
    <div class="add-note">
        <button
            type="button"
            class="button-icon"
            (click)="changeFormVisibility()"
            [matTooltip]="'dtmSupNetworkMonitoring.eventTile.addNoteTooltip' | transloco"
            *ngIf="!vm.isFormVisible && !vm.deviceHistory.note"
        >
            <dtm-ui-icon name="article"></dtm-ui-icon>
        </button>
    </div>
    <ng-container *ngIf="vm.deviceHistory.note">
        <section class="note">
            <div class="reporter">
                <div class="date">
                    <dtm-ui-icon name="calendar-event"></dtm-ui-icon>
                    {{ vm.deviceHistory.note.modifiedAt | localizeDate : { dateStyle: "short" } }}
                </div>
                <div class="time">
                    <dtm-ui-icon name="time"></dtm-ui-icon>
                    {{ vm.deviceHistory.note.modifiedAt | localizeDate : { timeStyle: "short" } }}
                </div>
                <div class="person" *ngIf="!vm.isFormVisible">
                    <dtm-ui-icon name="user"></dtm-ui-icon>
                    {{ vm.deviceHistory.note.supervisorName }}
                </div>
            </div>
        </section>
        <ng-container *ngIf="!vm.isFormVisible">
            <div class="description">
                <dtm-ui-icon name="article"></dtm-ui-icon>
                {{ vm.deviceHistory.note.content }}
            </div>
            <div class="edit-note">
                <button type="button" class="button-icon" (click)="changeFormVisibility()">
                    <dtm-ui-icon name="edit"></dtm-ui-icon>
                </button>
            </div>
        </ng-container>
    </ng-container>
    <div class="note-form" *ngIf="vm.isFormVisible" [formGroup]="noteForm">
        <dtm-ui-input-field>
            <label>{{ "dtmSupNetworkMonitoring.eventTile.userLabel" | transloco }}</label>
            <input formControlName="supervisorName" matInput type="text" />
            <div class="field-error" *dtmUiFieldHasError="noteForm.controls.supervisorName; name: 'required'">
                {{ "dtmSupNetworkMonitoring.eventTile.requiredFieldError" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="noteForm.controls.supervisorName; name: 'maxlength'; error as error">
                {{ "dtmSupNetworkMonitoring.eventTile.maxUserLengthErrorHint" | transloco : { max: error.requiredLength } }}
            </div>
        </dtm-ui-input-field>
        <dtm-ui-textarea-field [maxLength]="NOTE_MAX_LENGTH">
            <label>{{ "dtmSupNetworkMonitoring.eventTile.noteLabel" | transloco }}</label>
            <textarea formControlName="note" matInput></textarea>
            <div class="field-error" *dtmUiFieldHasError="noteForm.controls.note; name: 'maxlength'; error as error">
                {{ "dtmSupNetworkMonitoring.eventTile.maxNoteLengthErrorHint" | transloco : { max: error.requiredLength } }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="noteForm.controls.note; name: 'required'">
                {{ "dtmSupNetworkMonitoring.eventTile.requiredFieldError" | transloco }}
            </div>
        </dtm-ui-textarea-field>
        <div class="buttons">
            <button type="button" class="button-primary" (click)="saveForm()">
                {{ "dtmSupNetworkMonitoring.eventTile.saveButtonLabel" | transloco }}
            </button>
            <button type="button" class="button-secondary" (click)="changeFormVisibility()">
                {{ "dtmSupNetworkMonitoring.eventTile.cancelButtonLabel" | transloco }}
            </button>
        </div>
    </div>
</div>
<ng-template #signalReturnTemplate>
    {{ "dtmSupNetworkMonitoring.eventTile.signalReturn" | transloco }}
</ng-template>
